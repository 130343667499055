import React, {
    useState,
    useEffect,
    useRef,
    useReducer,
    Fragment,
  } from "react";
  import Table from "react-bootstrap/Table";
  import { fragment } from "react";
  import { useLocation } from "react-router-dom";
  
  import Select from "react-select";
  
  import {
    Form,
    Button,
    Row,
    Col,
    Alert,
    Modal,
    Container,
    Spinner,
    Image,
    Badge
  } from "react-bootstrap";
  //import Sidebar2 from "./Sidebar2";
  import SidebarMain from "./SidebarMain";
  
  import {
    BsFillTrashFill,
    BsPencilSquare,
    BsFileRuled,
    BsCardText,
    BsFillPrinterFill
  } from "react-icons/bs";
  
  import "../App.css";
  
  import { useNavigate } from "react-router-dom";
  import axios from "axios";
  import { client } from "../config/Config";
  import spinner_logo from '../assets/images/spinner_logo.png';
  import {formatDateIndian} from "./utils";
  
  
  function ViewCustomerDCRecon() {
    const initialized = useRef(false);
    var navigate = useNavigate();
    var menuSettingsStr = localStorage.getItem("_aP");
    var menuSettings = JSON.parse(menuSettingsStr);

    const currSelBusinessRef = useRef(0);
    const currBranchRef = useRef(0);
    const { state } = useLocation();

    const [checkedState, setCheckedState] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  
    function padTo2Digits(num) {
      //console.log("<<<<<<<<<<<<<< given number >>>>>>>>>>>>>>>> : ", num);
      var modNum = num.toString().padStart(2, "0");
      console.log("Modified  value : ", modNum);
      return modNum;
    }
  
    function formatDate(date) {
      return [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-");
    }
  
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    //var lastDay = new Date(date.getFullYear(), date.getMonth() + 1,
    const intialValues = {
      date_from: formatDate(new Date(firstDay)),
      date_to: formatDate(new Date()),
    }

    let customerArr = [
  
      { value: 1, label: "All Customers" },
      { value: 2, label: "Deppam Motors" },
      { value: 3, label: "ABC Pumps" },
      { value: 4, label: "ADP Compressors" },
    ];
  
    let taxArr = [
      {
      tax_details: "CGST",
       net_sales: "2000",
       tax_collected: "5000",
       sales: "7000",
       net_purchase: "600",
       tax_paid: "1000",
       purchase: "8000",
       tax_liablity: "6700"
      },

      {
      tax_details: "SGST",
       net_sales: "4000",
       tax_collected: "2000",
       sales: "1000",
       net_purchase: "500",
       tax_paid: "3000",
       purchase: "6000",
       tax_liablity: "1000"
      },
      
      
    ];
  
   
    
    
  
    const [item, setItem] = useState([]);
    const [dcList, setDcList] = useState([]);
    const [customerList, setCustomerList] = useState({});
    const [taxSummary, setTaxSummary] = useState([]);
    const [customerIdSelect, setCustomerIdSelect] = useState({});
    const [isSearched, setIsSearched] = useState(false);
    const [optCustomer, setOptCustomer] = useState(customerArr);
    const [toRender, setToRender] = useState(false);
    const [show, setShow] = useState(false);
    const [formValues, setFormValues] = useState(intialValues);
    const [showMsg, setShowMsg] = useState("Please login again");
    const [showMsgTitle, setShowMsgTitle] = useState("Invalid authentication Session!");
   
  
   
    /*
    const getCustomerList = async () => {
      try {
        var aIVal = localStorage.getItem("_aI");
        var headers = { ..
          .client.headers,Authorization: `Bearer ${aIVal}`}
      
  
        var res = await axios.get(
          client.domain + "/crm/getAllCustomers",
          { headers },
          { withCredentials: false }
        );
        console.log("Response from server : ", res.data.detail);
  
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server : ", 0);
          setCustomerList(res.data.detail.data);
          setCustomerListFull(res.data.detail.data);
          setShowMsgTitle("Customer Info Added Successfully");
          setToRender(true);
          //navigate("/dashboard");
          //setDcReportData(res.data.detail.res_data);
          //setToRender(true);
        }
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log(
            "Not able to reach target server please try after sometime"
          );
          setShowMsg("Please try after sometime");
          setShowMsgTitle("Network Issue!");
          setShow(true);
        } else {
          console.log("ERROR occured : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log(
              "Request failed due to ",
              error.response.data.detail.res_data
            );
            setShowMsg("Please try again ...");
            setShowMsgTitle("Unable to process your request!");
            setShow(true);
            //navigate("/auth");
          } else {
            console.log("Non status ERROR code ...");
            setShowMsg(error.response.data.detail);
            setShowMsgTitle("Unable to process your request at this moment!");
            setShow(true);
          }
        }
      }
    };
    */
   
    
  
    const getCustomerDCList = async (indcIdPassed) => {
      var retStateLoad = false;
      var itemRet = [];
      var dcRet = [];
      var customerRet = [];
      try {
        var aIVal = localStorage.getItem("_aT");
        var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
  
        var reqParams = {
          bid: currSelBusinessRef.current,
          brid: currBranchRef.current,
          indc_id: indcIdPassed,
        };
  
        var res = await axios.post(client.domain + "/bms/getCustomerDCRecon", reqParams, { headers }, { withCredentials: false });
        console.log("Response from server : ", res.data.detail);
  
        if (res.data.detail.status === "SUCCESS") {
          console.log("+*_*_***_*_**_*_*__**_*_***** Response message from server : ", res.data.detail);
          setCustomerList(res.data.detail.data.customer_indc);
          setItem(res.data.detail.data.customer_indc_items);
          setDcList(res.data.detail.data.customer_outdc_items)
          
          setToRender(true);
          customerRet = res.data.detail.data.customer_indc;
          itemRet = res.data.detail.data.customer_indc_items;
          dcRet = res.data.detail.data.customer_outdc_items;
          retStateLoad = true;
          
        }
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log( "Not able to reach target server please try after sometime");
          setShowMsg("Please try after sometime");
          setShowMsgTitle("Network Issue!");
          setShow(true);
        } else {
          console.log("ERROR occured : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log( "Request failed due to ",error.response.data.detail.res_data);
            setShowMsg("Please try again ...");
            setShowMsgTitle("Unable to process your request!");
            setShow(true);
            //navigate("/auth");
          } else {
            console.log("Non status ERROR code ...");
            setShowMsg(error.response.data.detail);
            setShowMsgTitle("Unable to process your request at this moment!");
            setShow(true);
          }
        }
      }
      return [retStateLoad, itemRet, dcRet, customerRet];
    };
    
   
   
    useEffect(() => {
      if (!initialized.current) {
        console.log("*************** useEffect::ViewTaxSummary called ##########################");
        //setToRender(true);
        if (state === null) {
          navigate("/viewCustomerINOUTDC");
        } else {
          console.log("PASSED STATE VALUE IN Employee Add/Update page : ", state);
           console.log("Inside else for view or edit ...");
        ( async() => {
          var [retStateLoad, customerRet, dcRet, itemRet] = await getCustomerDCList(state.passed_element.indc_id);
        })();
      }
        //getTaxList();
        setToRender(true);
        initialized.current = true;
       
      }
    }, []);
   /*
    const handleAdd = async (e) => {
      console.log("handle add customers called : ", e);
      navigate("/addUpdateStockIn", {
        state: { passed_element: [], action: "add" },
      });
    };
    */
  
    
    
   
    
    const handleDCPrint = async (e) => {
     //navigate("/printCustomer")
      
      var urlStr = window.location.href;
      var paramsTmp = urlStr.substring(
        urlStr.lastIndexOf(client.urlDelimiter) + 1
      );
      var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));
  
      console.log("CALLED URL : ", urlTmp);
      console.log("CALLED PAGE : ", paramsTmp);
      var idPass = state.passed_element.indc_id; 
  
      var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printCustomerDCRecon?bid=${currSelBusinessRef.current}&brid=${currBranchRef.current}&indc_id=${idPass}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
      winPrint.moveTo(0,0);
      winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
      
      
    };
   
    const onChangeStartDatePicker = (e) => {
      console.log("Date onChange called ...", e);
      console.log("Date onChange DATE called ...", e.target.value);
      setFormValues({ ...formValues, date_from: e.target.value });
    };
  
    const onChangeEndDatePicker = (e) => {
      console.log("Date onChange called ...", e);
      console.log("Date onChange DATE called ...", e.target.value);
      setFormValues({ ...formValues, date_to: e.target.value });
    };
  
  
    const handleSearch = async () => {
      console.log("handleDateSearch called ...");
      // await getTaxList();
  
    };

    const sendDataToParent  = (passedBackValue) => {
      console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
      if ( passedBackValue.hasOwnProperty("business_id") ) {
        currSelBusinessRef.current = passedBackValue["business_id"];
        //navigate(0);
      }
      else if (passedBackValue.hasOwnProperty("branch_id")) {
        currBranchRef.current = passedBackValue["branch_id"];
      }
      else {
        // Future implementation
      }
      //setCurrSelBusinessId(passedBackValue);
      
      //getBranchList();
    };
  
   
    return (
      <Container>
        <SidebarMain sendBacktoParent={sendDataToParent} />
        { toRender ?
        <form>
        <Row>
            <Col xs={10} md={10} sm={10} lg={10}>
              <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.25rem", verticalAlign: "bottom" }}>{menuSettings.permissions.find(element => (element.code === 'viewCustomerINOUTDC' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'viewCustomerINOUTDC')).page_title_detailview : "Customer In OUT DC Recon"}</span>
            </Col>
            
            <Col
              xs={2}
              md={2}
              sm={2}
              lg={2}
              className="d-flex justify-content-end float-end">
              <Button id="dcPrint" name = "dcPrint"  onClick={(e)  => handleDCPrint(e)}
              //disabled={menuSettings.permissions.find(element => (element.code === 'viewCustomerINOUTDC' && element.a_print === true)) ? false : true}
              >
                  <BsFillPrinterFill
                    size={18}
                    style={{ color: "white", cursor: "pointer" }}
                    name={`print-dc-icon`}
                    id={`print-dc-icon`}
                    
                  />
                </Button>
               
                
            </Col>
            </Row>
            <hr align="center" />
            <h4>
              <Badge pill bg="secondary">
                  Inward DC
              </Badge>
            </h4>
            <Row>
            
            <Col xs={2} md={2} sm={2} lg={2}>
              <Form.Label style={{fontWeight: 'bold'}}>
                  Inward DC No:  {customerList.dc_no}
              </Form.Label>
              </Col>
              
            <Col xs={2} md={2} sm={2} lg={2}>
              <Form.Label style={{fontWeight: 'bold'}}>
                  Inward DC Date: {customerList.dc_date}
              </Form.Label>
            </Col>

            <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Label style={{fontWeight: 'bold'}}>
                  Customer: {customerList.customer_name}
              </Form.Label> 
            </Col>

            <Col xs={2} md={2} sm={2} lg={2}>
              <Form.Label style={{fontWeight: 'bold'}}>
                  Customer DC Ref No: {customerList.cust_indc_no}
              </Form.Label>
            </Col>

            <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Label style={{fontWeight: 'bold'}}>
                  Customer DC Date: {customerList.cust_indc_date}
              </Form.Label>
            </Col>
            </Row>
            <h4>
              <Badge pill bg="secondary">
                Inward DC Items
              </Badge>
            </h4>

            <Table striped bordered hover>
                      <thead>
                        <tr className="table-primary">
                          <th className="table-row-heading" >S.No</th>
                          <th className="table-row-heading">Item</th>
                          <th className="table-row-heading">Unit</th>
                          <th className="table-row-heading">Total Qty</th>
                          <th className="table-row-heading">Used Qty</th>
                          <th className="table-row-heading">DC Qty</th>
                          <th className="table-row-heading">Balance Qty</th>
                        </tr>
                      </thead>

                      <tbody>
                      {item.map((item, idx) => (
                      <tr>
                            <td style={{
                                width: "10%",
                                padding: "5px",
                                textAlign: "right",
                              }}>{idx + 1}</td>
                            <td
                              style={{
                                width: "30%",
                                padding: "5px",
                                textAlign: "left",
                              }}
                            >
                              {item.item_name}
                            </td>

                            <td
                              style={{
                                width: "20%",
                                padding: "5px",
                                textAlign: "left",
                              }}
                            >
                              {item.unit}
                            </td>

                            <td
                              style={{
                                width: "15%",
                                padding: "5px",
                                textAlign: "right",
                              }}
                            >
                              {item.qty_total}
                            </td>

                            <td
                              style={{
                                width: "10p%",
                                padding: "5px",
                                textAlign: "right",
                              }}
                            >
                              {item.qty_used.toFixed(2)}
                            </td>

                            <td
                              style={{
                                width: "10p%",
                                padding: "5px",
                                textAlign: "right",
                              }}
                            >
                              {item.qty_yield.toFixed(2)}
                            </td>

                            <td
                              style={{
                                width: "10p%",
                                padding: "5px",
                                textAlign: "right",
                              }}
                            >
                              {item.qty_balance.toFixed(2)}
                            </td>
                            </tr>
                        ))}
                      </tbody>
                    </Table> 

                    <h4>
              <Badge pill bg="secondary">
                Outward DC & Items
              </Badge>
            </h4>

            <Table striped bordered hover>
                      <thead>
                        <tr className="table-primary">
                          <th className="table-row-heading" >S.No</th>
                          <th className="table-row-heading">DC No.</th>
                          <th className="table-row-heading">DC Date</th>
                          <th className="table-row-heading" >Item</th>
                          <th className="table-row-heading">Unit</th>
                          <th className="table-row-heading">DC Qty</th>
                          <th className="table-row-heading">Used Qty</th>
                        </tr>
                      </thead>

                      <tbody>
                      {dcList.map((dc, idx) => (
                      <tr>
                            <td style={{
                                width: "10%",
                                padding: "5px",
                                textAlign: "right",
                              }}>{idx + 1}</td>
                            <td
                              style={{
                                width: "15%",
                                padding: "5px",
                                textAlign: "right",
                              }}
                            >
                              {dc.dc_no}
                            </td>

                            <td
                              style={{
                                width: "10%",
                                padding: "5px",
                                textAlign: "right",
                              }}
                            >
                              {dc.dc_date}
                            </td>

                            <td
                              style={{
                                width: "30%",
                                padding: "5px",
                                textAlign: "left",
                              }}
                            >
                              {dc.item_name}
                            </td>

                            <td
                              style={{
                                width: "20px",
                                padding: "5px",
                                textAlign: "left",
                              }}
                            >
                              {dc.unit}
                            </td>

                            <td
                              style={{
                                width: "15px",
                                padding: "5px",
                                textAlign: "right",
                              }}
                            >
                              {dc.jw_yield_qty.toFixed(2)}
                            </td>

                            <td
                              style={{
                                width: "15px",
                                padding: "5px",
                                textAlign: "right",
                              }}
                            >
                              {dc.qty_used.toFixed(2)}
                            </td>
                            </tr>
                        ))}
                      </tbody>
                    </Table> 
          
          </form>
       : 
       <div className="container-spinner">
      <div className="vertical-center-spinner">
       <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
 
       </div>
       </div>
      }      
       </Container>
     
    );
  }
  export default ViewCustomerDCRecon;