import React, { useState, useEffect, useRef, Fragment } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Form,
    Button,
    Table,
    Spinner,
    Image
} from "react-bootstrap";
import { withRouter } from "react-router";
//import Sidebar from './Sidebar';
//import Sidebar2 from "./Sidebar2";
//import Footer from "./Footer";
//import Topmenu from "./Topmenu";
//import OrderDetails from "./OrderDetails";
import "../Dashboard.css";

//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";

import { useNavigate } from "react-router-dom";

//import * as Icon from "react-bootstrap-icons";
import axios from "axios";
import { client } from "../config/Config";
import Select from "react-select";
import spinner_logo from '../assets/images/spinner_logo.png';



import {
    BsFillTrashFill,
    BsPencilSquare,
    BsFileRuled,
    BsArrowLeftCircle,
    BsCardText,
    BsReceipt,
    BsFillPrinterFill,
} from "react-icons/bs";

import { AiOutlineOrderedList } from "react-icons/ai";

//import MenuOms from "./MenuOms";

import rmcBlueIcon from "../assets/images/ready_mix_blue.png";
import SidebarMain from "./SidebarMain";

import { getDistrictList, getStateList, getProductApiCall, getProductBuy, getProductsSell, formatDate, formatDateIndian } from "./utils";

import { OS_RECEIPT_FOR_NDAYS } from "../config/Config";

const ListCustReceipts = (props) => {

  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
//var lastDay = new Date(date.getFullYear(), date.getMonth() + 1,
  const intialValues = {
    date_from: formatDate(new Date(firstDay)),
    date_to: formatDate(new Date())
   };


    const initialized = useRef(false);
    var menuSettingsStr = localStorage.getItem("_aP");
    var menuSettings = JSON.parse(menuSettingsStr);
    const [toRender, setToRender] = useState(false);
    
    /*
    let receiptArr = [
      {
      sno: "1",
      receipt_number: "RCPT001",
      receipt_date: "01-01-2024",
      customer_name: "RRR Constructions",
      amount: "5000",
      received_mode: "Cash",
      ref_number: "123",
      received_by_name: "Joel",
      towards: "",
	    addl_notes: "",  
      },
      {
        sno: "2",
        receipt_number: "RCPT002",
        receipt_date: "04-02-2024",
        customer_name: "SMS Readymix",
        amount: "8000",
        received_mode: "Cheque",
        ref_number: "675",
        received_by_name: "Manoj",
        towards: "",
	      addl_notes: "",  
        },
        {
          sno: "3",
          receipt_number: "RCPT003",
          receipt_date: "08-04-2024",
          customer_name: "ABC Constructions",
          amount: "1000",
          received_mode: "Net Banking",
          ref_number: "342",
          received_by_name: "Mariya",
          towards: "",
	        addl_notes: "",  
          },
          {
            sno: "4",
            receipt_number: "RCPT004",
            receipt_date: "07-08-2024",
            customer_name: "DCR Readymix",
            amount: "9000",
            received_mode: "Cash",
            ref_number: "765",
            received_by_name: "Bala",  
            towards: "",
	          addl_notes: "",
            },
            {
              sno: "5",
              receipt_number: "RCPT005",
              receipt_date: "02-05-2024",
              customer_name: "MMC Constructions",
              amount: "6000",
              received_mode: "Cheque",
              ref_number: "190",
              received_by_name: "Farina",
              towards: "",
	            addl_notes: "",  
              },

    ]
    */
  /*
    let customerArr = [
  
      { value: 1, label: "All Customers" },
      { value: 2, label: "Deppam Motors" },
      { value: 3, label: "ABC Pumps" },
      { value: 4, label: "ADP Compressors" },
    ];
    */

    const [optCustomer, setOptCustomer] = useState([]);
    const [customerIdSelect, setCustomerIdSelect] = useState({});
    const [receiptList, setReceiptList] = useState([]);
    const [receiptSummary, setReceiptSummary] = useState([]);
    //const [receiptListFull, setReceiptListFull] = useState([]);
    const [formValues, setFormValues] = useState(intialValues);
    const [show, setShow] = useState(false);
    const [showMsg, setShowMsg] = useState("Please login again");
    const [showMsgTitle, setShowMsgTitle] = useState( "Invalid authentication Session!" );
    const currSelBusinessRef = useRef(0);
    const currBranchRef = useRef(0);
    
    /*
    var total_receipt_ref = useRef(0.00);
    var amount_ref = useRef(0.00);

    
     total_receipt_ref.current = receiptList.length;
     amount_ref.current = 0.00;
     receiptList.map((receiptInd) => {
     amount_ref.current = parseFloat(amount_ref.current) + parseFloat(receiptInd.amount);
    })
    */
    const navigate = useNavigate();

    const getReceiptList = async (customer_id_pass) => {
      var retStateLoad = false;
      var receiptRet = [];
      var summaryRet = [];
      try {
        var aIVal = localStorage.getItem("_aT");
        var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
        console.log("Selected Customer :", customerIdSelect);
        var reqParams = {
          bid: currSelBusinessRef.current,
          brid: currBranchRef.current || 0,
          customer_id: customer_id_pass,
          date_from: formValues["date_from"],
          date_to: formValues["date_to"]
  
        };
        console.log("REQUEST PARAMS: ", reqParams );
        var res = await axios.post(client.domain + "/bms/getCustRcptList", reqParams, { headers }, { withCredentials: false });
        console.log("Response from server : ", res.data.detail);
  
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server Invoice : ",res.data.detail.data );
          setReceiptList(res.data.detail.data.receipts);
          setReceiptSummary(res.data.detail.data.receipt_summary[0]);
          //setCustomerListFull(res.data.detail.data);
          //setShowMsgTitle("Customer Info Added Successfully");
          setToRender(true);
          receiptRet = res.data.detail.data.receipts;
          summaryRet = res.data.detail.data.receipt_summary;
          retStateLoad = true;
          
        }
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log( "Not able to reach target server please try after sometime");
          setShowMsg("Please try after sometime");
          setShowMsgTitle("Network Issue!");
          setShow(true);
        } else {
          console.log("ERROR occured : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log( "Request failed due to ",error.response.data.detail.res_data);
            setShowMsg("Please try again ...");
            setShowMsgTitle("Unable to process your request!");
            setShow(true);
            //navigate("/auth");
          } else {
            console.log("Non status ERROR code ...");
            setShowMsg(error.response.data.detail);
            setShowMsgTitle("Unable to process your request at this moment!");
            setShow(true);
          }
        }
      }
      return [retStateLoad, receiptRet, summaryRet];
    };
  
    const CallCustomerData = async () => {
      var retStateLoad = false;
      var customerRet = [];
  
      try {
        var aIVal = localStorage.getItem("_aT");
        var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
  
        var reqParams = {
          bid: currSelBusinessRef.current
        };
  
        var res = await axios.post(client.domain + "/bms/getCustRcptSearchPreLoadData", reqParams, { headers }, { withCredentials: false });
        console.log("Response from server : ", res.data.detail);
  
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server : ", res.data.detail.data.customers);
          var customerList = [];
  
          //customerList.push({label: "All Customers", value: 0});
          res.data.detail.data.customers.map((customer) => {
            customerList.push(customer);
          })
          setOptCustomer(customerList);
          //setCustomerIdSelect(customerList[0]);
          //setCustomerIdSelect(res.data.detail.data.customers[0]);
          /*
            var findDefault = res.data.detail.data.customers.find(element => element.is_default === true);
            if (findDefault !== undefined) {
              setCustomerIdSelect(findDefault);  
            };
            */
          
          setToRender(true);
          customerRet = customerList;
          retStateLoad = true;
          
            
        }
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log( "Not able to reach target server please try after sometime");
          setShowMsg("Please try after sometime");
          setShowMsgTitle("Network Issue!");
          setShow(true);
        } else {
          console.log("ERROR occured : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log( "Request failed due to ",error.response.data.detail.res_data);
            setShowMsg("Please try again ...");
            setShowMsgTitle("Unable to process your request!");
            setShow(true);
            //navigate("/auth");
          } else {
            console.log("Non status ERROR code ...");
            setShowMsg(error.response.data.detail);
            setShowMsgTitle("Unable to process your request at this moment!");
            setShow(true);
          }
        }
      }
      return [retStateLoad, customerRet];
    };
    
   

    useEffect(() => {
        if (!initialized.current) {
            console.log("*************** useEffect called ##########################");
            //setCustomerIdSelect(optCustomer[0]);
            setToRender(true);
            ( async() => {
              var [retStateLoad, customerRet] = await CallCustomerData();
              if (retStateLoad){
                  var findDefault = customerRet.find(element => element.is_default === true);
                      if (findDefault !== undefined) {
                        setCustomerIdSelect(findDefault);
                        
                        var [retStateLoad, dcRet, summaryRet] = await getReceiptList(findDefault.value);
                     
                    }
                console.log(" Customer :", customerRet);
               
                //setCustomerIdSelect(customerRet[0]);
      
                 
              }
             
            })();
            /*
            (async () => {
                var [retState, customerArr] = await getCustomerListDropdown();
                if ( retState ) {
                    setOptCustomer(customerArr);
                    setCustomerIdSelect(customerArr[0]);
                }

                if ( retState ) {
                  await getReceiptList(customerArr[0].value, formValues.start_date, formValues.end_date);
                  setToRender(true);
                }
                else {
                  await getReceiptList(0, formValues.start_date, formValues.end_date);
                  setToRender(true);
                }
            })();
            */

            initialized.current = true;
        }
    }, []);



    

    

    const handleCustomerSelect = async (e) => {
        console.log("Handle customer select called : ", e);
        setCustomerIdSelect(e);
    };
    const onChangeStartDatePicker = (e) => {
      console.log("Date onChange called ...", e);
      console.log("Date onChange DATE called ...", e.target.value);
      setFormValues({ ...formValues, date_from: e.target.value });
    };
   
    const onChangeEndDatePicker = (e) => {
      console.log("Date onChange called ...", e);
      console.log("Date onChange DATE called ...", e.target.value);
      setFormValues({ ...formValues, date_to: e.target.value });
    };

    
    const handleDateSearch = async (e) => {
        console.log("handleDateSearch called : ", e);
        await getReceiptList(customerIdSelect.value);
    }

    const handleReceiptAdd = async (e) => {
        console.log("handleClickAddOrder called : ", e);

        navigate(`/addUpdateCustReceipt`, {
            state: { passed_element: [], action: "add" },
          });
    }
  
  const handleView = async (e) => {
    console.log("handle edit customers called : ", e);
    console.log("handle edit customers currentTarget called : ", e.currentTarget);
    console.log("handle edit customers target called : ", e.target);
    var selectedTagId = e.currentTarget.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    if (selectedTagId) { var currentId = selectedTagId.substring(selectedTagId.lastIndexOf("-") + 1);
      var nameOfElement = selectedTagId.substring(0, selectedTagId.lastIndexOf("-"));
      console.log("Name of element VIEW: ", nameOfElement);
      console.log("Current id VIEW : ", currentId);
      console.log("<<<<<<<<<<<<<<<<< ITEM SELECTED VIEW ********** ", receiptList[currentId]);
      navigate("/addUpdateCustReceipt", {
        state: {
          passed_element: receiptList[currentId],
          action: "view",
        },
      });
    }
  };

  const handleReceiptPrint = async (e) => {
    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printListCustReceipts?bid=${currSelBusinessRef.current}&brid=${currBranchRef.current}&customer_id=${customerIdSelect.value}&date_from=${formValues["date_from"]}&date_to=${formValues["date_to"]}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0,0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
  };

  const sendDataToParent  = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if ( passedBackValue.hasOwnProperty("business_id") ) {
      currSelBusinessRef.current = passedBackValue["business_id"];
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);
    
    //getBranchList();
  }

    return (
        <Container>
            <SidebarMain sendBacktoParent={sendDataToParent} />
            { toRender ?  
             <form>
                    <Row>

                        <Col xs={9} md={9} sm={9} lg={9}>
                            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem" }}>{menuSettings.permissions.find(element => (element.code === 'listCustReceipts' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'listCustReceipts')).page_title_list : "Receipts"}</span>
                        </Col>
                        <Col xs={3}
                            md={3}
                            sm={3}
                            lg={3}
                            className="d-flex justify-content-end float-end">

                              <Button id="receiptPrint" name = "receiptPrint"  onClick={(e)  => handleReceiptPrint(e)}
                                disabled={menuSettings.permissions.find(element => (element.code === 'listCustReceipts' && element.a_print_list === true && receiptList.length > 0 )) ? false : true}>
                              <BsFillPrinterFill
                              size={18}
                              style={{ color: "white", cursor: "pointer" }}
                              name={`print-receipt-icon`}
                              id={`print-receipt-icon`}
                              
                              />
                            </Button>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                        {menuSettings.permissions.find(element => (element.code === 'listCustReceipts' && element.a_add === true)) &&
                            <Button id="add-order-id"
                                size={"sm"}
                                variant="warning"
                                onClick={(e) => handleReceiptAdd(e)}>
                                New Receipt
                            </Button>
                        }    
                        </Col>
                        </Row>
                        <hr align="center" />
                        <Row>
        <Col
            xs={3}
            md={3}
            sm={3}
            lg={3}>
             
                <Select
                    inline
                    id="customer"
                    name="customer"
                    value={{
                      value: customerIdSelect.value,
                      label: customerIdSelect.label,
                    }}
                    options={optCustomer}
                    defaultValue={{
                      value: customerIdSelect.value,
                      label: customerIdSelect.label,
                    }}
                    onChange={handleCustomerSelect}
                    //isDisabled={isReadOnly}
                  />
                  </Col>

                 
             
            <Col xs={5} md={5} sm={5} lg={5}>
              <Row>
              <Col>
                <Form.Control
                  type="date"
                  name="date_from"
                  placeholder="Start Date"
                  value={formValues.date_from}
                  onChange={onChangeStartDatePicker}
                  //readOnly={isReadOnly}
                  
                />
              
            </Col>
            <Col>
              <Form.Label style={{textAlign: "center", paddingTop: "5px"}}>To</Form.Label>
            </Col>
          
            <Col>
           
                <Form.Control
                  type="date"
                  name="date_to"
                  placeholder="End Date"
                  value={formValues.date_to}
                  onChange={onChangeEndDatePicker}
                  //readOnly={isReadOnly}
                  
                />
                 </Col>
                <Col>
                 <Button id="search-order-id"
                    size={"md"}
                    variant="success"
                    onClick={(e) => handleDateSearch(e)}>
                    Search
                    </Button>
                  </Col>
                 </Row>
                </Col>        
                  </Row>
                 
                  <hr align="center" />
                  {/*
                  <Row>
                    
                    <Col xs={12} md={12} sm={12} lg={12} style={{textAlign: "right"}}> 
                    
                        <Form.Label style={{fontWeight: 'bold',color: "#43C70F"}}>
                            Total Receipts :
                        </Form.Label>
                        <Form.Label style={{fontWeight: 'bold', paddingLeft: "2px",color: "#43C70F"}}>
                              {receiptSummary.rcpt_count}
                        </Form.Label>
          
                             <span style={{ color: '#C0C0C0'}}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
        
                        <Form.Label style={{fontWeight: 'bold',color: "#43C70F"}}>
                            Total Amount :
                        </Form.Label>
                        <Form.Label style={{fontWeight: 'bold', paddingLeft: "2px",color: "#43C70F"}}>
                        { receiptSummary.rcpt_total_amt > 0.01 ?
                        <>
                        {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(receiptSummary.rcpt_total_amt).toFixed(2)))}
                        </>
                        : (0.00).toFixed(2)}
                        </Form.Label>
                   </Col>  
                  </Row>
                  */}

                  <tr className="table-primary">
              <th className="table-row-heading" key={"order-list-heading-key"} style={{width: "25%"}}>Total Receipts : {receiptSummary.rcpt_count}</th>
              <th className="table-row-heading" style={{textAlign : "center", width: "0%"}}></th>
              <th className="table-row-heading" style={{textAlign : "center", width: "0%"}}></th>
              <th className="table-row-heading" style={{textAlign : "right", width: "25%"}}>Total :</th>
              <th className="table-row-heading" style={{textAlign : "right", width: "15%"}}>{ receiptSummary.rcpt_total_amt > 0.01 ?new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(receiptSummary.rcpt_total_amt).toFixed(2)) : (0.00).toFixed(2)}</th>
              <th className="table-row-heading" style={{textAlign : "center", width: "15%"}}></th>
              <th className="table-row-heading" style={{textAlign : "center", width: "15%"}}></th>
              <th className="table-row-heading" style={{textAlign : "center", width: "5%"}}></th>
            </tr>

          <Table striped bordered hover size="sm">
          <thead>
            <tr className="table-primary">
              <th className="table-row-heading" key={"order-list-heading-key"} style={{width: "5%"}}>S.No</th>
              <th className="table-row-heading" style={{textAlign : "center", width: "10%"}}>Receipt Date</th>
              <th className="table-row-heading" style={{textAlign : "center", width: "10%"}}>Receipt No</th>
              <th className="table-row-heading" style={{textAlign : "center", width: "25%"}}>Customer</th>
              <th className="table-row-heading" style={{textAlign : "center", width: "15%"}}>Amount</th>
              <th className="table-row-heading" style={{textAlign : "center", width: "15%"}}>Receipt Mode</th>
              <th className="table-row-heading" style={{textAlign : "center", width: "15%"}}>Reference No</th>
              <th className="table-row-heading" style={{textAlign : "center", width: "5%"}}>Details</th>
            </tr>
          </thead>

          <tbody>
            {receiptList.map((receiptInd, idx) => (
              <tr key={`receipt-table-row-key-${idx}`}>
                
                <td style={{textAlign : "right", width: "5%"}}
                  key={`receipt-table-row-key-sno-${idx}`}
                  id={`receipt-table-row-id-sno-${idx}`}
                  name={`receipt-table-row-name-sno-${idx}`}
                  
                >
                  {idx + 1}
                </td>
                <td
                  key={`receipt-table-row-key-date-${idx}`}
                  id={`receipt-table-row-id-date-${idx}`}
                  name={`rceipt-table-row-name-date-${idx}`}
                  style= {{width: "10%", textAlign: "left"}}
                >
                  {receiptInd.rcpt_date}
                  {/*{formatDateIndian(new Date(receiptInd.rcpt_date))}*/}
                </td>

                <td
                  key={`receipt-table-row-key-ono-${idx}`}
                  id={`receipt-table-row-id-ono-${idx}`}
                  name={`rceipt-table-row-name-ono-${idx}`}
                  style= {{width: "10%", textAlign: "right"}}
                >
                  {receiptInd.rcpt_no}
                </td>

                <td style={{textAlign: "left",  width: "25%"}}
                  key={`receipt-table-row-key-customer-${idx}`}
                  id={`receipt-table-row-id-customer-${idx}`}
                  name={`receipt-table-row-name-customer-${idx}`}
                  
                >
                  {receiptInd.customer_name}
                </td>

                <td
                  key={`receipt-table-row-key-amount-${idx}`}
                  id={`receipt-table-row-id-amount-${idx}`}
                  name={`rceipt-table-row-name-amount-${idx}`}
                  style={{textAlign: "right", width: "15%"}}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(receiptInd.rcpt_amt).toFixed(2)))}
                </td>


                <td style={{textAlign: "left", width: "15%"}}
                  key={`receipt-table-row-key-mode-${idx}`}
                  id={`receipt-table-row-id-mode-${idx}`}
                  name={`rceipt-table-row-name-mode-${idx}`}
                  //style= {{width: "120px"}}
                >
                  {receiptInd.rcpt_mode}
                </td>
                <td style={{textAlign: "right", width: "15%"}}
                  key={`receipt-table-row-key-ref-number-${idx}`}
                  id={`receipt-table-row-id-ref-number-${idx}`}
                  name={`rceipt-table-row-name-ref-number-${idx}`}
                >
                  {receiptInd.ref_no}
                </td>
                
                <td style={{textAlign: "center", width: "5%"}}
                  key={`order-table-row-key-actions-${idx}`}
                  id={`order-table-row-id-actions-${idx}`}
                  name={`order-table-row-name-actions-${idx}`}
                >
                  <Button
                    variant="light"
                    size="sm"
                    onClick={(e) => handleView(e)}
                    name={`receipt-view-icon-${idx}`}
                    id={`receipt-view-icon-${idx}`}
                  >
                    <BsCardText
                    size={28}
                    style={{ color: "#0d6efd", cursor: "pointer" }}
                    name={`receipt-view-icon-${idx}`}
                    id={`receipt-view-icon-${idx}`}
                    disabled={menuSettings.permissions.find(element => (element.code === 'listCustReceipts' && element.a_detailview === true)) ? false : true}
                    />
                  </Button>
                </td>
                </tr>
            ))}
            </tbody>
            </Table>
                
            </form>
       : 
       <div className="container-spinner">
      <div className="vertical-center-spinner">
       <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
 
       </div>
       </div>
      }       
                
                
        </Container>
    );

}

export default ListCustReceipts;