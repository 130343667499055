import { React, Fragment, useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  Badge,
  Modal,
  Spinner,
  Image
} from "react-bootstrap";

//import Sidebar2 from "./Sidebar2";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import "../Dashboard.css";
import "../App.css";
import { client ,appGlobal, LS_MSG_TAG } from "../config/Config";
import axios from "axios";


import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsTrashFill,
  BsArrowLeftCircle,
  BsFillPenFill,
  BsFillPrinterFill
} from "react-icons/bs";
import SidebarMain from "./SidebarMain";
import {getDistrictList, getStateList } from "./utils";
import spinner_logo from '../assets/images/spinner_logo.png';
//import { MSG_BRANCH_NAME_VALIDATE, MSG_PHONE_NUMBER_CONTAINS_VALIDATE, MSG_ADDRESS1_VALIDATE, MSG_PINCODE_CONTAINS_VALIDATE } from "../config/VbMessages";
import { getCustomerMessages } from "./utils";


//function AddUpdateCustomer () {
const AddUpdateBranch = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();

  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);
  
  let initialItems = [];
  const intialValues = {
    name: "",
    phone: "",
    email: "",
    website: "",
    address_line1: "",
    address_line2: "",
    district: "",
    pincode: "",
    state_id: 1,
    country_id: 1,

    
  };

  let stateArr = [
    { value: 1, label: "Andhra Pradesh" },
    { value: 2, label: "Assam" },
    { value: 3, label: "Tamil Nadu" },
    { value: 4, label: "Telangana" },
  ];

  let countryArr = [
    { value: 1, label: "Australia" },
    { value: 3, label: "India" },
    { value: 4, label: "China" },
  ];
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});

  const [validateError, setValidateError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);

  const [isReadOnly, setIsReadOnly] = useState(true);
  const [toRender, setToRender] = useState(false);

  const [show, setShow] = useState(false);
 // const [showMsg, setShowMsg] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  
  //const [selectedId, setSelectedId] = useState(-1);
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );
  const [submitAction, setSubmitAction] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [stateIdSelect, setStateIdSelect] = useState({});
  const [optionsState, setOptionsState] = useState([]);
  const [countryIdSelect, setCountryIdSelect] = useState({});
  const [optCountry, setOptCountry] = useState([]);
  const [addrCopyCheck, setAddrCopyCheck] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [districtIdSelect, setDistrictIdSelect] = useState({});
  const [optDistrict, setOptDistrict] =  useState([]);
  
  const [checkState, setCheckState] =  useState(true);
  
  const currSelBusinessRef = useRef(0);
  const currBranchRef = useRef(0);
  
  

  var validateErr = false;

  const countryListFromApi = { "countries": [
    {"country_name": "India", "states": ["Tamil Nadu", "Kerala", "Karnataka","Andra pradesh", "Telengana", "Maharastra", "Punjab"]},
    {"country_name": "Australia", "states": ["New South Wales", "Victoria, Queensland", "Western Australia", "Tasmania", "South Australia"]},
    {"country_name": "China", "states": ["Hebei", "Shanxi", "Liaoning", "Jilin", "Heilongjiang"]}
  ]}

  const handleOnChange = (e) => {
    //e.preventDefault();
    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);

    if (name === "phone" || name === "contact_person_number" || name === "pincode")  {
      console.log("Inside check ******************");
      //const numericRegExp = /^(?:[1-9]\d*|\d)$/;
      if (e.target.value.match(appGlobal.wholeNumberRegExp)) {
        //var valueTmp = Math.round(e.target.value.replace(/\D/g, ""));
        setFormValues({ ...formValues, [name]: e.target.value });
      }
    } 
    else if (name === "opening_balance") {
     if ( e.target.value.match(appGlobal.numericRegExp) ) {
      setFormValues({ ...formValues, [name]: value });
     }
    }
    else {
      setFormValues({ ...formValues, [name]: value });
    }
    /*
    {
      console.log("Inside check ******************");
     
      var valueTmp = e.currentTarget.value.replace(/[^\d]/g, '');
    
      setFormValues({ ...formValues, [name]: valueTmp });
      //}
    } else {
      setFormValues({ ...formValues, [name]: value });

    }

    */
    
  };
  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.name) {
      errors.name = getCustomerMessages(LS_MSG_TAG,"MSG_BRANCH_NAME_MANDATORY");
      validateErr = true;
      //setValidateError(true);
    }
    if (!values.address_line1) {
      errors.address_line1 = getCustomerMessages(LS_MSG_TAG,"MSG_ADDRESS1_MANDATORY");
      validateErr = true;
      //setValidateError(true);
    }
    
    if (!values.pincode) {
      errors.pincode = getCustomerMessages(LS_MSG_TAG,"MSG_PINCODE_MANDATORY");
      validateErr = true;
      setValidateError(true);
    }
    
    if (values.pincode && values.pincode.length != 6) {
      errors.pincode = getCustomerMessages(LS_MSG_TAG, "MSG_PINCODE_INVALID");
      validateErr = true;
      setValidateError(true);
    }
    
    if (!values.phone) {
      errors.phone = getCustomerMessages(LS_MSG_TAG, "MSG_PHONE_MANDATORY");
      validateErr = true;
      //setValidateError(true);
    }
    
    if (values.phone && values.phone.length != 10) {
      errors.phone = getCustomerMessages(LS_MSG_TAG,"MSG_PHONE_INVALID");
      validateErr = true;
      //setValidateError(true);
    }

    /*
    if (values.contact_person_number && values.contact_person_number.length != 10) {
      errors.contact_person_number = "Contact Number should be 10 digits!";
      validateErr = true;
      setValidateError(true);
    }
    */
  
 
    //console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ",
      validateErr
    );
    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
      //console.log("LINE ITEMS : ", items);
      callUpdateApi();
     
    }
  };
    const handleSelect = (e, data) => {
    console.log("SELECTED Dropdown value : ", e);
    console.log("SELECTED element id : ", data);
    var currentId = data.name.substring(data.name.lastIndexOf("-") + 1);
    console.log("Current id : ", currentId);
  };
  const handleSearch = (e, dat) => {
    console.log("Handled search method called ...", e);

    //var stateIdInput = 1;
   // var stateNameInput = "TAMIL NADU";
    //getDistrictList(stateIdInput, stateNameInput);
  };

 
  
  const handleStateSelect = async(e) => {
    console.log("handleStateSelect called ...", e);
    setStateIdSelect(e);

    var districtsData = localStorage.getItem('districts');
    var districtsObj = JSON.parse(districtsData);

    /*
    var findCountry = districtsObj.find(element => element.state_id === e.value);
    if ( findCountry !== undefined ) {
    }
    */
   var distArrTmp = [];
    districtsObj.forEach(function(districtInfo){
      if ( districtInfo.state_id === e.value ) {
        distArrTmp.push(districtInfo);
      }
    });
    setOptDistrict(distArrTmp);
    setDistrictIdSelect(distArrTmp[0]);
    
  };

  const handleDistrictSelect = (e, data) => {
    console.log("handleDistrictSelect called ...", e);
    setDistrictIdSelect(e);
   
    
};


  
 
  const handleCountrySelect = (e, data) => {
    console.log("handleCountrySelect called ...", e);
    setCountryIdSelect(e);

}

  const handleEdit = async (e) => {
    console.log("handle edit  clicked : ", e);
    setIsReadOnly(false);
  };
  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
    navigate(-1);
  };

  const handleReset = async (e) => {
    console.log("handle reset clicked : ", e);
    navigate(0);
  };
  
  

  const callBranchDetById = async(branchIdPassed) => {
    var retState = false;
    var branchInfoRet = {};
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      //var apiToCall = "";

    var reqParams = {
      id: branchIdPassed
    };

    var res = await axios.post(client.domain + "/core/getBranchDetails", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      console.log("Response from server INSIDE ARRAY : ", res.data.detail.data[0]);
      if (res.data.detail.status === "SUCCESS") {
        //setShowMsg(res.data.detail.data.message);
        //setShowMsgTitle("Success");
        //setShowDialog(true);
        setFormValues(res.data.detail.data[0]);
        branchInfoRet = res.data.detail.data[0];
        setSubmitAction(false);
        retState = true;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          
        }
      }
      setSubmitAction(false);
    }
    return [retState, branchInfoRet];  
  }

  
 


  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect::addUpdateBranch called ###############: ", state);
      ( async() =>{
      setToRender(true);
      

      var countriesData = localStorage.getItem('countries');
      var countriesObj = JSON.parse(countriesData);
      console.log("BRANCHS DATA IN OBJECT : ", countriesObj);
      setOptCountry(countriesObj);
      setCountryIdSelect(countriesObj[0]);

      var districtData = localStorage.getItem('districts');
      var districtObj = JSON.parse(districtData);
      console.log("DISTRICT LIST FOR AddUpdateBranch : ", districtObj);
      
      var statesData = localStorage.getItem('states');
      var statesObj = JSON.parse(statesData);
      console.log("STATE LIST FOR AddUpdateBranch : ", statesObj);

      if ( state.action === 'edit' || state.action === 'view' ) {
        var [retState, branchInfoRet] = await callBranchDetById(state.passed_element.id);
        if ( retState ) {
          console.log("RETURNED BRANCH INFO : ", branchInfoRet);

          var findDistrict = districtObj.find(element => element.value === branchInfoRet.district_id);
          if (findDistrict !== undefined) {
            console.log("----------------- DISTRICT ID MATCHED at AddUpdateBranch ++++++++++++")
            setDistrictIdSelect(findDistrict);  
          };

          var optDistArr = [];
          districtObj.forEach(function(district){
            if (district.state_id === branchInfoRet.state_id) {
              optDistArr.push(district);
            }
          });
          console.log("FILTERED DISTRICT FOR EDIT <<<<<<<<<<<<<<^^^^^^^^^^^^^ : ", optDistArr);
          setOptDistrict(optDistArr);
        }
      }
      else {
        setDistrictIdSelect(districtObj[0]);

        var optDistArr = [];
        districtObj.forEach(function(district){
          if (district.state_id === statesObj[0].value) {
            optDistArr.push(district);
          }
        });
        console.log("FILTERED DISTRICT FOR ADD <<<<<<<<<<<<<<^^^^^^^^^^^^^ : ", optDistArr);
        setOptDistrict(optDistArr);
      }

      
      if ( state.action === 'edit' || state.action === 'view' ) {
        var [retState, branchInfoRet] = await callBranchDetById(state.passed_element.id);
        if ( retState ) {
          console.log("RETURNED BUSINESS INFO : ", branchInfoRet);

          var findState = statesObj.find(element => element.value === branchInfoRet.state_id);
          if (findState !== undefined) {
            setStateIdSelect(findState);  
          };

          var optStateArr = [];
          statesObj.forEach(function(state){
            if (state.country_id === branchInfoRet.country_id) {
              optStateArr.push(state);
            }
          });
          console.log("FILTERED STATE FOR EDIT <<<<<<<<<<<<<<^^^^^^^^^^^^^ : ", optStateArr);
          setOptionsState(optStateArr);
        }
      }
      else {
        setStateIdSelect(statesObj[0]);

        var optStateArr = [];
        statesObj.forEach(function(state){
          if (state.country_id === countriesObj[0].value) {
            optStateArr.push(state);
          }
        });
        console.log("FILTERED STATE FOR ADD <<<<<<<<<<<<<<^^^^^^^^^^^^^ : ", optStateArr);
        setOptionsState(optStateArr);
      }


      
      
      currSelBusinessRef.current = state.passed_element.business_id;
      currBranchRef.current = state.passed_element.branch_id;

      console.log("Business :", currSelBusinessRef.current);
      console.log("Branch :", currBranchRef.current);


      /*
      console.log("Process api fetched country list : ", countryListFromApi.countries);

      var newCountryArr = [];
      countryListFromApi.countries.forEach(function(object1){
        console.log("^^^^^^^^^^^^^^^^^^^ INDIVIDUAL OBJECT VALUE : ", object1);
        
       newCountryArr.push({value: object1.country_name, label: object1.country_name});
      })

      setOptCountry(newCountryArr);
      setCountryIdSelect(newCountryArr[0]);

      setOptionsState(countryListFromApi.countries[0].states);
      setStateIdSelect({value: countryListFromApi.countries[0].states[0], label: countryListFromApi.countries[0].states[0]});

     
      */
      if (state === null) {
        navigate("/listBranch");
      } else {
        console.log("PASSED STATE VALUE IN  Add/Update page : ", state);
         console.log("Inside else for view or edit ...");
          
        if (state.action === "add") {
          setIsReadOnly(true);
        }
        else if ( state.action === 'view' || state.action === 'edit') {
          // call api to get business info
          callBranchDetById(state.passed_element.id);
        }
        else {
          setFormValues(state.passed_element);
        }
        
         setToRender(true);
       
        console.log("newObj :", formValues);
        if (state.action === "add") {
          setIsReadOnly(false);
        }
      }
      
      initialized.current = true;
    })();
    }
  }, []);

 
  const callUpdateApi = async () => {
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var apiToCall = "";
      if (state.action === "add") {
        apiToCall = "/core/addBranch";
      } else if (state.action === "view") {
        apiToCall = "/core/updateBranch";
      } else {
        apiToCall = "/core/addBranch";
      }

      var reqParam = {
        bid: currSelBusinessRef.current,
        name: formValues["name"] || "",
        phone: formValues["phone"],
        email: formValues["email"] || "",
        website: formValues["website"] || "",
        address_line1: formValues["address_line1"] || "",
        address_line2: formValues["address_line2"] || "",
        district_id: districtIdSelect.value,
        district_name: formValues["district"] || "",
        pincode: formValues["pincode"] || "",
        state_id: stateIdSelect.value,
        
      };

      if ( state.action === "edit" || state.action === "view") {
        reqParam.id = formValues["id"];
        reqParam.is_active = checkState;
      }

      var res = await axios.post(client.domain + apiToCall, reqParam, { headers }, { withCredentials: false });
     
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        setShowMsgTitle("Success");
        setShowDialog(true);
        setSubmitAction(false);
        if (state.action === "add"){
          setShowMsg(getCustomerMessages(LS_MSG_TAG,"MSG_BRANCH_SAVED"));
          
        }
        else{
          setShowMsg(getCustomerMessages(LS_MSG_TAG,"MSG_BRANCH_UPDATED"))
        }
        
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          
        }
      }
      setSubmitAction(false);
    }
  };

  const handlePostSaveOk = () => {
    console.log("user confirmed to customer added ...");
    setShowDialog(false);
    navigate("/listBranches");
  };
  const handleSave = async (e) => {
    e.preventDefault();
    console.log("Handle Click save called : ", e);
    console.log("Formvalues : ", formValues);

    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (validateErr === false) {
      setSubmitAction(true);
     // callUpdateApi();
     
    }
  };

  const handlePrintBranch = async () => {
    console.log("Handle print branch called ...");
    //navigate("/printCustReceipt")
    /*

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printCustReceipt?receipt_id=${state.passed_element.id}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0, 0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
    */
  };
  const handleCheckBox = async (e) => {
    console.log("handle copy to billing called :", e);
    console.log("handle copy to billing called CHECKED :", e.target.checked); 
    setCheckState(e.target.checked);
    
    if ( state.action === 'view' || state.action === 'edit'){
      setCheckState(e.target.checked);
    }
    
    setRefresh(true);
  };

  const sendDataToParent  = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if ( passedBackValue.hasOwnProperty("business_id") ) {
      currSelBusinessRef.current = passedBackValue["business_id"];
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);
    
  }

  


 

  return (
      <Container>
        <SidebarMain passedFrom="AddUpdateBranch" passedValue={{business_id: state.passed_element.business_id, branch_id: state.passed_element.branch_id}} sendBacktoParent={sendDataToParent} />
        { toRender ?
        (
        <Form>
          <Row>
            <Col xs={4} md={4} sm={4} lg={4}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>{menuSettings.permissions.find(element => (element.code === 'listBranches' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'listBranches')).page_title_addupd : "Branches"}</span>
            {/*
            { (state.action === 'view' || state.action === 'edit') &&
              <>
              &nbsp;&nbsp;
             
            <Button variant={state.passed_element.is_active ? "outline-success" : "outline-danger"} size="sm"><span style={{cursor: "default", fontWeight: "500"}}>{state.passed_element.status}</span></Button>
            </>
            }
          */}
            
            </Col>
            
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
              className="float-end justify-content-end"
            >
              <div className="d-flex justify-content-end">
                <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                  <BsArrowLeftCircle
                    key={`back-btn-branch-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                   
                    name={`back-icon-branch-name`}
                    id={`edit-icon-branch-id`}
                  />
                </Button>
                &nbsp;&nbsp;
                {(state.action === "edit" || state.action === "view") && (

                  <>
                  <Button id="branchPrint" name="branchPrint" onClick={(e) => handlePrintBranch(e)}
                  disabled={menuSettings.permissions.find(element => (element.code === 'listBranches' && element.a_print === true)) ? false : true}>
                    <BsFillPrinterFill
                      size={18}
                      style={{ color: "white", cursor: "pointer" }}
                      name={`print-branch-icon`}
                      id={`print-branch-icon`}
                    />
                  </Button>
                &nbsp;&nbsp;
                <Button variant="primary" className="btn btn-primary btn-md"  onClick={(e) => handleEdit(e)}
                disabled={menuSettings.permissions.find(element => (element.code === 'listBranches' && element.a_edit === true)) ? false : true}>
                  <BsPencilSquare
                    key={`edit-btn-branch-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                  
                    name={`edit-icon-paBiSolidEditAlttient-name`}
                    id={`edit-icon-branch-id`}
                  />
                </Button>
                </>
                )}
              </div>
            </Col>
          </Row>

          <div className="ui-divider"></div>
          <div className="ui-form">
            <hr align="center" />
           

            <Row>
              <Col xs={6} md={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="name-id"
                    name="name"
                    placeholder=" Name"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["name"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.name}</p>
              </Col>
             {/*
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    GSTIN
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="gst-no-id"
                    name="gst_no"
                    placeholder="GSTIN"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["gst_no"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.gst_no}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>PAN</Form.Label>
                  <Form.Control
                    type="text"
                    id="pan_no"
                    name="pan_no"
                    //className="mobNum"
                    placeholder="PAN"
                    onChange={handleOnChange}
                    readOnly={isReadOnly}
                   // maxLength={10}
                    value={formValues["pan_no"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.pan_no}</p>
              </Col>
                  */}
             
            </Row>
           
            
           

            <Row>
              <Col xs={6} md={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">
                    Street / Location
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="address_line1"
                    name="address_line1"
                    placeholder="Street / Location"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["address_line1"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.address_line1}</p>
              </Col>
              <Col xs={6} md={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="field">
                    City / Town
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="address_line2"
                    name="address_line2"
                    placeholder="City / Town"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["address_line2"]}
                    style={{
                      border: !isReadOnly && "1px solid gray"
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.address_line2}</p>
              </Col>
              </Row>
              <Row>
              {/*
              <Col xs={3} sm={3} md={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Country</Form.Label>
                  <Select
                    id="country"
                    name="country"
                    value={{
                      value: countryIdSelect.value,
                      label: countryIdSelect.label,
                    }}
                    options={optCountry}
                    defaultValue={{
                      value: countryIdSelect.value,
                      label: countryIdSelect.label,
                    }}
                    onChange={handleCountrySelect}
                    isDisabled={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.country_id}</p>
              </Col>
              */}
              <Col xs={3} sm={3} md={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>State</Form.Label>
                  <Select
                    id="state"
                    name="state"
                    value={{
                      value: stateIdSelect.value,
                      label: stateIdSelect.label,
                    }}
                    defaultValue={{
                      value: stateIdSelect.value,
                      label: stateIdSelect.label
                    }}
                    options={optionsState}
                    onChange={handleStateSelect}
                    isDisabled={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.state_id}</p>
              </Col>
              <Col xs={3} sm={3} md={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>District</Form.Label>
                  <Select
                    id="district_id"
                    name="district_id"
                    value={{
                      value: districtIdSelect.value,
                      label: districtIdSelect.label,
                    }}
                    defaultValue={{
                      value: districtIdSelect.value,
                      label: districtIdSelect.label
                    }}
                    options={optDistrict}
                    onChange={handleDistrictSelect}
                    isDisabled={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>

                <p className="error-msg">{formErrors.district}</p>
              </Col>

              
              
              
              <Col xs={3} sm={3} md={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Pincode</Form.Label>
                  <Form.Control
                    type="text"
                    id="pincode-id"
                    name="pincode"
                    placeholder="Pincode"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    maxLength={6}
                    value={formValues["pincode"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>

                <p className="error-msg">{formErrors.pincode}</p>
              </Col>
            </Row>

            <Row>
              {/*
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Contact Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="contact_person"
                    name="contact_person"
                    placeholder="Contact Person"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["contact_person"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.contact_person}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Contact Mobile
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="cont-person-mobile-id"
                    name="contact_person_number"
                    className="mobNum"
                    placeholder=" Contact Number"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["contact_person_number"]}
                    maxLength={10}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.contact_person_number}</p>
              </Col>
                  */}

              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">
                    Phone
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="phone"
                    name="phone"
                    //className="mobNum"
                    placeholder=" Phone"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["phone"]}
                    maxLength={10}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.phone}</p>
              </Col>
             
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    id="email-id"
                    name="email"
                    placeholder="Email"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["email"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.email}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Website</Form.Label>
                  <Form.Control
                    type="text"
                    id="Web-id"
                    name="website"
                    placeholder="Website"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["website"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.website}</p>
              </Col>
            </Row>

           
            {!isReadOnly && (
              <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                  <Form.Check
                            style={{paddingTop: "5px"}}
                            label="Active"
                            name="checkbox"
                            id="id-addr-copy-bill"
                            type="checkbox"
                            className="checkCopy"
                            //defaultChecked=
                            checked={checkState}
                            onChange={handleCheckBox}
                          ></Form.Check>
                          &nbsp;&nbsp;
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
              
            
                    <Button
                      type="submit"
                      id="save"
                      onClick={(e) => handleSubmit(e)}
                      disabled={submitAction ? true: false}
                    >
                     {submitAction && 
                      <>
                      <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                     <span className="visually-hidden">Loading...</span>
                     </>
                    }
                      
                     Save
                    </Button>
                  </div>
                </Col>
                
              </Row>
            )}
            
           
            <Modal
              show={showDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{showMsgTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{showMsg}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          </Form>
     )
        :
        <div className="container-spinner">
        <div className="vertical-center-spinner">
         <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>  
         
                <span className="visually-hidden">Loading...</span>
              </Spinner>
         </div>
         </div>
      }
        
      </Container>
  );
};

export default AddUpdateBranch;
