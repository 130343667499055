
import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  Label,
  Badge,
  Table,
  Spinner,
  Image,
  Modal
} from "react-bootstrap";
import "../Dashboard.css";
import "../App.css";
import { useNavigate } from "react-router-dom";

import Select from "react-select";

import { client, ConfigMinValue, configMaxValue, appGlobal, LS_MSG_TAG } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

//import "react-datetime-picker/dist/DateTimePicker.css";
//import "react-calendar/dist/Calendar.css";
//import "react-clock/dist/Clock.css";

import { BsCartX, BsArrowLeftCircle, BsFillXCircleFill, BsFillPrinterFill, BsPencilSquare } from "react-icons/bs";
import SidebarMain from "./SidebarMain";

import { formatDate, formatTime, getCustomerMessages } from "./utils";
import spinner_logo from '../assets/images/spinner_logo.png';
import { AsyncTypeahead } from "react-bootstrap-typeahead";

const AddUpdateCustReceipt = (async) => {

  let initialItems = [];

  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);

  const intialValues = {
    rcpt_no: "",
    rcpt_amt: 0.00,
    reference_no: "",
    notes: "",
    rcpt_against_id: "",
    rcpt_date: formatDate(new Date()),
    ref_date: formatDate(new Date()),
    customer_id: 0,
    customer_name: "",
    ref_no: 0,
    received_for: "",
  };

  let invArr = [
    { value: 657, label: "INV001 - 12-12-2023 - 2000" },
    { value: 876, label: "INV002 - 10-1-2023 - 3000" },
    { value: 978, label: "INV003 - 05-2-2023  - 5000" },
  ]
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  //const [receivedSaved, setReceivedSaved] = useState(false);
  const [toRender, setToRender] = useState(false);
  const [pendingOrderList, setPendingOrderList] = useState([]);
  const [orderPendingLoaded, setOrderPendingLoaded] = useState(false);
  const [isLoadingCust, setIsLoadingCust] = useState(false);
  const [isLoadingInv, setIsLoadingInv] = useState(false);
  const [optionsSearch, setOptionsSearch] = useState([]);
  const [optionsSearchInv, setOptionsSearchInv] = useState([]);
  const [customerSearchText, setCustomerSearchText] = useState("");
  const [invoiceSearchText, setInvoiceSearchText] = useState("");
  const [searchedCustomer, setSearchedCustomer] = useState({});
  const [items, setItems] = useState(initialItems);
  const [selectedDCItemRow, setSelectedDCItemRow] = useState(0);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [submitPrintAction, setSubmitPrintAction] = useState(false);
  const [submitAction, setSubmitAction] = useState(false);
  const [show, setShow] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showMsgTitle, setShowMsgTitle] = useState("Invalid authentication Session!");

  const [orderSelectedIndex, setOrderSelectedIndex] = useState([])
  const initialized = useRef(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const currSelBusinessRef = useRef(0);
  const currBranchRef = useRef(0);
  const submitActionRef = useRef("save-only")

  const refInvoiceList = [
    { value: "1", label: "INV001" },
    { value: "2", label: "INV002" },
    { value: "3", label: "INV003" },
    { value: "4", label: "INV004" }
  ];

  const [optReferenceInvoice, setOptReferenceInvoice] = useState(refInvoiceList);
  const [referenceInvoiceIdSelect, setReferenceInvoiceIdSelect] = useState({ value: 0, label: "Please select" });
  const [receiptInfoDet, setReceiptInfoDet] = useState({});

  const [showAlert, setShowAlert] = useState();
  const [alertPurpose, setAlertPurpose] = useState('init');
  const [alertTitle, setAlertTitle] = useState({});
  const [alertBody, setAlertBody] = useState({});
  const [submitAddAction, setSubmitAddAction] = useState(false);
  const [optCustomer, setOptCustomer] = useState([]);
  const [customerIdSelect, setCustomerIdSelect] = useState({});



  var validateErr = false;

  /*
  const receivedModeList = [
    { value: "Cheque", label: "CHEQUE" },
    { value: "Cash", label: "CASH" },
    { value: "DD", label: "Demand Draft" },
    { value: "NetBanking", label: "Net Banking" }
  ]
  */

  const [optReceiptMode, setOptReceiptMode] = useState([]);
  const [receiptModeIdSelect, setReceiptModeIdSelect] = useState({});

  const [optReceived, setOptReceived] = useState([]);
  const [receivedIdSelect, setReceivedIdSelect] = useState({});
  const [receipt, setReceipt] = useState({});


  const receivedFromList = [
    { value: "1", label: "Raja" },
    { value: "2", label: "Jegan" },
    { value: "3", label: "Arul" },
    { value: "4", label: "Ravi" }
  ];
  const [optReceivedBy, setOptReceivedBy] = useState(receivedFromList);
  //const [receivedByIdSelect, setReceivedByIdSelect] = useState({ value: 0, label: "Please select" });
  const [receivedByIdSelect, setReceivedByIdSelect] = useState({});
  /*
    const getEmployeeList = async () => {
      var retState = false;
      var receivedByArr = [];
      try {
        var aIVal = localStorage.getItem("_aI");
        var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
        var res = await axios.get(client.domain + "/paymentReceiveEmployeeList", { headers }, { withCredentials: false });
        console.log("Response from server getEmployeeList : ", res.data.detail);
  
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server getEmployeeList : ", res.data.detail.res_data);
  
          
          receivedByArr = res.data.detail.res_data.employee_list;
          
          setToRender(true);
          retState = true;
        }
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log("Not able to reach target server please try after sometime");
  
        } else {
          console.log("ERROR : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log("Request failed due to ", error.response.data.detail.res_data);
  
          }
        }
      }
      return [retState, receivedByArr]
    }
    */
  /*
  const getReceivedMode = async () => {
    var receivedModeArr = [];
    var retState = false;
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
      var res = await axios.get(client.domain + "/getReceivedMode", { headers }, { withCredentials: false });
      console.log("Response from server getReceivedMode : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getReceivedMode : ", res.data.detail.data);


        res.data.detail.data.map((cust) => {
          var modeInd = { value: cust, label: cust };
          receivedModeArr.push(modeInd);
        })

        
        setToRender(true);

        retState = true;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);

        }
      }
    }
    return [retState, receivedModeArr]
  }
  */

  /*
    const getPendingOrders = async (customer_id) => {
  
      try {
        //var customer_id = 227;
        var aIVal = localStorage.getItem("_aI");
        var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
        var res = await axios.get(client.domain + "/getPendingOrders?" + "customer_id=" + customer_id, { headers }, { withCredentials: false });
        console.log("Response from server getPendingOrders : ", res.data.detail);
  
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server getPendingOrders : ", res.data.detail.data);
  
          setPendingOrderList(res.data.detail.data);
          setOrderPendingLoaded(true);
          
        }
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log("Not able to reach target server please try after sometime");
  
        } else {
          console.log("ERROR : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log("Request failed due to ", error.response.data.detail.res_data);
  
          }
        }
      }
    }
    */

  const CallReceiptData = async () => {
    var retStateLoad = false;
    var customerRet = [];
    var receiptModeRet = [];
    var receiptRet = [];
    var receivedRet = [];

    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }

      var reqParams = {
        bid: currSelBusinessRef.current
      };

      var res = await axios.post(client.domain + "/bms/getCustRcptAddUpdPreLoadData", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.status);
        setOptCustomer(res.data.detail.data.customers);
        //setItemTypeIdSelect(res.data.detail.data.item_types[0]);
        setOptReceiptMode(res.data.detail.data.receipt_modes);
        console.log("Response message from server TRADE TYPE : ", res.data.detail.data.receipt_modes);
        //setTradeTypeIdSelect(res.data.detail.data.trade_types[0]);
        setReceipt(res.data.detail.data.new_rcpt_no);
        setOptReceived(res.data.detail.data.receipt_against)
        //setOptUnit(res.data.detail.data.units);
        //setUnitIdSelect(res.data.detail.data.units[0]);

        setToRender(true);

        customerRet = res.data.detail.data.customers;
        receiptModeRet = res.data.detail.data.receipt_modes;
        receiptRet = res.data.detail.data.new_rcpt_no;
        receivedRet = res.data.detail.data.receipt_against;
        retStateLoad = true;

      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
        setShow("Please try after sometime");
        setShow("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          setShow("Please try again ...");
          setShow("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShow(error.response.data.detail);
          setShow("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
    return [retStateLoad, customerRet, receiptModeRet, receiptRet, receivedRet];
  };



  const callReceiptDetById = async (receiptIdPassed) => {
    var retState = false;
    var receiptInfoRet = {};
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
      //var apiToCall = "";

      var reqParams = {
        id: receiptIdPassed
      };

      var res = await axios.post(client.domain + "/bms/getCustRcptDetails", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      console.log("Response from server INSIDE ARRAY : ", res.data.detail.data[0]);
      if (res.data.detail.status === "SUCCESS") {
        //setShowMsg(res.data.detail.data.message);
        //setShowMsgTitle("Success");
        //setShowDialog(true);

        //setFormValues(res.data.detail.data[0]);
        setFormValues({ ...formValues, 
          id:  res.data.detail.data[0].id,
          bid: res.data.detail.data[0].bid,
          brid: res.data.detail.data[0].brid,
          customer_id: res.data.detail.data[0].customer_id, 
          customer_name: res.data.detail.data[0].customer_name,
          notes: res.data.detail.data[0].notes,
          rcpt_against_id: res.data.detail.data[0].rcpt_against_id,
          rcpt_amt: res.data.detail.data[0].rcpt_amt,
          rcpt_date: formatDate(new Date(res.data.detail.data[0].rcpt_date)),
          rcpt_mode_id: res.data.detail.data[0].rcpt_mode_id,
          rcpt_no: res.data.detail.data[0].rcpt_no,
          received_for: res.data.detail.data[0].received_for,
          ref_date: formatDate(new Date(res.data.detail.data[0].ref_date)), 
          ref_no:   res.data.detail.data[0].ref_no
        });
        receiptInfoRet = res.data.detail.data[0];
       
        setSubmitAction(false);
        retState = true;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);

        }
      }
      setSubmitAction(false);
    }
    return [retState, receiptInfoRet];
  };


  useEffect(() => {
    if (!initialized.current) {
      if (state !== null) {
        console.log("PASSED VALUE : ", state);
        //setReceivedModeIdSelect(optReceivedMode[0]);
        //setReceivedByIdSelect(optReceivedBy[0]);
        //setReferenceInvoiceIdSelect(optReferenceInvoice[0]);


        (async () => {
          var [retStateLoad, customerRet, receiptModeRet, receiptRet, receivedRet] = await CallReceiptData();
          if (state.action === 'edit' || state.action === 'view') {
            
            setIsReadOnly(true);
            setFormValues({ ...formValues, rcpt_no: state.passed_element.rcpt_no });

            var [retState, receiptInfoRet] = await callReceiptDetById(state.passed_element.id);
            if (retState) {
              console.log("RETURNED RECEIP INFO : ", receiptInfoRet);
              if (retStateLoad) {
                //console.log("RETURNED TRADE TYPE : ", tradeTypeRet);
                var findCustomer = customerRet.find(element => element.value === receiptInfoRet.customer_id);
                if (findCustomer !== undefined) {
                  setCustomerIdSelect(findCustomer);
                }

                var findReceived = receivedRet.find(element => element.value === receiptInfoRet.rcpt_against_id);
                if (findReceived !== undefined) {
                  setReceivedIdSelect(findReceived);
                }

                var findMode = receiptModeRet.find(element => element.value === receiptInfoRet.rcpt_mode_id);
                if (findMode !== undefined) {
                  setReceiptModeIdSelect(findMode);
                }
              }
            }
          }
          else {
            setFormValues({ ...formValues, rcpt_no: receiptRet.new_rcpt_no });
            setCustomerIdSelect(customerRet[0]);
            //formValues({ ...formValues, rcpt_no: receiptRet });
            //setCustomerIdSelect(customerRet[0]);

            var findDefaultMode = receiptModeRet.find(element => element.is_default === true);
            if (findDefaultMode !== undefined) {
                setReceiptModeIdSelect(findDefaultMode);  
            }

            setReceipt(receiptRet[0]);

            var findDefaultReceived = receivedRet.find(element => element.is_default === true);
            if (findDefaultReceived !== undefined) {
              setReceivedIdSelect(findDefaultReceived);  
            }
          }
        })();

        /*
        if (state.action === 'view' || state.action === 'edit') {
          console.log("+++++++++++++++++++++inside useEffect edit set+++++++++++++++++++++", state.passed_element);
          setFormValues({
            ...formValues,
            receipt_id: state.passed_element.id,
            receipt_number: state.passed_element.receipt_number,
            customer_id: state.passed_element.received_from,
            receipt_date: state.passed_element.receipt_date,
            customer_name: state.passed_element.customer_name,
            received_amount: state.passed_element.amount,
            received_type: state.passed_element.received_type,
            towards: state.passed_element.towards,
            ref_date: state.passed_element.ref_date,
            ref_number: state.passed_element.ref_number,
            addl_notes: state.passed_element.addl_notes
          });
        }
         */
      }




      /*
      (async () => {
        //await getEmployeeList();
        if (state.action === 'edit' || state.action === 'view') {
          var [retState, invoice_list] = await searchHandleInvoiceFunc(state.passed_element.received_from);
        }
        var [retStateMode, receivedModeArr] = await getReceivedMode();
        if (retStateMode) {
          if (state.action === 'edit' || state.action === 'view') {
            setOptReceivedMode(receivedModeArr);
            var findMode = receivedModeArr.find(element => element.value === state.passed_element.received_mode);
            if (findMode !== undefined) {
              console.log("++++++++++++++++++------------------- FOUND RECEIVED MODE : ", findMode);
              setReceivedModeIdSelect({ value: state.passed_element.received_mode, label: findMode.label });
            }
            else {
              console.log("<<<<<<<<<<<<<< RECEIVED MODE NOT FOUND >>>>>>>>>>>>>>>");
            }
          }
          else {
            setOptReceivedMode(receivedModeArr);
            setReceivedModeIdSelect(receivedModeArr[0]);
          }
        }

        var [retStateby, receivedByArr] = await getEmployeeList();
        if (retStateby) {
          if (state.action === 'view' || state.action === 'edit') {
            setOptReceivedBy(receivedByArr);
            var findBy = receivedByArr.find(element => element.value === state.passed_element.received_by);
            if (findBy !== undefined) {
              console.log("++++++++++++++++++------------------- FOUND EMPLOYEE : ", findBy);
              setReceivedByIdSelect({ value: state.passed_element.received_by, label: findBy.label });
            }
            else {
              console.log("<<<<<<<<<<<<<< EmPLOYEE NOT FOUND >>>>>>>>>>>>>>>");
            }
          }
          else {
            setOptReceivedBy(receivedByArr);
            setReceivedByIdSelect(receivedByArr[0]);
          }
        }

       
        setToRender(true);
      })();
      */
      /*
       if (state === null) {
         navigate("/listCustReceipts");
       } else {
         console.log("PASSED STATE VALUE IN Employee Add/Update page : ", state);
          console.log("Inside else for view or edit ...");
            
         if (state.action === "add") {
           setIsReadOnly(true);
         }
        
         else {
           setFormValues(state.passed_element);
         }
         
          setToRender(true);
        
         console.log("newObj :", formValues);
         if (state.action === "add") {
           setIsReadOnly(false);
         }
       }
       */


      setToRender(true)
      initialized.current = true;
    }
  }, []);

  /*
  const callUpdateApi = async () => {
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
      
      var apiToCall = "/bms/addCustomerReceipt";
      if (state.action === "view" || state.action === 'edit') {
        apiToCall = "/bms/updateCustomerReceipt";
      }

      var reqParam = {
        bid: currSelBusinessRef.current,
        brid: currBranchRef.current,
        date: formValues["date"] || formatDate(new Date()),
        customer_id: customerIdSelect.value,
        amount: formValues["amount"],
        rcpt_mode_id: receiptModeIdSelect.value,
        reference_no: formValues["reference_no"] || "",
        reference_date: formValues["reference_date"] || formatDate(new Date()),
        rcpt_against_id: receivedIdSelect.value,
        received_for: formValues["received_for"] || "",
        notes: formValues["notes"] || "",

      };

      if (state.action === "edit" || state.action === "view") {
        reqParam.id = formValues["id"];
        //reqParam.is_active = checkState;
      }

      var res = await axios.post(client.domain + apiToCall, reqParam, { headers }, { withCredentials: false });

      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        
        setAlertTitle("Success");
        setShowAlert(true);
        setSubmitAction(false);
        if (state.action === "add") {
          setAlertBody(getCustomerMessages(LS_MSG_TAG, "MSG_CUSTOMER_RECEIPT_SAVED"));

        }
        else {
          setAlertBody(getCustomerMessages(LS_MSG_TAG, "MSG_CUSTOMER_RECEIPT_UPDATED"))
        }
        setShowAlert(true);
        

      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);

        }
      }
      setSubmitAction(false);
    }
  };
  */




  const handleReceiptDateChange = async (e) => {
    console.log("handleReceiptDateChange called ...", e);
    console.log("handleReceiptDateChange DATE called ...", e.target.value);

    setFormValues({ ...formValues, rcpt_date: e.target.value });
  };

  const handleChequeDateChange = async (e) => {
    console.log("handleChequeDateChange called ...", e);
    console.log("handleChequeDateChange DATE called ...", e.target.value);
    setFormValues({ ...formValues, ref_date: e.target.value });
  };

  /*
  const onChangeReceivedTime = (e) => {
    console.log("Date onChange Loading time called ...", e);
    console.log("Date onChange Loading time TIME called ...", e.target.value);
    
    setFormValues({ ...formValues, ["receipt_time"]: e.target.value });
  };
  */

  const handleBack = async (e) => {
    console.log("handle back called : ", e);
    navigate(-1);
  };

  const handleReceiptMode = (e) => {
    console.log("Handle Received Mode Select Called : ", e);
    setReceiptModeIdSelect({ value: e.value, label: e.label });
  }

  const handleReferenceInvoice = (e) => {
    console.log("Handle Reference Invoice Select Called : ", e);
    setReferenceInvoiceIdSelect({ value: e.value, label: e.label });
  }

  

  const handleChange = (e, data) => {
    console.log("Handle Change clicked : ", e);
    console.log("Handle Change clicked target : ", e.currentTarget);
    //console.log("HTML tag : ", data);
    const { name, value } = e.currentTarget;
    console.log("Captured Quantity name : ", name);
    console.log("Captured Quantity value : ", value);

    if (name === "rcpt_amt") {
      console.log("Received Amount item edited ...");
      if (e.currentTarget.value.match(appGlobal.numericRegExp)) {
        //setFormValues({ ...formValues, [name]: e.currentTarget.value.replace(/^0+/, '') });
        setFormValues({ ...formValues, [name]: value });
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  }

  const handleClear = async (e) => {
    e.preventDefault();
    console.log("handle clear called : ", e);
    console.log("Handle clear clicked current target ID: ", e.currentTarget.id);
    navigate(0);
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);

    /*
    if (!values.customer_name) {
      errors.customer_name = "Customer Name is required!";
      validateErr = true;
    }
    */

    if (receiptModeIdSelect.label === 'Cheque') {
      if (!values.ref_no) {
        errors.ref_no = "Reference Number is required!";
        validateErr = true;
      }
    }

    if (!values.rcpt_amt) {
      errors.rcpt_amt = " Amount is required!";
      validateErr = true;

    }


    console.log("BEFORE RETURN validate errors ...", validateErr);
    return errors;
  };
  /*
  const callApiToSubmitReceipt = async (settledOrderIds, actionVal) => {
    console.log("ACTON VALUE : ", actionVal);
    console.log("RECEIVED MODE SELECT : ", receivedModeIdSelect.value);
    console.log("RECEIVED MODE SELECT : ", receivedByIdSelect.value);
    var receipt_id_tmp = 0;
    if ( state.action === 'view' || state.action === 'edit' ) {
      receipt_id_tmp = formValues["receipt_id"];
    }
    var reqReceipt = {
      "receipt_id": receipt_id_tmp,
      "receipt_date": formValues.receipt_date,
      "received_mode": receivedModeIdSelect.value,
      "received_by": receivedByIdSelect.value,
      "received_from": formValues.customer_id,
      "received_amount": formValues.received_amount,
      "received_towards": formValues.towards,
      "addl_notes": formValues.addl_notes,
      "ref_number": formValues.ref_number,
      "ref_date": formValues.ref_date,
      "settledOrders": settledOrderIds,
      "invoice_id": referenceInvoiceIdSelect.value,
      "receipt_number": formValues.receipt_number,
      "received_type": formValues.received_type
    };

    console.log("Receipt settle Request payload : ", reqReceipt);

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
      
      if ( actionVal === 'save-print') {
        setSubmitPrintAction(true);
      }
      else {
        setSubmitAction(true);
      }

      var res = await axios.post(client.domain + "/insertReceiptWOAdjust", reqReceipt, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.data);

        if ( actionVal === 'save-print') {
          setSubmitPrintAction(false);
        }
        else {
          setSubmitAction(false);
        }
        setAlertPurpose("success");
        setAlertTitle("Success");
        
        if(state.action === "add"){
          setAlertBody("Receipt Saved Successfully!");
        }
        else{
          setAlertBody("Receipt Updated Successfully!");
        }
        
          if ( actionVal === 'save-print' ) {
            navigate(-1);
            var receipt_id_tmp = res.data.detail.receipt_id;
            console.log("RETURNED RECEIPT ID AFTER SUBMIT : ", receipt_id_tmp);

            var urlStr = window.location.href;
            var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
            var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

            console.log("CALLED URL : ", urlTmp);
            console.log("CALLED PAGE : ", paramsTmp);

            var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printCustReceipt?receipt_id=${receipt_id_tmp}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
            winPrint.moveTo(0, 0);
            winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
          }
          else {
            setShowAlert(true);
          }  
      }
      else {
        console.log("FAILED RECEIPT INSERTION ...");
        setAlertPurpose("failure");
        setSubmitAction(false);
        setAlertTitle("Failure");
        if ( state.action === 'add') {
          setAlertBody("Receipt Save Failed!");
        }
        else {
          setAlertBody("Receipt Update Failed!");
        }
        
        setShowAlert(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);

      if (error.code === 'ERR_NETWORK') {
        console.log("Not able to reach target server please try after sometime");
        //setShowMsg('Please try after sometime');
        //setShowMsgTitle('Network Issue!');
        //setShow(true);
      }
      else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === 'FAILED') {
          console.log('Request failed due to ', error.response.data.detail.res_data);
          //setShowMsg('Please Re-Login');
          //setShowMsgTitle('Invalid Session!');
          //setShow(true);
          //navigate("/auth");
        }
      }
      setAlertPurpose("failure");
      setSubmitAction(false);
      setAlertTitle("Warning");
      setAlertBody("Unable to process your request at this moment.Please try after sometime!");
      setShowAlert(true);
    }
  }
  */

  

  const handleSubmit = async (event, actionVal) => {
    event.preventDefault();
    console.log("<<<<<<<<<<<<<< handleSubmit called ...", actionVal);
    validateErr = false;
    setFormErrors(validate(formValues));
    //setIsSubmit(true);
    console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ", validateErr);

    console.log("FORM VALUES at handleSubmit: ", formValues);
      validateErr = false;
      setFormErrors(validate(formValues));
      console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ", validateErr);
      if (validateErr) {
        return -1;
      }

    var reqParam = {
      bid: currSelBusinessRef.current,
      brid: currBranchRef.current,
      rcpt_date: formValues["rcpt_date"] || formatDate(new Date()),
      customer_id: customerIdSelect.value,
      rcpt_amt: formValues["rcpt_amt"],
      rcpt_mode_id: receiptModeIdSelect.value,
      ref_no: formValues["ref_no"] || "",
      ref_date: formValues["ref_date"] || formatDate(new Date()),
      rcpt_against_id: receivedIdSelect.value,
      received_for: formValues["received_for"] || "",
      notes: formValues["notes"] || "",

    };

    if (state.action === "edit" || state.action === "view") {
      reqParam.id = state.passed_element.id;
      //reqParam.is_active = checkState;
    }
     try {
       var aIVal = localStorage.getItem("_aT");
       var headers = {...client.headers ,Authorization: `Bearer ${aIVal}`}
 
       if ( actionVal === 'save-print') {
         setSubmitPrintAction(true);
       }
      else if ( actionVal === 'save-add') {
        setSubmitAddAction(true);
      }
       else {
         setSubmitAction(true);
       }
       var apiToCall = "/bms/addCustomerReceipt";
      if (state.action === "view" || state.action === 'edit') {
        apiToCall = "/bms/updateCustomerReceipt";
      }
      var res = await axios.post(client.domain + apiToCall, reqParam, { headers }, { withCredentials: false });
       console.log("Response from server : ", res.data.detail);
       if (res.data.detail.status === "SUCCESS") {
         console.log("Response message from server : ", res.data.detail.res_data);
         if ( actionVal === 'save-print' ) {
           setSubmitPrintAction(false);
           submitActionRef.current = "save-print";
         }
         else if ( actionVal === 'save-add') {
          setSubmitAddAction(false);
          submitActionRef.current = "save-add";
        }
         else {
           setSubmitAction(false);
           submitActionRef.current = "save-only";
         }
         setAlertTitle("Success");
         setAlertPurpose('success');
         if(state.action === "add"){ 
           setAlertBody(getCustomerMessages(LS_MSG_TAG, "MSG_CUSTOMER_RECEIPT_SAVED"));
         } 
        else {
         setAlertBody(getCustomerMessages(LS_MSG_TAG, "MSG_CUSTOMER_RECEIPT_UPDATED"));
         }
         //setShowAlert(true);
 
         if ( actionVal === 'save-print' ) { 
           var urlStr = window.location.href;
           var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
           var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));
       
           console.log("CALLED URL : ", urlTmp);
           console.log("CALLED PAGE : ", paramsTmp);
           //var idPass = state.passed_element.id;
           //window.open(`${urlTmp}${client.urlDelimiter}/printFullOrder?order_id=${passedValue.order_id}`);
           var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printCustReceipt?id=${res.data.detail.data.id}`, "width=" + window.screen.width + ",height=" + window.screen.height);
           winPrint.moveTo(0,0);
           winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
           navigate('/listCustReceipts');
         }
         else if ( actionVal === 'save-add') {
          //navigate(0);
          setShowAlert(true);
        }
         else {
           setShowAlert(true);
         }  
       }
       else {
         setSubmitAction(false);
         setAlertPurpose('failed');  
         setAlertTitle("Failed");
         if(state.action === 'add'){    
           setAlertBody("Receipt Save Failed!");
         }
         else{
           setAlertBody("Receipt Update Failed!");
         }
        
         setShowAlert(true);
       }
     } catch (error) {
       console.log("FULL ERROR : ", error);
       setAlertPurpose("failure");
       setAlertTitle("Warning");
       setAlertBody("Unable to process your request at this moment.Please try after sometime!");
       setShowAlert(true);
       
      
       setSubmitAction(false);
     }

  };
  

  /*
  const handleSubmit = async (event, actionVal) => {
    event.preventDefault();
    console.log("<<<<<<<<<<<<<< handleSubmit called ...", actionVal);
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ", validateErr);

    var settledOrderIds = [];


    if (!validateErr) {
      callUpdateApi();
      //await callApiToSubmitReceipt(settledOrderIds, actionVal);
    }
    //setReceivedSaved(true);
  }
  */
  

  const handlePrintReceipt = async () => {
    console.log("Handle print receipt called ...");
    //navigate("/printCustReceipt")

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);
    //var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printCustReceipt`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printCustReceipt?id=${state.passed_element.id}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0, 0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
  }

  const handleCheckBoxOnChange = async (e, data) => {
    console.log("handleCheckBoxOnChange called event : ", e);
    console.log("handleCheckBoxOnChange called currentTarget : ", e.currentTarget);
    console.log("handleCheckBoxOnChange called currentTarget CHECKED STATE : ", e.currentTarget.checked);

    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelRowId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(0, clickedTagId.lastIndexOf("-"));

      console.log("current selected element name view : ", nameOfElement);
      console.log("Current SELECTED row id : ", curSelRowId);

      var selectedPendingOrder = pendingOrderList[curSelRowId];
      console.log("SELECTED PENDING ORDER LIST : ", selectedPendingOrder);

      if (e.currentTarget.checked === true) {
        selectedPendingOrder.selected = true;
      }
      else {
        selectedPendingOrder.selected = false;
      }

      var pendingOrderListNew = pendingOrderList.slice();
      pendingOrderListNew[curSelRowId] = selectedPendingOrder;
      setPendingOrderList(pendingOrderListNew);
    }
  };
  /*
  const getTotVolPerCustPerProd = async (customerId, productId, locationName, dc_date) => {
    console.log("SELECTED CUSTOMER ID : ", customerId);
    console.log("SELECTED PRODUCT ID : ", productId);

    var retState = false;
    var prodRes = {};
    var total_quantity_ret = 0.00;
    console.log("getTotVolPerCustPerProd called ... ");

    var userReq = {
      params: {
        customerId: customerId,
        productId: productId,
        location: locationName,
        dc_date: dc_date
      }
    };

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`, SerialKey: 'HNGD8743JESWE' };
      var res = await axios.get(client.domain + "/getTotVolPerCustPerProd", userReq, { headers }, { withCredentials: false });
      console.log("Response from server getProductForLoad : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getTotVolPerCustPerProd : ", res.data.detail.res_data);
        //setShowConfigDialog(true);

        retState = true;
        total_quantity_ret = res.data.detail.res_data.total_quantity;

        var itemSelected = items[selectedDCItemRow];
        //itemSelected.total_quantity = total_quantity_ret + itemSelected.quantity;
        itemSelected.existing_quantity = parseFloat(total_quantity_ret);
        itemSelected.total_quantity = itemSelected.existing_quantity + parseFloat(itemSelected.quantity);
        var itemsNew = items.slice();
        itemsNew[selectedDCItemRow] = itemSelected;
        setItems(itemsNew);


        //console.log("PRODUCT LOAD STATUS SET TO TRUE")
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );

        }
      }
    }
    return [retState, total_quantity_ret];
  }
  */


  const customerSearchSelectFunc = async (selected) => {

    console.log("**************** User selected value from search result +++++++++++++ : ", selected);
    /*
    formValues["customer_name"] = selected[0].name;
    if (selected.length > 0) {
      setSearchedCustomer(selected[0]);

      const response = await fetch(`${client.domain}/getCustomerInfo?customer_id=${selected[0].value}`);
      const customer_info = await response.json();
      console.log("CUSTOMER INFO : ", customer_info.detail.res_data.customer_info);
      
      for (const [key, value] of Object.entries(customer_info.detail.res_data.customer_info)) {
       
        console.log("KEY : %s, VAL: %s", key, value);

      }
      setFormValues({ ...formValues, customer_id: customer_info.detail.res_data.customer_info.customer_id, customer_name: customer_info.detail.res_data.customer_info.customer_name });
      //var [retState, invoice_list] = await searchHandleInvoiceFunc(customer_info.detail.res_data.customer_info.customer_id);
      console.log("++++++++++++++++++++++++++Before assign^^^^^^^^^^^^^^^^^^^^^^^^^^^")
      
    }
    */
  };

  /*
   const invoiceSearchSelectFunc = async (selected) => {
     console.log("**************** User selected value from search result +++++++++++++ : ", selected);
     if (selected.length > 0) {
       setSearchedCustomer(selected[0]);
 
       const response = await fetch(`${client.domain}/getCustomerInfo?customer_id=${selected[0].value}`);
       const customer_info = await response.json();
       console.log("CUSTOMER INFO : ", customer_info.detail.res_data.customer_info);
       //setIsLoading(false);
 
       //setFormValues({...formValues, [name]: value});
       //let entries = Object.entries(company_info.detail.res_data.company_info)
       //entries.map( ([key, val] = entry) => {
       //entries.map( ([key, val] = entry) => {
       for (const [key, value] of Object.entries(customer_info.detail.res_data.customer_info)) {
         //console.log("KEY : ", key);
         //console.log("VAL : ", val);
         console.log("KEY : %s, VAL: %s", key, value);
 
         //setFormValues({...formValues, [key]: value});
       }
 
       setFormValues({ ...formValues, ref_invoice_number: customer_info.detail.res_data.customer_info.customer_id, ref_invoice_value: customer_info.detail.res_data.customer_info.customer_name });
       
     }
   };
   */

   const handlePostSaveOk = (e) => {
    console.log("user confirmed to customer added ...");
    setShowAlert(false);
    if( submitActionRef.current === 'save-add'){
      navigate(0);
      //navigate("/listCustomers");
      console.log("SaveOk action called for dialog confirm **************************");
    }
    else if( submitActionRef.current === 'save-print'){
      console.log("SavePrint action called for dialog confirm **************************");
      navigate('/printCustReceipt');
    }
    else{
    console.log("Action called for dialog confirm **************************");
    navigate("/listCustReceipts");
    }
  };
  /*
  const handlePostSaveOk = async (e) => {
    setShowAlert(false);
    console.log("user confirmed to customer added ...");
    if( submitActionRef.current = "save-add"){
      console.log("SaveOk action called for dialog confirm **************************");
      navigate(0);
      //navigate("/listCustReceipts");

    }
   else if( submitActionRef.current === 'save-print'){
      console.log("SavePrint action called for dialog confirm **************************");
      navigate('/printCustReceipt');
    }
    else{
    console.log("Action called for dialog confirm **************************");
    navigate("/listCustReceipts");
    }  
  };
  */

  const handleCloseDialog = async () => {
    setShowAlert(false);
    console.log("handleCloseDialog clicked ...");

    if (alertPurpose === 'success') {
      navigate(-1);
      setAlertPurpose('init');
    }
    else {
      setAlertPurpose('init');
    }
    /*
    if (dialogResStatus === 'success') {
      navigate(-1);
    }
    */
  };
  const searchHandleFunc = async (query) => {
    setIsLoadingCust(true);

    fetch(`${client.domain}/search/customers?q=${query}`)
      .then((resp) => resp.json())
      .then((json) => {
        //searchActionFunc(json)
        setOptionsSearch(json.detail.res_data.customer_list);
        setIsLoadingCust(false);
        console.log("SEARCHED VALUE inside searchHandleFunc : ", json.detail.res_data.customer_list);
      });
    //formValues["customer_name"] = query;
    //setFormValues({ ...formValues, ["customer_disp_name"]: query });
    /*
    if ( addrCopyCheck && !formValues.contact_person) {
      setFormValues({ ...formValues, ["shipContactName"]: query });
    }
    */
    //setFormValues({ ...formValues, ["customer_name"]: query });
    setCustomerSearchText(query);
  };

  const searchHandleInvoiceFunc = async (customer_id_pass) => {
    var retState = false;
    var invoice_list_ret = [];
    console.log("searchHandleInvoiceFunc called ...");
    setIsLoadingInv(true);
    fetch(`${client.domain}/search/invoice?customer_id=${customer_id_pass}`)
      .then((resp) => resp.json())
      .then((json) => {
        //searchActionFunc(json)
        if (json.detail.res_data.hasOwnProperty("invoice_list")) {
          console.log("Invoice List exist ...");
          console.log("SEARCHED VALUE inside searchHandleInvoiceFunc : ", json.detail.res_data.invoice_list);
          invoice_list_ret = json.detail.res_data.invoice_list;
          setOptReferenceInvoice(invoice_list_ret);
          var findInvoice = json.detail.res_data.invoice_list.find(element => element.value === state.passed_element.invoice_id);
          if (findInvoice !== undefined) {
            console.log("++++++++++++++++++------------------- FOUND INVOICE INFO ++++++++++++ : ", findInvoice);
            setReferenceInvoiceIdSelect({ value: state.passed_element.invoice_id, label: findInvoice.label });
          }
          //setOptionsSearchInv(json.detail.res_data.invoice_list);
          retState = true;
        }
        else {
          //setOptionsSearchInv([]);
        }
        //let testArr = [{ value:657, label: " INV001 - 12-12-2023 - 2000" }, { value:876, label: " INV002 - 10-1-2023 - 3000" },{ value:978, label: " INV003 - 05-2-2023  - 5000" }]
        //setOptionsSearchInv(testArr);
        setIsLoadingInv(false);

      });
    //formValues["customer_name"] = query;
    //setFormValues({ ...formValues, ["adderss1"]: query });SEARCHED VALUE inside searchHandleInvoiceFunc
    /*
    if ( addrCopyCheck && !formValues.contact_person) {
      setFormValues({ ...formValues, ["shipContactName"]: query });
    }
    */
    //setFormValues({ ...formValues, ["customer_name"]: query });
    setInvoiceSearchText(customerSearchText);

    return [retState, invoice_list_ret];
  };

  const handleEdit = async (e) => {
    console.log("handle edit  clicked : ", e);
    setIsReadOnly(false);
  };

  console.log("FORMVALUES FULL : ", formValues);
  console.log("INVOICE OPTIONS : ", optionsSearchInv);

  const handleRadioChange = async (e) => {
    console.log("Value button clicked : ", e);

    const { name, id } = e.currentTarget;
    console.log("Captured value name at handleEdit : ", name);
    console.log("Captured value id at handleEdit : ", id);

    var currentRowName = id.substring(id.lastIndexOf("-") + 1);
    var nameOfElement = id.substring(0, id.lastIndexOf("-"));
    console.log("Name of element at handleEdit : ", nameOfElement);
    console.log("Current row id at handleEdit : ", currentRowName);
    setFormValues({ ...formValues, ["received_type"]: currentRowName.toUpperCase() });
};

  const handleCustomerSelect = async (e) => {
    console.log("Handle customer select called : ", e);
    setCustomerIdSelect(e);
  };

  const handleReceivedRadioChange = async (e) => {
    console.log("Value button clicked : ", e);

    const { name, id } = e.currentTarget;
    console.log("Captured value name at handleReceivedRadioChange : ", name);
    console.log("Captured value id at handleReceivedRadioChange : ", id);

    var currentRowName = id.substring(id.lastIndexOf("-") + 1);
    var nameOfElement = id.substring(0, id.lastIndexOf("-"));
    console.log("Name of element at handleReceivedRadioChange : ", nameOfElement);
    console.log("Current row id at handleReceivedRadioChange : ", currentRowName);
    //setFormValues({ ...formValues, ["received_type"]: currentRowName.toUpperCase() });
    setReceivedIdSelect({ value: parseInt(currentRowName), label: e.currentTarget.label })


  };


  console.log("************** RECEIVED AMOUNT &&&&&&&&&&&&&&&&& : ", formValues.received_amount);

  const sendDataToParent = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if (passedBackValue.hasOwnProperty("business_id")) {
      currSelBusinessRef.current = passedBackValue["business_id"];
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);

  }

  return (
    <Container>
      <SidebarMain sendBacktoParent={sendDataToParent} />
      {toRender ?
        (
          <Form>
            <Row>
              <Col xs={4} md={4} sm={4} lg={4}>
                <span style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                  {menuSettings.permissions.find(element => (element.code === 'listCustReceipts' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'listCustReceipts')).page_title_addupd : "Receipt"}
                </span>
                {/*
                {(state.action === "view" || state.action === "edit") && (
                    <>
                      &nbsp;&nbsp;
                      <Badge pill bg="success">
                        <h6>{state.passed_element.receipt_number}</h6>
                      </Badge>
                    </>
                  )}
                */}

              </Col>
              <Col
                xs={8}
                md={8}
                sm={8}
                lg={8}
                className="d-flex justify-content-end float-end"
              >
                <Button variant="warning" className="btn btn-warning btn-md">
                  <BsArrowLeftCircle
                    key={`back-btn-receipt-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleBack(e)}
                    name={`back-icon-receipt-name`}
                    id={`back-icon-receipt-id`}
                  />
                </Button>
                &nbsp;&nbsp;
                {(state.action === "edit" || state.action === "view") &&
                  <Button id="orderPrint" name="orderPrint" onClick={(e) => handlePrintReceipt(e)}
                    disabled={menuSettings.permissions.find(element => (element.code === 'listCustReceipts' && element.a_print === true)) ? false : true}
                  >
                    <BsFillPrinterFill
                      size={18}
                      style={{ color: "white", cursor: "pointer" }}
                      name={`print-receipt-icon`}
                      id={`print-receipt-icon`}
                    />
                  </Button>
                }
                &nbsp;

                {(state.action === "edit" || state.action === "view") && (
                  <>
                    &nbsp;&nbsp;
                    <Button variant="primary" className="btn btn-primary btn-md" onClick={(e) => handleEdit(e)}
                      disabled={menuSettings.permissions.find(element => (element.code === 'listCustReceipts' && element.a_edit === true)) ? false : true}
                    >
                      <BsPencilSquare
                        key={`edit-btn-patient-key`}
                        size={24}
                        style={{ cursor: "pointer" }}

                        name={`edit-icon-paBiSolidEditAlttient-name`}
                        id={`edit-icon-doctor-id`}
                      />
                    </Button>
                  </>
                )}
              </Col>
            </Row>

            <p></p>
            <hr align="center" />

            <Row>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Receipt No</Form.Label>
                  <Form.Control
                    type="text"
                    name="rcpt_no"
                    id="rcpt_no"
                    placeholder="Receipt No"
                    value={formValues.rcpt_no}
                    onChange={handleChange}
                    disabled={ (state.action === 'edit' || state.action === 'view') ?  true : false}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  //maxLength={12}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.rcpt_no}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Receipt Date</Form.Label>
                  <Form.Control
                    type="date"
                    id="rcpt_date"
                    name="rcpt_date"
                    placeholder="Receipt Date"
                    value={formValues.rcpt_date}
                    onChange={handleReceiptDateChange}
                    readOnly={isReadOnly}
                  />
                </Form.Group>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Label className="field">Customer</Form.Label>
                <Select
                  inline
                  id="customer"
                  name="customer"
                  value={{
                    value: customerIdSelect.value,
                    label: customerIdSelect.label,
                  }}
                  options={optCustomer}
                  defaultValue={{
                    value: customerIdSelect.value,
                    label: customerIdSelect.label,
                  }}
                  onChange={handleCustomerSelect}
                //isDisabled={isReadOnly}
                />
              </Col>
              {/*
                 <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group
                  className="mb-3"

                >
                  <Form.Label className="field">Customer</Form.Label>
                  <AsyncTypeahead
                    id="customer-search-fd-id"
                    name="customer_name_search"
                    isLoading={isLoadingCust}
                    onSearch={(query) => {
                      searchHandleFunc(query);
                    }}
                    onChange={(selected) => {
                      customerSearchSelectFunc(selected);
                    }}
                    options={optionsSearch}
                    placeholder="Search Customer ..."
                    readOnly={(state === null || state.action === 'add') ? isReadOnly : true}
                    style={{
                      borderColor: "gray"
                    }}
                    className="rounded-sm" />
                </Form.Group>
              </Col>
                 
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Customer Name </Form.Label>
                  <Form.Control
                    type="text"
                    name="customer_name"
                    id="customer_name"
                    placeholder="Customer Name"
                    value={formValues.customer_name}
                    onChange={handleChange}
                    disabled="true"
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                    //maxLength={12}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.customer_name}</p>
              </Col>
               

              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Received By</Form.Label>
                  <Select

                    id="received_from"
                    name="received_from"
                    value={{
                      value: receivedByIdSelect.value,
                      label: receivedByIdSelect.label,
                    }}
                    options={optReceivedBy}
                    defaultValue={{
                      value: receivedByIdSelect.value,
                      label: receivedByIdSelect.label,
                    }}
                    onChange={handleReceivedBy}
                    isDisabled={isReadOnly}
                  />
                </Form.Group>
              </Col>
              */}
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Amount</Form.Label>
                  <Form.Control
                    type="text"
                    name="rcpt_amt"
                    id="rcpt_amt"
                    placeholder="Amount"
                    value={formValues.rcpt_amt}
                    //maxLength={10}
                    onChange={handleChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray", textAlign: "right"
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.rcpt_amt}</p>
              </Col>

            </Row>
            <Row>



              <Col xs={3} sm={3} md={3} lg={3}>

                <Form.Group className="mb-3">
                  <Form.Label >Receipt Mode</Form.Label>
                  <Select

                    id="rcpt_mode_id"
                    name="rcpt_mode_id"
                    value={{
                      value: receiptModeIdSelect.value,
                      label: receiptModeIdSelect.label,
                    }}
                    options={optReceiptMode}
                    defaultValue={{
                      value: receiptModeIdSelect.value,
                      label: receiptModeIdSelect.label,
                    }}
                    onChange={handleReceiptMode}
                    isDisabled={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.rcpt_mode_id}</p>

              </Col>

              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  {/*<Form.Label>Cheque / Reference No </Form.Label>*/}
                  <Form.Label className={receiptModeIdSelect.label === 'Cheque' ? 'field required' : 'field mb-3'}>Cheque / Reference No </Form.Label>
                  <Form.Control
                    type="text"
                    name="ref_no"
                    id="ref_no"
                    placeholder="Cheque / Reference No"
                    value={formValues.ref_no}
                    onChange={handleChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.ref_no}</p>
              </Col>


              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Cheque / Reference Date</Form.Label>
                  <Form.Control
                    type="date"
                    id="ref_date"
                    name="ref_date"
                    placeholder="Cheque / Reference Date"
                    value={formValues.ref_date}
                    onChange={handleChequeDateChange}
                    readOnly={isReadOnly}
                  />
                </Form.Group>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                {/*<div key={`inline-radio`} className="mb-3" style={{ paddingTop: '35px' }}>*/}
                <Form.Label>Received Against</Form.Label>
                <Form.Group>

                  {optReceived.map((receivedInd, idx) => (
                    <>
                      <Form.Check
                        disabled={isReadOnly}
                        inline
                        label={receivedInd.label}
                        name="receipt_against"
                        type={"radio"}
                        id={`inline-radio-${receivedInd.value}`}
                        onChange={(e) => handleReceivedRadioChange(e)}
                        //checked={formValues["payment_type"] === paymentInd.value ? true : false}
                        checked={receivedIdSelect.value === receivedInd.value ? true : false}
                      />
                    </>
                  ))}
                </Form.Group>
                {/*</div>*/}
              </Col>
              {/*
              <Col xs={3} md={3} sm={3} lg={3}>
                <div key={`inline-radio`} className="mb-3" style={{ paddingTop: '35px' }}>
                  <Form.Group>
                    <Form.Label>Received As &nbsp;&nbsp;</Form.Label>
                    <Form.Check
                      disabled={isReadOnly}
                      inline
                      label="Outstanding"
                      name="received_type"
                      type={"radio"}
                      id={`inline-radio-outstanding`}
                      onChange={(e) => handleRadioChange(e)}
                      checked={formValues["received_type"] === 'OUTSTANDING' ? true : false}
                    />
                    <Form.Check
                      disabled={isReadOnly}
                      inline
                      label="Advance"
                      name="received_type"
                      type={"radio"}
                      id={`inline-radio-advance`}
                      onChange={(e) => handleRadioChange(e)}
                      checked={formValues["received_type"] === 'ADVANCE' ? true : false}
                    />
                  </Form.Group>
                </div>
              </Col>
                  */}


            </Row>
            <Row>
              {/*
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group
                  className="mb-3"
                >
                  <Form.Label className="field">Reference Invoice</Form.Label>
                  <Select

                    id="reference_invoice"
                    name="reference_invoice"
                    value={{
                      value: referenceInvoiceIdSelect.value,
                      label: referenceInvoiceIdSelect.label,
                    }}
                    options={optReferenceInvoice}
                    defaultValue={{
                      value: referenceInvoiceIdSelect.value,
                      label: referenceInvoiceIdSelect.label,
                    }}
                    onChange={handleReferenceInvoice}
                    isDisabled={isReadOnly}
                  />
                  


                  
                </Form.Group>
              </Col>
              */}

            </Row>


            <Row>
              <Col xs={6} md={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Received For</Form.Label>
                  <Form.Control
                    as="textarea" rows={3}
                    name="notes"
                    id="notes"
                    placeholder="Notes"
                    value={formValues.notes}
                    onChange={handleChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
              </Col>

              <Col xs={6} md={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    as="textarea" rows={3}
                    name="received_for"
                    id="received_for"
                    placeholder="Additional Notes"
                    value={formValues.received_for}
                    onChange={handleChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
              </Col>

            </Row>
            {!isReadOnly && (
              <Row>
                <Col xs={12} md={12} sm={12}>
                  <div className="d-flex justify-content-end">
                    <Button
                      className="btn btn-secondary"
                      id="btn-clear-receipt-id"
                      name="btn-clear-receipt-name"
                      onClick={handleClear}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    {state.action === "add" &&
                    <Button
                      className="btn btn-primary"
                      id="save-print"
                      name="save-print"
                      onClick={(e) => handleSubmit(e, "save-add")}
                    >
                      {submitAddAction &&
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      }
                      Save & Add</Button>
                    }
                    &nbsp;&nbsp;
                    <Button
                      className="btn btn-primary"
                      id="save-print"
                      name="save-print"
                      onClick={(e) => handleSubmit(e, "save-print")}
                    >
                      {submitPrintAction &&
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      }
                      Save & Print</Button>
                    &nbsp;
                    &nbsp;&nbsp;
                    <button className="btn btn-primary" onClick={(e) => handleSubmit(e, 'save-only')}>
                      {submitAction &&
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      }

                      Save</button>
                  </div>
                </Col>
              </Row>
            )}
            <Modal
              show={showAlert}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{alertTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{alertBody}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>

          </Form>
        )
        :
        <div className="container-spinner">
          <div className="vertical-center-spinner">
            <Image src={spinner_logo} roundedCircle style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
            <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning" style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>

              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      }
    </Container>
  );

}

export default AddUpdateCustReceipt;