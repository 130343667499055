import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';

import { client } from '../config/Config';

//import StockDocument from "./StockDocument";
import {getCompanyInfo, formatDateIndian } from "./utils";

//import avinaoffice_logo from '../avinaoffice_logo.png';
import business_logo from '../assets/images/business_logo.png';


const borderColor = '#000000';
//const rowHeight = 26;
const tblHeadHeight = 24;
const itemRowHeight = 24;
const paddingHeight = 2;
const itemExtraHeight = 0;
const amountInWordsHeight = 22;
const styles = StyleSheet.create({
  
  page: {
    fontFamily: 'Helvetica',
    fontSize: 9,
    paddingTop: 30,
    paddingLeft: 20,
    paddingRight: 20,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    //width: '100%',
    //height: '33%',
    width: '100%',
    height: '100%',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logo_image2: {
    width: '15%',
    height: '98px',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  business_content: {
    width: '60%',
    height: '98px',
    //paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container2: {
    //paddingBottom:0,
    flexDirection: 'row',
    //borderBottomColor: '#bff0fd',
    //backgroundColor: '#bff0fd',
    //borderBottomWidth: 1,
    //alignItems: 'center',
    //height: 12,
    //textAlign: 'center',
    //fontStyle: 'bold',
    //flexGrow: 1,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
    tableContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      //marginTop: 14,
      borderWidth: 1,
      //borderLeft:2,
      borderColor: '#000000', 
      borderStyle:  'solid'
  },
  tableContainer1: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderRadius: '1px',
    //border: '1 solid gray'
    //paddingLeft: "1px",
    //paddingRight: "1px",
    //borderLeftWidth: "1px",
    //borderRightWidth: "1px",
    //borderTopWidth: "1px",
    //borderBottomWidth: "1px",
    //marginTop: 1,
    //marginBottom: 11,
    //borderWidth: 1,
    //borderColor: '#a8a8a8',

  },
  
 

   
  /*
  container: {
    flexDirection: 'row',
    borderBottomColor: '#a8a8a8',
    backgroundColor: '#a8a8a8',
    //borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
  },
  */
  
  row: {
    flexDirection: 'row',
    //borderBottomColor: '#a8a8a8',
    //borderBottmWidth: 1,
    alignItems: 'center',
    height: 10,
    fontStyle: 'bold',
  },
   


    container: {
      flexDirection: 'row',
      //borderBottomColor: '#a8a8a8',
      backgroundColor: '#a8a8a8',
      borderBottomWidth: 1,
      alignItems: 'center',
      //height: '36px',
      textAlign: 'center',
      fontStyle: 'bold',
      //borderStyle: 'solid'
      //borderWidth: 1
      //flexGrow: 1
  },
  sno: {
      width: '10%',
      //height: itemRowHeight,
      height: tblHeadHeight,
      paddingLeft:"5px",
      borderRightColor: borderColor,
      borderRightWidth: 1,
      borderLeftWidth:1,
     
      paddingTop: '6px',
      borderRightColor: borderColor,
  },
  description: {
      width: '50%',
      height: tblHeadHeight,
      borderRightColor: borderColor,
     borderRightWidth: 1,
      paddingTop: '6px',
      borderRightColor: borderColor,
  },
 
  hsncode: {
      width: '0%',
      height: tblHeadHeight,
      borderRightColor: borderColor,
      borderRightWidth: 1,
      paddingTop: '6px',
      borderRightColor: borderColor,
  },

  qty: {
      width: '10%',
      height: tblHeadHeight,
      borderRightColor: borderColor,
      borderRightWidth: 1,
      paddingTop: '6px',
      borderRightColor: borderColor,
  },
 
  rate: {
      width: '10%',
      height: tblHeadHeight,
      //flexDirection: 'row',
      borderRightColor: borderColor,
      borderRightWidth: 1,
      //paddingRight:"10px",
      paddingTop: '6px',
      borderRightColor: borderColor,
  },
 
  
  amount: {
    width: '20%',
    height: itemRowHeight,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    //paddingTop: '6px',
    borderRightColor: borderColor,
    //borderLeftWidth:1,
     
},

rate1: {
  //width: '10%',
  height: tblHeadHeight,
  //flexDirection: 'row',
  borderRightColor: borderColor,
  borderRightWidth: 1,
  //paddingRight:"10px",
  paddingTop: '6px',
  borderRightColor: borderColor,
},


amount1: {
//width: '20%',
height: tblHeadHeight,
 borderRightColor: borderColor,
borderRightWidth: 1,
paddingTop: '6px',
borderRightColor: borderColor,
borderLeftWidth:1,
 
},


sno_with_space: {
  width: '10%',
  textAlign: 'left',
  //paddingLeft:"5px",
  borderRightColor: borderColor,
  borderRightWidth: 1,
  height: itemRowHeight,
  borderLeftWidth:1,
  paddingLeft: "2px"
     
  //paddingRight: 18,
},
description_with_space: {
  width: '50%',
  textAlign: 'left',
  borderRightColor: borderColor,
  borderRightWidth: 1,
  height: itemRowHeight,
  paddingLeft: "2px"
  //paddingLeft: 5,
  //height: 48,
},
hsncode_with_space: {
  width: '0%',
  textAlign: 'left',
  borderRightColor: borderColor,
  borderRightWidth: 1,
  height: itemRowHeight,
  paddingLeft: 5,
},
qty_with_space: {
  width: '10%',
  borderRightColor: borderColor,
  borderRightWidth: 1,
  textAlign: 'right',
  height: itemRowHeight,
  paddingRight: 5,
},
rate_with_space: {
  width: '10%',
  borderRightColor: borderColor,
  borderRightWidth: 1,
  textAlign: 'right',
  height: itemRowHeight,
  paddingRight: 5,
},


amount_with_space: {
  width: '20%',
  textAlign: 'right',
  borderRightColor: borderColor,
  borderRightWidth:1,
  height: itemRowHeight,
  paddingRight: 5,
},
row_empty: {
  flexDirection: 'row',
  borderBottomColor: '#a8a8a8',
  //borderBottomWidth: 1,
  alignItems: 'center',
  height: itemRowHeight,
  fontStyle: 'bold',
},
amount_in_words: {
  //flexWrap: 'wrap',
  width: '50%',
  borderRightColor: borderColor,
  borderRightWidth: 1,
  textAlign: 'right',
  paddingRight: 8,
  height: amountInWordsHeight,
  borderLeftWidth: 1,
  borderLeftColor:borderColor,
  height: itemRowHeight
  //marginTop: 1,
  //marginBottom: 10,
  //borderWidth: 1,
},

amount_in_words1: {
  //flexWrap: 'wrap',
  width: '50%',
  borderRightColor: borderColor,
  borderRightWidth: 1,
  textAlign: 'right',
  paddingRight: 8,
  height: amountInWordsHeight,
  //borderLeftWidth: 1,
  borderLeftColor:borderColor,
  height: itemRowHeight
  //marginTop: 1,
  //marginBottom: 10,
  //borderWidth: 1,
},

total: {
  width: '18%',
  height: itemRowHeight,
  //flexDirection: 'row',
  borderRightColor: borderColor,
  borderRightWidth: 1,
  //paddingRight:"10px",
  //paddingTop: '1px',
},
/*
amount: {
width: '20%',
height: 20,
// borderRightColor: borderColor,
//borderRightWidth: 1,
//paddingTop: '6px',
borderRightColor: borderColor,
},
*/
cgst: {
  width: '18%',
  height: itemRowHeight,
  //flexDirection: 'row',
  borderRightColor: borderColor,
  borderRightWidth: 1,
  //paddingRight:"10px",
  //paddingTop: '1px',
},
sgst: {
  width: '18%',
  height: itemRowHeight,
  //flexDirection: 'row',
  borderRightColor: borderColor,
  borderRightWidth: 1,
  //paddingRight:"10px",
  //paddingTop: '1px',
},
round_off: {
  width: '18%',
  height: itemRowHeight,
  //flexDirection: 'row',
  borderRightColor: borderColor,
  borderRightWidth: 1,
  //paddingRight:"10px",
  //paddingTop: '1px',
},
grant_total: {
  width: '18%',
  height: itemRowHeight,
  //flexDirection: 'row',
  borderRightColor: borderColor,
  borderRightWidth: 1,
  //paddingRight:"10px",
  //paddingTop: '1px',
},

rupees: {
  //width: '18%',
  height: 50,
  //flexDirection: 'row',
  borderleftColor: borderColor,
  borderLeftWidth: 1,
  //paddingRight:"10px",
  //paddingTop: '1px',
  //borderRightColor: borderColor,
},

left3: {
    // width: '33%',//<- working alternative
    padding: 5,
    flexGrow: 0,
    //flexShrink: 1,
    //flexBasis: 230,
    margin: 0.5, 
    borderTop: 1, 
    borderBottom: 1, 
    borderLeft: 1, 
    borderRight: 1, 
    //borderColor: '#a8a8a8', 
    BorderStyle: 'dashed',
    height: '120px',
    //border: 'solid #e7e5e5 3px',
    //padding: '1em',
    //margin: '1em',
    borderRadius: 5
  },

  headerContainer: {
    margin: 0.5,
    borderTop: 1, 
    borderBottom: 1, 
    borderLeft: 1, 
    borderRight: 1,
    //borderColor: '#a8a8a8', 
    BorderStyle: 'dashed',
    height: '120px',
    borderRadius:5
    //paddingLeft: "150px"
    //borderBottomColor: '#bff0fd',
},
to: {
    marginTop: 5,
    //paddingBottom: 3,
    //fontFamily: 'Helvetica-Oblique'
    fontFamily: 'Helvetica-Bold'
},

left: {
  // width: '33%',//<- working alternative
  padding: 5,
  flexGrow: 0,
  //flexShrink: 1,
  //flexBasis: 230,
  margin: 0.5, 
  borderTop: 1, 
  borderBottom: 1, 
  borderLeft: 1, 
  borderRight: 1, 
  //borderColor: '#a8a8a8', 
  BorderStyle: 'dashed',
  height: '70px',
  //border: 'solid #e7e5e5 3px',
  //padding: '1em',
  //margin: '1em',
  borderRadius: 5
},

right: {
  margin: 0.5,
  borderTop: 1, 
  borderBottom: 1, 
  borderLeft: 1, 
  borderRight: 1,
  //borderColor: '#a8a8a8', 
  BorderStyle: 'dashed',
  height: '70px',
  borderRadius: 5
  //paddingLeft: "150px"
  //borderBottomColor: '#bff0fd',
},



  
});
const PrintBillPayment = (props) => {
    const initialized = useRef(false);
    var gstSlab = useRef({});
    const [reportData, setReportData] = useState({});
    const [toRender, setToRender] = useState(false);
    const [companyInfo, setCompanyInfo] = useState({});
    const [receiptDetails, setReceiptDetails] = useState({});
    var invoiceDataAsWhole = {
      sum_product_cost: 1000.00,
      sum_product_cgst: 45.33,
      sum_product_sgst: 34.22,
      sum_rounded_off: 0.07,
      sum_product_total: 1089.93
    };

    let invoiceData = [
        
      {
       description: "TMT Rods",
       quantity: "6",
       rate: "8700",
       amount:"7865"

    },
    {
      description: "Amman Steels",
      quantity: "5",
      rate: "100",
      amount:"5643"
      

   },
   {
    description: "Jay Steels",
    quantity: "8",
    rate: "7800",
    amount:"7653"
   
 },
 
    ]
  const [invoiceList, setInvoiceList] = useState(invoiceData);
   
 

    

    useEffect(() => {
        console.log("useEffect method called ...");
       /*
        (async () => {
          
          var [retState, companyInfoTmp] = await getCompanyInfo();
          if (retState) {
            setCompanyInfo(companyInfoTmp);
          }
         
        setToRender(true);
        })();
        */
        setToRender(true);
        
      }, [])
    


    return (
        <PDFViewer style={styles.viewer}>
          {toRender === true &&
            <Document>
              <Page size="A4" orientation="portrait" style={styles.page}>
              
    
               
             
              {/*<View style={{ ...styles.tableContainer, paddingBottom: '20px'}}>*/}
               {/*
               <View style={{ flexDirection: 'row'}}>
                   <View style={{flex: 1, textAlign: 'left', alignItems: 'left', alignContent: 'left'}}>
                   <Text> GSTIN : {rcInfo.reg_number}</Text>
                   </View>
                   <View style={{flex: 1}}>
                   <Text style={{textAlign: 'right', alignItems: 'right', alignContent: 'right'}}>Cell : {rcInfo.phone}</Text>
                   </View>
                </View>
                */}
                {/*
                <View style={{flexDirection: 'row', paddingBottom: '10px'}}>
                  <View>
                    <Text style={{textAlign: 'left', width: '50%'}} ><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>GSTIN : </Text>{rcInfo.reg_number}</Text>
                  </View>
                  
                  <View>
                    <Text style={{textAlign: 'right', width: '50%'}}><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Cell : </Text>{rcInfo.phone}</Text>
                  </View> 
                </View>
              */}
               
                <View style={{flexDirection: 'row'}}>
                  <View style={{flex: 1}}>
                    <Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold', paddingLeft:"5px"}}>GSTIN : 33BBYPG1751JIZE</Text>
                  </View>
                  <View style={{  textAlign: 'center', width: '15%',paddingTop:"2px" }}>
                    {/*<Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold', fontSize: "10px"}}>LABOUR BILL</Text>*/}
                  </View>
                  
                  <View style={{flex: 1}}>
                    <Text style={{textAlign: 'right', paddingRight:"5px"}}><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Cell : </Text>9092867133</Text>
                  </View>
                  
                </View>
                <View style={{ flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: '#a7a5a5' }}>
                <View style={{ textAlign: 'right', width: '25%' }}>
                  {/*<Image style={{ marginHorizontal: '10%', width: 'auto', height: 'auto', height: "64px", width: "64px" }} src={logo_left} />*/}
                </View>
                <View style={{ width: "50%", alignItems: "center", /*paddingRight: "30px",*/ /*borderBottomWidth: 1,*/ borderBottomColor: '#a7a5a5'/*,marginTop: 1, marginBottom: 10, borderWidth: 1*/ }} key="address-key">
                  <Text style={{ fontSize: "20px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>GKB Forgings</Text>
                  <Text>4/236, Malakarar Thottam, Annur Road, Velliyam Palayam</Text>
                  <Text>Sembiaanallur(po), Avinashi-641 654</Text>
                  <Text>Tiruppur, Tamilnadu</Text>
                  <Text style={{ fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica'}}><Text style={{ fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>Mobile:</Text> {companyInfo.phone}</Text>
                </View>
                <View style={{ textAlign: 'right', width: '25%',paddingRight: '50px', alignItems: 'right'}}>
                  {/*<Image style={{  width: 'auto', height: 'auto', height: "64px", width: "64px"}} src={logo_right} />*/}
                </View>
              </View>
                
 
                  <View style={{ width: "100%", alignItems: "center", textAlign: 'center',  paddingTop: "5px",borderBottomWidth: 1, borderBottomColor: '#a7a5a5' }}>
              <Text style={{ fontSize: "12px", textAlign: 'center', alignContent: 'center', fontFamily: 'Helvetica-Bold', color: '#646363' }}>LABOUR BILL</Text>
            </View>
            <hr style={{paddingBottom: "10px"}}/>
                  <View style={{flexDirection: "row"}}>
                  <View style={{...styles.left3,width: "70%"}}>
                  <Text style={styles.to}>To:</Text>
        {/*<Text style={styles.billTo}>Order Details:</Text>*/}
        <View style={{flexDirection: 'row', }}>
               <Text style={{width: '20%'}}> M/s</Text>
               <Text style={{width: '80%', borderBottomWidth: 1, fontFamily: 'Helvetica-Bold', borderStyle: "dotted"}}>:</Text>
               <Text style={{width: '100%', borderBottomWidth: 1, fontFamily: 'Helvetica-Bold', borderBottomStyle: 'dashed', paddingBottom: "10px"}}></Text>
            </View>
            <Text style={{borderBottomWidth: 1, borderBottomStyle: 'dashed', paddingBottom: "20px"}}></Text>
            <Text style={{borderBottomWidth: 1, borderBottomStyle: 'dashed', paddingBottom: "20px"}}></Text>
            <View style={{flexDirection: 'row', paddingTop: "5px"}}>
            <Text style={{width: '17%', paddingTop: "5px"}}>Party GSTIN</Text>
               <Text style={{width: '83%', borderBottomWidth: 1,  borderBottomStyle: 'dashed', paddingTop: "5px"}}>:</Text>
               </View>
       
    </View>
    <View style={{width: "0.25%"}}></View>

    <View style={{...styles.headerContainer, width:"29.75%"}}>
    <View style={{flexDirection: 'row', paddingTop: "10px"}}>
            <Text style={{width: '25%',paddingLeft: "2px"}}>No </Text>
               <Text style={{width: '73%', borderBottomWidth: 1,  borderBottomStyle: 'dashed'}}>:</Text>
               </View>
               <View style={{flexDirection: 'row', paddingTop: "10x"}}>
               <Text style={{width: '25%',paddingLeft: "2px"}}>Date </Text>
               <Text style={{width: '73%', borderBottomWidth: 1,  borderBottomStyle: 'dashed'}}>:</Text>
               </View>

               <View style={{flexDirection: 'row', paddingTop: "10px"}}>
               <Text style={{width: '25%',paddingLeft: "2px"}}>Party DC </Text>
               <Text style={{width: '73%', borderBottomWidth: 1,  borderBottomStyle: 'dashed'}}>:</Text>
               </View>

               <View style={{flexDirection: 'row', paddingTop: "10px"}}>
               <Text style={{width: '25%',paddingLeft: "2px"}}>Date </Text>
               <Text style={{width: '73%', borderBottomWidth: 1,  borderBottomStyle: 'dashed'}}>:</Text>
               </View>
        
        
    </View>
    </View>
                 
     
                  <View style={{...styles.tableContainer1}}>
                  
                   <View style={styles.container}>
                     <Text style={styles.sno} className="align-items-center">S.No</Text>
                     <Text style={styles.description} className="align-items-center align-middle"> Particulars</Text>
                    
                     <Text style={styles.qty} className="align-items-center">Quantity</Text>
                     <Text style={styles.rate} className="align-items-center"><View><Text style={styles.rate1}>Rate</Text></View>{'\n'}<View><Text style={{}}>Rs</Text><Text style={{textAlign: 'right !important'}}>Ps</Text></View></Text>
                     
                    
                     <Text style={styles.amount} className="align-items-center"><View><Text style={styles.amount1}>Amount</Text></View>{'\n'}<View><Text style={{}}>Rs</Text><Text style={{textAlign: 'right !important'}}>Ps</Text></View></Text>
                     
                     </View>
                     <View style={{flexDirection: 1}}>
                     {invoiceList.map((invoice, idx) => (
                    
                     <View style={{ ...styles.row , borderBottomWidth: 1, paddingBottom: itemRowHeight / 2, paddingTop: itemRowHeight / 2 }} key="items">
                        <Text style={{ ...styles.sno_with_space, paddingTop: `${paddingHeight}px`, height: styles.sno_with_space.height + itemExtraHeight}}>{idx + 1}</Text>
                        
                        <Text style={{ ...styles.description_with_space, paddingTop: `${paddingHeight}px`, height: styles.description_with_space.height + itemExtraHeight}}>
                         {invoice.description} 
                        </Text>
                        {/*<Text style={{ ...styles.hsncode_with_space, paddingTop: `${paddingHeight}px`, height: styles.hsncode_with_space.height + itemExtraHeight}}></Text>*/} 
                        <Text style={{ ...styles.qty_with_space, paddingTop: `${paddingHeight}px`, height: styles.qty_with_space.height + itemExtraHeight}}> {invoice.quantity} </Text>
                        <Text style={{ ...styles.rate_with_space, paddingTop: `${paddingHeight}px`, height: styles.rate_with_space.height + itemExtraHeight}}> {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invoice.rate).toFixed(2)))} </Text>
                        
                       
                        <Text style={{ ...styles.amount_with_space, paddingTop: `${paddingHeight}px`, height: styles.amount_with_space.height + itemExtraHeight}}> {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invoice.amount).toFixed(2)))} </Text>
               
                      </View>
                    ))}
                      </View>
    
                      </View>
                      
                      {/*
                      <View style={{...styles.row_empty}} key="items">
                       
                       <Text style={{ ...styles.amount_in_words, fontWeight: 'bold', fontFamily: 'Helvetica-Bold', textAlign: 'left', width: '62%', paddingLeft: 8, paddingTop: '2px'}}>asdajsbsadcb</Text>
                      </View>
                      */}
                      
                      
           
                      
                     
           
           
            <View style={{ ...styles.row_empty}} key="items">
              <View style={{flexDirection: "row", width:"62%"}}>
                <Text style={{ borderLeftWidth:1, fontWeight: 'bold', fontFamily: 'Helvetica-Bold', textAlign: 'left', width: '100%', paddingLeft: '8px', paddingTop: '8px',borderBottomWidth: 1, borderBottomStyle: "dashed",}}>Rupees</Text>
                <Text style={{ ...styles.amount_in_words1, fontWeight: 'bold', fontFamily: 'Helvetica-Bold', textAlign: 'left', width: '0%', paddingLeft: 8, paddingTop: '1px'}}></Text>
                </View>
                <Text style={{ ...styles.total, borderBottomWidth: 1, borderBottomColor: borderColor, fontFamily: 'Helvetica-Bold', width: '19%', textAlign: 'right', paddingTop:"5px",  paddingRight: '62px'}}>Total</Text>
                <Text style={{ ...styles.amount, borderBottomWidth: 1, borderBottomColor: borderColor, fontFamily: 'Helvetica-Bold', width: '19%', textAlign: 'right',  paddingRight: '4px'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invoiceDataAsWhole.sum_product_cost).toFixed(2)))}</Text>
            </View>
                 
            
            
            
            <View style={styles.row_empty} key="items">
            <View style={{flexDirection: "row", width:"62%" }}>
                <Text style={{ borderLeftWidth:1, fontWeight: 'bold', fontFamily: 'Helvetica-Bold', textAlign: 'left', width: '100%', paddingLeft: 8, paddingTop: '8px',borderBottomWidth: 1, borderBottomStyle: "dashed",}}></Text>
                <Text style={{ ...styles.amount_in_words1, fontWeight: 'bold', fontFamily: 'Helvetica-Bold', textAlign: 'left', width: '0%', paddingLeft: 8, paddingTop: '1px'}}></Text>
                </View>
               
                <Text style={{ ...styles.total, borderBottomWidth: 1, borderBottomColor: borderColor, width: '19%', textAlign: 'right',  paddingRight: '50px',paddingTop:"5px" }}>CGST %</Text>
                <Text style={{ ...styles.amount, borderBottomWidth: 1, borderBottomColor: borderColor, width: '19%', textAlign: 'right',  paddingRight: '4px' ,paddingTop:"5px"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invoiceDataAsWhole.sum_product_cgst).toFixed(2)))}</Text>
            </View>
                 
    
            
            <View style={{ ...styles.row_empty}} key="items">
            <View style={{flexDirection: "row", width:"62%" }}>
                <Text style={{ borderLeftWidth:1, fontWeight: 'bold', fontFamily: 'Helvetica-Bold', textAlign: 'left', width: '100%', paddingLeft: 8, paddingTop: '8px',borderBottomWidth: 1, borderBottomStyle: "dashed",}}></Text>
                <Text style={{ ...styles.amount_in_words1, fontWeight: 'bold', fontFamily: 'Helvetica-Bold', textAlign: 'left', width: '0%', paddingLeft: 8, paddingTop: '1px'}}></Text>
                </View>
                
                <Text style={{ ...styles.sgst, borderBottomWidth: 1, borderBottomColor: borderColor, width: '19%', textAlign: 'right',  paddingRight: '50px',paddingTop:"5px" }}>SGST %</Text>
                <Text style={{ ...styles.amount, borderBottomWidth: 1, borderBottomColor: borderColor, width: '19%', textAlign: 'right',  paddingRight: '4px',paddingTop:"5px"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invoiceDataAsWhole.sum_product_sgst).toFixed(2)))}</Text>
            </View>
            
            
            <View style={{ ...styles.row_empty }} key="items">
                <Text style={{ ...styles.amount_in_words, width: '62%'}}> </Text>
                <Text style={{ ...styles.round_off, width: '19%', textAlign: 'right', paddingRight: '43px' , borderBottomColor: borderColor,borderBottomWidth: 1,paddingTop:"5px"}}>Round off </Text>
                <Text style={{ ...styles.amount, width: '19%', textAlign: 'right',  paddingRight: '4px' , borderBottomColor: borderColor,borderBottomWidth: 1,paddingTop:"5px"}}>{invoiceData.sum_rounded_off}</Text>
            </View>
    
            <View style={{ ...styles.row_empty, borderBottomWidth: 1, borderBottomColor: borderColor }} key="items">
                <Text style={{ ...styles.amount_in_words, width: '62%'}}> </Text>
                <Text style={{ ...styles.grant_total, width: '19%', textAlign: 'right', paddingRight: '33px', fontFamily: 'Helvetica-Bold',paddingTop:"5px" }}>Grand Total</Text>
                <Text style={{ ...styles.amount, width: '19%', textAlign: 'right', paddingRight: '4px', fontFamily: 'Helvetica-Bold',paddingTop:"5px" }}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(Math.round(invoiceDataAsWhole.sum_product_total)).toFixed(2)))}</Text>
            </View>

            
            <View style={{flexDirection: "row"}}>
                  <View style={{...styles.left, width: "70%"}}>
                  
       
                <View style={{flexDirection: "row"}}>
                  <View style={{paddingTop: "8px",paddingLeft: "20px"}}>
                    <Text style={{paddingBottom: "5px"}}>Bank Details</Text>
                    <Text style={{paddingBottom: "5px"}}><Text style={{fontFamily: 'Helvetica-Bold'}}>ANDHRA BANK</Text>, Avinashi Branch</Text>
                    <Text style={{paddingBottom: "5px"}}>C/A : A/c No: 257911100001079</Text>
                    <Text style={{paddingBottom: "5px"}}>IFSC Code : ANDB0002579</Text>
                  </View>
               
                 
                
               </View>
               
           </View>
                     

               <View style={{...styles.right, width:"30%"}}>
               <View style={{flexDirection: 'row', paddingTop:"10px"}}>
               <Text style={{paddingLeft: "50px"}}>For  </Text>
               <Text style={{fontFamily: 'Helvetica-Bold', fontSize: "10px"}}>GKB Forgings</Text>
               
               </View>    
        
    </View>
    </View>
         
       
            
    
           
            
           
    
            
            {/*
            <View style={{ paddingLeft: "2px", width: "62%",alignItems: "left", textAlign: 'left' , paddingTop: "10px", borderBottomColor: '#646363'}}>
                  <Text style={{fontSize: "10px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica', borderBottomWidth: 1, paddingTop: '0px',borderBottomStyle: 'dashed'}}><Text style={{fontSize: "10px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Rupees</Text> {splittedAmount[0]}<Text style={{fontFamily: 'Helvetica-Bold', fontSize: "10px"}}></Text></Text>
                  <Text  style={{  fontSize: "10px", textAlign: 'left', alignContent: 'left',  borderBottomWidth: 1, borderBottomStyle: 'dashed', paddingTop: '20px'}}>{(splittedAmount.length > 1 && splittedAmount[1]) ? splittedAmount[1] : ""}</Text>
                  </View>
        */}
                  {/*</Page></View>*/}
                 
                  
                 
                 
                  <View style={{flexDirection: 'row'}}>
                {/*
                <Text style={{textAlign: 'left', flex: 1,paddingTop:"5px"}} render={({ pageNumber, totalPages }) => (
                    `Printed On: ${formatPrintDate(new Date())}`
                )} fixed />
                */}
                 
                 
                  </View>
       
                  
               
                 
                 
                
    
               
               
                  
    
              </Page>
            </Document>
          }
        </PDFViewer>
      );
    }
    
    export default PrintBillPayment;