import { React, Fragment, useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  Badge,
  Modal,
  Spinner,
  Image
} from "react-bootstrap";

//import Sidebar2 from "./Sidebar2";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import "../Dashboard.css";
import "../App.css";
import { client,appGlobal, LS_MSG_TAG } from "../config/Config";
import axios from "axios";


import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsTrashFill,
  BsArrowLeftCircle,
  BsFillPenFill,
} from "react-icons/bs";
import SidebarMain from "./SidebarMain";
import {getDistrictList, getStateList } from "./utils";
import spinner_logo from '../assets/images/spinner_logo.png';
import { getCustomerMessages } from "./utils";



//function AddUpdateCustomer () {
const AddUpdateCustomer = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();

  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);
  
  //let initialItems = [];
  const intialValues = {
    name: "",
    contact_name: "",
    mobile: "",
    gstin: "",
    phone: "",
    email: "",
    website: "",
    /*
    address_line1_bill: "",
    address_line2_bill: "",
    pincode_bill: "",
    state_id_bill: 1,
    country_id_bill: 1,

    address_line1_shipp: "",
    address_line2_shipp: "",
    pincode_shipp: "",
    state_id_shipp: 1,
    country_id_shipp: 1,
    */

    openbal: 0.00
  };

  let stateArr = [
    { value: 1, label: "Andhra Pradesh" },
    { value: 2, label: "Assam" },
    { value: 3, label: "Tamil Nadu" },
    { value: 4, label: "Telangana" },
  ];

  let countryArr = [
    { value: 1, label: "Australia" },
    { value: 3, label: "India" },
    { value: 4, label: "China" },
  ];
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});

  const [validateError, setValidateError] = useState(false);
  //const [isSubmit, setIsSubmit] = useState(false);
  //const [items, setItems] = useState(initialItems);

  const [isReadOnly, setIsReadOnly] = useState(true);
  const [toRender, setToRender] = useState(false);  
  const [submitAction, setSubmitAction] = useState(false);
 // const [stateIdSelectBill, setStateIdSelectBill] = useState({});
  //const [optionsStateBill, setOptionsStateBill] = useState([]);
  //const [countryIdSelectBill, setCountryIdSelectBill] = useState(countryArr[0]);
  //const [optCountryBill, setOptCountryBill] = useState(countryArr);
  //const [addrCopyCheck, setAddrCopyCheck] = useState(false);
  //const [refresh, setRefresh] = useState(false);
  //const [stateIdSelectShipp, setStateIdSelectShipp] = useState({});
  //const [optionsStateShipp, setOptionsStateShipp] = useState([]);
  //const [countryIdSelectShipp, setCountryIdSelectShipp] = useState(countryArr[0]);
  //const [optCountryShipp, setOptCountryShipp] = useState(countryArr);
  const [checkState, setCheckState] =  useState(false);
  const [submitAddAction, setSubmitAddAction] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  //const [alertPurpose, setAlertPurpose] = useState({});
  const [alertTitle, setAlertTitle] = useState({});
  const [alertBody, setAlertBody] = useState({});
  const currSelBusinessRef = useRef(0);
  const currBranchRef = useRef(0);
  const submitActionRef = useRef("save");

  var validateErr = false;

  const countryListFromApi = { "countries": [
    {"country_name": "India", "states": ["Tamil Nadu", "Kerala", "Karnataka","Andra pradesh", "Telengana", "Maharastra", "Punjab"]},
    {"country_name": "Australia", "states": ["New South Wales", "Victoria, Queensland", "Western Australia", "Tasmania", "South Australia"]},
    {"country_name": "China", "states": ["Hebei", "Shanxi", "Liaoning", "Jilin", "Heilongjiang"]}
  ]}

  const handleOnChange = (e) => {
    //e.preventDefault();
    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);

    if (name === "phone" || name === "mobile" || name === "pincode")  {
      console.log("Inside check ******************");
      //const numericRegExp = /^(?:[1-9]\d*|\d)$/;
      if (e.target.value.match(appGlobal.wholeNumberRegExp)) {
        //var valueTmp = Math.round(e.target.value.replace(/\D/g, ""));
        setFormValues({ ...formValues, [name]: e.target.value });
      }
    } 
    else if (name === "openbal") {
     if ( e.target.value.match(appGlobal.numericRegExp) ) {
      setFormValues({ ...formValues, [name]: value });
     }
    }
    else if (name === "gstin") {
      //if ( e.target.value.match(gstRegExp) ) {
       setFormValues({ ...formValues, [name]: value });
      //}
    }
    /*
    const reggst = /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9]){1}?$/;
      if(!reggst.gstin && gstin!=''){
        setFormValues({ ...formValues, [name]: value });
        //alert('GST Identification Number is not valid. It should be in this "11AAAAA1111Z1A1" format');
    }
    */
    else {
      setFormValues({ ...formValues, [name]: value });
    }
    /*
    {
      console.log("Inside check ******************");
     
      var valueTmp = e.currentTarget.value.replace(/[^\d]/g, '');
    
      setFormValues({ ...formValues, [name]: valueTmp });
      //}
    } else {
      setFormValues({ ...formValues, [name]: value });

    }

    */
    
  };
  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.name) {
      errors.name = getCustomerMessages(LS_MSG_TAG,"MSG_CUSTOMER_NAME_MANDATORY");
      validateErr = true;
      //setValidateError(true);
    }

    if ( values.gstin ) {
      var gstRegExp = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
      if ( values.gstin.match(gstRegExp) ) {
        console.log("VALID GST NUMBER : ", values.gstin);
      }
      else {
        errors.gstin = "Enter Valid GST Number";
        validateErr = true;
      }
    }

    if ( values.pan ) {
      var panRegExp = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
      if ( values.pan.match(panRegExp) ) {
        console.log("VALID GST NUMBER : ", values.pan);
      }
      else {
        errors.pan = "Enter Valid PAN Number";
        validateErr = true;
      }
    }
    
    if (values.pincode && values.pincode.length != 6) {
      errors.pincode = getCustomerMessages(LS_MSG_TAG, "MSG_PINCODE_INVALID");
      validateErr = true;
      //setValidateError(true);
    }
    /*
    if (values.pincode_shipp && values.pincode_shipp.length != 6) {
      errors.pincode_shipp = getCustomerMessages(LS_MSG_TAG, "MSG_PINCODE_INVALID");
      validateErr = true;
      setValidateError(true);
    }
    */

    if (values.phone && values.phone.length != 10) {
      errors.phone = getCustomerMessages(LS_MSG_TAG,"MSG_PHONE_INVALID");
      validateErr = true;
      //setValidateError(true);
    }
     
    /*
    if (values.gstin && values.gstin.length != 15) {
      errors.gstin = "GST Number invalid"
      validateErr = true;
      //setValidateError(true);
    }
    */
    
    if (values.mobile && values.mobile.length != 10) {
      errors.mobile =  getCustomerMessages(LS_MSG_TAG,"MSG_MOBILE_INVALID");
      validateErr = true;
      //setValidateError(true);
    }
   /*
    if (values.work_phone && values.work_phone.length != 10) {
      errors.work_phone =  getCustomerMessages(LS_MSG_TAG,"MSG_WORK_PHONE_NUMBER_INVALID");
      validateErr = true;
      setValidateError(true);
    }
    */
    //console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };

  /*
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ",
      validateErr
    );
    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
      //console.log("LINE ITEMS : ", items);
      callUpdateApi();
     
    }
  };
  */
 /*
    const handleSelect = (e, data) => {
    console.log("SELECTED Dropdown value : ", e);
    console.log("SELECTED element id : ", data);
    var currentId = data.name.substring(data.name.lastIndexOf("-") + 1);
    console.log("Current id : ", currentId);
  };
  */

  /*
  const handleSearch = (e, dat) => {
    console.log("Handled search method called ...", e);

    //var stateIdInput = 1;
   // var stateNameInput = "TAMIL NADU";
    //getDistrictList(stateIdInput, stateNameInput);
  };
  */

  /*
  const handleStateSelectShipp = async(e) => {
    console.log("handleStateSelectShipp called ...", e);
    setStateIdSelectShipp(e);
    
  };
  */

  /*
  const handleStateSelectBill = async(e) => {
    console.log("handleStateSelect called ...", e);
    setStateIdSelectBill(e);
    
  };
  */

  /*
  const handleCountrySelectShipp = (e, data) => {
    console.log("handleCountrySelectShipp called ...", e);
    setCountryIdSelectShipp(e);

    console.log("************** COUNTRY ID SELECTED ++++++++++++++++ at handleCountrySelect < : ", e.value);
    console.log("SERACH COUNTRY SOURCE JSON : ", countryListFromApi.countries);
    var findCountry = countryListFromApi.countries.find(element => element.country_name === e.value);
    if ( findCountry !== undefined ) {
      console.log("++++++++++++++++++------------------- FOUND COUNTRY : ", findCountry);
      //setCountryIdSelect({value: countries[0].country_id, label: findCountry.label});
      var newStatesArr = [];
      findCountry.states.forEach(function(stateInfo){
        newStatesArr.push({value: stateInfo, label: stateInfo});
      })
      setOptionsStateShipp(newStatesArr);
      setStateIdSelectShipp(newStatesArr[0]);
  };
};
*/
 
/*
  const handleCountrySelectBill = (e, data) => {
    console.log("handleCountrySelect called ...", e);
    setCountryIdSelectBill(e);

    console.log("************** COUNTRY ID SELECTED ++++++++++++++++ at handleCountrySelect < : ", e.value);
    console.log("SERACH COUNTRY SOURCE JSON : ", countryListFromApi.countries);
    var findCountry = countryListFromApi.countries.find(element => element.country_name === e.value);
    if ( findCountry !== undefined ) {
      console.log("++++++++++++++++++------------------- FOUND COUNTRY : ", findCountry);
      //setCountryIdSelect({value: countries[0].country_id, label: findCountry.label});
      var newStatesArr = [];
      findCountry.states.forEach(function(stateInfo){
        newStatesArr.push({value: stateInfo, label: stateInfo});
      })
      setOptionsStateBill(newStatesArr);
      setStateIdSelectBill(newStatesArr[0]);
  };
}
*/

  const handleEdit = async (e) => {
    console.log("handle edit  clicked : ", e);
    setIsReadOnly(false);
  };
  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
    navigate(-1);
  };

  const handleReset = async (e) => {
    console.log("handle reset clicked : ", e);
    navigate(0);
  };
  
  const handleCloseDialog = () => {
    setShowAlert(false);
    navigate("/ListCustomers");
  };

  
  const callCustomerDetById = async(customerIdPassed) => {

    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      //var apiToCall = "";

    var reqParams = {
      id: customerIdPassed
    };

    var res = await axios.post(client.domain + "/bms/getCustomerDetails", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      console.log("Response from server INSIDE ARRAY : ", res.data.detail.data[0]);
      if (res.data.detail.status === "SUCCESS") {
        setFormValues(res.data.detail.data[0]);
        setSubmitAction(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          
        }
      }
      setSubmitAction(false);
    }
      
  };


  

  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect::addUpdateCustomer called ##########################");
      setToRender(true);
      //setCountryIdSelect(optCountry[0]);
      //setStateIdSelect(optionsState[0]);
      console.log("Process api fetched country list : ", countryListFromApi.countries);
      

      /*
      var newCountryArr = [];
      countryListFromApi.countries.forEach(function(object1){
        console.log("^^^^^^^^^^^^^^^^^^^ INDIVIDUAL OBJECT VALUE : ", object1);
        
       newCountryArr.push({value: object1.country_name, label: object1.country_name});
      })

      setOptCountryBill(newCountryArr);
      setCountryIdSelectBill(newCountryArr[0]);

      setOptionsStateBill(countryListFromApi.countries[0].states);
      setStateIdSelectBill({value: countryListFromApi.countries[0].states[0], label: countryListFromApi.countries[0].states[0]});

      setOptCountryShipp(newCountryArr);
      setCountryIdSelectShipp(newCountryArr[0]);

      setOptionsStateShipp(countryListFromApi.countries[0].states);
      setStateIdSelectShipp({value: countryListFromApi.countries[0].states[0], label: countryListFromApi.countries[0].states[0]});
      */
    
      if (state === null) {
        navigate("/listCustomers");
      } else {
        console.log("PASSED STATE VALUE IN Employee Add/Update page : ", state);
         console.log("Inside else for view or edit ...");
           
        if (state.action === "add") {
          setIsReadOnly(true);
        }
        else if ( state.action === 'view' || state.action === 'edit') {
          // call api to get business info
          if ( state.passed_element.status === "Active") {
            setCheckState(true);
          }
          callCustomerDetById(state.passed_element.id);
        }
        else {
          setFormValues(state.passed_element);
        }
        
         setToRender(true);
       
        console.log("newObj :", formValues);
        if (state.action === "add") {
          setIsReadOnly(false);
        }
      }
      
      initialized.current = true;
    }
  }, []);

 /*
  const callUpdateApi = async () => {
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var apiToCall = "";
      if (state.action === "add") {
        apiToCall = "/bms/addCustomer";
      } else if (state.action === "view") {
        apiToCall = "/bms/updateCustomer";
      } else {
        apiToCall = "/bms/addCustomer";
      }

      var reqParam = {
        bid: currSelBusinessRef.current,
        name: formValues["name"],
        contact_name: formValues["contact_name"] || "",
        mobile: formValues["mobile"] || "",
        gstin: formValues["gstin"] || "",
        pan: formValues["pan"] || "",
        phone: formValues["phone"] || "",
        openbal: formValues["openbal"] || 0.00,
        email: formValues["email"] || ""
      };

      if ( state.action === "edit" || state.action === "view") {
        reqParam.id = formValues["id"];
        reqParam.is_active = checkState;
      }

      var res = await axios.post(client.domain + apiToCall, reqParam, { headers }, { withCredentials: false });
     
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        setAlertTitle("Success");
        setShowAlert(true);
        setSubmitAction(false);
        if (state.action === "add"){
          setAlertBody(getCustomerMessages(LS_MSG_TAG,"MSG_CUSTOMER_SAVED"));
          
        }
        else{
          setAlertBody(getCustomerMessages(LS_MSG_TAG,"MSG_CUSTOMER_UPDATED"))
        }
        
        
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          
        }
      }
      setSubmitAction(false);
    }
  };
  */

  const handlePostSaveOk = (e) => {
    console.log("user confirmed to customer added ...");
    setShowAlert(false);
    if( submitActionRef.current === 'save-add'){
      navigate(0);
      //navigate("/listCustomers");
      console.log("SaveOk action called for dialog confirm **************************");
    }
    else{
    console.log("Action called for dialog confirm **************************");
    navigate("/listCustomers");
    }
  };
  /*
  const handleSave = async (e) => {
    e.preventDefault();
    console.log("Handle Click save called : ", e);
    console.log("Formvalues : ", formValues);

    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (validateErr === false) {
      setSubmitAction(true);
     // callUpdateApi();
     
    }
  };
  */

  /*
  const handleCopyToShipping = async (e) => {
    console.log("handle copy to billing called :", e);
    console.log("handle copy to billing called CHECKED :", e.target.checked);
    setAddrCopyCheck(e.target.checked);

    if (e.target.checked === true) {
      formValues["address_line1_shipp"] = formValues["address_line1_bill"];
      formValues["address_line2_shipp"] = formValues["address_line2_bill"];
      //formValues["country_shipp"] = formValues["country_bill"];
      //formValues["state_shipp"] = formValues["state_bill"];

      setFormValues({...formValues, ["pincode_shipp"]: formValues["pincode_bill"]});

      setCountryIdSelectShipp(countryIdSelectBill);

      setOptionsStateShipp(optionsStateBill);
      setStateIdSelectShipp(stateIdSelectBill);
    }
    setRefresh(true);
  };
  */

 /*
  const handleCopyToShipping = async (e) => {
    console.log("handleCopyToShipping called : ", e);
    console.log("handleCopyToShipping called CHECKED : ", e.target.checked);
    
   
      if (e.target.checked === true) {
        /*
        if ( !formValues.contact_person ) {
         formValues["billaddress_line1"] = formValues["address_line1"]; 
        }
        else {
          formValues["shipContactName"] = formValues["contact_person"];
        }

        if ( !formValues.contact_number ) {
          formValues["shipContactNumber"] = formValues["phone"];
        }
        else {
          formValues["shipContactNumber"] = formValues["contact_number"];
        }
        * /
        formValues["billAddress_line1"] = formValues["address_line1"];
        formValues["shipAddress2"] = formValues["address2"];
        setFormValues({ ...formValues, ["shipPincode"]: formValues["pincode"] });
        //setFormValues(e.target.checked);
      }
      setAddrCopyCheck(e.target.checked);
      setRefresh(true);
    };
    */
    const handleSubmit = async (event, actionVal) => {
      event.preventDefault();
      console.log("handleSubmit called ...");
      event.preventDefault();
      console.log("handleSubmit called ...");
      validateErr = false;
      setFormErrors(validate(formValues));
      //setIsSubmit(true);
  
      console.log("FORM VALUES at handleSubmit: ", formValues);
      validateErr = false;
      setFormErrors(validate(formValues));
      console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ", validateErr);
      if (validateErr) {
        return -1;
      }

      var apiToCall = "";
      if (state.action === "add") {
        apiToCall = "/bms/addCustomer";
      } else if (state.action === "view") {
        apiToCall = "/bms/updateCustomer";
      } else {
        apiToCall = "/bms/addCustomer";
      }
      
      var reqParam = {
        bid: currSelBusinessRef.current,
        name: formValues["name"],
        contact_name: formValues["contact_name"] || "",
        mobile: formValues["mobile"] || "",
        gstin: formValues["gstin"] || "",
        pan: formValues["pan"] || "",
        phone: formValues["phone"] || "",
        openbal: formValues["openbal"] || 0.00,
        email: formValues["email"] || ""
      };

      if ( state.action === "edit" || state.action === "view") {
        reqParam.id = formValues["id"];
        reqParam.is_active = checkState;
      } 

      
       try {
         var aIVal = localStorage.getItem("_aT");
         var headers = {...client.headers ,Authorization: `Bearer ${aIVal}`}
   
         if ( actionVal === 'save-add') {
           setSubmitAddAction(true);
           submitActionRef.current = "save-add";
         }
         else {
           setSubmitAction(true);
           submitActionRef.current = "save";
         }
         var res = await axios.post(client.domain + apiToCall, reqParam, { headers }, { withCredentials: false });
       
         //var res = await axios.post(client.domain + "/bms/addUpdateDCApi", reqDeliveryAddUpdate, { headers }, { withCredentials: false });
         console.log("Response from server : ", res.data.detail);
         if (res.data.detail.status === "SUCCESS") {
           console.log("Response message from server : ", res.data.detail.data);
           console.log("ACTION VALUE PASSED++++++++++++++++++++++++++-------------: ", actionVal);
           if ( actionVal === 'save-add' ) {
             setSubmitAddAction(false);
           }
           else {
             setSubmitAction(false);
           }
           setAlertTitle("Success");
           //setShowAlert(true);
           setSubmitAction(false);
           
           if(state.action === "add"){ 
             setAlertBody(getCustomerMessages(LS_MSG_TAG,"MSG_CUSTOMER_SAVED"));
           } 
          else {
            setAlertBody(getCustomerMessages(LS_MSG_TAG,"MSG_CUSTOMER_UPDATED"));
           }
           //setShowAlert(true);
   
           if ( actionVal === 'save-add' ) {
            console.log("++++++++++++++++++++++++ SAVE AND PRINT ACTION BUTTON CICKED ...");
            //setShowAlert(true);
             //navigate(0);
             setShowAlert(true);
             //navigate(0);
           }
           else {
             setShowAlert(true);
           }  
         }
         else {
          setAlertTitle("FAILED!");
           setShowAlert(true);
           setSubmitAction(false);
           
           if(state.action === 'add'){    
             setAlertBody("Customer Save Failed!");
           }
           else{
             setAlertBody("Customer Update Failed!");
           }
          
           setShowAlert(true);
         }
       } catch (error) {
         console.log("FULL ERROR : ", error);
         setAlertTitle("Warning");
         setAlertBody("Unable to process your request at this moment.Please try after sometime!");
         setShowAlert(true);
         setSubmitAction(false);
       }
    };

    const handleCheckBox = async (e) => {
      console.log("handle copy to billing called :", e);
      console.log("handle copy to billing called CHECKED :", e.target.checked); 
      setCheckState(e.target.checked);
      
    if ( state.action === 'view' || state.action === 'edit'){
      setCheckState(e.target.checked);
    }
  
      //setRefresh(true);
    };

  console.log("formvalues FULL : ", formValues);



  const sendDataToParent  = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if ( passedBackValue.hasOwnProperty("business_id") ) {
      currSelBusinessRef.current = passedBackValue["business_id"];
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);
    
  }

 

  return (
      <Container>
        <SidebarMain sendBacktoParent={sendDataToParent}/>
        { toRender ?
        (
        <Form>
          <Row>
            <Col xs={4} md={4} sm={4} lg={4}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>{menuSettings.permissions.find(element => (element.code === 'listCustomers' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'listCustomers')).page_title_addupd : "Customer"}</span>
            {/*
            { (state.action === 'view' || state.action === 'edit') &&
              <>
              &nbsp;&nbsp;
             
            <Button variant={state.passed_element.is_active ? "outline-success" : "outline-danger"} size="sm"><span style={{cursor: "default", fontWeight: "500"}}>{state.passed_element.status}</span></Button>
            </>
            }
          */}
            
            </Col>
            
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
              className="float-end justify-content-end"
            >
              <div className="d-flex justify-content-end">
                <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                  <BsArrowLeftCircle
                    key={`back-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                   
                    name={`back-icon-patient-name`}
                    id={`edit-icon-patient-id`}
                  />
                </Button>
                {(state.action === "edit" || state.action === "view") && (
                  <>
                &nbsp;&nbsp;
                <Button variant="primary" className="btn btn-primary btn-md"  onClick={(e) => handleEdit(e)}
                disabled={menuSettings.permissions.find(element => (element.code === 'listCustomers' && element.a_edit === true)) ? false : true}
                >
                  <BsPencilSquare
                    key={`edit-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                  
                    name={`edit-icon-paBiSolidEditAlttient-name`}
                    id={`edit-icon-doctor-id`}
                  />
                </Button>
                </>
                )}
              </div>
            </Col>
          </Row>

          <div className="ui-divider"></div>
          <div className="ui-form">
            <hr align="center" />
           

            <Row>
              <Col xs={5} md={5} sm={5} lg={5}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Customer Name"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["name"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.name}</p>
              </Col>
             
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    GSTIN
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="gstin"
                    name="gstin"
                    placeholder="GSTIN"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["gstin"]}
                    maxLength={15}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.gstin}</p>
              </Col>
              <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>PAN</Form.Label>
                  <Form.Control
                    type="text"
                    id="pan"
                    name="pan"
                    //className="mobNum"
                    placeholder="PAN"
                    onChange={handleOnChange}
                    readOnly={isReadOnly}
                   // maxLength={10}
                    value={formValues["pan"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.pan}</p>
              </Col>
              <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>Opening Balance</Form.Label>
                  <Form.Control
                    type="text"
                    id="openbal"
                    name="openbal"
                    placeholder="Opening Balance"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["openbal"]}
                    style={{
                      border: !isReadOnly && "1px solid gray", textAlign: 'right'
                    }}
                  />
                </Form.Group>
              </Col> 
            </Row>
           
            <Row>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Contact Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="contact_name"
                    name="contact_name"
                    placeholder="Contact Name"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["contact_name"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.contact_name}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Contact Mobile
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="mobile"
                    name="mobile"
                    //className="mobNum"
                    placeholder=" Contact Mobile"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["mobile"]}
                    maxLength={10}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.mobile}</p>
              </Col>

              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Work Phone
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="phone"
                    name="phone"
                    //className="mobNum"
                    placeholder=" Work Phone"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["phone"]}
                    maxLength={10}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.phone}</p>
              </Col>
             
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    id="email-id"
                    name="email"
                    placeholder="Email"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["email"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.email}</p>
              </Col>
            </Row>
            {/*

            <h5>Billing Address</h5>
            <hr align="center" />

            <Row>
              <Col xs={6} md={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Street / Location
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="address-1-id_bill"
                    name="address_line1_bill"
                    placeholder="Street / Location"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["address_line1_bill"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.address1}</p>
              </Col>
              <Col xs={6} md={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="field">
                    City / Town
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="address-2-id_bill"
                    name="address_line2_bill"
                    placeholder="City / Town"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["address_line2_bill"]}
                    style={{
                      border: !isReadOnly && "1px solid gray"
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.address_line2}</p>
              </Col>
              <Col xs={3} sm={3} md={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Country</Form.Label>
                  <Select
                    id="country_bill"
                    name="country_bill"
                    value={{
                      value: countryIdSelectBill.value,
                      label: countryIdSelectBill.label,
                    }}
                    options={optCountryBill}
                    defaultValue={{
                      value: countryIdSelectBill.value,
                      label: countryIdSelectBill.label,
                    }}
                    onChange={handleCountrySelectBill}
                    isDisabled={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.country_id_bill}</p>
              </Col>

              <Col xs={3} sm={3} md={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>State</Form.Label>
                  <Select
                    id="state_bill"
                    name="state_bill"
                    value={{
                      value: stateIdSelectBill.value,
                      label: stateIdSelectBill.label,
                    }}
                    defaultValue={{
                      value: stateIdSelectBill.value,
                      label: stateIdSelectBill.label
                    }}
                    options={optionsStateBill}
                    onChange={handleStateSelectBill}
                    isDisabled={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.state_id_bill}</p>
              </Col>

              <Col xs={3} sm={3} md={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Pincode</Form.Label>
                  <Form.Control
                    type="text"
                    id="pincode_bill"
                    name="pincode_bill"
                    placeholder="Pincode"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    maxLength={6}
                    value={formValues["pincode_bill"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>

                <p className="error-msg">{formErrors.pincode_bill}</p>
              </Col>
            </Row>
            <Row>
             <Col xs={2}  md={2} sm={2} lg={2}>
             <h5>Shipping Address</h5>
            </Col>
            <Col
                        xs={6}
                        md={6}
                        sm={6}
                        lg={6}
                        //className="float-center justify-content-center"
                      >   
                      
                        <div>
                          <Form.Check
                            label="Same as Billing Address"
                            name="address-copy"
                            id="id-addr-copy-ship"
                            //defaultChecked= {addrCopyCheck}
                            type="checkbox"
                            className="checkCopy"
                            checked={addrCopyCheck}
                            onChange={handleCopyToShipping}
                            ></Form.Check>
                            </div>
                            </Col>
                            </Row>
            <hr align="center" />
            <Row>
            <Col xs={3} sm={3} md={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Shipping Ref Number</Form.Label>
                  <Form.Control
                    type="text"
                    id="ref_number"
                    name="ref_number"
                    placeholder="Shipping Ref Number"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    maxLength={6}
                    value={formValues["ref_number"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>

                <p className="error-msg">{formErrors.ref_number}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                     Phone
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="phone_number"
                    name="phone_number"
                    //className="mobNum"
                    placeholder=" Phone Number"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["phone_number"]}
                    maxLength={10}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.phone_number}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    id="email-id"
                    name="email"
                    placeholder="Email"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["email"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.email}</p>
              </Col>
            </Row>
            <Row>
            <Col xs={6} md={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Street / Location
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="address_line1_shipp"
                    name="address_line1_shipp"
                    placeholder="Street / Location"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["address_line1_shipp"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.address_line1_shipp}</p>
              </Col>
              <Col xs={6} md={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="field">
                    City / Town
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="address_line2_shipp"
                    name="address_line2_shipp"
                    placeholder="City / Town"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["address_line2_shipp"]}
                    style={{
                      border: !isReadOnly && "1px solid gray"
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.address_line2_shipp}</p>
              </Col>
              <Col xs={3} sm={3} md={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Country</Form.Label>
                  <Select
                    id="country_shipp"
                    name="country_shipp"
                    value={{
                      value: countryIdSelectShipp.value,
                      label: countryIdSelectShipp.label,
                    }}
                    options={optCountryShipp}
                    defaultValue={{
                      value: countryIdSelectShipp.value,
                      label: countryIdSelectShipp.label,
                    }}
                    onChange={handleCountrySelectShipp}
                    isDisabled={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.country_shipp}</p>
              </Col>

              <Col xs={3} sm={3} md={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>State</Form.Label>
                  <Select
                    id="state_shipp"
                    name="state_shipp"
                    value={{
                      value: stateIdSelectShipp.value,
                      label: stateIdSelectShipp.label,
                    }}
                    defaultValue={{
                      value: stateIdSelectShipp.value,
                      label: stateIdSelectShipp.label
                    }}
                    options={optionsStateShipp}
                    onChange={handleStateSelectShipp}
                    isDisabled={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.state_shipp}</p>
              </Col>

              <Col xs={3} sm={3} md={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Pincode</Form.Label>
                  <Form.Control
                    type="text"
                    id="pincode_shipp"
                    name="pincode_shipp"
                    placeholder="Pincode"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    maxLength={6}
                    value={formValues["pincode_shipp"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>

                <p className="error-msg">{formErrors.pincode_shipp}</p>
              </Col>
            </Row>
            */}
            {!isReadOnly && (
              <Row>       
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                  {state.action !== "add" &&   
                  <Form.Check
                            style={{paddingTop: "5px"}}
                            label="Active"
                            name="checkbox"
                            id="id-addr-copy-bill"
                            type="checkbox"
                            className="checkCopy"
                            //defaultChecked=
                            checked={checkState}
                            onChange={handleCheckBox}
                          ></Form.Check>
                            }
                  
                          &nbsp;&nbsp;     
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    
                    &nbsp;&nbsp;
                    {state.action === "add" &&
                    <Button
                          className="btn btn-primary"
                          id='save-add'
                          name='save-add'
                          onClick={(e) => handleSubmit(e, 'save-add')}
                        >
                          
                          {submitAddAction &&
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          }
                        
                          Save & Add</Button>
                        }
                    &nbsp;&nbsp;
                    <Button
                      type="submit"
                      id="save"
                      onClick={(e) => handleSubmit(e)}
                      disabled={submitAction ? true: false}
                    >
                     {submitAction && 
                      <>
                      <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                     <span className="visually-hidden">Loading...</span>
                     </>
                    }
                      
                     Save
                    </Button>
                  </div>
                </Col>
                
              </Row>
            )}
            
            <Modal
              show={showAlert}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{alertTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{alertBody}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
       
        </Form>
        )
           :
           <div className="container-spinner">
           <div className="vertical-center-spinner">
            <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
             <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>  
            
                   <span className="visually-hidden">Loading...</span>
                 </Spinner>
            </div>
            </div>
         }
       
      </Container>
  );
};

export default AddUpdateCustomer;
