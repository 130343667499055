//import "bootstrap/dist/css/bootstrap.min.css"
import React, { Fragment, useState } from 'react';

import { client } from '../config/Config';
import axios from 'axios';

import { useNavigate } from "react-router-dom";

import Container from 'react-bootstrap/Container';
//import Nav from 'react-bootstrap/Nav';
import {Nav, NavItem, Navbar, NavDropdown, Dropdown, Row} from 'react-bootstrap';
//import { Glyphicon } from '../GlyphiconToFa';
//import { RBGlyphicon } from 'react-bootstrap';
//import { ArrowRight } from 'react-bootstrap-icons';
//import Offcanvas from 'react-bootstrap/Offcanvas';

//import "bootstrap/dist/css/bootstrap.min.css"
//import 'bootstrap/dist/css/bootstrap.min.css';
import "../App.css"

import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import logo_avs from '../assets/images/logo_avs.png';
//import avinaoffice_logo from '../avinaoffice_logo.png';
import app_logo from '../assets/images/app_logo.png';

import { COMPANY_NAME } from '../config/Config';
import { storeMsgsFromServer } from './utils';

function Auth() {

  const [justifyActive, setJustifyActive] = useState('tab1');;
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState('Unable to login');
  const [showMsgTitle, setShowMsgTitle] = useState('Login FAILED!');
  const handleJustifyClick = (value) => {
    if (value === justifyActive) {
      return;
    }

    setJustifyActive(value);
  };

  const navigate = useNavigate();

  const callApiToGetStaticData = async() => {
    console.log("callApiToGetStaticData called ...");

    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`}
    
      var res =  await axios.get( client.domain + "/appbase/getAppPreLoadData", { headers }, { withCredentials: false });
      console.log("<<<<<<<<<<<<<<<<<<< Response from server : ", res);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.data);
        console.log("Response message from server Business Types : ", res.data.detail.data.business_types);
        console.log("Response message from server Business Categories : ", res.data.detail.data.business_categories);
        console.log("Response message from server Countries : ", res.data.detail.data.countries);
        console.log("Response message from server States : ", res.data.detail.data.states);
        console.log("Response message from server  Districts : ", res.data.detail.data.districts);
        console.log("Response message from server Business : ", res.data.detail.data.business);
        console.log("Response message from server Branches : ", res.data.detail.data.branches);

        localStorage.setItem('business_types', JSON.stringify(res.data.detail.data.business_types));
        localStorage.setItem('business_categories', JSON.stringify(res.data.detail.data.business_categories));
        localStorage.setItem('countries', JSON.stringify(res.data.detail.data.countries));
        localStorage.setItem('states', JSON.stringify(res.data.detail.data.states));
        localStorage.setItem('districts', JSON.stringify(res.data.detail.data.districts));
        localStorage.setItem('business', JSON.stringify(res.data.detail.data.business));
        localStorage.setItem('branches', JSON.stringify(res.data.detail.data.branches));
       
        /*
        var userperm = localStorage.getItem('_aP');
        var objPerm = JSON.parse(userperm);
        console.log("landing page ++++++++++++^^^^^^^^^^^^^************** : ", objPerm.landingpage);
        navigate(`/${objPerm.landingpage}`);
        */
        
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
       
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          
        } else {
          console.log("Non status ERROR code ...");
        }
      }
    }
  };

  const callApiToGetMessages = async() => {
    console.log("callApiToGetMessages called ...");

    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`}
    
      var res =  await axios.get( client.domain + "/appbase/getAppMessages", { headers }, { withCredentials: false });
      console.log("<<<<<<<<<<<<<<<<<<< Response from server : ", res);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.data);
        
        localStorage.setItem('app_message', JSON.stringify(res.data.detail.data));
        
       
        /*
        var userperm = localStorage.getItem('_aP');
        var objPerm = JSON.parse(userperm);
        console.log("landing page ++++++++++++^^^^^^^^^^^^^************** : ", objPerm.landingpage);
        navigate(`/${objPerm.landingpage}`);
        */
        
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
       
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          
        } else {
          console.log("Non status ERROR code ...");
        }
      }
    }
  }


  const handleSubmit = async (event) => {
    // Prevent page reload
    event.preventDefault();

    console.log("Form submit clicked ...");

    console.log("DATA : ", document.forms[0]);

    var { username, password } = document.forms[0];
    console.log("username : ", username.value);
    console.log("pasword : ", password.value);

    
    let reqLoginPayload = {
      username : username.value, 
      password : password.value
    };

    function base64ToBytes(base64) {
      const binString = atob(base64);
      return Uint8Array.from(binString, (m) => m.codePointAt(0));
    }
    
    function bytesToBase64(bytes) {
      const binString = String.fromCodePoint(...bytes);
      return btoa(binString);
    }

    var loginData = {
      "apikey":"AOAVN123",
      "uname":username.value,
      "upass":password.value
    };

    let bytesArr = new TextEncoder().encode(JSON.stringify(loginData));
    console.log("BYTE ARRAY : ", bytesArr);
    let loginEncode64 = bytesToBase64(bytesArr);
  
      var headers = client.headers;
      var headers = { ...client.headers, Authorization: `Basic ${loginEncode64}`, Licensekey: 'VBBLKEY123' }
      //var headers = {...client.headers, Authorization: `Basic eyJBUElLRVkiOiJBVklOQUVSUEtNQzAxIiwiVU5BTUUiOiI4ODcwODg2OTU4IiwiVVBBU1MiOiJ0ZXN0In0=`}
      //var headers = { ...client.headers,Authorization: `Basic eyJBUElLRVkiOiJBVklOQUVSUEtNQzAxIiwiVU5BTUUiOiI4ODcwODg2OTU4IiwiVVBBU1MiOiJ0ZXN0In0=`}
      console.log("payload:", reqLoginPayload);
      console.log("Request header :", headers);

      try {
      var res = await axios.post(client.domain + '/core/login', reqLoginPayload, { headers }, { withCredentials: false });
          console.log("Response from server : ", res.data);
          if ( res.data.detail.status === 'SUCCESS') {
            console.log("Response message from server : ", res.data.detail.res_data);
            console.log("Response message from server userInfo : ", res.data.detail.res_data.user_info);
            console.log("Response message from server aT : ", res.data.detail.res_data.aT);
            console.log("Response message from server PERMISSIONS : ", res.data.detail.res_data.user_info.usr_perm.permissions);


            localStorage.setItem('_aP', JSON.stringify(res.data.detail.res_data.user_info.usr_perm));

            localStorage.setItem('_aT', res.data.detail.res_data.aT);
            localStorage.setItem('dis_name', res.data.detail.res_data.user_info.dis_name);

            await callApiToGetStaticData();
            await callApiToGetMessages();

            var userperm = localStorage.getItem('_aP');
            var objPerm = JSON.parse(userperm);
            console.log("landing page ++++++++++++^^^^^^^^^^^^^************** : ", objPerm.landingpage);
            navigate(`/${objPerm.landingpage}`);
          }
          else if ( res.data.detail.status === 'FAILED') {
            setShowMsgTitle("LOGIN FAILED!");
            setShowMsg(res.data.detail.res_data);
            setShow(true);          
          }
          /*
          if (res.data.status === "SUCCESS" ) {
            this.setState ({
            tokenJwt: res.data.res_data.token,
            isLoaded: true,
            openOTP: true,
            })
              // this.props.history.push({pathname: client.taskViewPath, state:{pageList: this.state.page, orgName: this.state.orgName} });
          }
          else {
          }
        */
        }
        catch(error) {
          console.log("FULL ERROR : ", error);
          if ( error.code === 'ERR_NETWORK' ) {
            console.log("Not able to reach target server please try after sometime");
          }
          else{
            console.log("ERROR : ", error.response.data.detail);
            if (error.response.data.detail.status === 'FAILED') {
              console.log('Request failed due to ', error.response.data.detail.res_data);
	            setShowMsg(error.response.data.detail.res_data);
              setShowMsgTitle('LOGIN FAILED!');
	            setShow(true);
            }
          }
        }
        
  }
  
  

  return (
    <Fragment>
      <div style={{marginTop: 150, alignItems: 'center', textAlign: 'center'}}>

    <div>
     {/* <img src={vb_logo} height="10%" width="10%" alt="Avinasoft"/>*/}
      {/*<label style={{fontFamily: 'Helvetica-Bold', textAlign: 'center', fontWeight: 'bold', fontSize: "20px"}}>{COMPANY_NAME}</label>*/}
    </div>
    

      
    
    <div style={{flexDirection: "row"}} >
    <div><h4 style={{fontStyle: 'italic'}}>{COMPANY_NAME}</h4></div>
    <div className="Auth-form-container">
    
    
      

      <form className="Auth-form  d-flex justify-content-center" onSubmit={handleSubmit}>
      
      <div className="d-inline-block" style={{borderRightWidth: 1, }}>
      <img src={app_logo } alt="Avinasoft" style={{ paddingRight: "30px",paddingTop: "28px"}}/>
      </div>

      <div style={{ borderLeft: "2px solid #A0A0A0"}}></div>
        
        <div className="Auth-form-content d-inline-block">
       
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="form-group mt-1">
            <label>Username</label>
            <input
              type="text"
              name="username"
              className="form-control mt-1"
              placeholder="Enter username"
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              name="password"
              className="form-control mt-1"
              placeholder="Enter password"
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
          {/*
          <p className="forgot-password text-right mt-2">
            Forgot <a href="#">password?</a>
          </p>
  */}
        </div>
        

   </form> 
   

   </div>
  
   <div className="Error-msg" style={{flexDirection: "row"}}>
   { show === true &&
   <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>{showMsgTitle}</Alert.Heading>
        <p>
        {showMsg}
        </p>
      </Alert>
      }
   </div>

   </div>
   
    
    </div>
   </Fragment>
  );
}

export default Auth;
