import React, {
    useState,
    useEffect,
    useRef,
    useReducer,
    Fragment,
  } from "react";
  import Table from "react-bootstrap/Table";
  import { fragment } from "react";
  
  import Select from "react-select";
  
  import {
    Form,
    Button,
    Row,
    Col,
    Alert,
    Modal,
    Container,
    Spinner,
    Image
  } from "react-bootstrap";
  //import Sidebar2 from "./Sidebar2";
  import SidebarMain from "./SidebarMain";
  
  import {
    BsFillTrashFill,
    BsPencilSquare,
    BsFileRuled,
    BsCardText,
    BsFillPrinterFill
  } from "react-icons/bs";
  
  import "../App.css";
  
  import { useNavigate } from "react-router-dom";
  import axios from "axios";
  import { client } from "../config/Config";
  import spinner_logo from '../assets/images/spinner_logo.png';
  import {formatDateIndian} from "./utils";
  
  
  function ViewTaxSummary() {
    const initialized = useRef(false);
    var navigate = useNavigate();
    var menuSettingsStr = localStorage.getItem("_aP");
    var menuSettings = JSON.parse(menuSettingsStr);

    const currSelBusinessRef = useRef(0);
    const currBranchRef = useRef(0);

    const [checkedState, setCheckedState] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  
    function padTo2Digits(num) {
      //console.log("<<<<<<<<<<<<<< given number >>>>>>>>>>>>>>>> : ", num);
      var modNum = num.toString().padStart(2, "0");
      console.log("Modified  value : ", modNum);
      return modNum;
    }
  
    function formatDate(date) {
      return [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-");
    }
  
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    //var lastDay = new Date(date.getFullYear(), date.getMonth() + 1,
    const intialValues = {
      date_from: formatDate(new Date(firstDay)),
      date_to: formatDate(new Date()),
    }

    let customerArr = [
  
      { value: 1, label: "All Customers" },
      { value: 2, label: "Deppam Motors" },
      { value: 3, label: "ABC Pumps" },
      { value: 4, label: "ADP Compressors" },
    ];
  
    let taxArr = [
      {
      tax_details: "CGST",
       net_sales: "2000",
       tax_collected: "5000",
       sales: "7000",
       net_purchase: "600",
       tax_paid: "1000",
       purchase: "8000",
       tax_liablity: "6700"
      },

      {
      tax_details: "SGST",
       net_sales: "4000",
       tax_collected: "2000",
       sales: "1000",
       net_purchase: "500",
       tax_paid: "3000",
       purchase: "6000",
       tax_liablity: "1000"
      },
      
      
    ];
  
   
    
    
  
    const [taxList, setTaxList] = useState([]);
    const [taxSummary, setTaxSummary] = useState([]);
    const [customerIdSelect, setCustomerIdSelect] = useState({});
    const [isSearched, setIsSearched] = useState(false);
    const [optCustomer, setOptCustomer] = useState(customerArr);
    const [toRender, setToRender] = useState(false);
    const [show, setShow] = useState(false);
    const [formValues, setFormValues] = useState(intialValues);
    const [showMsg, setShowMsg] = useState("Please login again");
    const [showMsgTitle, setShowMsgTitle] = useState(
      "Invalid authentication Session!"
    );
   
  
   
    /*
    const getCustomerList = async () => {
      try {
        var aIVal = localStorage.getItem("_aI");
        var headers = { ..
          .client.headers,Authorization: `Bearer ${aIVal}`}
      
  
        var res = await axios.get(
          client.domain + "/crm/getAllCustomers",
          { headers },
          { withCredentials: false }
        );
        console.log("Response from server : ", res.data.detail);
  
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server : ", 0);
          setCustomerList(res.data.detail.data);
          setCustomerListFull(res.data.detail.data);
          setShowMsgTitle("Customer Info Added Successfully");
          setToRender(true);
          //navigate("/dashboard");
          //setDcReportData(res.data.detail.res_data);
          //setToRender(true);
        }
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log(
            "Not able to reach target server please try after sometime"
          );
          setShowMsg("Please try after sometime");
          setShowMsgTitle("Network Issue!");
          setShow(true);
        } else {
          console.log("ERROR occured : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log(
              "Request failed due to ",
              error.response.data.detail.res_data
            );
            setShowMsg("Please try again ...");
            setShowMsgTitle("Unable to process your request!");
            setShow(true);
            //navigate("/auth");
          } else {
            console.log("Non status ERROR code ...");
            setShowMsg(error.response.data.detail);
            setShowMsgTitle("Unable to process your request at this moment!");
            setShow(true);
          }
        }
      }
    };
    */
   
    
  
    const getTaxList = async () => {
      var retStateLoad = false;
      var taxRet = [];
      var summaryRet = [];
      try {
        var aIVal = localStorage.getItem("_aT");
        var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
  
        var reqParams = {
          bid: currSelBusinessRef.current,
          brid: currBranchRef.current,
          date_from: formValues["date_from"],
          date_to: formValues["date_to"]
        };
  
        var res = await axios.post(client.domain + "/bms/getTaxSummaryList", reqParams, { headers }, { withCredentials: false });
        console.log("Response from server : ", res.data.detail);
  
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server : ", 0);
          setTaxList(res.data.detail.data.tax_details);
          setTaxSummary(res.data.detail.data.tax_total[0]);
          //setCustomerListFull(res.data.detail.data);
          //setShowMsgTitle("Customer Info Added Successfully");
          setToRender(true);
          taxRet = res.data.detail.data.tax_details;
          summaryRet = res.data.detail.data.tax_total;
          retStateLoad = true;
          //navigate("/dashboard");
          //setDcReportData(res.data.detail.res_data);
          //setToRender(true);
        }
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log( "Not able to reach target server please try after sometime");
          setShowMsg("Please try after sometime");
          setShowMsgTitle("Network Issue!");
          setShow(true);
        } else {
          console.log("ERROR occured : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log( "Request failed due to ",error.response.data.detail.res_data);
            setShowMsg("Please try again ...");
            setShowMsgTitle("Unable to process your request!");
            setShow(true);
            //navigate("/auth");
          } else {
            console.log("Non status ERROR code ...");
            setShowMsg(error.response.data.detail);
            setShowMsgTitle("Unable to process your request at this moment!");
            setShow(true);
          }
        }
      }
      return [retStateLoad, taxRet, summaryRet];
    };
   
   
    useEffect(() => {
      if (!initialized.current) {
        console.log("*************** useEffect::ViewTaxSummary called ##########################");
        //setToRender(true);
        ( async() => {
          var [retStateLoad, customerRet] = await getTaxList();
        })();
        //getTaxList();
        setToRender(true);
        initialized.current = true;
       
      }
    }, []);
   /*
    const handleAdd = async (e) => {
      console.log("handle add customers called : ", e);
      navigate("/addUpdateStockIn", {
        state: { passed_element: [], action: "add" },
      });
    };
    */
  
    
    
   
    
    const handleTaxPrint = async (e) => {
     //navigate("/printCustomer")
      
      var urlStr = window.location.href;
      var paramsTmp = urlStr.substring(
        urlStr.lastIndexOf(client.urlDelimiter) + 1
      );
      var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));
  
      console.log("CALLED URL : ", urlTmp);
      console.log("CALLED PAGE : ", paramsTmp);
  
      var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printTaxSummary?bid=${currSelBusinessRef.current}&brid=${currBranchRef.current}&date_from=${formValues["date_from"]}&date_to=${formValues["date_to"]}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
      winPrint.moveTo(0,0);
      winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
      
      
    };
   
    const onChangeStartDatePicker = (e) => {
      console.log("Date onChange called ...", e);
      console.log("Date onChange DATE called ...", e.target.value);
      setFormValues({ ...formValues, date_from: e.target.value });
    };
  
    const onChangeEndDatePicker = (e) => {
      console.log("Date onChange called ...", e);
      console.log("Date onChange DATE called ...", e.target.value);
      setFormValues({ ...formValues, date_to: e.target.value });
    };
  
  
    const handleSearch = async () => {
      console.log("handleDateSearch called ...");
       await getTaxList();
  
    };

    const sendDataToParent  = (passedBackValue) => {
      console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
      if ( passedBackValue.hasOwnProperty("business_id") ) {
        currSelBusinessRef.current = passedBackValue["business_id"];
        //navigate(0);
      }
      else if (passedBackValue.hasOwnProperty("branch_id")) {
        currBranchRef.current = passedBackValue["branch_id"];
      }
      else {
        // Future implementation
      }
      //setCurrSelBusinessId(passedBackValue);
      
      //getBranchList();
    };
  
   
    return (
      <Container>
        <SidebarMain sendBacktoParent={sendDataToParent} />
        { toRender ?
        <form>
        <Row>
            <Col xs={10} md={10} sm={10} lg={10}>
              <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.25rem", verticalAlign: "bottom" }}>{menuSettings.permissions.find(element => (element.code === 'viewTaxSummary' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'viewTaxSummary')).page_title : "Tax Summary"}</span>
            </Col>
            
            <Col
              xs={2}
              md={2}
              sm={2}
              lg={2}
              className="d-flex justify-content-end float-end">
              <Button id="taxPrint" name = "taxPrint"  onClick={(e)  => handleTaxPrint(e)}
              disabled={menuSettings.permissions.find(element => (element.code === 'viewTaxSummary' && element.a_print === true && taxList.length > 0)) ? false : true}
              >
                  <BsFillPrinterFill
                    size={18}
                    style={{ color: "white", cursor: "pointer" }}
                    name={`print-tax-icon`}
                    id={`print-tax-icon`}
                    
                  />
                </Button>
               
                
            </Col>
            </Row>
  
  
            
            
            <hr align="center" />
           
            <Row>
               <Col xs={1} md={1} sm={1} lg={1}>
                  <Form.Label style={{ textAlign: "center", paddingTop: "5px" }}>Period</Form.Label>
                </Col>
            
               <Col xs={3} md={3} sm={3} lg={3}>
              
                <Form.Control
                  type="date"
                  name="date_from"
                  placeholder="Start Date"
                  value={formValues.date_from}
                  onChange={onChangeStartDatePicker}
                  //readOnly={isReadOnly}
                  
                />
              
            </Col>
            <Col xs={1} md={1} sm={1} lg={1}>
                  <Form.Label style={{ textAlign: "center", paddingTop: "5px" }}>To</Form.Label>
                </Col>
          
            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Control
                  type="date"
                  name="date_to"
                  placeholder="End Date"
                  value={formValues.date_to}
                  onChange={onChangeEndDatePicker}
                  //readOnly={isReadOnly}
                  
                />
                 </Col>
  
                
                <Col xs={1} md={1} sm={1} lg={1} style={{}}>
                <Button id="search-order-id"
                variant="success"
                onClick={(e) => handleSearch(e)}>
                Search
              </Button>
              </Col>
            </Row>
            <hr align="center" />
            <Row>

            <Col xs={12} md={12} sm={12} lg={12} style={{ textAlign: "right" }}>

              <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                Total Tax Amount(Sales) :
              </Form.Label>
              <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
              {taxSummary.sales_tax > 0.01 ?
                  <>
                    {((new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(taxSummary.sales_tax).toFixed(2))))}
                  </>
                  : (0.00).toFixed(2)}
                
              </Form.Label>

              <span style={{ color: '#C0C0C0' }}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>

              <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                Total Tax Amount ITC (Purchase) :
              </Form.Label>
              <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
                {taxSummary. purchase_tax_itc> 0.01 ?
                  <>
                    {((new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(taxSummary.purchase_tax_itc).toFixed(2))))}
                  </>
                  : (0.00).toFixed(2)}
              </Form.Label>
              {/*
              <span style={{ color: '#C0C0C0' }}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
              <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
              Total Tax Amount ITC (Purchase) :
              </Form.Label>
              <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
                {taxSummary.purchase_tax_nit > 0.01 ?
                  <>
                    {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(taxSummary.purchase_tax_nit).toFixed(2)))}
                  </>
                  : (0.00).toFixed(2)}
              </Form.Label>
              */}

              <span style={{ color: '#C0C0C0' }}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
              
              <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                Total Net Tax Diff :
              </Form.Label>
              <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
                
              {taxSummary.tax_net_diff > 0.01 ?
                  <>
                    {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(taxSummary.tax_net_diff).toFixed(2)))}
                    </>
                  : (0.00).toFixed(2)}
              </Form.Label>

            </Col>
          </Row>

            <Table striped bordered hover>
              <thead>
                <tr className="table-primary">
                <th className="table-row-heading" style={{textAlign: "center"}}>S.No</th>
                  <th className="table-row-heading" style={{textAlign: "center"}}>Tax</th>
                  <th className="table-row-heading" style={{textAlign: "center"}}>Tax %</th>
                  <th className="table-row-heading" style={{textAlign: "center"}}>Taxable Sales</th>
                  <th className="table-row-heading" style={{textAlign: "center"}}>Tax Amount(Sales)</th>
                  <th className="table-row-heading" style={{textAlign: "center"}}>Taxable Purchase</th>
                  <th className="table-row-heading" style={{textAlign: "center"}}>Tax Amount ITC(Purchase)</th>
                  {/*<th className="table-row-heading" style={{textAlign: "center"}}>Tax Amount NITC(Purchase)</th>*/}
                  <th className="table-row-heading" style={{textAlign: "center"}}>Net Tax Diff.</th>
                </tr>
              </thead>
              <tbody>
                {taxList.map((tax, idx) => (
                  <tr>
                    <td style={{textAlign: "right"}}>{idx + 1}</td>
                    <td style={{textAlign: "left"}}>{tax.tax}</td>
                    <td style={{textAlign: "right"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(tax.tax_per).toFixed(2)))}</td>
                    <td style={{textAlign: "right"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(tax.sales_amt).toFixed(2)))}</td>
                    <td style={{textAlign: "right"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(tax.sales_tax).toFixed(2)))}</td>
                    <td style={{textAlign: "right"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(tax.purchase_amt).toFixed(2)))}</td>
                    <td style={{textAlign: "right"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(tax.purchase_tax_itc).toFixed(2)))}</td>
                    {/*<td style={{textAlign: "right"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(tax.purchase_tax_nitc).toFixed(2)))}</td>*/}
                    <td style={{textAlign: "right"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(tax.tax_net_diff).toFixed(2)))}</td>
                    
              
                  </tr>
                  
                ))}
               
              </tbody>
            </Table>
          
          </form>
       : 
       <div className="container-spinner">
      <div className="vertical-center-spinner">
       <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
 
       </div>
       </div>
      }      
       </Container>
     
    );
  }
  export default ViewTaxSummary;