import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';

import { client } from '../config/Config';

//import StockDocument from "./StockDocument";
import {formatDateIndian, convertNumberToWords } from "./utils";
import _ from "lodash";

//import avinaoffice_logo from '../avinaoffice_logo.png';
import business_logo from '../assets/images/business_logo.png';


const borderColor = '';
//const rowItemHeight = 18;
const MAX_CHAR_PER_ROW = 40;
const MAX_CHAR_PER_ROW_DATA = 15;
const NTIMES_SIZE = 0.65;
const itemRowHeight = 18;
const MULTIPLY_PERC = 0.75;
const ADDR1_ITEM_ROW_HEIGHT = 50;
const ADDR_ITEM_ROW_HEIGHT = 46;
const ADDR3_ITEM_ROW_HEIGHT = 80;
const BORDER_COLOR = '#DCDCDC';
const BORDER_COLOR2 = "#C8C8C8";
const CONTAINER_BG_COLOR = "#F0F0F0";
const BORDER_WIDTH = 0.5;

const SNO_WIDTH = 4;
const DESC_WIDTH = 33;
const HSN_WIDTH = 11;
const QUANTITY_WIDTH = 11;
const WEIGHT_WIDTH = 11;
const RATE_WIDTH = 11;
const PER_WIDTH = 5;
const AMOUNT_WIDTH = 14;
const PADDING_HEIGHT = 120;

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 9,
    paddingTop: 10,
    paddingLeft: 25,
    paddingRight: 25,
    paddingBottom: 10,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    //width: '100%',
    //height: '33%',
    width: '100%',
    height: '100%',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logo_image2: {
    width: '15%',
    height: '98px',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  business_content: {
    width: '60%',
    height: '98px',
    //paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container2: {
    //paddingBottom:0,
    flexDirection: 'row',
    //borderBottomColor: '#bff0fd',
    //backgroundColor: '#bff0fd',
    //borderBottomWidth: 1,
    //alignItems: 'center',
    //height: 12,
    //textAlign: 'center',
    //fontStyle: 'bold',
    //flexGrow: 1,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    //marginTop: 0.5,
    //marginBottom: 0.5,
    borderWidth: BORDER_WIDTH,
    borderColor: BORDER_COLOR
   // borderColor: '#a8a8a8',

  },
  container: {
    flexDirection: 'row',
    //borderBottomColor: '#a8a8a8',
    //backgroundColor: '#dcdcdc',
    borderBottomWidth: BORDER_WIDTH,
    alignItems: 'center',
    height: 48,
    textAlign: 'center',
    fontStyle: 'bold',
    //flexGrow: 1,
    //flexWrap: 'wrap',
    //marginTop: 1,
    //marginBottom: 10,
    //borderWidth: 1,
    //borderWidth: 1,
    //marginBottom: 5,
  },

  container1: {
    flexDirection: 'row',
    //borderBottomColor: '#a8a8a8',
    backgroundColor: CONTAINER_BG_COLOR,
    //borderBottomWidth: 1,
    //borderTopWidth: 1,
    alignItems: 'center',
    height: 20,
    textAlign: 'center',
    //fontStyle: 'bold',
    //flexGrow: 1,
    //flexWrap: 'wrap',
    //marginTop: 1,
    //marginBottom: 10,
    //borderWidth: 1,
    //borderWidth: 1,
    //marginBottom: 5,
  },
  row: {
    flexDirection: 'row',
    //borderBottomColor: '#a8a8a8',
    //borderBottmWidth: 1,
    alignItems: 'center',
    //height: 24,
    fontStyle: 'bold',
  },
  

  
  address1: {
    width: '100%',
    textAlign: 'left',
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    borderRightColor: BORDER_COLOR,
    //paddingLeft: 8,
    height: ADDR1_ITEM_ROW_HEIGHT,
},
inv_no: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: "2px",
    //height: itemRowHeight,
    //borderRightColor: borderColor,
    //borderLeftWidth: 1,
    //borderRightWidth: 1,
    //borderBottomWidth: BORDER_WIDTH,
    
},
date: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: "2px",
    //height:itemRowHeight,
    //borderRightColor: borderColor,
    borderLeftWidth: BORDER_WIDTH,
    borderLeftColor: BORDER_COLOR,
    //borderRightWidth: 1,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR
    //paddingLeft: 8,
},

address2: {
    width: '100%',
    textAlign: 'left',
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    borderRightColor: BORDER_COLOR,
    height: ADDR_ITEM_ROW_HEIGHT,
    //paddingLeft: 8,
},
ref_no: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: "2px",
    //borderRightColor: borderColor,
    //borderLeftWidth: 1,
    //borderRightWidth: 1,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR
    //paddingLeft: 8,
},
ref: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: "2px",
    //borderRightColor: borderColor,
    borderLeftWidth: BORDER_WIDTH,
    borderLeftColor: BORDER_COLOR,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR
    //borderLeftColor: BORDER_COLOR
    //borderRightWidth: 1,
    //borderBottomWidth: BORDER_WIDTH
    //paddingLeft: 8,
},

address3: {
    width: '100%',
    textAlign: 'left',
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    borderRightColor: BORDER_COLOR,
    height: ADDR3_ITEM_ROW_HEIGHT,
    //paddingLeft: 8,
},
dispatch_no: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: "2px",
    //borderRightColor: borderColor,
    //borderLeftWidth: 1,
    //borderRightWidth: 1,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR
    //paddingLeft: 8,
},
dispatch: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: "2px",
    //borderRightColor: borderColor,
    borderLeftWidth: BORDER_WIDTH,
    borderLeftColor: BORDER_COLOR,
    borderBottomColor: BORDER_COLOR,
    //borderRightWidth: 1,
    borderBottomWidth: BORDER_WIDTH
    //paddingLeft: 8,
},

tableContainer1: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    

  },

  sno: {
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
    //paddingTop:"5px"
   // paddingLeft:10
   
  },
  description: {
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
    //paddingTop:"5px"
    //paddingRight:90
    //paddingLeft:10
  },
  
  hsn: {
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
   // paddingTop:"5px"
   // paddingLeft:10
   
  },
  quantity: {
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
    //paddingTop:"5px"
    //paddingRight:90
    //paddingLeft:10
  },
  rate: {
    
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
    //paddingTop:"2px"
   // paddingLeft:10
   
  },
  per: {
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
    //paddingTop:"2px"
    //paddingRight:90
    //paddingLeft:10
  },
  amount: {
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'center',
    height: itemRowHeight,
    //paddingTop:"1px"
    //paddingRight:90
    //paddingLeft:10
  },
  snoData: {
    width: '4%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'right',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingRight:"2px"
    //paddingTop:"5px"
   // paddingLeft:10
   
  },
  descriptionData: {
    width: '24%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'left',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingLeft:"2px"
    //paddingTop:"5px"
    //paddingRight:90
    //paddingLeft:10
  },
  
  hsnData: {
    width: '13%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'left',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingLeft:"2px"
    //paddingTop:"5px"
   // paddingLeft:10
   
  },
  quantityData: {
    width: '14%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'right',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingRight:"2px"
    //paddingTop:"5px"
    //paddingRight:90
    //paddingLeft:10
  },
  rateData: {
    width: '12%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'right',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingRight:"2px"
    //paddingTop:"5px"
   // paddingLeft:10
   
  },
  perData: {
    width: '5%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'left',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingLeft:"1px"
    //paddingTop:"5px"
    //paddingRight:90
    //paddingLeft:10
  },
  amountData: {
    width: '15%',
    fontSize: "10px",
    //borderRightWidth: 1,
    textAlign: 'right',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingRight:"2px"
  },

});

const PrintDNMfgT2 = (props) => {
    /*
    let invArr = [
        {
       description: "M5",
       hsn:"566125",
       quantity:"5",
       rate:"4500",
       per: "2",
       amount:"9000"
        },
        {
            description: "M8",
            hsn:"1234456",
            quantity:"2",
            rate:"6700",
            per: "6",
            amount:"4563"
             },
             {
                description: "lsgvncxmk nxcjfsdkj tyure yueie",
                hsn:"6775443",
                quantity:"8",
                rate:"7865",
                per: "3",
                amount:"7865"
                 },
                 {
                  description: "M5",
                  hsn:"57546",
                  quantity:"9",
                  rate:"3452",
                  per: "1",
                  amount:"3000"
                   },
                   {
                    description: "M7",
                    hsn:"89765",
                    quantity:"5",
                    rate:"2007",
                    per: "8",
                    amount:"5674"
                     }
       
      ];
      */
      
 
  const [reportData, setReportData] = useState({});
  const [toRender, setToRender] = useState(false);
  //const [invoiceList, setInvoiceList] = useState(invArr);
  const [companyInfo, setCompanyInfo] = useState({});
  const [invList, setInvList] = useState([]);
  const [reportHeader, setReportHeader] = useState({});
  const [reportTitle, setReportTitle] = useState({});
  const [billTo, setBillTo] = useState({});
  const [shipTo, setshipTo] = useState({});
  const [branchAddress, setBranchAddress] = useState({}); 
  const [businessAddress, setBusinessAddress] = useState({});
  const [dc, setDc] = useState({});
  const [addlDetails, setAddlDetails] = useState({});
  const [dcItems, setDcItems] = useState([]);
  const [printParams, setPrintParams] = useState([]);
  const [setting, setSetting] = useState({});
  //const [bankDetails, setBankDetails] = useState({});
  const initialized = useRef(false);

  var qty_ref = useRef(0.00);

     qty_ref.current = 0.00;
     dcItems.map((dcInd) => {
     qty_ref.current = parseFloat(qty_ref.current) + parseFloat(dcInd.qty);
    })

 
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  const formateTime = (currTime) => {
    var h = (currTime.getHours() < 10 ? "0" : "") + currTime.getHours();
    var m = (currTime.getMinutes() < 10 ? "0" : "") + currTime.getMinutes();
    var hrMin = h + ":" + m;
    return hrMin;
  };

  const formatPrintDate = (currTime) => {
    var dateComponent = [
      padTo2Digits(currTime.getDate()),
      padTo2Digits(currTime.getMonth() + 1),
      currTime.getFullYear(),
    ].join("-");

    var timeComponent = [
      padTo2Digits(currTime.getHours() % 12 || 12 ),
      padTo2Digits(currTime.getMinutes())
    ].join(":");

    const ampm = currTime.getHours() >= 12 ? 'PM' : 'AM';

    return dateComponent + " " + timeComponent + " " + ampm;
  }
 /*
  const getTaskList = async() => {
    //console.log("getPaymentList method called ...");
    //console.log("start date   : ", start_date);
   // console.log("end date     : ", end_date);
    //console.log("paid to  : ", paid_to);
    //console.log("expense category : ", expense_category_id);
    var retState = false;
    var retTaskList = [];
    try {
      var aIVal = localStorage.getItem("_aI");
      console.log("Authorization token : ", aIVal);

      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}

      console.log("Headers : ", headers);

      
      var res = await axios.get(client.domain + `/pmo/getAllTasks?`, {headers}, {
        withCredentials: false,
      });
     
      console.log("Response from server getTaskList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getTaskList : ", res.data.detail.data);
       if (res.data.detail.data.length > 0) {
          setTaskList(res.data.detail.data);
          //setReceiptListFull(res.data.detail.data);
          retTaskList = res.data.detail.data;
        }
        retState = true;
        setToRender(true);
      }
      
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
    setToRender(true);
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ",error.response.data.detail.res_data);
        }
      }
    }
    setToRender(true);

    return [retState, retTaskList];
  };
  */
  const getDcPrint = async (id_pass) => {
    var retStateLoad = false;
    var paramsRet = [];
    var titleRet = [];
    var dcRet = [];
    var billToRet = [];
    var shipToRet = [];
    var branchAddressRet = [];
    var businessAddressRet = [];
    var addlDetailsRet =  [];
    var dcItemsRet = [];
    var settingRet = [];
    //var bankRet = [];

    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      //console.log("Selected Customer :", customerIdSelect);
      var reqParams = {
        id: id_pass,

      };
      console.log("REQUEST PARAMS: ", reqParams );
      var res = await axios.post(client.domain + "/bms/getCustDCPrint", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server DC : ", res.data.detail.data );
        setPrintParams(res.data.detail.data.report_print_params);
        setBillTo(res.data.detail.data.billTo);
        setReportTitle(res.data.detail.data.report_title);
        setBranchAddress(res.data.detail.data.branch_address);
        setBusinessAddress(res.data.detail.data.business_address);
        setDc(res.data.detail.data.customer_dc);
        setAddlDetails(res.data.detail.data.customer_dc_additional_details);
        setDcItems(res.data.detail.data.customer_dc_items);
        setshipTo(res.data.detail.data.shipTo);
        setSetting(res.data.detail.data.report_print_setting);
        //setBankDetails(res.data.detail.data.invoice.bank_acc_details);
        //setCustomerListFull(res.data.detail.data);
        //setShowMsgTitle("Customer Info Added Successfully");
        //setToRender(true);
        paramsRet = res.data.detail.data.report_print_params;
        billToRet = res.data.detail.data.billTo;
        titleRet = res.data.detail.data.report_title;
        branchAddressRet = res.data.detail.data.branch_address;
        businessAddressRet = res.data.detail.data.business_address;
        dcRet = res.data.detail.data.customer_dc;
        addlDetailsRet = res.data.detail.data.customer_dc_additional_details;
        dcItemsRet = res.data.detail.data.customer_dc_items;
        shipToRet = res.data.detail.data.shipTo;
        settingRet = res.data.detail.data.report_print_setting;
        //bankRet = res.data.detail.data.invoice.bank_acc_details;
        retStateLoad = true;
        //navigate("/dashboard");
        //setDcReportData(res.data.detail.res_data);
        //setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log( "Not able to reach target server please try after sometime");
        //setShowMsg("Please try after sometime");
        //setShowMsgTitle("Network Issue!");
        //setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log( "Request failed due to ",error.response.data.detail.res_data);
          //setShowMsg("Please try again ...");
          //setShowMsgTitle("Unable to process your request!");
          //setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          //setShowMsg(error.response.data.detail);
          //setShowMsgTitle("Unable to process your request at this moment!");
          //setShow(true);
        }
      }
    }
    
    return [retStateLoad, dcRet, paramsRet,titleRet, billToRet, shipToRet, branchAddressRet, businessAddressRet, addlDetailsRet, dcItemsRet, settingRet];
  };
  
  
  
  useEffect(() => {
    console.log("useEffect method called ...");
    if (!initialized.current) {
    console.log("CALLED CURRENT PAGE : ", window.location.href);

    console.log("CALLED window locaion : ", window.location);
    let params = (new URL(window.location)).searchParams;
    console.log("COMPLETE PARAMS : ", params);
    
    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.indexOf('?') + 1);
    var urlTmp = urlStr.substring(0, urlStr.indexOf("?"));

    console.log("URL : ", urlTmp);
    console.log("URL : ", paramsTmp);

    let searchParams = new URLSearchParams(paramsTmp);
    console.log("SEARCH PARAMS :", searchParams );
    var id = searchParams.get("id");
    console.log("^^^^^^^^^^^^^^^^^^^  ID : ", id);

    
    (async () => {
      initialized.current = true;
      var [retStateLoad, dcRet, paramsRet,titleRet, billToRet, shipToRet, branchAddressRet, businessAddressRet, addlDetailsRet, dcItemsRet, settingRet] = await getDcPrint(id);
      console.log("<<<<<<<<<<<<<<<<<<< ADDITIONAL INFO >>>>>>>>>>>>>> : ", addlDetailsRet);
      //setAddlDetails(addlDetailsRet);
      //console.log("VEHICLE NUMBER ^^^^^^^^^^^^^^^^^ : ", addlDetailsRet.vehicle_no);
      setToRender(true);
    })();
    }
    
  }, [])

  return (
    <PDFViewer style={styles.viewer}>
      {toRender === true &&
        <Document>
          {_.times(setting.print_copies, (idx) => (
          <Page size="A4" orientation="portrait" style={styles.page}>
            <View style={{ flexDirection: 'row', borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR }}>
              
              {setting.is_print_tag_text === true &&
                <View style={{ textAlign: 'right', width: '100%', alignItems: 'right'}}>
                  { idx === 0 && printParams.find(element => (element.code === 'Print_TagText_1stcopy')) &&
                  <Text style={{paddingLeft: "240px"}}>{(printParams.find(element => (element.code === 'Print_TagText_1stcopy'))).text}</Text>
                   }

                 { idx === 1 && printParams.find(element => (element.code === 'Print_TagText_2ndcopy')) &&
                  <Text style={{paddingLeft: "240px"}}>{(printParams.find(element => (element.code === 'Print_TagText_2ndcopy'))).text}</Text>
                   }

                 { idx === 2 && printParams.find(element => (element.code === 'Print_TagText_3rdcopy')) &&
                  <Text style={{paddingLeft: "240px"}}>{(printParams.find(element => (element.code === 'Print_TagText_3rdcopy'))).text}</Text>
                   }
                </View>
                 }
            </View>
             
            <View style={{ width: "100%", alignItems: "center", textAlign: 'center', borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR, paddingTop: "2px" }}>
              <Text style={{ fontSize: "12px", textAlign: 'center', alignContent: 'center', fontFamily: 'Helvetica-Bold'}}>{reportTitle.rpt_title}</Text>
            </View>
            <hr style={{paddingBottom: "1px"}}/>

            <View style={styles.tableContainer}>
            <View style={{flexDirection: "row"}}>
            
            <View style={{width: "45%", height: '90px'}}>
              <Text style={{...styles.address1,paddingBottom: "0.2px",paddingLeft: "1px", height: '90px'}}><Text style={{fontFamily: 'Helvetica-Bold'}}>{businessAddress.business_name}</Text>{'\n'}{businessAddress.address_line1},{businessAddress.address_line2}{' '}{businessAddress.district},
           {businessAddress.state},{businessAddress.country}-{businessAddress.pincode}{'\n'}Mobile : {businessAddress.mobile}{'\n'}Email : {businessAddress.email} {'\n'}GSTIN : {businessAddress.gstin}{/*PAN :{businessAddress.pan}*/}
              </Text>
           </View>

           <View style={{width: "30%", height: '90px'}}>
           <Text style={{...styles.inv_no, height: '30px'}}>Delivery Note No:{'\n'}<Text style={{fontFamily: 'Helvetica-Bold'}}>{dc.dc_no}</Text></Text>
           <Text style={{...styles.inv_no, height: '30px'}}></Text>
           <Text style={{...styles.inv_no,paddingBottom: "0px",borderTopWidth: BORDER_WIDTH, borderBottomWidth: BORDER_WIDTH, borderTopColor: BORDER_COLOR, borderBottomColor:BORDER_COLOR,  height: '30px'}}>Reference No & Date{'\n'}<Text style={{fontFamily: 'Helvetica-Bold'}}>{addlDetails.ref_no} & {addlDetails.ref_date}</Text></Text>
           </View>

           <View style={{width: "25%", height: '90px'}}>
           <Text style={{...styles.date, height: '30px'}}>Delivery Note Date:{'\n'}<Text style={{fontFamily: 'Helvetica-Bold'}}>{dc.dc_date}</Text></Text>
           <Text style={{...styles.date, height: '30px'}}>Mode/Terms Of payment {'\n'}<Text style={{fontFamily: 'Helvetica-Bold', fontSize: '9px'}}>{addlDetails.pay_terms}</Text></Text>
           <Text style={{...styles.date,paddingBottom: "0px", height: '30px'}}>Other References{'\n'}<Text style={{fontFamily: 'Helvetica-Bold'}}>{addlDetails.ref_others}</Text></Text>
           </View>

           </View>


           <View style={{flexDirection: "row"}}>
            <View style={{width: "45%", height: '90px'}}>
           <Text style={{borderBottomWidth: BORDER_WIDTH, borderBottomColor:BORDER_COLOR, borderRightWidth: BORDER_WIDTH, borderRightColor:BORDER_COLOR, fontFamily: 'Helvetica-Bold',paddingLeft:"1px"}}>Dispatch To</Text>
           <Text style={{...styles.address2, paddingBottom: "2.5px", paddingLeft: "1px", height: '90px'}}><Text style={{fontFamily: 'Helvetica-Bold'}}>{shipTo.customer_name}</Text>{'\n'}{shipTo.address_line1},{shipTo.address_line2},{shipTo.district},{shipTo.state},{shipTo.pincode}{'\n'}GSTIN   : {shipTo.gstin}{'\n'}{/*PAN  : {shipTo.pan}*/}    
           
           </Text>
           </View>
           <View style={{width: "30%", height: '90px'}}>
           
           <Text style={{...styles.ref_no,paddingBottom: "0px", height: '30px'}}>Buyer's Order No.{'\n'}<Text style={{fontFamily: 'Helvetica-Bold'}}>{addlDetails.buyer_order_no}</Text></Text>
           <Text style={{...styles.ref_no,paddingBottom: "0px", height: '30px'}}>Dispatch Doc No{'\n'}<Text style={{fontFamily: 'Helvetica-Bold'}}>{addlDetails.dispatch_doc_no}</Text></Text>
           <Text style={{...styles.ref_no,paddingBottom: "0px", height: '30px'}}>Dispatch through {'\n'}<Text style={{fontFamily: 'Helvetica-Bold'}}>{addlDetails.dispatch_through}</Text></Text>
           </View>

           <View style={{width: "25%", borderBottomColor:BORDER_COLOR, borderBottomWidth: "0.1", height: '90px'}}>
           
           <Text style={{...styles.ref,  paddingBottom: "0px", borderBottomColor:BORDER_COLOR, borderBottomWidth: BORDER_WIDTH, height: '30px'}}>Dated: {'\n'}<Text style={{fontFamily: 'Helvetica-Bold', fontSize: '9px'}}>{addlDetails.buyer_order_date}</Text></Text>
           <Text style={{...styles.ref, paddingBottom: "0px", height: '30px'}}></Text>
           <Text style={{...styles.ref, paddingBottom: "0px",borderTopWidth: BORDER_WIDTH,borderTopColor: BORDER_COLOR ,height: '30px'}}>Destination: {'\n'}<Text style={{fontFamily: 'Helvetica-Bold', fontSize: '9px'}}>{addlDetails.destination}</Text></Text>
           </View>
           </View>
        
           <View style={{flexDirection: "row"}}>
            <View style={{width: "45%", height: '90px'}}>
           <Text style={{borderBottomWidth: BORDER_WIDTH,borderBottomColor: BORDER_COLOR, borderRightWidth: BORDER_WIDTH, borderRightColor: BORDER_COLOR,fontFamily: 'Helvetica-Bold',paddingLeft:"1px"}}>Party</Text>
           
           <Text style={{...styles.address3,paddingBottom: "0.1px",paddingLeft: "1px", height: '90px'}}><Text style={{fontFamily: 'Helvetica-Bold'}}>{billTo.customer_name}</Text>{'\n'}{billTo.address_line1},{billTo.address_line2},{billTo.district},{billTo.state},{billTo.pincode}{'\n'}GSTIN   : {billTo.gstin} {'\n'}{/*PAN  : {billTo.pan}*/}
           </Text>
           </View>
           <View style={{width: "30%", height: '90px'}}>
           
           <Text style={{...styles.dispatch_no,paddingBottom:"0px", height: '45px'}}>Date & Time of Issue {'\n'}<Text style={{fontFamily: 'Helvetica-Bold'}}>{addlDetails.bol_date}</Text></Text>
           <Text style={{...styles.dispatch_no,paddingBottom:"0px", height: '45px'}}>Bill of Lading/Lr.RR No.<Text style={{fontFamily: 'Helvetica-Bold'}}>{'\n'}{addlDetails.bol_no}</Text> {'\n'}<Text>Dated :<Text style={{fontFamily: 'Helvetica-Bold'}}>{'\n'}{addlDetails.bol_date}</Text></Text></Text>
           </View>
           <View style={{width: "25%", height: '90px'}}>
           <Text style={{...styles.dispatch,paddingBottom: "0px", height: '45px'}}>Duration of  Process :{'\n'}<Text style={{fontFamily: 'Helvetica-Bold'}}>{addlDetails.process_duration}</Text></Text>
           {/*<Text style={{...styles.dispatch,paddingBottom: "0px",height: (billTo.customer_name.length +  billTo.address_line1.length + billTo.address_line2.length +billTo.district.length + billTo.state.length + billTo.country.length + billTo.pincode.length) > MAX_CHAR_PER_ROW ? ((billTo.customer_name.length + billTo.address_line1.length + billTo.address_line2.length + billTo.district.length + billTo.state.length + billTo.country.length + billTo.pincode.length) / MAX_CHAR_PER_ROW) * ADDR3_ITEM_ROW_HEIGHT : ADDR3_ITEM_ROW_HEIGHT}}>Destination{'\n'}<Text style={{fontFamily: 'Helvetica-Bold'}}>{addlDetails.destination}</Text></Text>*/}
           <Text style={{...styles.dispatch,paddingTop: "0px", height: '45px'}}>Motor Vehicle No.{'\n'}<Text style={{fontFamily: 'Helvetica-Bold'}}>{addlDetails.vehicle_no}</Text></Text>
           </View>
           </View>
           
           <View style={{flexDirection: "row", paddingTop: "1px"}}>
           <View style={{width: "45%"}}>
           <Text style={{borderBottomColor: BORDER_COLOR, borderBottomWidth: BORDER_WIDTH, borderRightColor: BORDER_COLOR, borderRightWidth: BORDER_WIDTH, paddingLeft: "1px", height: itemRowHeight}}>Place of Supply  : <Text style={{fontFamily: 'Helvetica-Bold' }}>{dc.place_of_supply}</Text></Text>
            </View>
            <View style={{width: "55%", borderBottomColor: BORDER_COLOR, borderBottomWidth: BORDER_WIDTH}}>
                <Text>Nature of Processing:<Text style={{fontFamily: 'Helvetica-Bold' }}>{addlDetails.process_nature}</Text></Text>
            </View>
            </View>
            
            <View style={styles.container1}>
                 <Text style={{ ...styles.sno, width: `${SNO_WIDTH}%`}} className="align-items-center">S.No</Text>
                 <Text style={{ ...styles.description, width: `${DESC_WIDTH}%`}}> Description Of Goods</Text>
                 <Text style={{ ...styles.hsn, width: `${HSN_WIDTH}%`}}>HSN / SAC</Text>
                 <Text style={{ ...styles.quantity, width: `${QUANTITY_WIDTH}%`}}>Qty</Text>
                 <Text style={{ ...styles.quantity, width: `${WEIGHT_WIDTH}%`}}>Weight</Text>
                 <Text style={{ ...styles.per, width: `${PER_WIDTH}%`}}>Per</Text>
                 <Text style={{ ...styles.rate, width: `${RATE_WIDTH}%`}}>Rate</Text>                 
                 <Text style={{ ...styles.amount, width: `${AMOUNT_WIDTH}%`}}>Amount</Text>
                 </View>
                
                 <View style={{flexGrow: 1}}>
              {dcItems.map((dc, idx) => (
              <View style={styles.row} key={`key-${idx}`}>
                    <Text style={{...styles.snoData, width: `${SNO_WIDTH}%`, height: (dc.item_name.length + dc.notes.length * 2) > MAX_CHAR_PER_ROW_DATA ? (((dc.item_name.length + dc.notes.length * 2) / MAX_CHAR_PER_ROW_DATA) * itemRowHeight) : styles.snoData.height}}> {idx + 1}</Text>
                    <Text style={{...styles.descriptionData, width: `${DESC_WIDTH}%`, height: (dc.item_name.length + dc.notes.length * 2) > MAX_CHAR_PER_ROW_DATA ? (((dc.item_name.length + dc.notes.length * 2) / MAX_CHAR_PER_ROW_DATA) * itemRowHeight) : styles.descriptionData.height}}>{dc.item_name}{"\n"}<Text>&nbsp;&nbsp;{dc.notes}</Text></Text>
                    <Text style={{...styles.hsnData, width: `${HSN_WIDTH}%`, height: (dc.item_name.length + dc.notes.length * 2) > MAX_CHAR_PER_ROW_DATA ? (((dc.item_name.length + dc.notes.length * 2) / MAX_CHAR_PER_ROW_DATA) * itemRowHeight) : styles.hsnData.height}}>{dc.hsn_code}</Text>
                    <Text style={{...styles.quantityData, width: `${QUANTITY_WIDTH}%`, height: (dc.item_name.length + dc.notes.length * 2) > MAX_CHAR_PER_ROW_DATA ? (((dc.item_name.length + dc.notes.length * 2) / MAX_CHAR_PER_ROW_DATA) * itemRowHeight) : styles.quantityData.height}}>{dc.pkg_info}</Text>
                    <Text style={{...styles.quantityData, width: `${WEIGHT_WIDTH}%`, height: (dc.item_name.length + dc.notes.length * 2) > MAX_CHAR_PER_ROW_DATA ? (((dc.item_name.length + dc.notes.length * 2) / MAX_CHAR_PER_ROW_DATA) * itemRowHeight) : styles.quantityData.height}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(dc.qty).toFixed(2)))}{"\n"}</Text>
                    <Text style={{...styles.perData, width: `${PER_WIDTH}%`, height: (dc.item_name.length + dc.notes.length * 2) > MAX_CHAR_PER_ROW_DATA ? (((dc.item_name.length + dc.notes.length * 2) / MAX_CHAR_PER_ROW_DATA) * itemRowHeight) : styles.perData.height}}>{dc.unit}</Text>
                    <Text style={{...styles.rateData, width: `${RATE_WIDTH}%`, height: (dc.item_name.length + dc.notes.length * 2) > MAX_CHAR_PER_ROW_DATA ? (((dc.item_name.length + dc.notes.length * 2) / MAX_CHAR_PER_ROW_DATA) * itemRowHeight) : styles.rateData.height}}></Text>
                    <Text style={{...styles.amountData, width: `${AMOUNT_WIDTH}%`, height: (dc.item_name.length + dc.notes.length * 2) > MAX_CHAR_PER_ROW_DATA ? (((dc.item_name.length + dc.notes.length * 2) / MAX_CHAR_PER_ROW_DATA) * itemRowHeight) : styles.amountData.height}}></Text>        
                    
              </View>
               ))}

                { dcItems.length < 5 &&

                  <View style={styles.row} key={`key-${idx}`}>
                    <Text style={{...styles.snoData, width: `${SNO_WIDTH}%`, height: PADDING_HEIGHT}}></Text>
                    <Text style={{...styles.descriptionData, width: `${DESC_WIDTH}%`, height: PADDING_HEIGHT}}></Text>
                    <Text style={{...styles.hsnData, width: `${HSN_WIDTH}%`, height: PADDING_HEIGHT}}></Text>
                    <Text style={{...styles.quantityData, width: `${QUANTITY_WIDTH}%`, height: PADDING_HEIGHT}}></Text>
                    <Text style={{...styles.quantityData, width: `${WEIGHT_WIDTH}%`, height: PADDING_HEIGHT}}></Text>
                    <Text style={{...styles.perData, width: `${PER_WIDTH}%`, height: PADDING_HEIGHT}}></Text>
                    <Text style={{...styles.rateData, width: `${RATE_WIDTH}%`, height: PADDING_HEIGHT}}></Text>
                    <Text style={{...styles.amountData, width: `${AMOUNT_WIDTH}%`, height: PADDING_HEIGHT}}></Text>
                </View>
                }

              
                 </View>
                 {/*
                 <View style={{...styles.row}} key="items-${idx}">
                    <Text style={styles.snoData}></Text>
                    <Text style={{...styles.descriptionData,textAlign: "right",paddingRight: "2px", paddingTop: "3px", fontFamily: 'Helvetica-Bold'}}>Sub Total</Text>
                    <Text style={styles.hsnData}></Text>
                    <Text style={styles.quantityData}></Text>
                    <Text style={styles.perData}></Text>
                    <Text style={styles.rateData}></Text>
                    
                    <Text style={{...styles.amountData, fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(dc.sub_total_amt).toFixed(2)))}</Text>     
              </View>
                 {dc.igst_amt < 0.01 ?
                 <View>
                 <View style={{...styles.row}} key="items-${idx}">
                    <Text style={styles.snoData}></Text>
                    <Text style={{...styles.descriptionData, textAlign: "right",paddingRight: "2px", paddingTop: "3px", fontFamily: 'Helvetica-Bold'}}>CGST {dcItems[0].cgst_per}%</Text>
                    <Text style={styles.hsnData}></Text>
                    <Text style={styles.quantityData}></Text>
                    <Text style={styles.perData}></Text>
                    <Text style={styles.rateData}></Text>
                    
                    <Text style={{...styles.amountData, fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(dc.cgst_amt).toFixed(2)))}</Text>     
              </View>
                
               
               
              <View style={{...styles.row}} key="items-${idx}">
                    <Text style={styles.snoData}></Text>
                    <Text style={{...styles.descriptionData, textAlign: "right",paddingRight: "2px", paddingTop: "3px", fontFamily: 'Helvetica-Bold'}}>SGST {dcItems[0].sgst_per}%</Text>
                    <Text style={styles.hsnData}></Text>
                    <Text style={styles.quantityData}></Text>
                    <Text style={styles.perData}></Text>
                    <Text style={styles.rateData}></Text>
                    
                    <Text style={{...styles.amountData, fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(dc.sgst_amt).toFixed(2)))}</Text>     
              </View>
              </View>
            :
              <View style={{...styles.row}} key="items-${idx}">
                    <Text style={styles.snoData}></Text>
                    <Text style={{...styles.descriptionData, textAlign: "right",paddingRight: "2px", paddingTop: "3px", fontFamily: 'Helvetica-Bold'}}>IGST {dcItems[0].igst_per}%</Text>
                    <Text style={styles.hsnData}></Text>
                    <Text style={styles.quantityData}></Text>
                    <Text style={styles.perData}></Text>
                    <Text style={styles.rateData}></Text>
                    
                    <Text style={{...styles.amountData, fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(dc.igst_amt).toFixed(2)))}</Text>     
              </View>
              }

              <View style={{...styles.row}} key="items-${idx}">
                    <Text style={styles.snoData}></Text>
                    <Text style={{...styles.descriptionData, textAlign: "right",paddingRight: "2px", paddingTop: "3px", fontFamily: 'Helvetica-Bold'}}>Total</Text>
                    <Text style={styles.hsnData}></Text>
                    <Text style={styles.quantityData}></Text>
                    <Text style={styles.perData}></Text>
                    <Text style={styles.rateData}></Text>
                    
                    <Text style={{...styles.amountData, fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(dc.total_amt).toFixed(2)))}</Text>     
              </View>

              <View style={{...styles.row}} key="items-${idx}">
                    <Text style={styles.snoData}></Text>
                    <Text style={{...styles.descriptionData, textAlign: "right",paddingRight: "2px", paddingTop: "3px", fontFamily: 'Helvetica-Bold'}}>Round Off</Text>
                    <Text style={styles.hsnData}></Text>
                    <Text style={styles.quantityData}></Text>
                    <Text style={styles.perData}></Text>
                    <Text style={styles.rateData}></Text>
                    
                    <Text style={{...styles.amountData, fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(dc.roundoff_amt).toFixed(2)))}</Text>     
              </View>
            */}
            
              <View style={{...styles.row, borderBottomWidth: BORDER_WIDTH, borderTopWidth: BORDER_WIDTH, borderTopColor: BORDER_COLOR, borderBottomColor: BORDER_COLOR}} key="items-approx">
                    <Text style={{ ...styles.snoData, width: `${SNO_WIDTH}%`}}></Text>
                    <Text style={{ ...styles.descriptionData, width: `${DESC_WIDTH + HSN_WIDTH}%`, textAlign: "right", paddingTop: "3px", fontFamily: 'Helvetica-Bold', paddingRight: '2px' }}>Approx Value Rs {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(dc.dc_total_amt).toFixed(2)))}</Text>
                    <Text style={{ ...styles.quantityData, width: `${QUANTITY_WIDTH}%`}}></Text>
                    <Text style={{ ...styles.quantityData, width: `${WEIGHT_WIDTH}%`}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(qty_ref.current).toFixed(2)))}</Text>
                    <Text style={{ ...styles.perData, width: `${PER_WIDTH}%`}}>{dcItems[0].unit}</Text>
                    <Text style={{ ...styles.rateData, width: `${RATE_WIDTH}%`}}></Text>
                    
                    <Text style={{...styles.amountData, width: `${AMOUNT_WIDTH}%`, fontFamily: 'Helvetica-Bold'}}></Text>     
              </View>

              
              
              <View style={{borderBottomWidth: BORDER_WIDTH,  flexFlow: 1, width: '100%', borderBottomColor: BORDER_COLOR}}>
              <View style={{flexDirection: "row", paddingTop: "2px"}}>
                <Text style={{ paddingLeft:"1px"}}>Amount Chargeable (in words)</Text>
                <Text style={{ paddingLeft: "385px"}}>E & O.E</Text>
                </View>
                <Text style={{paddingBottom: "8px", paddingLeft:"1px", fontFamily: 'Helvetica-Bold'  }}>{convertNumberToWords(dc.dc_total_amt)}</Text>
                
                
                {/*
                <Text style={{paddingBottom: "8px", paddingLeft:"1px"}}>Notes {dcItems.notes}</Text>
                <Text style={{ paddingLeft:"1px"}}>T & C {'\n'}<Text style={{fontFamily: 'Helvetica-Bold' }}>{dc.declaration}</Text></Text>
               */}
                </View>
            {/*
              <View style={{flexDirection: "row",borderBottomWidth: 0.5}}>
                <View style={{width:"50%", paddingTop: "25px", borderRightWidth: 1}}>
                <Text style={{paddingLeft: "1px"}}>We declare that this invoice shows the actual price of the {'\n'}goods described and that all particulars are true and correct</Text>
                </View>
              
                <View style={{width:"50%",paddingLeft: "10px"}}>
                <Text style={{paddingTop: "5px"}}>Company's Bank Details</Text>
                <View style={{flexDirection: "row"}}>
                <Text style={{paddingBottom: "0px"}}>Bank Name</Text>
                <Text style={{paddingLeft:"40px"}}>: {bankDetails.BankName}</Text>
                </View>
                <View style={{flexDirection: "row"}}>
                <Text style={{paddingBottom: "0px"}}>A/C No.</Text>
                <Text style={{paddingLeft:"56px"}}>: {bankDetails.Acc}</Text>
                </View>
                <View style={{flexDirection: "row"}}>
                 <Text>Branch & IFSC Code </Text> 
                 <Text>: {bankDetails.IFSC}</Text>  
                </View>
                
                </View>
                 
              </View>
             */}
               <View style={{flexDirection: "row"}}>
            <View>
            { printParams.find(element => (element.code === 'ForBusinessName')) &&
              
              <Text style={{paddingLeft: "420px", paddingTop: "2px"}} >For {JSON.parse(localStorage.getItem('selected_business')).label}</Text>
              
              }
            </View>

            </View>

            <View style={{flexDirection: "row"}}>
            <View> 
              { printParams.find(element => (element.code === 'CustomerSealSignature')) &&
              <Text style={{paddingTop: "30px", paddingBottom: "-40px", paddingRight: "0px"}} >{(printParams.find(element => (element.code === 'CustomerSealSignature'))).text}</Text>
              }
              </View>
              
              <View>
              { printParams.find(element => (element.code === 'AuthorisedSignatory')) &&
              <Text style={{paddingTop: "30px", paddingLeft: "320px"}} >{(printParams.find(element => (element.code === 'AuthorisedSignatory'))).text}</Text>
              }              
            </View>
            
            </View>
             
              </View>
              
            <hr style={{paddingBottom: "10px"}}/>
             

            <View style={{flexDirection: 'row'}}>

            {setting.is_print_datetime === true &&
            <Text style={{textAlign: 'left', flex: 1}} render={({ pageNumber, totalPages }) => (
                `Printed On: ${formatPrintDate(new Date())}`
            )} fixed />
             }
            
             {printParams.find(element => element.code === "Note_ComputerGenDC") !== undefined &&
             <Text style={{ textAlign: 'center', paddingTop: '0px', flex: 1}}>{printParams.find(element => element.code === "Note_ComputerGenDC").text}</Text>
             }
             
             {setting.is_print_pageno === true &&
              <Text style={{flex: 1, textAlign: 'right', justifyContent: 'flex-end', alignSelf: 'flex-end'}} render={({ pageNumber, totalPages }) => (
                `Page No : ${pageNumber} / ${totalPages}`
              )} fixed />
              }
              </View>
              {setting.is_print_endofrpttext === true &&
              <View>
              <Text style={{ textAlign: 'center', paddingTop: '36px', flex: 1}} render={({ pageNumber, totalPages }) => (
                <View> {pageNumber === totalPages &&
                <Text>.... End of the document ....</Text>
                 }
                </View> 
                 )}  />
              </View>
              }
              

          </Page>
          ))
        }
        </Document>
      }
    </PDFViewer>
  );
}

export default PrintDNMfgT2;