


import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, pdf } from '@react-pdf/renderer';

import { client } from '../config/Config';

//import StockDocument from "./StockDocument";
import {getCompanyInfo, formatDateIndian } from "./utils";

//import logo_left from '../logo_left.png';
//import logo_right from '../logo_right.png';
//import { saveAs } from 'file-saver';
import business_logo from '../assets/images/business_logo.png';


const MAX_CHAR_PER_ROW = 30;
const NTIMES_SIZE = 0.65;
const itemRowHeight = 20;
const MULTIPLY_PERC = 0.75;
const TEXT_ALIGN = 4;
const BORDER_COLOR = '#DCDCDC';
const BORDER_COLOR2 = "#C8C8C8";
const BORDER_WIDTH = 0.5;
const HEADING_COLOR = '#646363';
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 9,
    paddingTop: 30,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    //width: '100%',
    //height: '33%',
    width: '100%',
    height: '100%',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logo_image2: {
    width: '15%',
    height: '98px',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  business_content: {
    width: '60%',
    height: '98px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container2: {
    flexDirection: 'row',
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 1,
    marginBottom: 10,
    //borderWidth: 1,
    borderColor: BORDER_COLOR,

  },
  container: {
    flexDirection: 'row',
    borderBottomColor: BORDER_COLOR,
    //backgroundColor: '#a8a8a8',
    borderBottomWidth: BORDER_WIDTH,
    borderTopWidth: BORDER_WIDTH,
    borderTopColor: BORDER_COLOR,
    borderTopWidth: BORDER_WIDTH,
    alignItems: 'center',
    //height: 17,
    textAlign: 'center',
    fontStyle: 'bold',
  },
  sno: {
    width: '3%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    borderLeftColor: BORDER_COLOR,
    borderLeftWidth: BORDER_WIDTH,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
   
  },
  date: {
    width: '8%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
    //paddingRight:2
  },
  number: {
    width: '12%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
    //paddingRight:2
  },
  total: {
    width: '10%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
    //paddingRight:2
  },
  
  
  customer: {
    width: '25%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    borderRightWidth: BORDER_WIDTH,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
  },
  cgst: {
    width: '8%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
    //paddingRight:2
  },
  sgst: {
    width: '8%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
    //paddingRight:2
  },
  igst: {
    width: '8%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
    //paddingRight:2
  },
  cess: {
    width: '8%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
    //paddingRight:2
  },
  amount: {
    width: '10%',
    fontSize: "9px",
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR
  },
  
  
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    fontStyle: 'bold',
    //height: 24
  },
  snoData: {
    width: '3%',   
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    fontSize: "9px",
    borderBottomWidth:BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    paddingRight: 4,
    height: itemRowHeight,
    borderLeftWidth: BORDER_WIDTH,
    borderLeftColor: BORDER_COLOR,
    textAlign: "right",
    paddingTop: TEXT_ALIGN
    //paddingRight: 2
  },
  dateData: {
    width: '8%',
    fontSize: "9px",
    textAlign: 'left',
    paddingLeft:4,
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    borderBottomWidth:BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
  },

  numberData: {
    width: '12%',
    fontSize: "9px",
    textAlign: 'left',
    paddingLeft: 4,
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    borderBottomWidth:BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    //paddingRight: 2,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
  },
  
 
  customerData: {
    width: '25%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    textAlign: 'left',
    paddingLeft: 4,
    borderBottomWidth:BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
  },
  totalData: {
    width: '10%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    borderBottomWidth:BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    //paddingRight: 2,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
  },

  cgstData: {
    width: '8%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    borderBottomWidth:BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    //paddingRight: 2,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
  },

  sgstData: {
    width: '8%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    borderBottomWidth:BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    //paddingRight: 2,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
  },

  igstData: {
    width: '8%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    borderBottomWidth:BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    //paddingRight: 2,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
  },

  cessData: {
    width: '8%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    borderBottomWidth:BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    //paddingRight: 2,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
  },

  amountData: {
    width: '10%',
    fontSize: "8px",
    borderRightColor: BORDER_COLOR,
    textAlign: 'right',
    borderBottomWidth:BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    paddingRight: 4,
    height: itemRowHeight,
    borderRightWidth: BORDER_WIDTH,
    paddingTop: TEXT_ALIGN
  },
  sno1Data: {
    width: '3%',   
    fontSize: "9px",
    //borderLeftColor: BORDER_COLOR,
    //borderLeftWidth: BORDER_WIDTH,
    
  },
  date1Data: {
    width: '8%',
    fontSize: "9px",
    textAlign: 'center',
    
  },
  number1Data: {
    width: '12%',
    fontSize: "9px",
    textAlign: 'center',
    //paddingRight: 4,
    
  },
  customer1Data: {
    width: '25%',
    fontSize: "9px",
    //borderRightColor: BORDER_COLOR,
    //borderRightWidth: BORDER_WIDTH,
    textAlign: 'right',
    paddingRight: 4,
    
  },
  total1Data: {
    width: '10%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    //borderRightColor: BORDER_COLOR,
    //borderRightWidth: BORDER_WIDTH,
   
  },


  cgst1Data: {
    width: '8%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    //borderRightColor: BORDER_COLOR,
    //borderRightWidth: BORDER_WIDTH,
   
  },

  sgst1Data: {
    width: '8%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    //borderRightColor: BORDER_COLOR,
    //borderRightWidth: BORDER_WIDTH,
   
  },

  igst1Data: {
    width: '8%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    //borderRightColor: BORDER_COLOR,
    //borderRightWidth: BORDER_WIDTH,
    
  },

  cess1Data: {
    width: '8%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    //borderRightColor: BORDER_COLOR,
    //borderRightWidth: BORDER_WIDTH,
   
  },
  amount1Data: {
    width: '10%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    //borderRightColor: BORDER_COLOR,
    //borderRightWidth: BORDER_WIDTH, 
  },

  sno2Data: {
    width: '11%',   
    fontSize: "9px",
    //borderLeftColor: BORDER_COLOR,
    //borderLeftWidth: BORDER_WIDTH,
    
  },
  date2Data: {
    width: '0%',
    fontSize: "9px",
    textAlign: 'center',
    
  },
  number2Data: {
    width: '12%',
    fontSize: "9px",
    textAlign: 'center',
    //paddingRight: 4,
    
  },
  customer2Data: {
    width: '25%',
    fontSize: "9px",
    //borderRightColor: BORDER_COLOR,
    //borderRightWidth: BORDER_WIDTH,
    textAlign: 'right',
    paddingRight: 4,
    
  },
  total2Data: {
    width: '10%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    //borderRightColor: BORDER_COLOR,
    //borderRightWidth: BORDER_WIDTH,
   
  },


  cgst2Data: {
    width: '8%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    //borderRightColor: BORDER_COLOR,
    //borderRightWidth: BORDER_WIDTH,
   
  },

  sgst2Data: {
    width: '8%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    //borderRightColor: BORDER_COLOR,
    //borderRightWidth: BORDER_WIDTH,
   
  },

  igst2Data: {
    width: '8%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    //borderRightColor: BORDER_COLOR,
    //borderRightWidth: BORDER_WIDTH,
    
  },

  cess2Data: {
    width: '8%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    //borderRightColor: BORDER_COLOR,
    //borderRightWidth: BORDER_WIDTH,
   
  },
  amount2Data: {
    width: '10%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    //borderRightColor: BORDER_COLOR,
    //borderRightWidth: BORDER_WIDTH, 
  },

  
});
const PrintCustomerBills = (props) => {
 
  const [reportData, setReportData] = useState({});
  const [toRender, setToRender] = useState(false);
  const [billList, setBillList] = useState([]);
  
  const [reportHeader, setReportHeader] = useState({});
  const [reportTitle, setReportTitle] = useState({});
  const [billSummary, setBillSummary] = useState([]);
  const [setting, setSetting] = useState({});
  const [printParams, setPrintParams] = useState([]);
 
  const [companyInfo, setCompanyInfo] = useState({});
  var customer_id_ref = useRef(0);
  var start_date_ref = useRef(formatDate(new Date()));
  var end_date_ref = useRef(formatDate(new Date()));

  //var amount_total_ref = useRef(0.00);
  const currSelBusinessRef = useRef(0);
  const currBranchRef = useRef(0);
  
 
  
  console.log("CALLED CURRENT PAGE : ", window.location.href);

    console.log("CALLED window locaion : ", window.location);
    let params = (new URL(window.location)).searchParams;
    console.log("COMPLETE PARAMS : ", params);
    let orderIdParam = params.get("order_id");
    console.log("ORDER ID FROM PARAM : ", orderIdParam);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.indexOf('?') + 1);
    var urlTmp = urlStr.substring(0, urlStr.indexOf("?"));

    console.log("URL : ", urlTmp);
    console.log("URL : ", paramsTmp);

    let searchParams = new URLSearchParams(paramsTmp);
    var customerIdTemp2 = searchParams.get("customer_id");
    console.log("CUSTOMED ID : ", customerIdTemp2);
    var productIdTemp2 = searchParams.get("product_id");
    console.log("PRODUCT ID : ", productIdTemp2);
    var startDateStr = searchParams.get("start_date");
    console.log("START DATE : ", startDateStr);
    var endDateStr = searchParams.get("end_date");
    console.log("END DATE : ", endDateStr);

    console.log("Extracted CUSTOMER ID  : ", customerIdTemp2);
    console.log("Extracted PRODUCT ID : ", productIdTemp2);
   
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  const formateTime = (currTime) => {
    var h = (currTime.getHours() < 10 ? "0" : "") + currTime.getHours();
    var m = (currTime.getMinutes() < 10 ? "0" : "") + currTime.getMinutes();
    var hrMin = h + ":" + m;
    return hrMin;
  };

  const formatPrintDate = (currTime) => {
    var dateComponent = [
      padTo2Digits(currTime.getDate()),
      padTo2Digits(currTime.getMonth() + 1),
      currTime.getFullYear(),
    ].join("-");

    var timeComponent = [
      padTo2Digits(currTime.getHours() % 12 || 12 ),
      padTo2Digits(currTime.getMinutes())
    ].join(":");

    const ampm = currTime.getHours() >= 12 ? 'PM' : 'AM';

    return dateComponent + " " + timeComponent + " " + ampm;
  }
  
 
  const getBillPrintList = async (bid_pass, brid_pass, customer_id_pass, date_from_pass, date_to_pass) => {
    var retStateLoad = false;
    var headerRet = [];
    var titleRet = [];
    var billRet = [];
    var summaryRet = [];
    var settingRet =  []; 
    var paramsRet = [];
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      //console.log("Selected Customer :", customerIdSelect);
      var reqParams = {
        bid: bid_pass,
        brid: brid_pass,
        customer_id: customer_id_pass,
        date_from: date_from_pass,
        date_to: date_to_pass

      };
      console.log("REQUEST PARAMS: ", reqParams );
      var res = await axios.post(client.domain + "/bms/getCustBillListPrint", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server Invoice : ",res.data.detail.data );
        setReportHeader(res.data.detail.data.report_header);
        setReportTitle(res.data.detail.data.report_title);
        setBillList(res.data.detail.data.bills);
        setBillSummary(res.data.detail.data.bill_Summary[0]);
        setSetting(res.data.detail.data.report_print_setting);
        setPrintParams(res.data.detail.data.report_print_params);

        //setCustomerListFull(res.data.detail.data);
        //setShowMsgTitle("Customer Info Added Successfully");
        //setToRender(true);
        headerRet = res.data.detail.data.report_header;
        titleRet = res.data.detail.data.report_title;
        billRet = res.data.detail.data.bills;
        summaryRet = res.data.detail.data.bill_Summary;
        settingRet = res.data.detail.data.report_print_setting;
        paramsRet = res.data.detail.data.report_print_params;
        retStateLoad = true;
        //navigate("/dashboard");
        //setDcReportData(res.data.detail.res_data);
        //setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log( "Not able to reach target server please try after sometime");
        //setShowMsg("Please try after sometime");
        //setShowMsgTitle("Network Issue!");
        //setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log( "Request failed due to ",error.response.data.detail.res_data);
          //setShowMsg("Please try again ...");
          //setShowMsgTitle("Unable to process your request!");
          //setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          //setShowMsg(error.response.data.detail);
          //setShowMsgTitle("Unable to process your request at this moment!");
          //setShow(true);
        }
      }
    }
    return [retStateLoad, billRet, summaryRet, headerRet, titleRet, settingRet, paramsRet];
  };

  useEffect(() => {
    console.log("useEffect method called ...");
    //setToRender(true); 
    //getCustomerList();
    console.log("CALLED CURRENT PAGE : ", window.location.href);

    console.log("CALLED window locaion : ", window.location);
    let params = (new URL(window.location)).searchParams;
    console.log("COMPLETE PARAMS : ", params);
    let customerIdParam = params.get("customer_id");
    console.log("CUSTOMER ID FROM PARAM : ", customerIdParam);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.indexOf('?') + 1);
    var urlTmp = urlStr.substring(0, urlStr.indexOf("?"));

    console.log("URL : ", urlTmp);
    console.log("URL : ", paramsTmp);

    let searchParams = new URLSearchParams(paramsTmp);
    console.log("SEARCH PARAMS :", searchParams );
    var bid = searchParams.get("bid");
    console.log("^^^^^^^^^^^^^^^^^^^ BUSINESS ID : ", bid);
    
    var brid = searchParams.get("brid");
    console.log("^^^^^^^^^^^^^^^^^^^ BUSINESS BRANCH0 ID : ", brid);

    var customerId = searchParams.get("customer_id");
    console.log("^^^^^^^^^^^^^^^^^^^ Customer ID : ", customerId);
    
    var date_from = searchParams.get("date_from");
    console.log("^^^^^^^^^^^^^^^^^^^ Date From : ", date_from);
    
    var date_to = searchParams.get("date_to");
    console.log("^^^^^^^^^^^^^^^^^^^ Date To : ", date_to);
    (async () => {
      //var [retState, companyInfoTmp] = await getCompanyInfo();
      /*
      if (retState) {
        setCompanyInfo(companyInfoTmp);
        setToRender(true); 
      }
      */
      //var [retStateLoad, invoiceRet, summaryRet, headerRet, titleRet] = await getInvoicePrintList(customerRet[0].value);
      var [retStateLoad, billRet, summaryRet, headerRet, titleRet, settingRet, paramsRet] = await getBillPrintList(bid, brid, customerId, date_from, date_to);
      setToRender(true);
    })();
  
    
  }, [])
 
  /*

  exportToJson = el => {
    if (!isEmpty(this.formData)) {
      //const obj = encodeURIComponent(JSON.stringify(this.formData, null, '\t'));
      //const data = "text/json; charset=utf-8," + "obj";
      //const date = new Date();

      const fileName = "customer.json";
      el.target.setAttribute('href', 'data:' + data);
      el.target.setAttribute('download', fileName);
    }
  };
  */
 
 

  return (
    //<PDFViewer fileName="myPdf.pdf" />
    <PDFViewer style={styles.viewer}>
      
      {toRender === true &&
        <Document>
          <Page size="A4" orientation="landscape" style={styles.page}>
           
              <View style={{ flexDirection: 'row', borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR }}>
                {setting.is_print_logo === true &&
                <View style={{ textAlign: 'right', width: '15%' }}>
                  <Image style={{ marginHorizontal: '10%', width: 'auto', height: 'auto', height: "64px", width: "64px" }} src={business_logo} />
                </View>
                }
                <View style={{ width: "85%", alignItems: "center", /*paddingRight: "30px",*/ /*borderBottomWidth: 1,*/ borderBottomColor: '#a7a5a5'/*,marginTop: 1, marginBottom: 10, borderWidth: 1*/ }} key="address-key">
                  <Text style={{ fontSize: "20px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>{reportHeader.business_name}</Text>
                  <Text>{reportHeader.ho_address}</Text>
                  {setting.is_print_branch_address === true &&
                    <Text>{reportHeader.bo_address}</Text>
                  }
                  <hr style={{paddingBottom: "5px"}}/>
                  
                </View> 
              </View>

           
            <View style={{ width: "100%", alignItems: "center", textAlign: 'center',  paddingTop: "5px",borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR }}>
              <Text style={{ fontSize: "10px", textAlign: 'center', alignContent: 'center', fontFamily: 'Helvetica-Bold', color: HEADING_COLOR }}>{reportTitle.rpt_title}</Text>
            </View>
            {/*
            <hr style={{paddingBottom: "5px"}}/>
            
            <View style={{flexDirection: "row",borderBottomWidth: 1, borderBottomColor: '#a7a5a5', paddingBottom: "5px", textAlign: "right"}}>
                <View style={{ width: "7%"}}>
                <Text style={{fontFamily: 'Helvetica-Bold'}}>Summary :-  </Text>
                </View>

                <View style={{flexDirection: "row", width: "8%", textAlign: "right"}}>
                <Text style={{fontFamily: 'Helvetica-Bold'}}>Bills :  </Text>
                <Text> {billSummary.bill_count}</Text>
                </View>

                <View style={{flexDirection: "row",  width: "14%", textAlign: "right"}}>
                <Text style={{fontFamily: 'Helvetica-Bold'}}>Amount :  </Text>
                <Text> {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.sub_total_amt).toFixed(2)))}</Text>
                </View>

                <View style={{flexDirection: "row", width: "14%", textAlign: "right"}}>
                <Text style={{fontFamily: 'Helvetica-Bold'}}>CGST :  </Text>
                <Text> {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.cgst_amt).toFixed(2)))}</Text>
                </View>

                <View style={{flexDirection: "row", width: "14%", textAlign: "right"}}>
                <Text style={{fontFamily: 'Helvetica-Bold'}}>SGST :  </Text>
                <Text> {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.sgst_amt).toFixed(2)))}</Text>
                </View>

                <View style={{flexDirection: "row",width: "13%", textAlign: "right"}}>
                <Text style={{fontFamily: 'Helvetica-Bold'}}>IGST :  </Text>
                <Text> {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.igst_amt).toFixed(2)))}</Text>
                </View>

                <View style={{flexDirection: "row", width: "12%", textAlign: "right"}}>
                <Text style={{fontFamily: 'Helvetica-Bold'}}>CESS :  </Text>
                <Text> {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.cess_amt).toFixed(2)))}</Text>
                </View>

                <View style={{flexDirection: "row",  width: "20%", textAlign: "right"}}>
                <Text style={{fontFamily: 'Helvetica-Bold'}}>Bill Amount :  </Text>
                <Text> {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.bill_total_amt).toFixed(2)))}</Text>
                </View>
            </View>
                */}
                <View style={{...styles.row, paddingTop:TEXT_ALIGN}} key="items-${idx}">
                    <Text style={{...styles.sno2Data, fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Total Bills : {billSummary.bill_count}</Text>
                    <Text style={styles.date2Data}></Text>
                    <Text style={styles.number2Data}></Text>
                    <Text style={{...styles.customer2Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Total:</Text>
                    <Text style={{...styles.total2Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.sub_total_amt).toFixed(2)))}</Text>
                    <Text style={{...styles.cgst2Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.cgst_amt).toFixed(2)))}</Text>
                    <Text style={{...styles.sgst2Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.sgst_amt).toFixed(2)))}</Text>
                    <Text style={{...styles.igst2Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.igst_amt).toFixed(2)))}</Text>
                    <Text style={{...styles.cess2Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.cess_amt).toFixed(2)))}</Text>
                    <Text style={{...styles.amount2Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.bill_total_amt).toFixed(2)))}</Text>
              </View>
  
              <hr style={{paddingBottom: "2px"}}/>
            <View style={styles.tableContainer}>
              
            <View fixed style={styles.container}>
                <Text style={styles.sno}>S.No</Text>
                <Text style={styles.date}>Bill Date</Text>
                <Text style={styles.number}>Bill No.</Text>
                <Text style={styles.customer}>Customer</Text>
                <Text style={styles.total}>Sub Total</Text>
                <Text style={styles.cgst}>CGST</Text>
                <Text style={styles.sgst}>SGST</Text>
                <Text style={styles.igst}>IGST</Text>
                <Text style={styles.cess}>CESS</Text>
                <Text style={styles.amount}>Total Amount</Text>
              </View>

              <View style={{flexGrow: 1}}>
              {billList.map((bill, idx) => (
              <View style={styles.row} key="bill-title">
                    <Text break style={{...styles.snoData, height: (bill.customer_name && bill.customer_name.length > MAX_CHAR_PER_ROW) ? ((bill.customer_name.length / MAX_CHAR_PER_ROW) * itemRowHeight) : styles.snoData.height}}>{idx + 1}</Text>
                    <Text style={{...styles.dateData, height: (bill.customer_name && bill.customer_name.length > MAX_CHAR_PER_ROW) ? ((bill.customer_name.length / MAX_CHAR_PER_ROW) * itemRowHeight) : styles.dateData.height}}>{bill.bill_date}</Text>
                    <Text style={{...styles.numberData, height: (bill.customer_name && bill.customer_name.length > MAX_CHAR_PER_ROW) ? ((bill.customer_name.length / MAX_CHAR_PER_ROW) * itemRowHeight) : styles.numberData.height}}>{bill.bill_no}</Text>
                    <Text style={{...styles.customerData, height: (bill.customer_name && bill.customer_name.length > MAX_CHAR_PER_ROW) ? ((bill.customer_name.length / MAX_CHAR_PER_ROW) * itemRowHeight) : styles.customerData.height}}>{bill.customer_name}</Text>
                    <Text style={{...styles.amountData, height: (bill.customer_name && bill.customer_name.length > MAX_CHAR_PER_ROW) ? ((bill.customer_name.length / MAX_CHAR_PER_ROW) * itemRowHeight) : styles.totalData.height}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(bill.sub_total_amt).toFixed(2)))}</Text>
                    <Text style={{...styles.cgstData, height: (bill.customer_name && bill.customer_name.length > MAX_CHAR_PER_ROW) ? ((bill.customer_name.length / MAX_CHAR_PER_ROW) * itemRowHeight) : styles.cgstData.height}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(bill.cgst_amt).toFixed(2)))}</Text>
                    <Text style={{...styles.sgstData, height: (bill.customer_name && bill.customer_name.length > MAX_CHAR_PER_ROW) ? ((bill.customer_name.length / MAX_CHAR_PER_ROW) * itemRowHeight) : styles.sgstData.height}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(bill.sgst_amt).toFixed(2)))}</Text>
                    <Text style={{...styles.igstData, height: (bill.customer_name && bill.customer_name.length > MAX_CHAR_PER_ROW) ? ((bill.customer_name.length / MAX_CHAR_PER_ROW) * itemRowHeight) : styles.igstData.height}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(bill.igst_amt).toFixed(2)))}</Text>
                    <Text style={{...styles.cessData, height: (bill.customer_name && bill.customer_name.length > MAX_CHAR_PER_ROW) ? ((bill.customer_name.length / MAX_CHAR_PER_ROW) * itemRowHeight) : styles.cessData.height}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(bill.cess_amt).toFixed(2)))}</Text>
                    <Text style={{...styles.totalData, height: (bill.customer_name && bill.customer_name.length > MAX_CHAR_PER_ROW) ? ((bill.customer_name.length / MAX_CHAR_PER_ROW) * itemRowHeight) : styles.totalData.height}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(bill.bill_total_amt).toFixed(2)))}</Text> 
              </View>
               ))}
              </View>
              
              </View>
              <View style={{...styles.row, borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR}} key="bill-list-data">
                    <Text style={styles.sno1Data}>  </Text>
                    <Text style={styles.date1Data}></Text>
                    <Text style={styles.number1Data}></Text>
                    <Text style={{...styles.customer1Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Total:</Text>
                    <Text style={{...styles.total1Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.sub_total_amt).toFixed(2)))}</Text>
                    <Text style={{...styles.cgst1Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.cgst_amt).toFixed(2)))}</Text>
                    <Text style={{...styles.sgst1Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.sgst_amt).toFixed(2)))}</Text>
                    <Text style={{...styles.igst1Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.igst_amt).toFixed(2)))}</Text>
                    <Text style={{...styles.cess1Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.cess_amt).toFixed(2)))}</Text>
                    <Text style={{...styles.amount1Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.bill_total_amt).toFixed(2)))}</Text>
              </View>
            
             
              


              
            <hr style={{paddingBottom: "20px"}}/>

            <View fixed style={{flexDirection: 'row'}}>
        
            {setting.is_print_datetime === true &&
            <Text style={{textAlign: 'left', flex: 1, paddingTop: "26px"}} render={({ pageNumber, totalPages }) => (
                `Printed On: ${formatPrintDate(new Date())}`
            )}  fixed />
            } 

              
            {setting.is_print_endofrpttext === true &&
              <Text style={{ textAlign: 'center', alignContent: 'center', flex: 1, paddingTop: "15px"}} render={({ pageNumber, totalPages }) => (
                <View> {pageNumber === totalPages &&
                <Text>.... End of the document ....</Text>
                 }
                </View>
            )}  />
            }
              {setting.is_print_pageno === true &&
              <Text style={{flex: 1, textAlign: 'right', justifyContent: 'flex-end', alignSelf: 'flex-end', paddingTop: "26px"}} render={({ pageNumber, totalPages }) => (
                `Page No : ${pageNumber} / ${totalPages}`
              )} fixed />
            }
              
              </View>
          
          </Page>
        </Document>
        
      }
    </PDFViewer>
  );
}

export default PrintCustomerBills;