import { client } from "../config/Config";

import axios from "axios";

  export const getProductBuy = async () => {
    var retState = false;
    var newBuyProdArr = [];
    console.log("getProductBuy method called ...");


    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(
        client.domain + "/ims/getProductsTTBuy",
        { headers },
        { withCredentials: false }
      );
      
      console.log(
        "Response from server getProductBuy : ",
        res.data.detail.data
      );
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getProductBuy : ", res.data.detail.data);

        
        res.data.detail.data.map((prodItem) => {
          var newBuyProd = {
            "value": prodItem.id,
            "label": prodItem.name,
            "id": prodItem.id,
            "name": prodItem.name,
            "description": prodItem.description,
            "hsncode": prodItem.hsncode,
            "uom": prodItem.uom_value,
            "unit_price": prodItem.unit_price,
            "cgst": prodItem.cgst,
            "sgst": prodItem.sgst,
            "igst": prodItem.igst
          }
          newBuyProdArr.push(newBuyProd);
        })
        //setProductList(newBuyProdArr);
        retState = true;

      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.data
          );

        }
      }
    }

    console.log("BEFORE RETURN in getProductBuy");
    return [retState, newBuyProdArr];
  };


  export const getProductsSell = async () => {
    var retState = false;
    var newSellProdArr = [];
    console.log("getProductSell method called ...");


    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(
        client.domain + "/ims/getProductsTTSell",
        { headers },
        { withCredentials: false }
      );
      
      console.log("Response from server getProductSell : ", res.data.detail.data);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getProductSell : ", res.data.detail.data);        
        res.data.detail.data.map((prodItem) => {
          var newSellProd = {
            "value": prodItem.id,
            "label": prodItem.name,
            "id": prodItem.id,
            "name": prodItem.name,
            "description": prodItem.description,
            "hsncode": prodItem.hsncode,
            "uom": prodItem.uom_value,
            "unit_price": prodItem.unit_price,
            "cgst": prodItem.cgst,
            "sgst": prodItem.sgst,
            "igst": prodItem.igst,
            "is_product_cc": prodItem.is_composite
          }
          newSellProdArr.push(newSellProd);
        })
        //setProductList(newBuyProdArr);
        retState = true;
      }
     
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          
        }
      }
    }

    return [retState, newSellProdArr];
  };

  export const getDistrictList = async(stateIdInput) => {
    var retState = false;
    var distArrRet = [];
    console.log("getDistrictList method called ...");
    
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + "/core/getDistricts?stateid=" + stateIdInput, { headers }, { withCredentials: false });
      console.log("After QUERY execution at getDistrictList ...");
     
      console.log("Response from server getDistrictList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getDistrictList : ", res.data.detail.data);
        
        
        if (res.data.detail.data.length > 0) {
          res.data.detail.data.map((distInd) => {
            var distObj = {"value": distInd.district_id, "label": distInd.district_name, "district_id": distInd.district_id, "district_code": distInd.district_code, "district_name": distInd.district_name};
            distArrRet.push(distObj);
          })
          //setOptDist(distArrRet);
        }
      retState = true;  
      }
     
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          
        }
      }
    }
    console.log("retState >>>>>>>>>>>>>>>>>>>>> :" , retState);
    console.log("distArrRet <<<<<<<<<<<<<<<< :" , distArrRet);

    return [retState, distArrRet];
  };

  export const getStateList = async (countryIdInput) => {
    var retState = false;
    var stateListRet = [];
    console.log("getStateList method called ...");

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + "/core/getStates?countryid=" + countryIdInput, { headers }, { withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getStateList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        /*
        console.log(
          "Response message from server getStateList : ",
          res.data.detail.res_data
        );
        console.log(
          "Response message from server getStateList user_info : ",
          res.data.detail.res_data.user_info
        );
        console.log(
          "Response message from server getStateList state list : ",
          res.data.detail.res_data.district_list
        );
        */
        console.log("State Array : ", res.data.detail.data);
        //optionsDistrict = res.data.detail.res_data.district_list

        //var stateArrTmp = [];
        if (res.data.detail.data.length > 0) {
          res.data.detail.data.map((stateInd) => {
            var stateObj = {"value": stateInd.state_id, "label": stateInd.state_name, "state_id": stateInd.state_id, "state_code": stateInd.state_code, "state_name": stateInd.state_name};
            console.log("State Obj: ", stateObj);
            stateListRet.push(stateObj);
          })
          //setOptState(stateListRet);
          /*
          setStateIdSelect({
            value: stateListRet[0].value,
            label: stateListRet[0].label,
          });
          */
        }
        retState = true;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.data
          );

        }
      }
    }
    return [retState, stateListRet];
  };
  
  export const getVehicleList = async () => {
    var retState = false;
    var vehicleArr = [];
    console.log("getVehicleist method called ...");

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(
        client.domain + "/vms/getDeliveryVehicles",
        { headers },
        { withCredentials: false }
      );
     
      console.log("Response from server getVehicleList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getVehicleList : ", res.data.detail.data);
        //console.log("Length of vehicle array : ", res.data.detail.data.length);
        //optionsDistrict = res.data.detail.res_data.district_list

        if (res.data.detail.data.length > 0) {
          
          res.data.detail.data.map((vehicle) => {
            console.log("Vehicle ind data : ", vehicle);
            var vehicleInd = {};
            vehicleInd.value = vehicle.id;
            vehicleInd.label = vehicle.name;
            vehicleArr.push(vehicleInd);
          
          })
          console.log("Vehicle Arr : ", vehicleArr);
          /*  
          setOptVehicle(vehicleArr);
          if (state.action === 'add') {
          setVehicleIdSelect({
            value: res.data.detail.data[0].id,
            label: res.data.detail.data[0].name
          });
        }
        */
        }
       retState = true;
      }
      
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
    return [retState, vehicleArr];
  };

 export const getVehicleTypeList = async () => {
  console.log("getVehicleTypeList method called ...");
  var retState = false;
  /* let reqLoginPayload = {
    stateId : stateIdInput, 
    stateName : stateNameInput
  };*/

  
  // console.log("payload:", reqLoginPayload);

  try {
    var aIVal = localStorage.getItem("_aI");
    var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
    var res = await axios.get(
      client.domain + "/vms/getVehicleTypes",
      { headers },
      { withCredentials: false }
    );
    //.then((res) => {
    //console.log("ADD New user res:", res);
    //localStorage.setItem('_token', res.data.res_data.token);
    console.log(
      "Response from server getVehicleTypeList : ",
      res.data.detail.data
    );
    if (res.data.detail.status === "SUCCESS") {
      console.log(
        "Response message from server getVehicleTypeList : ",
        res.data.detail.data
      );
      //console.log("Response message from server getVehicleTypeList user_info : ", res.data.detail.data);
      // console.log("Response message from server getVehicleTypeList dist list : ", res.data.detail.res_data.district_list);
      //console.log("Length of district array : ", res.data.detail.data.length);
      //optionsDistrict = res.data.detail.res_data.district_list

      //if ( res.data.detail.data.length > 0 ) {
      //setOptDist(res.data.detail.res_data.district_list)
      //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
      //setVehicleTypeOpt(res.data.detail.data)
      ///}

      var vehicleTypeArr = [];
      res.data.detail.data.map((itemVehicleType, idx) => {
        console.log("Ind val : ", itemVehicleType);
        var vehicleTypeInd = {
          value: itemVehicleType.id,
          label: itemVehicleType.vehicle_type,
        };
        console.log("In veh type modified : ", vehicleTypeInd);
        vehicleTypeArr.push(vehicleTypeInd);
      });

      console.log("Mod Full VALUE : ", vehicleTypeArr);
      retState = true;
 
     /*
      setVehicleTypeOpt(vehicleTypeArr);
      if (state.action === "add") {
        setVehicleTypeIdSelect(vehicleTypeArr[0]);
      } else {
        setVehicleTypeIdSelect({
          value: state.passed_element.vehicle_type_id,
          label: state.passed_element.vehicle_type,
        });
      }
      */
      //setDistrictIdSelect(res.data.detail.res_data.district_list);

      //localStorage.setItem('_aI', res.data.detail.res_data.aI);
      //localStorage.setItem('userRoleId', userRoleId);
      //localStorage.setItem('username', res.data.detail.res_data.user_info.username);

      //navigate("/dashboard");
    }
    /*
        if (res.data.status === "SUCCESS" ) {
          this.setState ({
          tokenJwt: res.data.res_data.token,
          isLoaded: true,
          openOTP: true,
          })
            // this.props.history.push({pathname: client.taskViewPath, state:{pageList: this.state.page, orgName: this.state.orgName} });
        }
        else {
        }
      */
  } catch (error) {
    console.log("FULL ERROR : ", error);
    if (error.code === "ERR_NETWORK") {
      console.log(
        "Not able to reach target server please try after sometime"
      );
    } else {
      console.log("ERROR : ", error.response.data.detail);
      if (error.response.data.detail.status === "FAILED") {
        console.log(
          "Request failed due to ",
          error.response.data.detail.data
        );
        //setShowMsg(error.response.data.detail.res_data);
        //setShowMsgTitle('LOGIN FAILED!');
        //setShow(true);
      }
    }
  }
  return [retState, vehicleTypeArr];
};
export  const getProductApiCall = async () => {
  console.log("getTradeType method called ...");
  try {
    var aIVal = localStorage.getItem("_aI");
    var headers = {
      ...client.headers,
      Authorization: ` ${aIVal}`
    }
    var res = await axios.get(
      client.domain + "/ims/getProductsTTSell",
      { headers },
      { withCredentials: false }
    );

    console.log("Response from server getProducts : ", res.data.detail.data);
    if (res.data.detail.status === "SUCCESS") {
      console.log("Response message from server getProducts : ", res.data.detail.data);

      var newSellProdArr = [];
      res.data.detail.data.map((prodItem) => {
        var newSellProd = {
          "value": prodItem.id,
          "label": prodItem.name,
          "product_id": prodItem.id,
          "product_name": prodItem.name,
          "stock_id": 1,
          "uom": prodItem.uom_value,
          "unit_cost": prodItem.unit_price,
          "gst": { "cgst": prodItem["cgst"], "sgst": prodItem["sgst"], "igst": prodItem["igst"] }

        }
        newSellProdArr.push(newSellProd);
      })
     // setProductMaster(newSellProdArr);
    }
  } catch (error) {
    console.log("FULL ERROR : ", error);
    if (error.code === "ERR_NETWORK") {
      console.log("Not able to reach target server please try after sometime");
    } else {
      console.log("ERROR : ", error.response.data.detail);
      if (error.response.data.detail.status === "FAILED") {
        console.log("Request failed due to ", error.response.data.detail.data);

      }
    }
  }
};

export const getDriverList = async () => {
  var retState = false;
  var driverArr = [];
  console.log("getDriverList method called ...");
  try {
    var aIVal = localStorage.getItem("_aI");
    var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
    var res = await axios.get(
      client.domain + "/hrms/getDrivers",
      { headers },
      { withCredentials: false }
    );
   
    console.log("Response from server getDriverList : ", res.data.detail);
    if (res.data.detail.status === "SUCCESS") {
      console.log("Response message from server getDriverList : ", res.data.detail.data);
      //console.log("Length of driver array : ", res.data.detail.data.length);
      //optionsDistrict = res.data.detail.res_data.district_list

      if (res.data.detail.data.length > 0) {
        res.data.detail.data.map((driver) => {
          console.log("Driver ind data : ", driver);
          var driverInd = {};
          driverInd.value = driver.id;
          driverInd.label = driver.name;
          driverArr.push(driverInd);
        
        })
        console.log("Driver Arr : ", driverArr);  
        /*
        setOptDriver(driverArr);
        setDriverIdSelect({
          value: res.data.detail.data[0].id,
          label: res.data.detail.data[0].name,
        });
        */
      }
      //setDistrictIdSelect(res.data.detail.res_data.district_list);
      retState = true;
    }
   
  } catch (error) {
    console.log("FULL ERROR : ", error);
    if (error.code === "ERR_NETWORK") {
      console.log(
        "Not able to reach target server please try after sometime"
      );
    } else {
      console.log("ERROR : ", error.response.data.detail);
      if (error.response.data.detail.status === "FAILED") {
        console.log(
          "Request failed due to ",
          error.response.data.detail.res_data
        );
        //setShowMsg(error.response.data.detail.res_data);
        //setShowMsgTitle('LOGIN FAILED!');
        //setShow(true);
      }
    }
  }
  return [retState, driverArr];
};

export function NumToWord(inputNumber) {
  var str = new String(inputNumber)
  var splt = str.split("");
  var rev = splt.reverse();
  var once = ['Zero', ' One', ' Two', ' Three', ' Four', ' Five', ' Six', ' Seven', ' Eight', ' Nine'];
  var twos = [' Ten', ' Eleven', ' Twelve', ' Thirteen', ' Fourteen', ' Fifteen', ' Sixteen', ' Seventeen', ' Eighteen', ' Nineteen'];
  var tens = ['', ' Ten', ' Twenty', ' Thirty', ' Forty', ' Fifty', ' Sixty', ' Seventy', ' Eighty', ' Ninety'];

  var numLength = rev.length;
  var word = new Array();
  var j = 0;

  for (var i = 0; i < numLength; i++) {
      switch (i) {

          case 0:
              if ((rev[i] == 0) || (rev[i + 1] == 1)) {
                  word[j] = '';
              }
              else {
                  word[j] = '' + once[rev[i]];
              }
              word[j] = word[j];
              break;

          case 1:
              aboveTens();
              break;

          case 2:
              if (rev[i] == 0) {
                  word[j] = '';
              }
              else if ((rev[i - 1] == 0) || (rev[i - 2] == 0)) {
                  word[j] = once[rev[i]] + " Hundred and";
              }
              else {
                  word[j] = once[rev[i]] + " Hundred and";
              }
              break;

          case 3:
              if (rev[i] == 0 || rev[i + 1] == 1) {
                  word[j] = '';
              }
              else {
                  word[j] = once[rev[i]];
              }
              if ((rev[i + 1] != 0) || (rev[i] > 0)) {
                  word[j] = word[j] + " Thousand";
              }
              break;


          case 4:
              aboveTens();
              break;

          case 5:
              if ((rev[i] == 0) || (rev[i + 1] == 1)) {
                  word[j] = '';
              }
              else {
                  word[j] = once[rev[i]];
              }
              if (rev[i + 1] !== '0' || rev[i] > '0') {
                  word[j] = word[j] + " Lakh";
              }

              break;

          case 6:
              aboveTens();
              break;

          case 7:
              if ((rev[i] == 0) || (rev[i + 1] == 1)) {
                  word[j] = '';
              }
              else {
                  word[j] = once[rev[i]];
              }
              if (rev[i + 1] !== '0' || rev[i] > '0') {
                  word[j] = word[j] + " Crore";
              }
              break;

          case 8:
              aboveTens();
              break;


          default: break;
      }
      j++;
  }

  function aboveTens() {
      if (rev[i] == 0) { word[j] = ''; }
      else if (rev[i] == 1) { word[j] = twos[rev[i - 1]]; }
      else { word[j] = tens[rev[i]]; }
  }

  word.reverse();
  var finalOutput = '';
  for (i = 0; i < numLength; i++) {
      finalOutput = finalOutput + word[i];
  }
return finalOutput;
}

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export function formatDate(date) {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join("-");
}

export function formatDateIndian(date) {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear()
  ].join("-");
}

export const formatTime = (currTime) => {
  var h = (currTime.getHours() < 10 ? "0" : "") + currTime.getHours();
  var m = (currTime.getMinutes() < 10 ? "0" : "") + currTime.getMinutes();
  var hrMin = h + ":" + m;
  return hrMin;
};


export const searchAllUniqLocations = async() => {
  var retState = false;
  var uniqLocations = [];
  console.log("searchAllUniqLocations called ...");

  try {
    var aIVal = localStorage.getItem("_aI");
    var headers = {...client.headers, Authorization: `Bearer ${aIVal}`, SerialKey: 'HNGD8743JESWE'};
    var res = await axios.get(client.domain + "/search/getUniqueLocations", { headers }, { withCredentials: false });
    console.log("Response from server searchHandleAllUniqLocations : ", res.data.detail);

    if (res.data.detail.status === "SUCCESS") {
      console.log("Response message from server searchHandleAllUniqLocations : ", res.data.detail.res_data.location_list);
      
      retState = true;
      if ( res.data.detail.res_data.location_list.length > 0 ) {
        uniqLocations = res.data.detail.res_data.location_list;
      }
    }
  } catch (error) {
    console.log("FULL ERROR : ", error);
    if (error.code === "ERR_NETWORK") {
      console.log("Not able to reach target server please try after sometime");

    } else {
      console.log("ERROR : ", error.response.data.detail);
      if (error.response.data.detail.status === "FAILED") {
        console.log(
          "Request failed due to ",
          error.response.data.detail.res_data
        );

      }
    }
  }
  return [retState, uniqLocations];
};

export const searchHandleAllUniqLocationsByCust = (customer_id) => {
  console.log("searchHandleAllUniqLocationsByCust called ...");
  var retState = false;
  var uniqLocations = [];

  fetch(`${client.domain}/search/getUniqueLocationsByCust?customer_id=${customer_id}`)
    .then((resp) => resp.json())
    .then((json) => {
      //searchActionFunc(json)
      //setOptionsSearchLoc(json.detail.res_data.location_list);
      //setIsLoading(false);
      retState = true;
      uniqLocations = json.detail.res_data.location_list;
      console.log("SEARCHED VALUE inside searchActionFunc : ", json.detail.res_data.location_list);
    });
  //formValues["customer_name"] = query;
  //setFormValues({ ...formValues, ["adderss1"]: query });
  /*
  if ( addrCopyCheck && !formValues.contact_person) {
    setFormValues({ ...formValues, ["shipContactName"]: query });
  }
  */
  //setFormValues({ ...formValues, ["customer_name"]: query });
  //setLocationSearchText(query);
};

export const getCompanyInfo = async () => {
  var retState = false;
  var companyInfoTmp = {};
  console.log("getCompany api called ..");
  try {
    var aIVal = localStorage.getItem("_aI");
    var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`}
    var res = await axios.get(client.domain + "/core/getCompany", { headers }, { withCredentials: false });
    console.log("Response from server : ", res.data.detail);

    if (res.data.detail.status === "SUCCESS") {
      console.log("Response message from server : ", res.data.detail.data[0]);
      //setCompanyList(res.data.detail.data[0]);
      //setShowMsgTitle("Company Info Added Successfully");
      companyInfoTmp = res.data.detail.data[0];
      //setFormValues(res.data.detail.data[0]);

      /*
      var findDist = distArrRet.find(element => element.value === res.data.detail.data[0].district_id);
      if ( findDist !== undefined ) {
        console.log("++++++++++++++++++------------------- FOUND DISTRICT : ", findDist);
        setDistrictIdSelect({value: res.data.detail.data[0].district_id, label: findDist.label});
      }

      var findState = stateArrRet.find(element => element.value === res.data.detail.data[0].state_id);
      if ( findState !== undefined ) {
        console.log("++++++++++++++++++------------------- FOUND STATE : ", findState);
        setStateIdSelect({value: res.data.detail.data[0].state_id, label: findState.label});
      }
      */
      //setToRender(true);
      //navigate("/dashboard");
      //setDcReportData(res.data.detail.res_data);
      //setToRender(true);
      retState = true;
    }
  } catch (error) {
    console.log("FULL ERROR : ", error);
    if (error.code === "ERR_NETWORK") {
      console.log(
        "Not able to reach target server please try after sometime"
      );
      //setShowMsg("Please try after sometime");
      //setShowMsgTitle("Network Issue!");
      //setShow(true);
    } else {
      console.log("ERROR occured : ", error.response.data.detail);
      if (error.response.data.detail.status === "FAILED") {
        console.log(
          "Request failed due to ",
          error.response.data.detail.res_data
        );
        //setShowMsg("Please try again ...");
        //setShowMsgTitle("Unable to process your request!");
        //setShow(true);
        //navigate("/auth");
      } else {
        console.log("Non status ERROR code ...");
        //setShowMsg(error.response.data.detail);
        //setShowMsgTitle("Unable to process your request at this moment!");
        //setShow(true);
      }
    }
  }
  return [retState, companyInfoTmp];
};

export const getCustomerListDropdown = async () => {
  console.log("getCustomerListDropdown method called ...");
  var retState = false;
  
  var customerArr = [];

  try {
    var aIVal = localStorage.getItem("_aI");
    var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
    var res = await axios.get(client.domain + "/crm/getCustomersCheck", { headers }, { withCredentials: false });
    
    console.log("Response from server getCustomerList : ", res.data.detail.data);

    if (res.data.detail.status === "SUCCESS") {
      console.log("Response message from server getCustomerList : ", res.data.detail.data);
    
      customerArr = res.data.detail.data;
      var custAll = { value: 0, label: 'All Customers'};
      customerArr.splice(0, 0, custAll);
      console.log("Mod Full VALUE : ", customerArr);
      retState = true;
    }
   
  } catch (error) {
    console.log("FULL ERROR : ", error);
    if (error.code === "ERR_NETWORK") {
      console.log(
        "Not able to reach target server please try after sometime"
      );
    } else {
      console.log("ERROR : ", error.response.data.detail);
      if (error.response.data.detail.status === "FAILED") {
        console.log(
          "Request failed due to ",
          error.response.data.detail.data
        );
       
      }
    }
  }
  return [retState, customerArr];
};

function numberToWord(num) {
  var numParts = num.toString().split('.')
  var ones = ["", "One ", "Two ", "Three ", "Four ", "Five ", "Six ", "Seven ", "Eight ", "Nine ", "Ten ", "Eleven ", "Twelve ", "Thirteen ", "Fourteen ", "Fifteen ", "Sixteen ", "Seventeen ", "Eighteen ", "Nineteen "];
  var tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
  if ((numParts[0] = numParts[0].toString()).length > 9) return "Overflow: Maximum 9 digits supported";
  var n = ("000000000" + numParts[0]).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = "";
  str += n[1] != 0 ? (ones[Number(n[1])] || tens[n[1][0]] + " " + ones[n[1][1]]) + "Crore " : "";
  str += n[2] != 0 ? (ones[Number(n[2])] || tens[n[2][0]] + " " + ones[n[2][1]]) + "Lakh " : "";
  str += n[3] != 0 ? (ones[Number(n[3])] || tens[n[3][0]] + " " + ones[n[3][1]]) + "Thousand " : "";
  str += n[4] != 0 ? (ones[Number(n[4])] || tens[n[4][0]] + " " + ones[n[4][1]]) + "Hundred " : "";
  str += n[5] != 0 ? (str != "" ? "and " : "") + (ones[Number(n[5])] || tens[n[5][0]] + " " + ones[n[5][1]]) : "";

  return str;
}

export function convertNumberToWords(num) {
  console.log("PASSED RUPEE IN NUMERIC : ", num);
  var numParts = num.toString().split('.')
  var rupee = numberToWord(numParts[0])
  console.log("Rupee part : ", rupee);

  console.log("PAISA PART : ", numParts[1]);

  if ( numParts[1] !== undefined ) {
    console.log("ENTERED CONVERT TO STRING FOR PAISA PART ...")
    var paise = numberToWord(numParts[1])
    console.log("Paisa part : ", paise);
    return rupee + " rupees and " + paise + " paisa only";
  }
  else {
    console.log("ONLY RUPEE PART AVAILABLE ...");
    return rupee + " rupees only";
  }
}

export const storeMsgsFromServer = (category_type, full_msg_json) => {
  localStorage.setItem(category_type, JSON.stringify(full_msg_json));
}

export const getCustomerMessages = (category_type, msg_code) => {
  var message_det_json = localStorage.getItem(category_type);
  const obj = JSON.parse(message_det_json);
  console.log("FULL JSON ARRAY : ", obj);
  var msg_val_ret = "";
  var findMsgs = obj.find(element => element.msg_code === msg_code);
  if ( findMsgs !== undefined ) {
    console.log("++++++++++++++++++------------------- FOUND COUNTRY : ", findMsgs);
    msg_val_ret = findMsgs.msg_text;
  }
  return msg_val_ret;
}