import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Table,
  Spinner,
  Image
} from "react-bootstrap";
import { withRouter } from "react-router";
//import Sidebar from './Sidebar';
//import Sidebar2 from "./Sidebar2";
//import Footer from "./Footer";
//import Topmenu from "./Topmenu";
//import OrderDetails from "./OrderDetails";
import "../Dashboard.css";

//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";

import { useNavigate } from "react-router-dom";

//import * as Icon from "react-bootstrap-icons";
import axios from "axios";
import { client, OS_INVOICE_FOR_NDAYS } from "../config/Config";
import Select from "react-select";
import spinner_logo from '../assets/images/spinner_logo.png';
import CsvGenerate from "./CsvGenerate";



import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsArrowLeftCircle,
  BsCardText,
  BsFillPrinterFill
} from "react-icons/bs";

import { AiOutlineOrderedList } from "react-icons/ai";

//import MenuOms from "./MenuOms";

import rmcBlueIcon from "../assets/images/ready_mix_blue.png";
import SidebarMain from "./SidebarMain";
import { CSVLink, CSVDownload } from "react-csv";

import {getDistrictList, getStateList, getProductApiCall, getProductBuy, getProductsSell, searchAllUniqLocations, formatDateIndian } from "./utils";

const ListCustomerBills = () => {
  /*
  csvData={customers}
            fileName={"cust-Inv_2024_01_17_11_34_55_88899.csv"}
            wscols={wscols}
  */          
  const initialized = useRef(false);

  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);
  /*
  let billArr = [
    
    {
      
      bill_date: "02-03-2024",
      bill_number: "CB001",
      customer_name: "Ram",
      total_amount: "7896",
     
    },
    {
      
      bill_date: "07-08-2024",
      bill_number: "CB002",
      customer_name: "Ajay",
      total_amount: "6764",
     
    },
    {
      
      bill_date: "05-05-2024",
      bill_number: "CB003",
      customer_name: "Vishal",
      total_amount: "78654",
     
    },
  ];
  */
  
  

  
  const [dcListFull, setDcListFull] = useState([]);
  const [invoiceExcelList, setInvoiceExcelList] = useState([]);
  //const [orderList, setOrderList] = useState(orderListLoad);
  //const [orderListFull, setOrderListFull] = useState([]);
  //const [orderListFull, setOrderListFull] = useState([]);
  const [orderDcFetched, setOrderDcFetched] = useState(false);
  /*
  var total_bill_ref = useRef(0.00);
  var total_amount_ref = useRef(0.00);

  total_bill_ref.current = billList.length;
  total_amount_ref.current = 0.00;
  billList.map((billInd) => {
    total_amount_ref.current = parseFloat(total_amount_ref.current) + parseFloat(billInd.total_amount);
    })
    */
  
  function padTo2Digits(num) {
    //console.log("<<<<<<<<<<<<<< given number >>>>>>>>>>>>>>>> : ", num);
    var modNum = num.toString().padStart(2, "0");
    console.log("Modified  value : ", modNum);
    return modNum;
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
//var lastDay = new Date(date.getFullYear(), date.getMonth() + 1,
  const intialValues = {
    date_from: formatDate(new Date(firstDay)),
 
    //start_date: formatDate(new Date(new Date().setDate(new Date().getDate() - OS_INVOICE_FOR_NDAYS))),
    date_to: formatDate(new Date()),
    gst_number: "",

  }
  /*
  let customerArr = [
  
    { value: 1, label: "All Customers" },
    { value: 2, label: "Deppam Motors" },
    { value: 3, label: "ABC Pumps" },
    { value: 4, label: "ADP Compressors" },
  ];
  */

  

  
  const [toRender, setToRender] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [customerListFull, setCustomerListFull] = useState([]);
  const [customerIdSelect, setCustomerIdSelect] = useState({});
  const [optCustomer, setOptCustomer] = useState([]);
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("Please login again");
  const [showMsgTitle, setShowMsgTitle] = useState( "Invalid authentication Session!" );
  const [formValues, setFormValues] = useState(intialValues);
  
  


 
  const [billList, setBillList] = useState([]);
  const[billSummary, setBillSummary] = useState([])
  const navigate = useNavigate();
  const currSelBusinessRef = useRef(0);
  const currBranchRef = useRef(0);

  const handleAdd = (e) => {
    e.preventDefault();
    console.log("Add or Update Order button clicked ...", e);
    navigate(`/addUpdateCustBillMfgT1`, {state: { passed_element: [], action: "add" }})
  };
  
  const handleView = (e, data) => {
    console.log("Handle action edit Clicked : ", e);
    //console.log("Handle Clicked edit DATA : ", data);
    console.log("Handle action edit Clicked target : ", e.target);
    console.log("Handle action edit Clicked target Current : ", e.currentTarget);
    console.log("Handle action edit Clicked target Current id : ", e.currentTarget.id);
    console.log("Handle action edit Clicked target ID : ", e.target.id);
    //console.log("Handle action edit Clicked target NAME : ", e.target.name);
    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(0, clickedTagId.lastIndexOf("-"));
      console.log("current selected element name : ", nameOfElement);
      console.log("Current SELECTED id : ", curSelId);
      console.log("CUR SELECTED VALUE : ", billList[curSelId]);

      //navigate(`/${client.urlDelimiter}orderDetails`, {state : orderList[curSelId]});
      navigate(`/addUpdateCustBillMfgT1`, {
        state: { passed_element: billList[curSelId], action: "edit" },
      });
    }
  };

  
  const getBillList = async (customer_id_pass) => {
    var retStateLoad = false;
    var billRet = [];
    var summaryRet = [];
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      console.log("Selected Customer :", customerIdSelect);
      var reqParams = {
        bid: currSelBusinessRef.current,
        brid: currBranchRef.current || 0,
        customer_id: customer_id_pass,
        date_from: formValues["date_from"],
        date_to: formValues["date_to"]
      };
      console.log("REQUEST PARAMS: ", reqParams );
      var res = await axios.post(client.domain + "/bms/getCustBillList", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server Invoice : ",res.data.detail.data );
        setBillList(res.data.detail.data.bills);
        setBillSummary(res.data.detail.data.bills_summary[0]);
        //setCustomerListFull(res.data.detail.data);
        //setShowMsgTitle("Customer Info Added Successfully");
        setToRender(true);
        billRet = res.data.detail.data.bills;
        summaryRet = res.data.detail.data.bills_summary;
        retStateLoad = true;
        
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log( "Not able to reach target server please try after sometime");
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log( "Request failed due to ",error.response.data.detail.res_data);
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
    return [retStateLoad, billRet, summaryRet];
  };

  const CallCustomerData = async () => {
    var retStateLoad = false;
    var customerRet = [];

    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}

      var reqParams = {
        bid: currSelBusinessRef.current
      };

      var res = await axios.post(client.domain + "/bms/getCustBillSearchPreLoadData", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.data.customers);
        var customerList = [];

        //customerList.push({label: "All Customers", value: 0});
        res.data.detail.data.customers.map((customer) => {
          customerList.push(customer);
        })
        setOptCustomer(customerList);
        //setCustomerIdSelect(customerList[0]);
        //setCustomerIdSelect(res.data.detail.data.customers[0]);
        /*
          var findDefault = res.data.detail.data.customers.find(element => element.is_default === true);
          if (findDefault !== undefined) {
            setCustomerIdSelect(findDefault);  
          };
          */
        
        setToRender(true);
        customerRet = customerList;
        retStateLoad = true;
        
          
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log( "Not able to reach target server please try after sometime");
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log( "Request failed due to ",error.response.data.detail.res_data);
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
    return [retStateLoad, customerRet];
  };
  

  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect called ##########################");
      //setCustomerIdSelect(customerArr[0]);
      setToRender(true);
      ( async() => {
        var [retStateLoad, customerRet] = await CallCustomerData();
        if (retStateLoad){
          var findDefault = customerRet.find(element => element.is_default === true);
                      if (findDefault !== undefined) {
                        setCustomerIdSelect(findDefault);  
                     
                    }
          console.log(" Customer :", customerRet);
         
          //setCustomerIdSelect(customerRet[0]);

          var [retStateLoad, dcRet, summaryRet] = await getBillList(findDefault.value); 
        }
       
      })();
      initialized.current = true;
      
    }
  }, []);

  const handleBack = async (e) => {
    console.log("handle back called : ", e);
    navigate(-1);
  };
  
  const handleDateSearch = async() => {
    console.log("handleDateSearch called ...");
    await getBillList(customerIdSelect.value);

  };

  const onChangeStartDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);
    setFormValues({ ...formValues, date_from: e.target.value });
  };
 
  const onChangeEndDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);
    setFormValues({ ...formValues, date_to: e.target.value });
  };
  const handleCustomerSelect = (e, data) => {
    console.log("handleCustomerSelect called ...", e);
    setCustomerIdSelect(e);
  };
  const handleBillPrint = async (e) => {
    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printCustomerBills?bid=${currSelBusinessRef.current}&brid=${currBranchRef.current}&customer_id=${customerIdSelect.value}&date_from=${formValues["date_from"]}&date_to=${formValues["date_to"]}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0,0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
    //const { name, value } = e.currentTarget;
    //var currentId = name.substring(name.lastIndexOf("-") + 1);
    //console.log("SELECTED DC ROW ID FOR PRINT : ", currentId);
    //setSelectedDCRowForPrint(currentId);
  };

  const sendDataToParent  = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if ( passedBackValue.hasOwnProperty("business_id") ) {
      currSelBusinessRef.current = passedBackValue["business_id"];
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);
    
    //getBranchList();
  }

 

 
  return (
    <Container>
     <SidebarMain sendBacktoParent={sendDataToParent} />
     { toRender ?  
      <form>
        <Row>
        
        <Col xs={9} md={9} sm={9} lg={9}> 
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem" }}>{menuSettings.permissions.find(element => (element.code === 'listCustomerBills' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'listCustomerBills')).page_title_list : "Customer Bills"}</span>
        </Col>
       
           

                
          <Col
            xs={3}
            md={3}
            sm={3}
            lg={3}
            className="d-flex justify-content-end float-end"
          >  
           <Button id="billPrint" name = "billPrint"  onClick={(e)  => handleBillPrint(e)}
               disabled={menuSettings.permissions.find(element => (element.code === 'listCustomerBills' && element.a_print_list === true && billList.length > 0)) ? false : true}>
                  <BsFillPrinterFill
                  size={18}
                  style={{ color: "white", cursor: "pointer" }}
                  name={`print-bill-icon`}
                  id={`print-bill-icon`}
                 
                  />
                </Button>
                &nbsp;&nbsp;
                {menuSettings.permissions.find(element => (element.code === 'listCustomerBills' && element.a_add === true)) &&
                  <Button
                  variant="warning"
                  id="add-btn-id"
                  name="add-btn-name"
                  className=" float-end"
                  onClick={(e) => handleAdd(e)}
                  >
                  New Customer Bill
                  </Button>
                } 
          </Col>
         
        </Row>
        <hr align="center" />
        <Row>
        <Col
            xs={3}
            md={3}
            sm={3}
            lg={3}>
             
                <Select
                    inline
                    id="customer"
                    name="customer"
                    value={{
                      value: customerIdSelect.value,
                      label: customerIdSelect.label,
                    }}
                    options={optCustomer}
                    defaultValue={{
                      value: customerIdSelect.value,
                      label: customerIdSelect.label,
                    }}
                    onChange={handleCustomerSelect}
                    //isDisabled={isReadOnly}
                  />
                  </Col>

                 
             
            <Col xs={5} md={5} sm={5} lg={5}>
              <Row>
              <Col>
                <Form.Control
                  type="date"
                  name="date_from"
                  placeholder="Start Date"
                  value={formValues.date_from}
                  onChange={onChangeStartDatePicker}
                  //readOnly={isReadOnly}
                  
                />
              
            </Col>
            <Col>
              <Form.Label style={{textAlign: "center", paddingTop: "5px"}}>To</Form.Label>
            </Col>
          
            <Col>
           
                <Form.Control
                  type="date"
                  name="date_to"
                  placeholder="End Date"
                  value={formValues.date_to}
                  onChange={onChangeEndDatePicker}
                  //readOnly={isReadOnly}
                  
                />
                 </Col>
                <Col>
                 <Button id="search-order-id"
                    size={"md"}
                    variant="success"
                    onClick={(e) => handleDateSearch(e)}>
                    Search
                    </Button>
                </Col>
            </Row>
           </Col>          
                  </Row>
                
                  <hr align="center" />
                  {/*
                  <Row>
                       
                       <Col xs={12} md={12} sm={12} lg={12} style={{textAlign: "right"}}> 
                        
                         <Form.Label style={{fontWeight: 'bold',color: "#43C70F"}}>
                         Bills :
                       </Form.Label>
                         <Form.Label style={{fontWeight: 'bold', paddingLeft: "2px",color: "#43C70F"}}>
                           {billSummary.bill_count}
                       </Form.Label>
                       
                     <span style={{ color: '#C0C0C0'}}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
                     
                         <Form.Label style={{fontWeight: 'bold',color: "#43C70F"}}>
                          Amount :
                       </Form.Label>
                         <Form.Label style={{fontWeight: 'bold', paddingLeft: "2px",color: "#43C70F"}}>
                         { billSummary.sub_total_amt > 0.01 ?
                           <>
                         {((new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.sub_total_amt).toFixed(2))))}
                         </>
                          : (0.00).toFixed(2) }
                       </Form.Label>
                       <span style={{ color: '#C0C0C0'}}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
 
                       <Form.Label style={{fontWeight: 'bold',color: "#43C70F"}}>
                       CGST :
                       </Form.Label>
                         <Form.Label style={{fontWeight: 'bold', paddingLeft: "2px",color: "#43C70F"}}>
                           { billSummary.cgst_amt > 0.01 ?
                           <>
                         {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.cgst_amt).toFixed(2)))}
                          </>
                          : (0.00).toFixed(2) }
                       </Form.Label>
                       
                     <span style={{ color: '#C0C0C0'}}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
                     <Form.Label style={{fontWeight: 'bold',color: "#43C70F"}}>
                       SGST :
                       </Form.Label>
                         <Form.Label style={{fontWeight: 'bold', paddingLeft: "2px",color: "#43C70F"}}>
                         { billSummary.sgst_amt > 0.01 ?
                         <>
                         {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.sgst_amt).toFixed(2))) }
                         </>
                         : (0.00).toFixed(2)} 
                       </Form.Label>
                       <span style={{ color: '#C0C0C0'}}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
 
                     <Form.Label style={{fontWeight: 'bold',color: "#43C70F"}}>
                       IGST :
                       </Form.Label>
                         <Form.Label style={{fontWeight: 'bold', paddingLeft: "2px",color: "#43C70F"}}>
                         { billSummary.igst_amt > 0.01 ?
                         <>
                         {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.igst_amt).toFixed(2)))}
                       </>
                         : (0.00).toFixed(2)  }
                       </Form.Label>
 
                       <span style={{ color: '#C0C0C0'}}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
                     <Form.Label style={{fontWeight: 'bold',color: "#43C70F"}}>
                        CESS :
                       </Form.Label>
                       
                         <Form.Label style={{fontWeight: 'bold', paddingLeft: "2px",color: "#43C70F"}}>
                         { billSummary.cess_amt > 0.01 ?
                         <>
                         {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.cess_amt).toFixed(2)))}
                         </>
                         : (0.00).toFixed(2)}
                       </Form.Label>
 
                       <span style={{ color: '#C0C0C0'}}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
                     <Form.Label style={{fontWeight: 'bold',color: "#43C70F"}}>
                       Bill Amount :
                       </Form.Label>
                         <Form.Label style={{fontWeight: 'bold', paddingLeft: "2px",color: "#43C70F"}}>
                         { billSummary.bill_total_amt > 0.01 ?
                         <>
                         {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.bill_total_amt).toFixed(2)))}
                         </>
                         : (0.00).toFixed(2)}
                       </Form.Label>
                       
 
                       
                      
                        </Col>  
                      </Row>
                      */}
        
        <tr className="table-primary">
              <th className="table-row-heading" style={{ textAlign: "left", width: "25%"}} >Total Bills : {billSummary.bill_count}</th>
              <th className="table-row-heading"  style={{ textAlign: "center", width: "0%"}}></th>
              <th className="table-row-heading"  style={{ textAlign: "center", width: "0%" }}></th>
              <th className="table-row-heading"  style={{ textAlign: "right", width: "16%" }}>Total : </th>
              <th className="table-row-heading"  style={{ textAlign: "right", width: "9%" }}>{ billSummary.sub_total_amt > 0.01 ? (new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.sub_total_amt).toFixed(2))): (0.00).toFixed(2) }</th>
              <th className="table-row-heading"  style={{ textAlign: "right", width: "9%" }}>{ billSummary.cgst_amt > 0.01 ? (new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.cgst_amt).toFixed(2))): (0.00).toFixed(2) }</th>
              <th className="table-row-heading"  style={{ textAlign: "right", width: "9%" }}>{ billSummary.sgst_amt > 0.01 ? (new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.sgst_amt).toFixed(2))): (0.00).toFixed(2) }</th>
              <th className="table-row-heading"  style={{ textAlign: "right", width: "9%" }}>{ billSummary.igst_amt > 0.01 ? (new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.igst_amt).toFixed(2))): (0.00).toFixed(2) }</th>
              <th className="table-row-heading"  style={{ textAlign: "right", width: "9%" }}>{ billSummary.cess_amt > 0.01 ? (new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.cess_amt).toFixed(2))): (0.00).toFixed(2) }</th>
              <th className="table-row-heading"  style={{ textAlign: "right", width: "9%" }}>{ billSummary.bill_total_amt > 0.01 ? (new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billSummary.bill_total_amt).toFixed(2))): (0.00).toFixed(2) }</th>
              <th className="table-row-heading" style={{ textAlign: "right", width: "5%" }}></th>
            </tr>
           
         { toRender &&
        <Table striped bordered hover size="sm">
          <thead>
            <tr className="table-primary">
              <th className="table-row-heading" key={"order-list-heading-key"}style={{ textAlign: "center", width: "5%"}}>S.No</th>
              <th className="table-row-heading"  style={{ textAlign: "center", width: "10%"}}>Bill Date</th>
              <th className="table-row-heading"  style={{ textAlign: "center", width: "10%" }}>Bill No</th>
              <th className="table-row-heading"  style={{ textAlign: "center", width: "16%" }}>Customer</th>
              <th className="table-row-heading"  style={{ textAlign: "center", width: "9%" }}>Sub Total</th>
              <th className="table-row-heading"  style={{ textAlign: "center", width: "9%" }}>CGST</th>
              <th className="table-row-heading"  style={{ textAlign: "center", width: "9%" }}>SGST</th>
              <th className="table-row-heading"  style={{ textAlign: "center", width: "9%" }}>IGST</th>
              <th className="table-row-heading"  style={{ textAlign: "center", width: "9%" }}>CESS</th>
              <th className="table-row-heading"  style={{ textAlign: "center", width: "9%" }}>Total Bill Amount</th>
              <th className="table-row-heading" style={{ textAlign: "center", width: "5%" }}>Details</th>
            </tr>
          </thead>

          <tbody>
            {billList.map((billInd, idx) => (
              <tr key={`bill-table-row-key-${idx}`} style={{width: "5%"}}>
                <td style={{ textAlign: "right" }}
                  key={`bill-table-row-key-sno-${idx}`}
                  id={`bill-table-row-id-sno-${idx}`}
                  name={`bill-table-row-name-sno-${idx}`}
                >
                  {idx + 1}
                </td>
                
                
                <td style={{ textAlign: "left" ,width: "10%" }}
                  key={`bill-table-row-key-date-${idx}`}
                  id={`bill-table-row-id-date-${idx}`}
                  name={`bill-table-row-name-date-${idx}`}
                >
                  {billInd.bill_date}
                  {/*{formatDateIndian(new Date(billInd.bill_date))}*/}
                </td>

                <td style={{ textAlign: "right",width: "10%" }}
                  key={`bill-table-row-key-billno-${idx}`}
                  id={`bill-table-row-id-billno-${idx}`}
                  name={`bill-table-row-name-billno-${idx}`}
                >
                  {billInd.bill_no}
                </td>
                
                <td
                style={{width:"16%"}}
                  key={`bill-table-row-key-customer-${idx}`}
                  id={`bill-table-row-id-customer-${idx}`}
                  name={`bill-table-row-name-customer-${idx}`}
                >
                  {billInd.customer_name}
                </td>
                <td
                  style={{ padding: "5px", textAlign: "right",width: "9%" }}
                  key={`bill-table-row-key-total_amount-${idx}`}
                  id={`bill-table-row-id-total_amount-${idx}`}
                  name={`bill-table-row-name-total_amount-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billInd.sub_total_amt).toFixed(2)))}
                </td>
                
                <td
                  style={{ padding: "5px", textAlign: "right",width: "9%" }}
                  key={`bill-table-row-key-total_amount-${idx}`}
                  id={`bill-table-row-id-total_amount-${idx}`}
                  name={`bill-table-row-name-total_amount-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billInd.cgst_amt).toFixed(2)))}
                </td>
                
                <td
                  style={{ padding: "5px", textAlign: "right",width: "9%" }}
                  key={`bill-table-row-key-total_amount-${idx}`}
                  id={`bill-table-row-id-total_amount-${idx}`}
                  name={`bill-table-row-name-total_amount-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billInd.sgst_amt).toFixed(2)))}
                </td>
                
                <td
                  style={{ padding: "5px", textAlign: "right",width: "9%" }}
                  key={`bill-table-row-key-total_amount-${idx}`}
                  id={`bill-table-row-id-total_amount-${idx}`}
                  name={`bill-table-row-name-total_amount-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billInd.igst_amt).toFixed(2)))}
                </td>
                
                <td
                  style={{ padding: "5px", textAlign: "right",width: "9%" }}
                  key={`bill-table-row-key-total_amount-${idx}`}
                  id={`bill-table-row-id-total_amount-${idx}`}
                  name={`bill-table-row-name-total_amount-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billInd.cess_amt).toFixed(2)))}
                </td>
                
             
               
                <td
                  style={{ padding: "5px", textAlign: "right",width: "9%" }}
                  key={`bill-table-row-key-total_amount-${idx}`}
                  id={`bill-table-row-id-total_amount-${idx}`}
                  name={`bill-table-row-name-total_amount-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(billInd.bill_total_amt).toFixed(2)))}
                </td>
                
                <td style={{ textAlign: "center",width: "5%" }}
                  key={`bill-table-row-key-actions-${idx}`}
                  id={`bill-table-row-id-actions-${idx}`}
                  name={`bill-table-row-name-actions-${idx}`}
                >
                  <BsCardText
                    size={28}
                    style={{ color: "#0d6efd", cursor: "pointer" }}
                    onClick={(e) => handleView(e)}
                    name={`bill-view-icon-${idx}`}
                    id={`bill-view-icon-${idx}`}
                    disabled={menuSettings.permissions.find(element => (element.code === 'listCustomerBills' && element.a_detailview === true)) ? false : true}
                  />
                  &nbsp;&nbsp; 
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        }  
        </form>
       : 
       <div className="container-spinner">
      <div className="vertical-center-spinner">
       {/*<Button variant="primary" disabled>*/}
       <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
 
       {/*</Button>*/}
       </div>
       </div>
      }      
       
      
    </Container>
  );
};
//const Dashboard = withRouter(Dash);
export default ListCustomerBills;
