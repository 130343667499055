import { React, Fragment, useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { CSVLink, CSVDownload } from "react-csv";
import { TbReportAnalytics } from "react-icons/tb";

const CsvGenerate = (props) => {

  const [invList, setInvList] = useState([]);

  console.log("CsvGenerate Loaded ...", props.dataPass);
  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("_");


  }

  const formateTime = (currTime) => {
    var h = (currTime.getHours() < 10 ? "0" : "") + currTime.getHours();
    var m = (currTime.getMinutes() < 10 ? "0" : "") + currTime.getMinutes();
    var s = (currTime.getSeconds() < 10 ? "0" : "") + currTime.getSeconds();
    var ms =  currTime.getMilliseconds();
    var hrMin = h + "_" + m + "_" + s + "_" + ms;
    return hrMin;
  };

  /*
  var endTimeTmp = new Date();
  const dateCopy = new Date(endTimeTmp.getTime());
  var minForFiiling = 10;
  var endTimeMod = dateCopy.setMinutes(dateCopy.getMinutes() + minForFiiling);
  console.log("endTimeMod: ", endTimeMod);
  var endTimeMod2 = new Date(endTimeMod);
  console.log("endTimeMod2: ", endTimeMod2);
  var custInvoice = new Date(endTimeMod) ;
  */
  var currTimeTmp = new Date();
  var date_str = formatDate(currTimeTmp);
  console.log("***************DATE:", date_str);
  var time_str = formateTime(currTimeTmp);
  console.log("***************TIME:", time_str);

  var filename_gen = date_str + "_" + time_str;

  /*
  sheet.getRow(1).fill = {
    type: "pattern",
    pattern: "darkVertical",
    fgColor: { argb: "FFFF00" },
  };


  sheet.getRow(1).border = {
    top: { style: "thick", color: { argb: "FFFF0000" } },
    left: { style: "thick", color: { argb: "000000FF" } },
    bottom: { style: "thick", color: { argb: "F08080" } },
    right: { style: "thick", color: { argb: "FF00FF00" } },
  };

  sheet.getRow(1).fill = {
    type: "pattern",
    pattern: "darkVertical",
    fgColor: { argb: "FFFF00" },
  };

  sheet.getRow(1).font = {
    name: "Comic Sans MS",
    family: 4,
    size: 16,
    bold: true,
  };
  */
 
  return(
  <CSVLink 
  data={props.dataPass}
  filename={filename_gen}
  //style={{color: "green"}}
  //className="btn btn-primary"
  >
    <Button
      variant="danger"
      disabled={(menuSettings.permissions.find(element => (element.code === 'listInvoices')).a_export_list === true && invList.length > 0) ? false : true}
      //disabled={menuSettings.permissions.find(element => (element.code === 'listInvoices' && element.a_export_list === true && invList.length > 0)) ? false : true}
      >
        <TbReportAnalytics style={{width: '21px', height: '21px', paddingTop: '-8px'}}/>
      Export Excel
    </Button>
    </CSVLink>  
  );
  }

  export default CsvGenerate;
