import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';

import { client } from '../config/Config';

//import StockDocument from "./StockDocument";
import {formatDateIndian, convertNumberToWords } from "./utils";
import _ from "lodash";

//import avinaoffice_logo from '../avinaoffice_logo.png';
import business_logo from '../assets/images/business_logo.png';


const borderColor = '';
//const rowItemHeight = 18;
const MAX_CHAR_PER_ROW = 40;
const MAX_CHAR_PER_ROW_DATA = 15;
const NTIMES_SIZE = 0.65;
const itemRowHeight = 18;
const MULTIPLY_PERC = 0.75;
const ADDR1_ITEM_ROW_HEIGHT = 50;
const ADDR_ITEM_ROW_HEIGHT = 46;
const ADDR3_ITEM_ROW_HEIGHT = 80;
const BORDER_COLOR = '#a9a9a9';
const BORDER_WIDTH = 0.5;
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 9,
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    //width: '100%',
    //height: '33%',
    width: '100%',
    height: '100%',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logo_image2: {
    width: '15%',
    height: '98px',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  business_content: {
    width: '60%',
    height: '98px',
    //paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container2: {
    //paddingBottom:0,
    flexDirection: 'row',
    //borderBottomColor: '#bff0fd',
    //backgroundColor: '#bff0fd',
    //borderBottomWidth: 1,
    //alignItems: 'center',
    //height: 12,
    //textAlign: 'center',
    //fontStyle: 'bold',
    //flexGrow: 1,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    //marginTop: 0.5,
    //marginBottom: 0.5,
    borderWidth: 0.5,
    borderColor: BORDER_COLOR
   // borderColor: '#a8a8a8',

  },
  container: {
    flexDirection: 'row',
    //borderBottomColor: '#a8a8a8',
    //backgroundColor: '#dcdcdc',
    borderBottomWidth: 0.5,
    alignItems: 'center',
    height: 48,
    textAlign: 'center',
    fontStyle: 'bold',
    //flexGrow: 1,
    //flexWrap: 'wrap',
    //marginTop: 1,
    //marginBottom: 10,
    //borderWidth: 1,
    //borderWidth: 1,
    //marginBottom: 5,
  },

  container1: {
    flexDirection: 'row',
    //borderBottomColor: '#a8a8a8',
    backgroundColor: '#dcdcdc',
    //borderBottomWidth: 1,
    //borderTopWidth: 1,
    alignItems: 'center',
    height: 20,
    textAlign: 'center',
    //fontStyle: 'bold',
    //flexGrow: 1,
    //flexWrap: 'wrap',
    //marginTop: 1,
    //marginBottom: 10,
    //borderWidth: 1,
    //borderWidth: 1,
    //marginBottom: 5,
  },
  row: {
    flexDirection: 'row',
    //borderBottomColor: '#a8a8a8',
    //borderBottmWidth: 1,
    alignItems: 'center',
    //height: 24,
    fontStyle: 'bold',
  },
  

  
  address1: {
    width: '100%',
    textAlign: 'left',
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    borderRightColor: BORDER_COLOR,
    //paddingLeft: 8,
    height: ADDR1_ITEM_ROW_HEIGHT,
},
inv_no: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: "2px",
    //height: itemRowHeight,
    //borderRightColor: borderColor,
    //borderLeftWidth: 1,
    //borderRightWidth: 1,
    //borderBottomWidth: BORDER_WIDTH,
    
},
date: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: "2px",
    //height:itemRowHeight,
    //borderRightColor: borderColor,
    borderLeftWidth: BORDER_WIDTH,
    borderLeftColor: BORDER_COLOR,
    //borderRightWidth: 1,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR
    //paddingLeft: 8,
},

address2: {
    width: '100%',
    textAlign: 'left',
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    borderRightColor: BORDER_COLOR,
    height: ADDR_ITEM_ROW_HEIGHT,
    //paddingLeft: 8,
},
ref_no: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: "2px",
    //borderRightColor: borderColor,
    //borderLeftWidth: 1,
    //borderRightWidth: 1,
    borderBottomWidth: 0.4,
    borderBottomColor: BORDER_COLOR
    //paddingLeft: 8,
},
ref: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: "2px",
    //borderRightColor: borderColor,
    borderLeftWidth: BORDER_WIDTH,
    borderLeftColor: BORDER_COLOR,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR
    //borderLeftColor: BORDER_COLOR
    //borderRightWidth: 1,
    //borderBottomWidth: BORDER_WIDTH
    //paddingLeft: 8,
},

address3: {
    width: '100%',
    textAlign: 'left',
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    borderRightColor: BORDER_COLOR,
    height: ADDR3_ITEM_ROW_HEIGHT,
    //paddingLeft: 8,
},
dispatch_no: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: "2px",
    //borderRightColor: borderColor,
    //borderLeftWidth: 1,
    //borderRightWidth: 1,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR
    //paddingLeft: 8,
},
dispatch: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: "2px",
    //borderRightColor: borderColor,
    borderLeftWidth: BORDER_WIDTH,
    borderLeftColor: BORDER_COLOR,
    borderBottomColor: BORDER_COLOR,
    //borderRightWidth: 1,
    borderBottomWidth: BORDER_WIDTH
    //paddingLeft: 8,
},

tableContainer1: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    

  },

  sno: {
    width: '4%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
    //paddingTop:"5px"
   // paddingLeft:10
   
  },
  description: {
    width: '24%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
    //paddingTop:"5px"
    //paddingRight:90
    //paddingLeft:10
  },
  
  hsn: {
    width: '13%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
   // paddingTop:"5px"
   // paddingLeft:10
   
  },
  quantity: {
    width: '14%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
    //paddingTop:"5px"
    //paddingRight:90
    //paddingLeft:10
  },
  rate: {
    width: '12%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
    //paddingTop:"2px"
   // paddingLeft:10
   
  },
  per: {
    width: '18%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
    //paddingTop:"2px"
    //paddingRight:90
    //paddingLeft:10
  },
  amount: {
    width: '15%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'center',
    height: itemRowHeight,
    //paddingTop:"1px"
    //paddingRight:90
    //paddingLeft:10
  },
  snoData: {
    width: '4%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'right',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingRight:"2px"
    //paddingTop:"5px"
   // paddingLeft:10
   
  },
  descriptionData: {
    width: '24%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'left',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingLeft:"2px"
    //paddingTop:"5px"
    //paddingRight:90
    //paddingLeft:10
  },
  
  hsnData: {
    width: '13%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'left',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingLeft:"2px"
    //paddingTop:"5px"
   // paddingLeft:10
   
  },
  quantityData: {
    width: '14%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'right',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingRight:"2px"
    //paddingTop:"5px"
    //paddingRight:90
    //paddingLeft:10
  },
  rateData: {
    width: '12%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'right',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingRight:"2px"
    //paddingTop:"5px"
   // paddingLeft:10
   
  },
  perData: {
    width: '18%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'left',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingLeft:"1px"
    //paddingTop:"5px"
    //paddingRight:90
    //paddingLeft:10
  },
  amountData: {
    width: '15%',
    fontSize: "10px",
    //borderRightWidth: 1,
    textAlign: 'right',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingRight:"2px"
  },

});

const PrintDNMfgT1 = (props) => {
    /*
    let invArr = [
        {
       description: "M5",
       hsn:"566125",
       quantity:"5",
       rate:"4500",
       per: "2",
       amount:"9000"
        },
        {
            description: "M8",
            hsn:"1234456",
            quantity:"2",
            rate:"6700",
            per: "6",
            amount:"4563"
             },
             {
                description: "lsgvncxmk nxcjfsdkj tyure yueie",
                hsn:"6775443",
                quantity:"8",
                rate:"7865",
                per: "3",
                amount:"7865"
                 },
                 {
                  description: "M5",
                  hsn:"57546",
                  quantity:"9",
                  rate:"3452",
                  per: "1",
                  amount:"3000"
                   },
                   {
                    description: "M7",
                    hsn:"89765",
                    quantity:"5",
                    rate:"2007",
                    per: "8",
                    amount:"5674"
                     }
       
      ];
      */
      
 
  const [reportData, setReportData] = useState({});
  const [toRender, setToRender] = useState(false);
  //const [invoiceList, setInvoiceList] = useState(invArr);
  const [companyInfo, setCompanyInfo] = useState({});
  const [invList, setInvList] = useState([]);
  const [reportHeader, setReportHeader] = useState({});
  const [reportTitle, setReportTitle] = useState({});
  const [billTo, setBillTo] = useState({});
  const [shipTo, setshipTo] = useState({});
  const [branchAddress, setBranchAddress] = useState({}); 
  const [businessAddress, setBusinessAddress] = useState({});
  const [dc, setDc] = useState({});
  const [addlDetails, setAddlDetails] = useState({});
  const [dcItems, setDcItems] = useState([]);
  const [printParams, setPrintParams] = useState([]);
  const [setting, setSetting] = useState({});
  //const [bankDetails, setBankDetails] = useState({});
  const initialized = useRef(false);

 
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  const formateTime = (currTime) => {
    var h = (currTime.getHours() < 10 ? "0" : "") + currTime.getHours();
    var m = (currTime.getMinutes() < 10 ? "0" : "") + currTime.getMinutes();
    var hrMin = h + ":" + m;
    return hrMin;
  };

  const formatPrintDate = (currTime) => {
    var dateComponent = [
      padTo2Digits(currTime.getDate()),
      padTo2Digits(currTime.getMonth() + 1),
      currTime.getFullYear(),
    ].join("-");

    var timeComponent = [
      padTo2Digits(currTime.getHours() % 12 || 12 ),
      padTo2Digits(currTime.getMinutes())
    ].join(":");

    const ampm = currTime.getHours() >= 12 ? 'PM' : 'AM';

    return dateComponent + " " + timeComponent + " " + ampm;
  }
 /*
  const getTaskList = async() => {
    //console.log("getPaymentList method called ...");
    //console.log("start date   : ", start_date);
   // console.log("end date     : ", end_date);
    //console.log("paid to  : ", paid_to);
    //console.log("expense category : ", expense_category_id);
    var retState = false;
    var retTaskList = [];
    try {
      var aIVal = localStorage.getItem("_aI");
      console.log("Authorization token : ", aIVal);

      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}

      console.log("Headers : ", headers);

      
      var res = await axios.get(client.domain + `/pmo/getAllTasks?`, {headers}, {
        withCredentials: false,
      });
     
      console.log("Response from server getTaskList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getTaskList : ", res.data.detail.data);
       if (res.data.detail.data.length > 0) {
          setTaskList(res.data.detail.data);
          //setReceiptListFull(res.data.detail.data);
          retTaskList = res.data.detail.data;
        }
        retState = true;
        setToRender(true);
      }
      
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
    setToRender(true);
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ",error.response.data.detail.res_data);
        }
      }
    }
    setToRender(true);

    return [retState, retTaskList];
  };
  */

  /*
  const getDcPrint = async (id_pass) => {
    var retStateLoad = false;
    var paramsRet = [];
    var titleRet = [];
    var dcRet = [];
    var billToRet = [];
    var shipToRet = [];
    var branchAddressRet = [];
    var businessAddressRet = [];
    var addlDetailsRet =  [];
    var dcItemsRet = [];
    var settingRet = [];
    //var bankRet = [];

    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      //console.log("Selected Customer :", customerIdSelect);
      var reqParams = {
        id: id_pass,

      };
      console.log("REQUEST PARAMS: ", reqParams );
      var res = await axios.post(client.domain + "/bms/getCustDCPrint", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server DC : ", res.data.detail.data );
        setPrintParams(res.data.detail.data.report_print_params);
        setBillTo(res.data.detail.data.billTo);
        setReportTitle(res.data.detail.data.report_title);
        setBranchAddress(res.data.detail.data.branch_address);
        setBusinessAddress(res.data.detail.data.business_address);
        setDc(res.data.detail.data.customer_dc);
        setAddlDetails(res.data.detail.data.customer_dc_additional_details);
        setDcItems(res.data.detail.data.customer_dc_items);
        setshipTo(res.data.detail.data.shipTo);
        setSetting(res.data.detail.data.report_print_setting);
        //setBankDetails(res.data.detail.data.invoice.bank_acc_details);
        //setCustomerListFull(res.data.detail.data);
        //setShowMsgTitle("Customer Info Added Successfully");
        //setToRender(true);
        paramsRet = res.data.detail.data.report_print_params;
        billToRet = res.data.detail.data.billTo;
        titleRet = res.data.detail.data.report_title;
        branchAddressRet = res.data.detail.data.branch_address;
        businessAddressRet = res.data.detail.data.business_address;
        dcRet = res.data.detail.data.customer_dc;
        addlDetailsRet = res.data.detail.data.customer_dc_additional_details;
        dcItemsRet = res.data.detail.data.customer_dc_items;
        shipToRet = res.data.detail.data.shipTo;
        settingRet = res.data.detail.data.report_print_setting;
        //bankRet = res.data.detail.data.invoice.bank_acc_details;
        retStateLoad = true;
        //navigate("/dashboard");
        //setDcReportData(res.data.detail.res_data);
        //setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log( "Not able to reach target server please try after sometime");
        //setShowMsg("Please try after sometime");
        //setShowMsgTitle("Network Issue!");
        //setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log( "Request failed due to ",error.response.data.detail.res_data);
          //setShowMsg("Please try again ...");
          //setShowMsgTitle("Unable to process your request!");
          //setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          //setShowMsg(error.response.data.detail);
          //setShowMsgTitle("Unable to process your request at this moment!");
          //setShow(true);
        }
      }
    }
    
    return [retStateLoad, dcRet, paramsRet,titleRet, billToRet, shipToRet, branchAddressRet, businessAddressRet, addlDetailsRet, dcItemsRet, settingRet];
  };
  */
  
  
  
  useEffect(() => {
    console.log("useEffect method called ...");
    if (!initialized.current) {
    console.log("CALLED CURRENT PAGE : ", window.location.href);

    console.log("CALLED window locaion : ", window.location);
    let params = (new URL(window.location)).searchParams;
    console.log("COMPLETE PARAMS : ", params);
    
    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.indexOf('?') + 1);
    var urlTmp = urlStr.substring(0, urlStr.indexOf("?"));

    console.log("URL : ", urlTmp);
    console.log("URL : ", paramsTmp);

    let searchParams = new URLSearchParams(paramsTmp);
    console.log("SEARCH PARAMS :", searchParams );
    var id = searchParams.get("id");
    console.log("^^^^^^^^^^^^^^^^^^^  ID : ", id);

    
    (async () => {
      initialized.current = true;
      //var [retStateLoad, dcRet, paramsRet,titleRet, billToRet, shipToRet, branchAddressRet, businessAddressRet, addlDetailsRet, dcItemsRet, settingRet] = await getDcPrint(id);
      //console.log("<<<<<<<<<<<<<<<<<<< ADDITIONAL INFO >>>>>>>>>>>>>> : ", addlDetailsRet);
      //setAddlDetails(addlDetailsRet);
      //console.log("VEHICLE NUMBER ^^^^^^^^^^^^^^^^^ : ", addlDetailsRet.vehicle_no);
      setToRender(true);
    })();
    }
    
  }, [])

  return (
    <PDFViewer style={styles.viewer}>
      {toRender === true &&
        <Document>
          <Page size="A4" orientation="portrait" style={styles.page}>
              
               
             
            <View style={{ width: "100%", alignItems: "center", textAlign: 'center', borderBottomWidth: 0.5, borderBottomColor: '#a7a5a5', paddingTop: "5px" }}>
              <Text style={{ fontSize: "12px", textAlign: 'center', alignContent: 'center', fontFamily: 'Helvetica'}}>Avinasoft Technologies</Text>
            </View>

            <View style={{ width: "100%", alignItems: "center", textAlign: 'center', borderBottomWidth: 0.5, borderBottomColor: '#a7a5a5', paddingTop: "5px" }}>
              <Text style={{ fontSize: "12px", textAlign: 'center', alignContent: 'center', fontFamily: 'Helvetica-Bold'}}>Rockbrezee Technologies</Text>
            </View>


            <hr style={{paddingBottom: "10px"}}/>
            </Page>
          
        </Document>
      }
    </PDFViewer>
  );
}

export default PrintDNMfgT1;