
import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';

import { client } from '../config/Config';

//import StockDocument from "./StockDocument";
import {getCompanyInfo, formatDateIndian } from "./utils";

//import avinaoffice_logo from '../avinaoffice_logo.png';
import business_logo from '../assets/images/business_logo.png';


const borderColor = '#000000';
//const rowHeight = 26;
const tblHeadHeight = 35;
const itemRowHeight = 44;
const paddingHeight = 2;
const itemExtraHeight = 0;
const amountInWordsHeight = 22;
const styles = StyleSheet.create({
  
  page: {
    fontFamily: 'Helvetica',
    fontSize: 9,
    paddingTop: 30,
    paddingLeft: 20,
    paddingRight: 20,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    //width: '100%',
    //height: '33%',
    width: '100%',
    height: '100%',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logo_image2: {
    width: '15%',
    height: '98px',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  business_content: {
    width: '60%',
    height: '98px',
    //paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container2: {
    //paddingBottom:0,
    flexDirection: 'row',
    //borderBottomColor: '#bff0fd',
    //backgroundColor: '#bff0fd',
    //borderBottomWidth: 1,
    //alignItems: 'center',
    //height: 12,
    //textAlign: 'center',
    //fontStyle: 'bold',
    //flexGrow: 1,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
    tableContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      //marginTop: 14,
      borderWidth: 1,
      //borderLeft:2,
      borderColor: '#000000', 
      borderStyle:  'solid',
      borderRadius:5
  },
  tableContainer1: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    //paddingLeft: "1px",
    //paddingRight: "1px",
    //borderLeftWidth: "1px",
    //borderRightWidth: "1px",
    //borderTopWidth: "1px",
    //borderBottomWidth: "1px",
    //marginTop: 1,
    //marginBottom: 11,
    //borderWidth: 1,
    //borderColor: '#a8a8a8',
    borderRadius:5

  },
  
 

   
  /*
  container: {
    flexDirection: 'row',
    borderBottomColor: '#a8a8a8',
    backgroundColor: '#a8a8a8',
    //borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
  },
  */
  
  row: {
    flexDirection: 'row',
    //borderBottomColor: '#a8a8a8',
    //borderBottmWidth: 1,
    alignItems: 'center',
    height: 10,
    fontStyle: 'bold',
    borderRadius: 4
  },
   


    container: {
      flexDirection: 'row',
      //borderBottomColor: '#a8a8a8',
      backgroundColor: '#a8a8a8',
      borderBottomWidth: 1,
      alignItems: 'center',
      //height: '36px',
      textAlign: 'center',
      fontStyle: 'bold',
      borderRadius:4
      //borderStyle: 'solid'
      //borderWidth: 1
      //flexGrow: 1
  },
  sno: {
      width: '10%',
      //height: itemRowHeight,
      height: tblHeadHeight,
      //paddingLeft:"5px",
      borderRightColor: borderColor,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      paddingTop: '6px',
      borderRightColor: borderColor,
  },
  description: {
      width: '50%',
      height: tblHeadHeight,
      borderRightColor: borderColor,
      borderRightWidth: 1,
      paddingTop: '6px',
      borderRightColor: borderColor,
  },
 
  hsncode: {
      width: '0%',
      height: tblHeadHeight,
      //borderRightColor: borderColor,
      //borderRightWidth: 1,
      paddingTop: '6px',
      borderRightColor: borderColor,
  },

  qty: {
      width: '10%',
      height: tblHeadHeight,
      borderRightColor: borderColor,
      borderRightWidth: 1,
      paddingTop: '6px',
      borderRightColor: borderColor,
  },
 
  
  
  weight: {
    width: '30%',
    height: tblHeadHeight,
   borderRightColor: borderColor,
    borderRightWidth: 1,
    
    paddingTop: '6px',
    borderRightColor: borderColor,
},


sno_with_space: {
  width: '10%',
  textAlign: 'left',
  //paddingLeft:"5px",
  borderRightColor: borderColor,
  borderRightWidth: 1,
  height: itemRowHeight,
  borderRightWidth: 1,
  borderLeftWidth: 1,
  paddingLeft: "2px"
  //paddingRight: 18,
},
description_with_space: {
  width: '50%',
  textAlign: 'left',
  borderRightColor: borderColor,
  borderRightWidth: 1,
  height: itemRowHeight,
  //paddingLeft: 5,
  //height: 48,
  paddingLeft: "2px"
},

qty_with_space: {
  width: '10%',
  borderRightColor: borderColor,
  borderRightWidth: 1,
  textAlign: 'right',
  height: itemRowHeight,
  paddingRight: 5,
},



weight_with_space: {
  width: '30%',
  textAlign: 'right',
  borderRightColor: borderColor,
  height: itemRowHeight,
  paddingRight: 5,
  borderRightWidth: 1,
},
row_empty: {
  flexDirection: 'row',
  borderBottomColor: '#a8a8a8',
  //borderBottomWidth: 1,
  alignItems: 'center',
  height: 20,
  fontStyle: 'bold',
},


left: {
    // width: '33%',//<- working alternative
    padding: 5,
    flexGrow: 0,
    //flexShrink: 1,
    //flexBasis: 230,
    margin: 0.5, 
    borderTop: 1, 
    borderBottom: 1, 
    borderLeft: 1, 
    borderRight: 1, 
    //borderColor: '#a8a8a8', 
    BorderStyle: 'dashed',
    height: '70px',
    //border: 'solid #e7e5e5 3px',
    //padding: '1em',
    //margin: '1em',
    borderRadius: 5
  },


left3: {
    // width: '33%',//<- working alternative
    padding: 5,
    flexGrow: 1,
    //flexShrink: 1,
    //flexBasis: 230,
    margin: 0.5, 
    borderTop: 1, 
    borderBottom: 1, 
    borderLeft: 1, 
    borderRight: 1, 
    //borderColor: '#a8a8a8', 
    BorderStyle: 'dashed',
    height: '120px',
    //border: 'solid #e7e5e5 3px',
    //padding: '1em',
    //margin: '1em',
    borderRadius: 5
  },

  headerContainer: {
    margin: 0.5,
    borderTop: 1, 
    borderBottom: 1, 
    borderLeft: 1, 
    borderRight: 1,
    //borderColor: '#a8a8a8', 
    BorderStyle: 'dashed',
    height: '120px',
    borderRadius: 5
    //paddingLeft: "150px"
    //borderBottomColor: '#bff0fd',
},
right: {
    margin: 0.5,
    borderTop: 1, 
    borderBottom: 1, 
    borderLeft: 1, 
    borderRight: 1,
    //borderColor: '#a8a8a8', 
    BorderStyle: 'dashed',
    height: '70px',
    borderRadius: 5
    //paddingLeft: "150px"
    //borderBottomColor: '#bff0fd',
},
to: {
    marginTop: 5,
    //paddingBottom: 3,
    //fontFamily: 'Helvetica-Oblique'
    fontFamily: 'Helvetica-Bold'
},


  
});
const PrintDeliveryChallan = (props) => {
    const initialized = useRef(false);
    var gstSlab = useRef({});
    const [reportData, setReportData] = useState({});
    const [toRender, setToRender] = useState(false);
    const [companyInfo, setCompanyInfo] = useState({});
    const [receiptDetails, setReceiptDetails] = useState({});

    let invoiceData = [
        
      {
       description: "TMT Rods",
       quantity: "5",
       weight: "100"

    },
    {
      description: "Amman Steels",
      quantity: "8",
      weight: "600"

   },
   {
    description: "Jay Steels",
    quantity: "6",
    weight: "300"

 },
 
    ]
  const [invoiceList, setInvoiceList] = useState(invoiceData);

    

    useEffect(() => {
        console.log("useEffect method called ...");
       /*
        (async () => {
          
          var [retState, companyInfoTmp] = await getCompanyInfo();
          if (retState) {
            setCompanyInfo(companyInfoTmp);
          }
         
        setToRender(true);
        })();
        */
        setToRender(true);
        
      }, [])
    


    return (
        <PDFViewer style={styles.viewer}>
          {toRender === true &&
            <Document>
              <Page size="A4" orientation="portrait" style={styles.page}>
              
    
               
             
              {/*<View style={{ ...styles.tableContainer, paddingBottom: '20px'}}>*/}
               {/*
               <View style={{ flexDirection: 'row'}}>
                   <View style={{flex: 1, textAlign: 'left', alignItems: 'left', alignContent: 'left'}}>
                   <Text> GSTIN : {rcInfo.reg_number}</Text>
                   </View>
                   <View style={{flex: 1}}>
                   <Text style={{textAlign: 'right', alignItems: 'right', alignContent: 'right'}}>Cell : {rcInfo.phone}</Text>
                   </View>
                </View>
                */}
                {/*
                <View style={{flexDirection: 'row', paddingBottom: '10px'}}>
                  <View>
                    <Text style={{textAlign: 'left', width: '50%'}} ><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>GSTIN : </Text>{rcInfo.reg_number}</Text>
                  </View>
                  
                  <View>
                    <Text style={{textAlign: 'right', width: '50%'}}><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Cell : </Text>{rcInfo.phone}</Text>
                  </View> 
                </View>
              */}
               
                <View style={{flexDirection: 'row'}}>
                  <View style={{flex: 1}}>
                    <Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold', paddingLeft:"5px"}}>GSTIN : 33BBYPG1751JIZE</Text>
                  </View>
                  <View style={{  textAlign: 'center', width: '15%',paddingTop:"2px" }}>
                    {/*<Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold', fontSize: "10px"}}>DELIVERY NOTE</Text>*/}
                  </View>
                  
                  <View style={{flex: 1}}>
                    <Text style={{textAlign: 'right', paddingRight:"5px"}}><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Cell : </Text>9092867133</Text>
                  </View>
                  
                </View>
                <View>
              <View style={{ flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: '#a7a5a5' }}>
                <View style={{ textAlign: 'right', width: '25%' }}>
                  {/*<Image style={{ marginHorizontal: '10%', width: 'auto', height: 'auto', height: "64px", width: "64px" }} src={logo_left} />*/}
                </View>
                <View style={{ width: "50%", alignItems: "center", /*paddingRight: "30px",*/ /*borderBottomWidth: 1,*/ borderBottomColor: '#a7a5a5'/*,marginTop: 1, marginBottom: 10, borderWidth: 1*/ }} key="address-key">
                  <Text style={{ fontSize: "20px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>GKB Forgings</Text>
                  <Text>4/236, Malakarar Thottam, Annur Road, Velliyam Palayam</Text>
                  <Text>Sembiaanallur(po), Avinashi-641 654</Text>
                  <Text>Tiruppur, Tamilnadu</Text>
                  <Text style={{ fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica'}}><Text style={{ fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>Mobile:</Text> {companyInfo.phone}</Text>
                </View>
                <View style={{ textAlign: 'right', width: '25%',paddingRight: '50px', alignItems: 'right'}}>
                  {/*<Image style={{  width: 'auto', height: 'auto', height: "64px", width: "64px"}} src={logo_right} />*/}
                </View>
              </View>
            </View>
            <View style={{ width: "100%", alignItems: "center", textAlign: 'center',  paddingTop: "5px",borderBottomWidth: 1, borderBottomColor: '#a7a5a5' }}>
              <Text style={{ fontSize: "12px", textAlign: 'center', alignContent: 'center', fontFamily: 'Helvetica-Bold', color: '#646363' }}>DELIVERY NOTE</Text>
            </View>
            <hr style={{paddingBottom: "10px"}}/>
                 {/*
                  <View style={{ flexDirection: 'row',  paddingBottom: '5px'}}>
                    <View style={{ alignItems: "center",  borderBottomColor: '#a7a5a5', paddingTop:"5px"}} key="address-key">
                      <View style={{ flexDirection: 'row'}}>
                        <Text style={{width: "20%"}}></Text>
                        <Text style={{ fontSize: "25px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold',textAlign: "center", width: "60%" }}>GKB Forgings</Text>
                        <Text style={{width: "20%"}}></Text>
                     </View>
                     <View style={{ flexDirection: 'row'}}>
                        <View style={{width: "40%"}}>
                     <Text >Factory :</Text>
                     <Text>4/236,Malakarar Thottam,Annur Road</Text>
                     <Text>Velliyam Palayam, Sembiaanallur(po), Avinashi</Text>
                     <Text>Tiruppur(Dt), Tamilnadu</Text>
                     </View>
                      <Text style={{width: "10%", textAlign: "center"}}></Text>
                      <View style={{width: "40%"}}>
                        <Text>Office : 18/303-A, Kamaraj Nagar, Cheyur Road</Text>
                        <Text>Avinashi 641 654, Tiruppur(Dt), TN</Text>
                        <Text>Email : gkbforgings@rediffmail.com</Text>
                      </View>
                      </View>
                    </View>
                  </View>
                  */}
                 

                  <View style={{flexDirection: "row"}}>
                  <View style={{...styles.left3, width: "70%"}}>
                  <Text style={styles.to}>To:</Text>
        {/*<Text style={styles.billTo}>Order Details:</Text>*/}
        <View style={{flexDirection: 'row', }}>
               <Text style={{width: '20%'}}> M/s</Text>
               <Text style={{width: '80%', borderBottomWidth: 1, fontFamily: 'Helvetica-Bold', borderStyle: "dotted"}}>:</Text>
               <Text style={{width: '100%', borderBottomWidth: 1, fontFamily: 'Helvetica-Bold', borderBottomStyle: 'dashed', paddingBottom: "10px"}}></Text>
            </View>
            <Text style={{borderBottomWidth: 1, borderBottomStyle: 'dashed', paddingBottom: "20px"}}></Text>
            <Text style={{borderBottomWidth: 1, borderBottomStyle: 'dashed', paddingBottom: "20px"}}></Text>
            <View style={{flexDirection: 'row', paddingTop: "5px"}}>
            <Text style={{width: '17%', paddingTop: "5px"}}>Party GSTIN</Text>
               <Text style={{width: '83%', borderBottomWidth: 1,  borderBottomStyle: 'dashed', paddingTop: "5px"}}>:</Text>
               </View>
       
    </View>
    <View style={{width: "0.25%"}}></View>

    <View style={{...styles.headerContainer, width:"31.75%"}}>
    <View style={{flexDirection: 'row', paddingTop: "10px"}}>
            <Text style={{width: '25%', paddingLeft: "2px"}}>No </Text>
               <Text style={{width: '73%'}}>:</Text>
               </View>
               <View style={{flexDirection: 'row', paddingTop: "10x"}}>
               <Text style={{width: '25%',paddingLeft: "2px"}}>Date </Text>
               <Text style={{width: '73%'}}>:</Text>
               </View>

               <View style={{flexDirection: 'row', paddingTop: "10px"}}>
               <Text style={{width: '25%', paddingLeft: "2px"}}>Order No</Text>
               <Text style={{width: '73%', }}>:</Text>
               </View>

               <View style={{flexDirection: 'row', paddingTop: "10px"}}>
               <Text style={{width: '25%', paddingLeft: "2px"}}>Party DC </Text>
               <Text style={{width: '73%',}}>:</Text>
               </View>

               
        
        
    </View>
    </View>
                 
     
                  <View style={{...styles.tableContainer1}}>
                  
                   <View style={styles.container}>
                     <Text style={styles.sno} className="align-items-center">S.No</Text>
                     <Text style={styles.description} className="align-items-center align-middle"> Particulars</Text>
                    
                     <Text style={styles.qty} className="align-items-center">Quantity</Text>
                     
                     
                    
                     <Text style={styles.weight} className="align-items-center">Weight</Text>
                     
                     </View>
                     <View style={{flexDirection: 1}}>
                    {invoiceList.map((invoice, idx) => (
                     <View style={{ ...styles.row , borderBottomWidth: 1, paddingBottom: itemRowHeight / 2, paddingTop: itemRowHeight / 2 }} key="items">
                        <Text style={{ ...styles.sno_with_space, paddingTop: `${paddingHeight}px`, height: styles.sno_with_space.height + itemExtraHeight}}>{idx + 1}</Text>
                        
                        <Text style={{ ...styles.description_with_space, paddingTop: `${paddingHeight}px`, height: styles.description_with_space.height + itemExtraHeight}}>
                         {invoice.description} 
                        </Text>
                        {/*<Text style={{ ...styles.hsncode_with_space, paddingTop: `${paddingHeight}px`, height: styles.hsncode_with_space.height + itemExtraHeight}}></Text>*/} 
                        <Text style={{ ...styles.qty_with_space, paddingTop: `${paddingHeight}px`, height: styles.qty_with_space.height + itemExtraHeight}}> {invoice.quantity} </Text>
                       
                        
                       
                        <Text style={{ ...styles.weight_with_space, paddingTop: `${paddingHeight}px`, height: styles.weight_with_space.height + itemExtraHeight}}> {invoice.weight} </Text>
               
                      </View>
                    ))}
                      </View>
    
                      </View>

                      <View style={{flexDirection: "row"}}>
                  <View style={{...styles.left, width: "70%"}}>
                  
       
                <View style={{flexDirection: "row"}}>
                  <View style={{paddingTop: "10px", borderRightWidth: 1, width: '50%'}}>
                    <Text style={{paddingBottom: "10px"}}> Dispatch :</Text>
                    <Text style={{width: '50%'}}> Vehicle No :</Text>
                  </View>
               
                  <View style={{paddingTop: "10px", width: '50%'}}>
                  <Text style={{paddingLeft: "0px", textAlign: 'right', alignContent: 'right', height: '50%'}}></Text>
                    <Text style={{paddingLeft: "0px", textAlign: 'right', alignContent: 'right', height: '50%'}}>Receiver's Signature</Text>
                  </View>

                
               </View>
               
           </View>
                     

               <View style={{...styles.right, width:"30%"}}>
               <View style={{flexDirection: 'row', paddingTop:"10px"}}>
               <Text style={{paddingLeft: "50px"}}>For  </Text>
               <Text style={{fontFamily: 'Helvetica-Bold', fontSize: "10px"}}>GKB Forgings</Text>
               
               </View>
              

               

             

               
        
        
    </View>
    </View>
                      
           
                     
                 
                  
                 
                 
                  <View style={{flexDirection: 'row'}}>
                {/*
                <Text style={{textAlign: 'left', flex: 1,paddingTop:"5px"}} render={({ pageNumber, totalPages }) => (
                    `Printed On: ${formatPrintDate(new Date())}`
                )} fixed />
                */}
                 
                 
                  </View>
       
                  
               
                 
                 
                
    
               
               
                  
    
              </Page>
            </Document>
          }
        </PDFViewer>
      );
    }
export default PrintDeliveryChallan;