import { React, Fragment, useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  Badge,
  Modal,
  Spinner,
  Image
} from "react-bootstrap";

//import Sidebar2 from "./Sidebar2";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import "../Dashboard.css";
import "../App.css";
import { client, appGlobal, LS_MSG_TAG } from "../config/Config";
import axios from "axios";


import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsTrashFill,
  BsArrowLeftCircle,
  BsFillPenFill,
  BsFillPrinterFill
} from "react-icons/bs";
import SidebarMain from "./SidebarMain";
import {getDistrictList, getStateList } from "./utils";
import spinner_logo from '../assets/images/spinner_logo.png';
//import { MSG_BUSINESS_NAME_VALIDATE, MSG_PHONE_NUMBER_MANDATORY_VALIDATE, MSG_PHONE_NUMBER_CONTAINS_VALIDATE, MSG_ADDRESS1_VALIDATE, MSG_PINCODE_CONTAINS_VALIDATE } from "../config/VbMessages";
import { getCustomerMessages } from "./utils";

//function AddUpdateCustomer () {
const AddUpdateBusiness = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();

  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);
  
  let initialItems = [];
  const intialValues = {
    name: "",
    pan: "",
    gst: "",
    phone: "",
    email: "",
    website: "",
    address_line1: "",
    address_line2: "",
    district: "",
    pincode: "",
    state_id: 1,
    country_id: 1,

    
  };

  let stateArr = [
    { value: 1, label: "Andhra Pradesh" },
    { value: 2, label: "Assam" },
    { value: 3, label: "Tamil Nadu" },
    { value: 4, label: "Telangana" },
  ];

  let countryArr = [
    { value: 1, label: "Australia" },
    { value: 3, label: "India" },
    { value: 4, label: "China" },
  ];
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});

  const [validateError, setValidateError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);

  const [isReadOnly, setIsReadOnly] = useState(true);
  const [toRender, setToRender] = useState(false);

  const [show, setShow] = useState(false);
 // const [showMsg, setShowMsg] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  
  //const [selectedId, setSelectedId] = useState(-1);
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );
  const [submitAction, setSubmitAction] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [stateIdSelect, setStateIdSelect] = useState({});
  const [optionsState, setOptionsState] = useState([]);
  const [countryIdSelect, setCountryIdSelect] = useState({});
  const [optCountry, setOptCountry] = useState([]);
  const [addrCopyCheck, setAddrCopyCheck] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [checkState, setCheckState] =  useState(true);
  const [typeIdSelect, setTypeIdSelect] = useState({});
  const [optBusinessType, setOptBusinessType] =  useState([]);
  const [categoryIdSelect, setCategoryIdSelect] = useState({});
  const [optCategory, setOptCategory] =  useState([]);
  const [districtIdSelect, setDistrictIdSelect] = useState({});
  const [optDistrict, setOptDistrict] =  useState([]);
  const [type, setType] = useState("");
  const currSelBusinessRef = useRef(0);
  const currBranchRef = useRef(0);

  var validateErr = false;
 /*
  const countryListFromApi = { "countries": [
    {"country_name": "India", "states": ["Tamil Nadu", "Kerala", "Karnataka","Andra pradesh", "Telengana", "Maharastra", "Punjab"]},
    {"country_name": "Australia", "states": ["New South Wales", "Victoria, Queensland", "Western Australia", "Tasmania", "South Australia"]},
    {"country_name": "China", "states": ["Hebei", "Shanxi", "Liaoning", "Jilin", "Heilongjiang"]}
  ]}
  */

  const handleOnChange = (e) => {
    //e.preventDefault();
    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);

    if (name === "phone" || name === "contact_person_number" || name === "pincode")  {
      console.log("Inside check ******************");
      //const numericRegExp = /^(?:[1-9]\d*|\d)$/;
      if (e.target.value.match(appGlobal.wholeNumberRegExp)) {
        //var valueTmp = Math.round(e.target.value.replace(/\D/g, ""));
        setFormValues({ ...formValues, [name]: e.target.value });
      }
    } 
    else if (name === "opening_balance") {
     if ( e.target.value.match(appGlobal.numericRegExp) ) {
      setFormValues({ ...formValues, [name]: value });
     }
    }
    else {
      setFormValues({ ...formValues, [name]: value });
    }
    /*
    {
      console.log("Inside check ******************");
     
      var valueTmp = e.currentTarget.value.replace(/[^\d]/g, '');
    
      setFormValues({ ...formValues, [name]: valueTmp });
      //}
    } else {
      setFormValues({ ...formValues, [name]: value });

    }

    */
    
  };
  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.name) {
      //errors.name = getCustomerMessages('ui_messages', "MSG_BUSINESS_NAME_VALIDATE");
      var errorMsgBusinessName = getCustomerMessages(LS_MSG_TAG,"MSG_BUSINESS_NAME_MANDATORY");
      console.log("^^^^^^^^^^^^^ BUSNESS NAME ERROR MESSAGE ************* : ", errorMsgBusinessName);
      errors.name = getCustomerMessages(LS_MSG_TAG,"MSG_BUSINESS_NAME_MANDATORY");
      validateErr = true;
      //setValidateError(true);
    }
  
    if (!values.address_line1) {
      errors.address_line1 = getCustomerMessages(LS_MSG_TAG, "MSG_ADDRESS1_MANDATORY");
      validateErr = true;
      //setValidateError(true);
    }
  
  
    if (!values.pincode) {
      errors.pincode = getCustomerMessages(LS_MSG_TAG, "MSG_PINCODE_MANDATORY");
      validateErr = true;
      setValidateError(true);
    }
  
    
   
    if (values.pincode && values.pincode.length != 6) {
      errors.pincode = getCustomerMessages(LS_MSG_TAG, "MSG_PINCODE_INVALID");
      validateErr = true;
      setValidateError(true);
    }
    
  
    if (!values.gstin) {
      errors.gstin = getCustomerMessages(LS_MSG_TAG,"MSG_GSTIN_MANDATORY");
      validateErr = true;
      //setValidateError(true);
    }
    
    if (!values.pan) {
      errors.pan = getCustomerMessages(LS_MSG_TAG,"MSG_PAN_MANDATORY");
      validateErr = true;
      //setValidateError(true);
    }
    
    /*
    if (values.gstin && values.gstin.length != 15) {
      errors.gstin = getCustomerMessages(LS_MSG_TAG, "MSG_GSTIN_INVALID");
      validateErr = true;
      //setValidateError(true);
    }
    
    if (values.pan && values.pan.length != 10) {
      errors.pan = getCustomerMessages(LS_MSG_TAG, "MSG_PAN_INVALID");
      validateErr = true;
      //setValidateError(true);
    }
    */
    
   
    /*
    if (!values.phone_number) {
      errors.phone_number = getCustomerMessages(LS_MSG_TAG,"MSG_PHONE_MANDATORY");
      validateErr = true;
      //setValidateError(true);
    }
    */
    
    if (values.phone && values.phone.length != 10) {
      errors.phone = getCustomerMessages(LS_MSG_TAG, "MSG_PHONE_INVALID");
      validateErr = true;
      //setValidateError(true);
    }

    //console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ",
      validateErr
    );
    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
      //console.log("LINE ITEMS : ", items);
      callUpdateApi();
     
    }
  };
    const handleSelect = (e, data) => {
    console.log("SELECTED Dropdown value : ", e);
    console.log("SELECTED element id : ", data);
    var currentId = data.name.substring(data.name.lastIndexOf("-") + 1);
    console.log("Current id : ", currentId);
  };
  const handleSearch = (e, dat) => {
    console.log("Handled search method called ...", e);

    //var stateIdInput = 1;
   // var stateNameInput = "TAMIL NADU";
    //getDistrictList(stateIdInput, stateNameInput);
  };

 
  
  const handleStateSelect = async(e) => {
    console.log("handleStateSelect called ...", e);
    setStateIdSelect(e);

    var districtsData = localStorage.getItem('districts');
    var districtsObj = JSON.parse(districtsData);

    /*
    var findCountry = districtsObj.find(element => element.state_id === e.value);
    if ( findCountry !== undefined ) {
    }
    */
   var distArrTmp = [];
    districtsObj.forEach(function(districtInfo){
      if ( districtInfo.state_id === e.value ) {
        distArrTmp.push(districtInfo);
      }
    });
    setOptDistrict(distArrTmp);
    setDistrictIdSelect(distArrTmp[0]);
  };

  const handleDistrictSelect = (e, data) => {
    console.log("handleDistrictSelect called ...", e);
    setDistrictIdSelect(e);
   
    
};


  
 
  const handleCountrySelect = (e, data) => {
    console.log("handleCountrySelect called ...", e);
    setCountryIdSelect(e);
   /*
    console.log("************** COUNTRY ID SELECTED ++++++++++++++++ at handleCountrySelect < : ", e.value);
    console.log("SERACH COUNTRY SOURCE JSON : ", countryListFromApi.countries);
    var findCountry = countryListFromApi.countries.find(element => element.country_name === e.value);
    if ( findCountry !== undefined ) {
      console.log("++++++++++++++++++------------------- FOUND COUNTRY : ", findCountry);
      //setCountryIdSelect({value: countries[0].country_id, label: findCountry.label});
      var newStatesArr = [];
      findCountry.states.forEach(function(stateInfo){
        newStatesArr.push({value: stateInfo, label: stateInfo});
      })
      setOptionsState(newStatesArr);
      setStateIdSelect(newStatesArr[0]);
  };
  */
};

const handleBusinessTypeSelect = async(e) => {
  console.log("handleBusinessTypeSelect called ...", e);
  setTypeIdSelect(e);
  
};

const handleBusinessCategorySelect = async(e) => {
  console.log("handleBusinessCategorySelect called ...", e);
  setCategoryIdSelect(e);
  
};
  const handleEdit = async (e) => {
    console.log("handle edit  clicked : ", e);
    setIsReadOnly(false);
  };
  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
    navigate(-1);
  };

  const handleReset = async (e) => {
    console.log("handle reset clicked : ", e);
    navigate(0);
  };
  
  
 
  const callBusinessDetById = async(businessIdPassed) => {
    var retState = false;
    var businessInfoRet = {};
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      //var apiToCall = "";

    var reqParams = {
      id: businessIdPassed
    };

    var res = await axios.post(client.domain + "/core/getBusinessDetails", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      console.log("Response from server INSIDE ARRAY : ", res.data.detail.data[0]);
      if (res.data.detail.status === "SUCCESS") {
        //setShowMsg(res.data.detail.data.message);
        //setShowMsgTitle("Success");
        //setShowDialog(true);
        setFormValues(res.data.detail.data[0]);
        businessInfoRet = res.data.detail.data[0];
        setSubmitAction(false);
        retState = true;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          
        }
      }
      setSubmitAction(false);
    }
      
    return [retState, businessInfoRet];
  }


  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect::addUpdateBusiness called ##########################");
      ( async() =>{
      
      setToRender(true);
      //setCountryIdSelect(optCountry[0]);
      //setStateIdSelect(optionsState[0]);
      var countriesData = localStorage.getItem('countries');
      var countriesObj = JSON.parse(countriesData);

      var statesData = localStorage.getItem('states');
      var statesObj = JSON.parse(statesData);
      
      console.log("BUSINESS DATA IN OBJECT : ", statesObj);
      if ( state.action === 'edit' || state.action === 'view' ) {
        var [retState, businessInfoRet] = await callBusinessDetById(state.passed_element.id);
        if ( retState ) {
          console.log("RETURNED BUSINESS INFO : ", businessInfoRet);

          var findState = statesObj.find(element => element.value === businessInfoRet.state_id);
          if (findState !== undefined) {
            setStateIdSelect(findState);  
          };

          var optStateArr = [];
          statesObj.forEach(function(state){
            if (state.country_id === businessInfoRet.country_id) {
              optStateArr.push(state);
            }
          });
          console.log("FILTERED STATE FOR EDIT <<<<<<<<<<<<<<^^^^^^^^^^^^^ : ", optStateArr);
          setOptionsState(optStateArr);
        }
      }
      else {
        setStateIdSelect(statesObj[0]);

        var optStateArr = [];
        statesObj.forEach(function(state){
          if (state.country_id === countriesObj[0].value) {
            optStateArr.push(state);
          }
        });
        console.log("FILTERED STATE FOR ADD <<<<<<<<<<<<<<^^^^^^^^^^^^^ : ", optStateArr);
        setOptionsState(optStateArr);
      }

      //setOptionsState(statesObj);
      //setStateIdSelect(statesObj[0]);

      var countriesData = localStorage.getItem('countries');
      var countriesObj = JSON.parse(countriesData);
      console.log("BUSINESS DATA IN OBJECT : ", countriesObj);
      setOptCountry(countriesObj);
      setCountryIdSelect(countriesObj[0]);

      var districtData = localStorage.getItem('districts');
      var districtObj = JSON.parse(districtData);
      console.log("BUSINESS DATA IN OBJECT : ", districtObj);
      
      if ( state.action === 'edit' || state.action === 'view' ) {
        var [retState, businessInfoRet] = await callBusinessDetById(state.passed_element.id);
        if ( retState ) {
          console.log("RETURNED BUSINESS INFO : ", businessInfoRet);

          var findDistrict = districtObj.find(element => element.value === businessInfoRet.district_id);
          if (findDistrict !== undefined) {
            setDistrictIdSelect(findDistrict);  
          };

          var optDistArr = [];
          districtObj.forEach(function(district){
            if (district.state_id === businessInfoRet.state_id) {
              optDistArr.push(district);
            }
          });
          console.log("FILTERED DISTRICT FOR EDIT <<<<<<<<<<<<<<^^^^^^^^^^^^^ : ", optDistArr);
          setOptDistrict(optDistArr);
        }
      }
      else {
        setDistrictIdSelect(districtObj[0]);

        var optDistArr = [];
        districtObj.forEach(function(district){
          if (district.state_id === statesObj[0].value) {
            optDistArr.push(district);
          }
        });
        console.log("FILTERED DISTRICT FOR ADD <<<<<<<<<<<<<<^^^^^^^^^^^^^ : ", optDistArr);
        setOptDistrict(optDistArr);
      }


      var business_categoriesData = localStorage.getItem('business_categories');
      var business_categoriesObj = JSON.parse(business_categoriesData);
      console.log("BUSINESS DATA IN OBJECT : ", business_categoriesObj);
      setOptCategory(business_categoriesObj);
      if (state.action === "add") {
      var findDefault = business_categoriesObj.find(element => element.is_default === true);
          if (findDefault !== undefined) {
            setCategoryIdSelect(findDefault);  
          }
        }

          else {
            var [retState, businessInfoRet] = await callBusinessDetById(state.passed_element.id);
            if ( retState ) {
              console.log("RETURNED BUSINESS INFO : ", businessInfoRet);
    
              var findCategory = business_categoriesObj.find(element => element.value === businessInfoRet.category_id);
              if (findCategory !== undefined) {
                setCategoryIdSelect(findCategory);  
              };
            }
            else {
              var findDefault = business_typesObj.find(element => element.is_default === true);
              if (findDefault !== undefined) {
                setCategoryIdSelect(findDefault);  
              };
            }
            
    
          }
          
      
      //setCategoryIdSelect(business_categoriesObj[0]);



      var business_typesData = localStorage.getItem('business_types');
      var business_typesObj = JSON.parse(business_typesData);
      console.log("BUSINESS DATA IN OBJECT : ", business_typesObj);
      setOptBusinessType(business_typesObj);
      if (state.action === 'add') {
        var findDefault = business_typesObj.find(element => element.is_default === true);
        if (findDefault !== undefined) {
          setTypeIdSelect(findDefault);  
        };  
      }
      else {
        var [retState, businessInfoRet] = await callBusinessDetById(state.passed_element.id);
        if ( retState ) {
          console.log("RETURNED BUSINESS INFO : ", businessInfoRet);

          var findType = business_typesObj.find(element => element.value === businessInfoRet.type_id);
          if (findType !== undefined) {
            setTypeIdSelect(findType);  
          };
        }
        else {
          var findDefault = business_typesObj.find(element => element.is_default === true);
          if (findDefault !== undefined) {
            setTypeIdSelect(findDefault);  
          };
        }
        

      }
      //setTypeIdSelect(business_typesObj[0]);


      //console.log("Process api fetched country list : ", countryListFromApi.countries);
     /*
      var newCountryArr = [];
      countryListFromApi.countries.forEach(function(object1){
        console.log("^^^^^^^^^^^^^^^^^^^ INDIVIDUAL OBJECT VALUE : ", object1);
        /*
        for (const [key, value] of Object.entries(object1)) {
          console.log(`${key} : ${value}`);
          newCountryArr.push({value: key, label: key});
        }
        * /
       newCountryArr.push({value: object1.country_name, label: object1.country_name});
      })
     

      setOptCountry(newCountryArr);
      setCountryIdSelect(newCountryArr[0]);

      setOptionsState(countryListFromApi.countries[0].states);
      setStateIdSelect({value: countryListFromApi.countries[0].states[0], label: countryListFromApi.countries[0].states[0]});
      */
     
      
      if (state === null) {
        navigate("/listBusiness");
      } else {
        console.log("PASSED STATE VALUE IN Employee Add/Update page : ", state);
         console.log("Inside else for view or edit ...");
            //setFormValues(state.passed_element);

            /*
            var findState = stateArr.find(element => element.value === countries[0].state_id);
            if ( findState !== undefined ) {
              console.log("++++++++++++++++++------------------- FOUND STATE : ", findState);
              setStateIdSelect({value: countries[0].state_id, label: findState.label});
            }
            */
           /*
            console.log("************** COUNTRY ID SELECTED ++++++++++++++++ < : ", countryIdSelect.value);
            var findCountry = countryArr.find(element => element.value === countryIdSelect.value);
            if ( findCountry !== undefined ) {
              console.log("++++++++++++++++++------------------- FOUND COUNTRY : ", findCountry);
              //setCountryIdSelect({value: countries[0].country_id, label: findCountry.label});
              //setOptionsState(findCountry);
            }
            */
        if (state.action === "add") {
          setIsReadOnly(true);
        }
        else if ( state.action === 'view' || state.action === 'edit') {
          // call api to get business info
          callBusinessDetById(state.passed_element.id);
        }
        else {
          setFormValues(state.passed_element);
        }
        
         setToRender(true);
       
        console.log("newObj :", formValues);
        if (state.action === "add") {
          setIsReadOnly(false);
        }
      }
      
      initialized.current = true;
    })();
    }
  }, []);

 
  const callUpdateApi = async () => {
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var apiToCall = "";
      if (state.action === "add") {
        apiToCall = "/core/addBusiness";
      } else if (state.action === "view") {
        apiToCall = "/core/updateBusiness";
      } else {
        apiToCall = "/core/addBusiness";
      }

      var reqParam = {
        type_id: typeIdSelect.value,
        category_id: categoryIdSelect.value,
        name: formValues["name"] || "",
        gstin: formValues["gstin"] || "",
        pan: formValues["pan"] || "",
        phone: formValues["phone"] || "" ,
        email: formValues["email"] || "",
        website: formValues["website"] || "",
        address_line1: formValues["address_line1"] || "",
        address_line2: formValues["address_line2"] || "",
        district_id: districtIdSelect.value,
        district_name: formValues["district"] || "",
        state_id: stateIdSelect.value,
        pincode: formValues["pincode"],
        
      };

      if ( state.action === "edit" || state.action === "view") {
        reqParam.id = formValues["id"];
        reqParam.is_active = checkState;
      }

      var res = await axios.post(client.domain + apiToCall, reqParam, { headers }, { withCredentials: false });
     
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        setShowMsgTitle("Success");
        setShowDialog(true);
        setSubmitAction(false);
        if (state.action === "add"){
        setShowMsg(getCustomerMessages(LS_MSG_TAG,"MSG_BUSINESS_SAVED"));
        
      }
      else{
        setShowMsg(getCustomerMessages(LS_MSG_TAG,"MSG_BUSINESS_UPDATED"))
      }
    }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          
        }
      }
      setSubmitAction(false);
    }
  };
  


  const handlePostSaveOk = () => {
    console.log("user confirmed to customer added ...");
    setShowDialog(false);
    navigate("/listBusiness");
  };
  /*
  const handleSave = async (e) => {
    e.preventDefault();
    console.log("Handle Click save called : ", e);
    console.log("Formvalues : ", formValues);

    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (validateErr === false) {
      setSubmitAction(true);
     callUpdateApi();
     
    }
  };
  */

  const handlePrintBusiness = async () => {
    console.log("Handle print business called ...");
    //navigate("/printCustReceipt")
    /*

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printCustReceipt?receipt_id=${state.passed_element.id}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0, 0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
    */
  };

  const handleCheckBox = async (e) => {
    console.log("handle copy to billing called :", e);
    console.log("handle copy to billing called CHECKED :", e.target.checked); 
    setCheckState(e.target.checked);
   
    if ( state.action === 'view' || state.action === 'edit'){
      setCheckState(e.target.checked);
    }
    setRefresh(true);
  };
  const sendDataToParent  = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if ( passedBackValue.hasOwnProperty("business_id") ) {
      currSelBusinessRef.current = passedBackValue["business_id"];
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);
    
  }


 

  return (
      <Container>
        <SidebarMain sendBacktoParent={sendDataToParent}/>
        { toRender ?
        (
        <Form>
          <Row>
            <Col xs={4} md={4} sm={4} lg={4}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>{menuSettings.permissions.find(element => (element.code === 'listBusiness' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'listBusiness')).page_title_addupd : "Business"}</span>
           {/*
            { (state.action === 'view' || state.action === 'edit') &&
              <>
              &nbsp;&nbsp;
             
            <Button variant={state.passed_element.is_active ? "outline-success" : "outline-danger"} size="sm"><span style={{cursor: "default", fontWeight: "500"}}>{state.passed_element.status}</span></Button>
            </>
            }
          */}
            
            </Col>
            
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
              className="float-end justify-content-end"
            >
              <div className="d-flex justify-content-end">
                <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                  <BsArrowLeftCircle
                    key={`back-btn-business-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                   
                    name={`back-icon-patient-name`}
                    id={`edit-icon-business-id`}
                  />
                </Button>
                &nbsp;&nbsp;
                {(state.action === "edit" || state.action === "view") && (

                  <>
                  <Button id="businessPrint" name="businessPrint" onClick={(e) => handlePrintBusiness(e)}
                  disabled={menuSettings.permissions.find(element => (element.code === 'listBusiness' && element.a_print === true)) ? false : true}>
                    <BsFillPrinterFill
                      size={18}
                      style={{ color: "white", cursor: "pointer" }}
                      name={`print-business-icon`}
                      id={`print-business-icon`}
                    />
                  </Button>
                &nbsp;&nbsp;
                <Button variant="primary" className="btn btn-primary btn-md"  onClick={(e) => handleEdit(e)}
                disabled={menuSettings.permissions.find(element => (element.code === 'listBusiness' && element.a_edit === true)) ? false : true}
                >
                  <BsPencilSquare
                    key={`edit-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                  
                    name={`edit-icon-paBiSolidEditAlttient-name`}
                    id={`edit-icon-doctor-id`}
                  />
                </Button>
                </>
                )}
              </div>
            </Col>
          </Row>

          <div className="ui-divider"></div>
          <div className="ui-form">
            <hr align="center" />
           

            <Row>
              <Col xs={6} md={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="name-id"
                    name="name"
                    placeholder=" Name"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["name"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.name}</p>
              </Col>
             
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">
                    GSTIN
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="gstin"
                    name="gstin"
                    placeholder="GSTIN"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    //maxLength={15}
                    value={formValues["gstin"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.gstin}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">PAN</Form.Label>
                  <Form.Control
                    type="text"
                    id="pan"
                    name="pan"
                    //className="mobNum"
                    placeholder="PAN"
                    onChange={handleOnChange}
                    readOnly={isReadOnly}
                    //maxLength={10}
                    value={formValues["pan"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.pan}</p>
              </Col>
             
            </Row>
            <Row>
            <Col xs={3} sm={3} md={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Business Type</Form.Label>
                  <Select
                    id="type_id"
                    name="type_id"
                    value={{
                      value: typeIdSelect.value,
                      label: typeIdSelect.label,
                    }}
                    options={optBusinessType}
                    defaultValue={{
                      value: typeIdSelect.value,
                      label: typeIdSelect.label,
                    }}
                    onChange={handleBusinessTypeSelect}
                    isDisabled={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.type_id}</p>
              </Col>


              <Col xs={3} sm={3} md={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Business Category</Form.Label>
                  <Select
                    id="category_id"
                    name="category_id"
                    value={{
                      value: categoryIdSelect.value,
                      label: categoryIdSelect.label,
                    }}
                    options={optCategory}
                    defaultValue={{
                      value: categoryIdSelect.value,
                      label: categoryIdSelect.label,
                    }}
                    onChange={handleBusinessCategorySelect}
                    isDisabled={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.category_id}</p>
              </Col>
            </Row>
           
            
           

            <Row>
              <Col xs={6} md={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">
                    Street / Location
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="address_line1"
                    name="address_line1"
                    placeholder="Street / Location"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["address_line1"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.address_line1}</p>
              </Col>
              <Col xs={6} md={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="field">
                    City / Town
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="address_line2"
                    name="address_line2"
                    placeholder="City / Town"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["address_line2"]}
                    style={{
                      border: !isReadOnly && "1px solid gray"
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.address_line2}</p>
              </Col>
              </Row>
              <Row>
              
              

              <Col xs={3} sm={3} md={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>State</Form.Label>
                  <Select
                    id="state"
                    name="state"
                    value={{
                      value: stateIdSelect.value,
                      label: stateIdSelect.label,
                    }}
                    defaultValue={{
                      value: stateIdSelect.value,
                      label: stateIdSelect.label
                    }}
                    options={optionsState}
                    onChange={handleStateSelect}
                    isDisabled={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.state_id}</p>
              </Col>
              <Col xs={3} sm={3} md={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>District</Form.Label>
                  <Select
                    id="district_id"
                    name="district_id"
                    value={{
                      value: districtIdSelect.value,
                      label: districtIdSelect.label,
                    }}
                    defaultValue={{
                      value: districtIdSelect.value,
                      label: districtIdSelect.label
                    }}
                    options={optDistrict}
                    onChange={handleDistrictSelect}
                    isDisabled={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>

                <p className="error-msg">{formErrors.district}</p>
              </Col>
              
              <Col xs={3} sm={3} md={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Pincode</Form.Label>
                  <Form.Control
                    type="text"
                    id="pincode-id"
                    name="pincode"
                    placeholder="Pincode"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    maxLength={6}
                    value={formValues["pincode"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>

                <p className="error-msg">{formErrors.pincode}</p>
              </Col>
              

              {/*
              <Col xs={3} sm={3} md={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Country</Form.Label>
                  <Select
                    id="country"
                    name="country"
                    value={{
                      value: countryIdSelect.value,
                      label: countryIdSelect.label,
                    }}
                    options={optCountry}
                    defaultValue={{
                      value: countryIdSelect.value,
                      label: countryIdSelect.label,
                    }}
                    onChange={handleCountrySelect}
                    isDisabled={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.country_id}</p>
              </Col>
              */}
            </Row>

            <Row>
              {/*
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Contact Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="contact_person"
                    name="contact_person"
                    placeholder="Contact Person"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["contact_person"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.contact_person}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Contact Mobile
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="cont-person-mobile-id"
                    name="contact_person_number"
                    className="mobNum"
                    placeholder=" Contact Number"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["contact_person_number"]}
                    maxLength={10}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.contact_person_number}</p>
              </Col>
                  */}

              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Phone
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="phone"
                    name="phone"
                    //className="mobNum"
                    placeholder=" Phone"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["phone"]}
                    maxLength={10}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.phone}</p>
              </Col>
             
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    id="email-id"
                    name="email"
                    placeholder="Email"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["email"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.email}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Website</Form.Label>
                  <Form.Control
                    type="text"
                    id="Web-id"
                    name="website"
                    placeholder="Website"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["website"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.website}</p>
              </Col>
            </Row>

           
            {!isReadOnly && (
              <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                    {(state.action === "view" || state.action === "edit") && (
                  <Form.Check
                            style={{paddingTop: "5px"}}
                            label="Active"
                            name="checkbox"
                            id="id-addr-copy-bill"
                            type="checkbox"
                            className="checkCopy"
                            //defaultChecked=
                            checked={checkState}
                            onChange={handleCheckBox}
                          ></Form.Check>
                    )}
                          &nbsp;&nbsp;
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
              
            
                    <Button
                      type="submit"
                      id="save"
                      onClick={(e) => handleSubmit(e)}
                      disabled={submitAction ? true: false}
                    >
                     {submitAction && 
                      <>
                      <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                     <span className="visually-hidden">Loading...</span>
                     </>
                    }
                      
                     Save
                    </Button>
                  </div>
                </Col>
                
              </Row>
            )}
            
           
            <Modal
              show={showDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{showMsgTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{showMsg}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          </Form>
     )
        :
        <div className="container-spinner">
        <div className="vertical-center-spinner">
         <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>  
         
                <span className="visually-hidden">Loading...</span>
              </Spinner>
         </div>
         </div>
      }
        
      </Container>
  );
};

export default AddUpdateBusiness;
