import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';

import { client } from '../config/Config';

//import StockDocument from "./StockDocument";
import {getCompanyInfo, formatDateIndian } from "./utils";

//import logo_left from '../logo_left.png';
//import logo_right from '../logo_right.png';
import business_logo from '../assets/images/business_logo.png';

const borderColor = '#55585a';
const MAX_CHAR_PER_ROW = 25;
const NTIMES_SIZE = 0.65;
const itemRowHeight = 20;
const MULTIPLY_PERC = 0.75;
const TEXT_ALIGN = 4;
const BORDER_COLOR = '#DCDCDC';
const BORDER_COLOR2 = "#C8C8C8";
const HEADING_COLOR = '#646363';
const BORDER_WIDTH = 0.5;
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 9,
    paddingTop: 30,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom:10,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    //width: '100%',
    //height: '33%',
    width: '100%',
    height: '100%',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logo_image2: {
    width: '15%',
    height: '98px',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  business_content: {
    width: '60%',
    height: '98px',
    //paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container2: {
    //paddingBottom:0,
    flexDirection: 'row',
    //borderBottomColor: '#bff0fd',
    //backgroundColor: '#bff0fd',
    //borderBottomWidth: 1,
    //alignItems: 'center',
    //height: 12,
    //textAlign: 'center',
    //fontStyle: 'bold',
    //flexGrow: 1,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 1,
    marginBottom: 10,
    //borderWidth: 1,
    //borderColor: '#a8a8a8',

  },
  container: {
    flexDirection: 'row',
    borderBottomColor: BORDER_COLOR,
    borderTopColor: BORDER_COLOR,
    borderTopWidth: BORDER_WIDTH,
    borderBottomWidth: BORDER_WIDTH,
    alignItems: 'center',
    //height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    //flexGrow: 1,
    //flexWrap: 'wrap',
    //marginTop: 1,
    //marginBottom: 10,
    //borderWidth: 1,
    //borderWidth: 1,
    //marginBottom: 5,
  },
  row: {
    flexDirection: 'row',
    //borderBottomColor: '#a8a8a8',
    //borderBottmWidth: 1,
    alignItems: 'center',
    //height: 20,
    fontStyle: 'bold',
  },
  
  sno: {
    width: '5%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    borderLeftColor: BORDER_COLOR,
    borderLeftWidth: BORDER_WIDTH,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN 
  },
  tax: {
    width: '20%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN 
  },
  tax_per: {
    width: '10%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN 
  },
  sales: {
    width: '10%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN  
  },
  sales_amount: {
    width: '15%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN 
  },
  purchase: {
    width: '10%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN  
  },
  purchase_amount: {
    width: '15%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN  
  },
  net: {
    width: '15%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN  
  },
  
  snoData: {
    width: '5%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    borderLeftWidth: BORDER_WIDTH,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    paddingRight:3,
    textAlign: 'right',
    paddingRight: 4,
    borderLeftColor: BORDER_COLOR,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
  },
  taxData: {
    width: '20%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    textAlign: 'left', 
    paddingLeft: 4,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
  },
  tax_perData: {
    width: '10%',
    fontSize: "8px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    textAlign: 'right',
    paddingRight: 4,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
  },
  salesData: {
    width: '10%',
    fontSize: "8px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    textAlign: 'right',
    paddingRight: 4,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
  },
  sales_amountData: {
    width: '15%',
    fontSize: "8px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    textAlign: 'right', 
    paddingRight: 4,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
  },
  purchaseData: {
    width: '10%',
    fontSize: "8px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    textAlign: 'right',
    paddingRight:4,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
  },
  purchase_amountData: {
    width: '15%',
    fontSize: "8px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    textAlign: 'right',
    paddingRight: 4,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
  },

  netData: {
    width: '15%',
    fontSize: "8px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    textAlign: 'right',
    paddingRight: 4,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
  },
  
  sno1Data: {
    width: '5%',
    fontSize: "9px",
    paddingRight:4,
    textAlign: 'right',
    paddingTop: TEXT_ALIGN,
    height: itemRowHeight
    //borderLeftWidth: BORDER_WIDTH, 
    //borderLeftColor: BORDER_COLOR,
  
  },
  tax1Data: {
    width: '20%',
    fontSize: "9px",
    textAlign: 'left',
    paddingLeft: 4,
    paddingTop: TEXT_ALIGN,
    height: itemRowHeight 
  },
  tax_per1Data: {
    width: '10%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    paddingTop: TEXT_ALIGN,
    height: itemRowHeight
  
  },
  sales1Data: {
    width: '10%',
    fontSize: "8px",
    textAlign: 'right',
    paddingLeft: 4,
    paddingTop: TEXT_ALIGN,
    height: itemRowHeight  
  },
  sales_amount1Data: {
    width: '15%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    paddingTop: TEXT_ALIGN,
    height: itemRowHeight 
  },
  purchase1Data: {
    width: '10%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    paddingTop: TEXT_ALIGN,
    height: itemRowHeight
    //paddingLeft: 2    
  },
  purchase_amount1Data: {
    width: '15%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    paddingTop: TEXT_ALIGN,
    height: itemRowHeight
    //borderRightWidth: 1,
    //borderRightColor: '#a7a5a5',
    
  },
  
  net1Data: {
    width: '15%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    //borderRightWidth: BORDER_WIDTH,
    //borderRightColor: BORDER_COLOR,
    paddingTop: TEXT_ALIGN,
    height: itemRowHeight
    
  },

  sno2Data: {
    width: '5%',
    fontSize: "9px",
    paddingRight:4,
    textAlign: 'right',
    paddingRight: 4,
    //paddingTop: TEXT_ALIGN
    //borderLeftWidth: BORDER_WIDTH, 
    //borderLeftColor: BORDER_COLOR,
  
  },
  tax2Data: {
    width: '20%',
    fontSize: "9px",
    textAlign: 'left',
    paddingLeft: 4,
    //paddingTop: TEXT_ALIGN 
  },
  tax_per2Data: {
    width: '10%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    //paddingTop: TEXT_ALIGN
  
  },
  sales2Data: {
    width: '10%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    //paddingTop: TEXT_ALIGN  
  },
  sales_amount2Data: {
    width: '15%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    //paddingTop: TEXT_ALIGN 
  },
  purchase2Data: {
    width: '10%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    //paddingTop: TEXT_ALIGN
    //paddingLeft: 2    
  },
  purchase_amount2Data: {
    width: '15%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    //paddingTop: TEXT_ALIGN
    //borderRightWidth: 1,
    //borderRightColor: '#a7a5a5',
    
  },
  
  net2Data: {
    width: '15%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    //borderRightWidth: BORDER_WIDTH,
    //borderRightColor: BORDER_COLOR,
    //paddingTop: TEXT_ALIGN
    
  },
  
  /*
  received_by1ReceiptData: {
    width: '10%',
    fontSize: "10px",
    textAlign: 'left',
    paddingLeft: 2  
   
  },
  towards1ReceiptData: {
    width: '15%',
    fontSize: "10px",
    paddingLeft: 2  
  },
  addl_notes1ReceiptData: {
    width: '15%',
    fontSize: "10px",
    textAlign: 'left',
    paddingLeft: 2  
  },
  */


});

const PrintTaxSummary = (props) => {
  let receiptArr = [
 
      {
      sno: "1",
      receipt_number: "RCPT001",
      receipt_date: "01-01-2024",
      customer_name: "RRR Constructions",
      amount: "5000",
      received_mode: "Cash",
      ref_number: "123",
      received_by_name: "Joel",
      towards: "testing",
	    addl_notes: "Ui ",
      },
                             
  ]


 
 

  const [reportData, setReportData] = useState({});
  const [toRender, setToRender] = useState(false);
  const [taxList, setTaxList] = useState([]);
  const [companyInfo, setCompanyInfo] = useState({});
  const [reportHeader, setReportHeader] = useState({});
  const [reportTitle, setReportTitle] = useState({});
  const [taxSummary, setTaxSummary] = useState([]);
  const [setting, setSetting] = useState({});
  const [printParams, setPrintParams] = useState([]);

  /*
  var sum_amount = useRef(0.00);

  var total_receipt_ref = useRef(0.00);
  var amount_ref = useRef(0.00);

  
  total_receipt_ref.current = receiptList.length;
  amount_ref.current = 0.00;
  receiptList.map((receipt) => {
    console.log("Total Amount Ref :", amount_ref.current);
    amount_ref.current = parseFloat(amount_ref.current) + parseFloat(receipt.amount);
    })
  console.log("Total amount:", amount_ref.current);


  var customer_id_ref = useRef(0);
  var start_date_ref = useRef("");
  var end_date_ref = useRef("");
 */
  
  /*
  console.log("CALLED CURRENT PAGE : ", window.location.href);

    console.log("CALLED window locaion : ", window.location);
    let params = (new URL(window.location)).searchParams;
    console.log("COMPLETE PARAMS : ", params);
    let orderIdParam = params.get("order_id");
    console.log("ORDER ID FROM PARAM : ", orderIdParam);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.indexOf('?') + 1);
    var urlTmp = urlStr.substring(0, urlStr.indexOf("?"));

    console.log("URL : ", urlTmp);
    console.log("URL : ", paramsTmp);

    let searchParams = new URLSearchParams(paramsTmp);
    var customerIdTemp2 = searchParams.get("customer_id");
    console.log("CUSTOMED ID : ", customerIdTemp2);
    var productIdTemp2 = searchParams.get("product_id");
    console.log("PRODUCT ID : ", productIdTemp2);
    var startDateStr = searchParams.get("start_date");
    console.log("START DATE : ", startDateStr);
    var endDateStr = searchParams.get("end_date");
    console.log("END DATE : ", endDateStr);

    console.log("Extracted CUSTOMER ID  : ", customerIdTemp2);
    console.log("Extracted PRODUCT ID : ", productIdTemp2);
   */

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  const formateTime = (currTime) => {
    var h = (currTime.getHours() < 10 ? "0" : "") + currTime.getHours();
    var m = (currTime.getMinutes() < 10 ? "0" : "") + currTime.getMinutes();
    var hrMin = h + ":" + m;
    return hrMin;
  };

  const formatPrintDate = (currTime) => {
    var dateComponent = [
      padTo2Digits(currTime.getDate()),
      padTo2Digits(currTime.getMonth() + 1),
      currTime.getFullYear(),
    ].join("-");

    var timeComponent = [
      padTo2Digits(currTime.getHours() % 12 || 12 ),
      padTo2Digits(currTime.getMinutes())
    ].join(":");

    const ampm = currTime.getHours() >= 12 ? 'PM' : 'AM';

    return dateComponent + " " + timeComponent + " " + ampm;
  }
  /*
  const getAllSummaryList = async () => {
    var retState = false;
    var deliverySummaryList = [];
    try {
      
      var res = await axios.get(client.domain + `/getDeliverySummaryList?customer_id=${customerIdTemp2}&start_date=${startDateStr}&end_date=${endDateStr}&product_id=${productIdTemp2}`, { withCredentials: false });
      console.log("Response from server getAllSummaryList : ", res.data.detail);
      if (res.data.detail.status === 'SUCCESS') {
        console.log("Response message from server SummaryList : ", res.data.detail.data);
        retState = true;
        if (res.data.detail.data.length > 0) {
          deliverySummaryList = res.data.detail.data; 
        }
        
      }
    }
    catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === 'ERR_NETWORK') {
        console.log("Not able to reach target server please try after sometime");
      }
      else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === 'FAILED') {
          console.log('Request failed due to ', error.response.data.detail.res_data);
         
        }
      }
    }
    return [retState, deliverySummaryList];
  }
  
    */

  const getReceiptPrintList = async (bid_pass, brid_pass,date_from_pass, date_to_pass) => {
    var retStateLoad = false;
    var headerRet = [];
    var titleRet = [];
    var taxRet = [];
    var summaryRet = [];
    var settingRet = [];
    var  paramsRet = [];
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      //console.log("Selected Customer :", customerIdSelect);
      var reqParams = {
        bid: bid_pass,
        brid: brid_pass,
        date_from: date_from_pass,
        date_to: date_to_pass

      };
      console.log("REQUEST PARAMS: ", reqParams );
      var res = await axios.post(client.domain + "/bms/getTaxSummaryListPrint", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server Receipt : ",res.data.detail.data );
        setReportHeader(res.data.detail.data.report_header);
        setReportTitle(res.data.detail.data.report_title);
        setTaxList(res.data.detail.data.tax_details);
        setTaxSummary(res.data.detail.data.tax_total[0]);
        setSetting(res.data.detail.data.report_print_setting);
        setPrintParams(res.data.detail.data.report_print_params);
        //setCustomerListFull(res.data.detail.data);
        //setShowMsgTitle("Customer Info Added Successfully");
        //setToRender(true);
        headerRet = res.data.detail.data.report_header;
        titleRet = res.data.detail.data.report_tile;
        taxRet = res.data.detail.data.tax_details;
        summaryRet = res.data.detail.data.tax_total;
        settingRet = res.data.detail.data.report_print_setting;
        paramsRet = res.data.detail.data.report_print_params;
        retStateLoad = true;
        //navigate("/dashboard");
        //setDcReportData(res.data.detail.res_data);
        //setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log( "Not able to reach target server please try after sometime");
        //setShowMsg("Please try after sometime");
        //setShowMsgTitle("Network Issue!");
        //setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log( "Request failed due to ",error.response.data.detail.res_data);
          //setShowMsg("Please try again ...");
          //setShowMsgTitle("Unable to process your request!");
          //setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          //setShowMsg(error.response.data.detail);
          //setShowMsgTitle("Unable to process your request at this moment!");
          //setShow(true);
        }
      }
    }
    return [retStateLoad, taxRet, summaryRet, headerRet, titleRet, settingRet, paramsRet];
  };
  
  useEffect(() => {
    console.log("useEffect method called ...");
    //setToRender(true); 
    //getCustomerList();
    console.log("CALLED CURRENT PAGE : ", window.location.href);

    console.log("CALLED window locaion : ", window.location);
    let params = (new URL(window.location)).searchParams;
    console.log("COMPLETE PARAMS : ", params);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.indexOf('?') + 1);
    var urlTmp = urlStr.substring(0, urlStr.indexOf("?"));

    console.log("URL : ", urlTmp);
    console.log("URL : ", paramsTmp);

    let searchParams = new URLSearchParams(paramsTmp);
    console.log("SEARCH PARAMS :", searchParams );
    var bid = searchParams.get("bid");
    console.log("^^^^^^^^^^^^^^^^^^^ BUSINESS ID : ", bid);
    
    var brid = searchParams.get("brid");
    console.log("^^^^^^^^^^^^^^^^^^^ BUSINESS BRANCH0 ID : ", brid);

    var date_from = searchParams.get("date_from");
    console.log("^^^^^^^^^^^^^^^^^^^ Date From : ", date_from);
    
    var date_to = searchParams.get("date_to");
    console.log("^^^^^^^^^^^^^^^^^^^ Date To : ", date_to);
    (async () => {
      var [retStateLoad, receiptRet, summaryRet, headerRet, titleRet, settingRet, paramsRet] = await getReceiptPrintList(bid, brid,date_from, date_to);
      setToRender(true);
    })();
  
    
  }, [])
  

  return (
    <PDFViewer style={styles.viewer}>
      {toRender === true &&
        <Document>
          <Page size="A4" orientation="landscape" style={styles.page} wrap={true}>
            
              <View style={{ flexDirection: 'row', borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR }}>
              {setting.is_print_logo === true &&
                <View style={{ textAlign: 'right', width: '15%' }}>
                  <Image style={{ marginHorizontal: '10%', width: 'auto', height: 'auto', height: "64px", width: "64px" }} src={business_logo} />
                </View>
              }
                <View style={{ width: "85%", alignItems: "center", /*paddingRight: "30px",*/ /*borderBottomWidth: 1,*/ borderBottomColor: BORDER_COLOR/*,marginTop: 1, marginBottom: 10, borderWidth: 1*/ }} key="address-key">
                  <Text style={{ fontSize: "20px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>{reportHeader.business_name}</Text>
                  {/*<Text>{reportHeader.business_taxdetails}</Text>*/}
                  <Text>{reportHeader.ho_address}</Text>
                  {setting.is_print_branch_address === true &&
                    <Text>{reportHeader.bo_address}</Text>
                  }
                  <hr style={{paddingBottom: "5px"}}/>
                </View>  
              </View>
            
           
            
            <View style={{ width: "100%", alignItems: "center",/* paddingLeft: "20px",*/ textAlign: 'center', borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR, paddingTop: "5px" }}>
              <Text style={{ fontSize: "10px", textAlign: 'center', alignContent: 'center', fontFamily: 'Helvetica-Bold', color: HEADING_COLOR }}>{reportTitle.rpt_title}</Text>
            </View>
            {/*
            <hr style={{paddingBottom: "5px"}}/>
            <View style={{flexDirection: "row",borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR, paddingBottom: "5px"}}>
            <View style={{ width: "7%"}}>
                <Text style={{fontFamily: 'Helvetica-Bold'}}>Summary :-  </Text>
                </View>

                <View style={{flexDirection: "row", width: "30%", textAlign: "right"}}>
                <Text style={{fontFamily: 'Helvetica-Bold'}}>Total Tax Amount(Sales) :  </Text>
                <Text> {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(taxSummary.sales_tax).toFixed(2)))}</Text>
                </View>

                <View style={{flexDirection: "row",  width: "30%", textAlign: "right"}}>
                <Text style={{fontFamily: 'Helvetica-Bold'}}>Total Tax Amount ITC (Purchase) :  </Text>
                <Text> {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(taxSummary.purchase_tax_itc).toFixed(2)))}</Text>
                </View>

                <View style={{flexDirection: "row",  width: "30%", textAlign: "right"}}>
                <Text style={{fontFamily: 'Helvetica-Bold'}}>Total Net Tax Diff  :  </Text>
                <Text> {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(taxSummary.tax_net_diff).toFixed(2)))}</Text>
                </View>
            </View>
            */}
            <View style={{...styles.row, paddingTop: TEXT_ALIGN}} key="items-${idx}">
                    <Text style={styles.sno2Data}></Text>
                    <Text style={{...styles.tax2Data}}></Text>
                    <Text style={{...styles.tax_per2Data}}></Text>
                    <Text style={{...styles.sales2Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Total :</Text>
                    <Text style={{...styles.sales_amount2Data}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(taxSummary.sales_tax).toFixed(2)))}</Text>
                    <Text style={{...styles.purchase2Data}}></Text>
                    <Text style={{...styles.purchase_amount2Data}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(taxSummary.purchase_tax_itc).toFixed(2)))}</Text>
                    <Text style={{...styles.net2Data}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(taxSummary.tax_net_diff).toFixed(2)))}</Text>
                    
              </View>
           
            <hr style={{paddingBottom: "2px"}}/>

            <View style={styles.tableContainer}>
            
            <View fixed style={styles.container}>
                <Text style={styles.sno}>S.No</Text>
                <Text style={styles.tax}>Tax</Text>
                <Text style={styles.tax_per}>Tax %</Text>
                <Text style={styles.sales}>Taxable Sales</Text>
                <Text style={styles.sales_amount}>Tax Amount(sales)</Text>
                <Text style={styles.purchase}>Taxable Purchase</Text>
                <Text style={styles.purchase_amount}>Tax Amount ITC(Purchase)</Text>
                <Text style={styles.net}>Net Tax Diff.</Text>
                
              </View>

              <View style={{flexGrow: 1}}>
              {taxList.map((tax, idx) => (
              <View style={styles.row} key="items-${idx}">
                    <Text break style={{...styles.snoData}}>{idx + 1}</Text>
                    <Text style={{...styles.taxData}}>{tax.tax}</Text>
                    <Text style={{...styles.tax_perData}}>{tax.tax_per}</Text>
                    <Text style={{...styles.salesData}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(tax.sales_amt).toFixed(2)))}</Text>
                    <Text style={{...styles.sales_amountData}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(tax.sales_tax).toFixed(2)))}</Text>
                    <Text style={{...styles.purchaseData}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(tax.purchase_amt).toFixed(2)))}</Text>
                    <Text style={{...styles.purchase_amountData}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(tax.purchase_tax_itc).toFixed(2)))}</Text>
                    <Text style={{...styles.netData}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(tax.tax_net_diff).toFixed(2)))}</Text>
                    
              </View>
               ))}
              </View>
              
              </View>
              <View style={{...styles.row, borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR}} key="items-${idx}">
                    <Text style={styles.sno1Data}>  </Text>
                    <Text style={{...styles.tax1Data}}></Text>
                    <Text style={{...styles.tax_per1Data}}></Text>
                    <Text style={{...styles.sales1Data, fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}></Text>
                    <Text style={{...styles.sales_amount1Data}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(taxSummary.sales_tax).toFixed(2)))}</Text>
                    <Text style={{...styles.purchase1Data}}></Text>
                    <Text style={{...styles.purchase_amount1Data}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(taxSummary.purchase_tax_itc).toFixed(2)))}</Text>
                    <Text style={{...styles.net1Data}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(taxSummary.tax_net_diff).toFixed(2)))}</Text>
                    
              </View>
             
              {/*
              <View style={{ width: "50%", alignItems: "right", textAlign: 'right', borderBottomWidth: 1, borderBottomColor: '#a7a5a5', paddingTop: "5px" }}>
              <Text style={{ fontSize: "12px", textAlign: 'right', alignContent: 'right', fontFamily: 'Helvetica-Bold', color: '#646363' }}>OUTSTANDING STATEMENT</Text>
            </View>
            */}
           
            {/*
            <View style={{width: '20%', alignContent: 'right'}}>
            <Text style={{ fontSize: "12px", fontFamily: 'Helvetica-Bold', color: '#646363' }}>Outstanding Statement</Text>
            
            <View style={{flexDirection: 'row', alignContent: 'right', alignItems: 'right', justifyContent: 'front-end !important'}}><Text style={{ fontSize: "12px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold',alignContent: 'right', alignItems: 'right',}}>Previous Balance: </Text> <Text style={{fontSize: "12px", textAlign: 'right !important', alignContent: 'right !important', fontFamily: 'Helvetica'}}>1000.00</Text></View>
            <View style={{flexDirection: 'row'}}><Text style={{ fontSize: "12px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold',alignContent: 'right', alignItems: 'right',}}>Previous Balance: </Text> <Text style={{fontSize: "12px", textAlign: 'right !important', alignContent: 'right !important', fontFamily: 'Helvetica'}}>10000.00</Text></View>
            
            <Text style={{ fontSize: "10px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}> Total Received <Text style={{fontSize: "12px", textAlign: 'right', alignContent: 'right', fontFamily: 'Helvetica'}}>: 1000.00</Text></Text>
            <View style={{flexDirection: 'row'}}><Text style={{ fontSize: "10px", paddingBottom: "5px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold',paddingLeft: "7px",}}>Current Balance</Text> <Text style={{fontSize: "12px", textAlign: 'right', alignContent: 'right', fontFamily: 'Helvetica'}}>: 3000.00</Text></View>
            <View style={{flexDirection: 'row'}}><Text style={{ fontSize: "10px", alignContent: 'left', paddingBottom: "5px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold',paddingLeft: "21px"}}>Current Balance</Text> <Text style={{fontSize: "12px", fontFamily: 'Helvetica'}}>: 5000.00</Text></View>
            <View style={{flexDirection: 'row'}}><Text style={{ fontSize: "10px", alignContent: 'left', paddingBottom: "5px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold',paddingLeft: "21px"}}>Total Balance</Text> <Text style={{fontSize: "12px", fontFamily: 'Helvetica', alignContent: 'right !important', alignItems: 'right !important', textAlign: 'right !important', alignSelf: 'flex-end !important'}}>: 60000.00</Text></View>
          
            </View>
            */}

            
           
            <hr style={{paddingBottom: "20px"}}/>
              
            <View fixed style={{flexDirection: 'row'}}>
        
        {setting.is_print_datetime === true &&
          <Text style={{textAlign: 'left', flex: 1, paddingTop: '15px'}} render={({ pageNumber, totalPages }) => (`Printed On: ${formatPrintDate(new Date())}`)} fixed />
        }

          
        {setting.is_print_endofrpttext === true &&
          <Text style={{ textAlign: 'center', paddingTop: '15px', flex: 1}} render={({ pageNumber, totalPages }) => (
            <View> {pageNumber === totalPages &&
            <Text>.... End of the document ....</Text>
             }
            </View>
            )}  />
          }
 
          {setting.is_print_pageno === true &&
          <Text style={{flex: 1, textAlign: 'right', paddingTop: '15px'}} render={({ pageNumber, totalPages }) => (
            `Page No : ${pageNumber} / ${totalPages}`
          )} fixed />
           }
          
          </View>
          </Page>
        </Document>
      }
    </PDFViewer>
  );
}

export default PrintTaxSummary;