import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Table,
  Spinner,
  Image
} from "react-bootstrap";
import { withRouter } from "react-router";
//import Sidebar from './Sidebar';
//import Sidebar2 from "./Sidebar2";
//import Footer from "./Footer";
//import Topmenu from "./Topmenu";
//import OrderDetails from "./OrderDetails";
import "../Dashboard.css";

//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";

import { useNavigate } from "react-router-dom";

//import * as Icon from "react-bootstrap-icons";
import axios from "axios";
import Select from "react-select";
import spinner_logo from '../assets/images/spinner_logo.png';
import CsvGenerate from "../pages/CsvGenerate";
import { client, OS_INVOICE_FOR_NDAYS } from '../config/Config';



import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsArrowLeftCircle,
  BsCardText,
  BsFillPrinterFill
} from "react-icons/bs";

import { AiOutlineOrderedList } from "react-icons/ai";

//import MenuOms from "./MenuOms";

import rmcBlueIcon from "../assets/images/ready_mix_blue.png";
import SidebarMain from "./SidebarMain";
import { CSVLink, CSVDownload } from "react-csv";

import { getDistrictList, getStateList, getProductApiCall, getProductBuy, getProductsSell, searchAllUniqLocations, formatDateIndian } from "./utils";

const SNO_WIDTH_PERC = 4;
const INV_DATE_WIDTH_PERC = 10;
const INV_NO_WIDTH_PERC = 11;
const CUSTOMER_WIDTH_PERC = 22;
const GSTIN_WIDTH_PERC = 10;
const SUB_TOT_WIDTH_PERC = 8;
const CGST_WIDTH_PERC = 8;
const SGST_WIDTH_PERC = 8;
const IGST_WIDTH_PERC = 8;
const TOT_AMT_WIDTH_PERC = 9;
const DETAILS_WIDTH_PERC = 2;


const SNO_WIDTH_PERC_TOTAL = 14;
const INV_DATE_WIDTH_PERC_TOTAL = 0;
const INV_NO_WIDTH_PERC_TOTAL = 11;
const CUSTOMER_WIDTH_PERC_TOTAL = 22;
const GSTIN_WIDTH_PERC_TOTAL = 10;
const SUB_TOT_WIDTH_PERC_TOTAL = 8;
const CGST_WIDTH_PERC_TOTAL = 8;
const SGST_WIDTH_PERC_TOTAL = 8;
const IGST_WIDTH_PERC_TOTAL = 8;
const TOT_AMT_WIDTH_PERC_TOTAL = 9;
const DETAILS_WIDTH_PERC_TOTAL = 2;
const ListInvoices = () => {
  /*
  csvData={customers}
            fileName={"cust-Inv_2024_01_17_11_34_55_88899.csv"}
            wscols={wscols}
  */
  const initialized = useRef(false);

  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);

  let invArr = [

    {

      inv_date: "02-03-2024",
      inv_number: "INV001",
      customer_name: "Ram",
      total_amount: "7896",

    },
    {

      inv_date: "07-08-2024",
      inv_number: "INV002",
      customer_name: "Ajay",
      total_amount: "6764",

    },
    {

      inv_date: "05-05-2024",
      inv_number: "INV003",
      customer_name: "Vishal",
      total_amount: "78654",

    },
  ];



  const [invList, setInvList] = useState([]);
  const [dcListFull, setDcListFull] = useState([]);
  const [invoiceExcelList, setInvoiceExcelList] = useState([]);
  //const [orderList, setOrderList] = useState(orderListLoad);
  //const [orderListFull, setOrderListFull] = useState([]);
  //const [orderListFull, setOrderListFull] = useState([]);
  const [orderDcFetched, setOrderDcFetched] = useState(false);


  var total_invoice_ref = useRef(0.00);
  var total_amount_ref = useRef(0.00);


  total_invoice_ref.current = invList.length;
  total_amount_ref.current = 0.00;
  invList.map((invInd) => {
    console.log("Total Amount Ref :", total_amount_ref.current);
    total_amount_ref.current = parseFloat(total_amount_ref.current) + parseFloat(invInd.total_amount);
  })
  console.log("Total amount:", total_amount_ref.current);
  function padTo2Digits(num) {
    //console.log("<<<<<<<<<<<<<< given number >>>>>>>>>>>>>>>> : ", num);
    var modNum = num.toString().padStart(2, "0");
    console.log("Modified  value : ", modNum);
    return modNum;
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  //var lastDay = new Date(date.getFullYear(), date.getMonth() + 1,
  const intialValues = {
    date_from: formatDate(new Date(firstDay)),
    //date_from: formatDate(new Date(new Date().setDate(new Date().getDate() - OS_INVOICE_FOR_NDAYS))),
    date_to: formatDate(new Date()),
    //date_from: formatDate(new Date()),
    gst_number: "",

  }
  /*
  let customerArr = [
  
    { value: 1, label: "All Customers" },
    { value: 2, label: "Deppam Motors" },
    { value: 3, label: "ABC Pumps" },
    { value: 4, label: "ADP Compressors" },
  ];
  */



  const [deliveryChellan, setDeliveryChellan] = useState([]);
  const [prodList, setProdList] = useState([]);
  const [toRender, setToRender] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [customerListFull, setCustomerListFull] = useState([]);
  const [customerIdSelect, setCustomerIdSelect] = useState({});
  const [optCustomer, setOptCustomer] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  ;
  const [formValues, setFormValues] = useState(intialValues);
  const [dcList, setDcList] = useState([]);
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("Please login again");
  const [showMsgTitle, setShowMsgTitle] = useState("Invalid authentication Session!");

  const currSelBusinessRef = useRef(0);
  const currBranchRef = useRef(0);



  const [optLocations, setOptLocations] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState({});
  const [invSummary, setInvSummary] = useState([])

  const [exportInvList, setExportInvList] = useState([]);
  const navigate = useNavigate();

  const handleAdd = (e) => {
    e.preventDefault();
    console.log("Add or Update Order button clicked ...", e);
    navigate(`/addUpdateInvoiceMfgT1`, {
      state: { passed_element: [], action: "add" },
    });
  };




  const handleView = (e, data) => {
    console.log("Handle action edit Clicked : ", e);
    //console.log("Handle Clicked edit DATA : ", data);
    console.log("Handle action edit Clicked target : ", e.target);
    console.log("Handle action edit Clicked target Current : ", e.currentTarget);
    console.log("Handle action edit Clicked target Current id : ", e.currentTarget.id);
    console.log("Handle action edit Clicked target ID : ", e.target.id);
    //console.log("Handle action edit Clicked target NAME : ", e.target.name);
    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(0, clickedTagId.lastIndexOf("-"));
      console.log("current selected element name : ", nameOfElement);
      console.log("Current SELECTED id : ", curSelId);
      console.log("CUR SELECTED VALUE : ", invList[curSelId]);

      //navigate(`/${client.urlDelimiter}orderDetails`, {state : orderList[curSelId]});
      navigate(`/addUpdateInvoiceMfgT1`, {
        state: { passed_element: invList[curSelId], action: "edit" },
      });
    }
  };

  const CallCustomerData = async () => {
    var retStateLoad = false;
    var customerRet = [];

    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }

      var reqParams = {
        bid: currSelBusinessRef.current
      };

      var res = await axios.post(client.domain + "/bms/getCustInvSearchPreLoadData", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.data.customers);
        var customerList = [];

        //customerList.push({label: "All Customers", value: 0});
        res.data.detail.data.customers.map((customer) => {
          customerList.push(customer);
        })
        setOptCustomer(customerList);
        //setCustomerIdSelect(customerList[0]);
        //setCustomerIdSelect(res.data.detail.data.customers[0]);
        /*
          var findDefault = res.data.detail.data.customers.find(element => element.is_default === true);
          if (findDefault !== undefined) {
            setCustomerIdSelect(findDefault);  
          };
          */

        setToRender(true);
        customerRet = customerList;
        retStateLoad = true;


      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
    return [retStateLoad, customerRet];
  };




  /*
    const getCustInvoiceList = async() => {
      try {
        var aIVal = localStorage.getItem("_aI");
        console.log("Authorization token : ", aIVal);
  
        var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
  
        console.log("Headers : ", headers);
  
        console.log("++++++++++++++++++++ CUSTOMER ID SELECT ----------------: ", customerIdSelect);
  
        
        var res = await axios.get(client.domain + `/getAllOrders?customer_id=${customerIdSelect.value}&start_date=${formValues["start_date"]}&end_date=${formValues["end_date"]}`, {headers}, {
          withCredentials: false,
        });
        //.then((res) => {
        //console.log("ADD New user res:", res);
        //localStorage.setItem('_token', res.data.res_data.token);
        console.log("Response from server getOrdersList : ", res.data.detail);
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server getDistrictList : ", res.data.detail.res_data);
          //setOrderList(res.data.detail.data);
          //setOrderListFull(res.data.detail.data);
  
          if (res.data.detail.res_data.order_list.length > 0) {
            setInvList(res.data.detail.res_data.order_list);
            //setOrderListFull(res.data.detail.res_data.order_list)
          }
          setToRender(true);
  
         
  
          var invoiceExcelArr = [];
          
              res.data.detail.res_data.order_list.map((invoice) => {
  
                var invoiceInd = {
                  
                  "Date": formatDateIndian(new Date(invoice.order_date)),
                  "Inv.No": invoice.order_no,
                  "Customer": invoice.customer_name,
                  //location : invoice.address_line1,
                  //"Address": invoice.address_line1,
                  //"State": invoice.state_name,
                  //"State Code": invoice.gst_number ? invoice.gst_number.substring(0, 2) : "",
                  //"GST IN": invoice.gst_number,
                  //"Sub Total": invoice.amount_basic.toFixed(2),                
                  //"CGST": invoice.cgst.toFixed(2),
                  //"SGST": invoice.sgst.toFixed(2),
                  //igst: invoice.igst, 
                  "Amount": invoice.amount_total.toFixed(2)
                }
                invoiceExcelArr.push(invoiceInd); 
              //setInvoiceExcelList(res.data.detail.res_data.order_list)
          })
          
          console.log("******EXCEL LIST:", invoiceExcelArr);
          setExportInvList(invoiceExcelArr);
          /*
          let invoiceExcelList = {
            inv_no: formValues.inv_no,
            inv_date: formValues.inv_date,
            gst: formValues.gst,
            invoice_array: invoiceExcelArr
            
            total_received: receipt_total_sum.current,
            current_balance: total_amount_sum.current,
            total_balance: total_balance_os.current,
            summary_array: summaryUpdateArr
            */


  /*
  res.data.detail.data.map((itemCustomer, idx) => {
    console.log("Ind val : ", itemCustomer);
    var customerInd = {
      value: itemCustomer.id,
      label: itemCustomer.name,
    };
    console.log("In customer modified : ", customerInd);
    customerArr.push(customerInd);
  });
  * /
}
 
} catch (error) {
console.log("FULL ERROR : ", error);
if (error.code === "ERR_NETWORK") {
  console.log("Not able to reach target server please try after sometime");
setToRender(true);
} else {
  console.log("ERROR : ", error.response.data.detail);
  if (error.response.data.detail.status === "FAILED") {
    console.log("Request failed due to ",error.response.data.detail.res_data);
  }
}
}
setToRender(true);
};
*/

  const getInvoiceList = async (customer_id_pass) => {
    var retStateLoad = false;
    var invoiceRet = [];
    var summaryRet = [];
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
      console.log("Selected Customer :", customerIdSelect);
      var reqParams = {
        bid: currSelBusinessRef.current,
        brid: currBranchRef.current || 0,
        customer_id: customer_id_pass,
        date_from: formValues["date_from"],
        date_to: formValues["date_to"]

      };
      console.log("REQUEST PARAMS: ", reqParams);
      var res = await axios.post(client.domain + "/bms/getCustInvList", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server Invoice : ", res.data.detail.data);
        setInvList(res.data.detail.data.Invoices);
        setInvSummary(res.data.detail.data.InvoiceSummary[0]);

        //setCustomerListFull(res.data.detail.data);
        //setShowMsgTitle("Customer Info Added Successfully");
        setToRender(true);
        /*
        var checkEmpty = false;
          var emptyCnt = 0;
          res.data.detail.data.Invoices.map((invoice)=> {
          })
          if ( emptyCnt < res.data.detail.data.customer_ledger.length ) {
            invoiceRet = res.data.detail.data.Invoices;
            //summaryRet = res.data.detail.data.InvoiceSummary
            setInvList(res.data.detail.data.Invoices);            
          }
          else {
            setInvList([]);
          }
          */
        invoiceRet = res.data.detail.data.Invoices;
        summaryRet = res.data.detail.data.InvoiceSummary
        retStateLoad = true;
        var invoiceExcelArr = [];

        res.data.detail.data.Invoices.map((invoice) => {

          var invoiceInd = {
            "Inv No": invoice.inv_no,
            "Inv.Date": formatDateIndian(new Date(invoice.inv_date)),
            "Customer": invoice.customer_name,
            "GSTIN": invoice.gstin,
            //"PAN" : invoice.pan,
            "Sub Total": invoice.sub_total_amt,
            "CGST": invoice.cgst_amt,
            "SGST": invoice.sgst_amt,
            "IGST": invoice.igst_amt,
            "Inv Total Amount": invoice.inv_total_amt
            //"State": invoice.state_name,
            //"State Code": invoice.gst_number ? invoice.gst_number.substring(0, 2) : "",


            //igst: invoice.igst, 

          }
          invoiceExcelArr.push(invoiceInd);
          //setInvoiceExcelList(res.data.detail.res_data.order_list)

        })
        console.log("******EXCEL LIST:", invoiceExcelArr);
        setExportInvList(invoiceExcelArr);
        //navigate("/dashboard");
        //setDcReportData(res.data.detail.res_data);
        //setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
    return [retStateLoad, invoiceRet, summaryRet];
  };

  /*
  const modifiedCsvData = invoiceInd.map((Invoice, index) => {
    if (Invoice[1]) {
      // Add custom styling (e.g., background color) to this row
      return Invoice.map(cell => ({ value: cell, style: { background: "#ec8e8e" } }));
    }
    return Invoice;
  });
  */

  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect called ##########################");
      //setCustomerIdSelect(customerArr[0]);
      //getInvoiceList();
      //CallCustomerData();

      //setToRender(true);
      (async () => {
        var [retStateLoad, customerRet] = await CallCustomerData();
        if (retStateLoad) {
          var findDefault = customerRet.find(element => element.is_default === true);
          if (findDefault !== undefined) {
            setCustomerIdSelect(findDefault);

          }
          console.log(" Customer :", customerRet);

          //setCustomerIdSelect(customerRet[0]);
          console.log("<<<<<<<<<<<<<<<<<< CURRENT BRANCH )))))))))))))))))) : ", currBranchRef.current);
          var [retStateLoad, invoiceRet, summaryRet] = await getInvoiceList(findDefault.value);
          setToRender(true);



        }
        /*
        if ( retState ) {
          setOptCustomer(customerArr);
          setCustomerIdSelect(customerArr[0]);
        }
        */


      })();


      //setProductInfo(product_info);
      //getOrderList();


      //setToRender(true);
      initialized.current = true;
    }
  }, []);

  const handleBack = async (e) => {
    console.log("handle back called : ", e);
    navigate(-1);
  };


  const onChangeStartDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);
    setFormValues({ ...formValues, date_from: e.target.value });
    /*
    var date_to = new Date();
    date_to.setHours(0,0,0,0);
    var selectedDate = new Date(e.target.value);

    if ( selectedDate <= date_to ) {
      console.log("Date selected:", selectedDate);
      setFormValues({ ...formValues, date_from: e.target.value });
      setFormErrors({ ...formErrors, "start_date_selection_error": ""});
    }
    else {
      setFormValues({ ...formValues, date_from: e.target.value });
      setFormErrors({ ...formErrors, "start_date_selection_error": "Please select Past date ..."});
    }
    */
  };

  const onChangeEndDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);
    setFormValues({ ...formValues, date_to: e.target.value });
    /*
    var date_from = new Date();
    date_from.setHours(0,0,0,0);
    var selectedDate = new Date(e.target.value);

    if ( selectedDate >= date_from ) {
      console.log("Date selected:", selectedDate);
      setFormValues({ ...formValues, date_to: e.target.value });
      setFormErrors({ ...formErrors, "end_date_selection_error": ""});
    }
    else {
      setFormValues({ ...formValues, date_to: e.target.value });
      setFormErrors({ ...formErrors, "end_date_selection_error": "Please select future date ..."});
    }
    */
  };


  const handleDateSearch = async () => {
    console.log("handleDateSearch called ...");
    await getInvoiceList(customerIdSelect.value);

  };
  const handleCustomerSelect = (e, data) => {
    console.log("handleCustomerSelect called ...", e);
    setCustomerIdSelect(e);
  };
  const handleInvoicePrint = async (e) => {
    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));
    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);
    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printListInvoices?bid=${currSelBusinessRef.current}&brid=${currBranchRef.current}&customer_id=${customerIdSelect.value}&date_from=${formValues["date_from"]}&date_to=${formValues["date_to"]}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0, 0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
  };

  const sendDataToParent = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if (passedBackValue.hasOwnProperty("business_id")) {
      currSelBusinessRef.current = passedBackValue["business_id"];
      //navigate(0);
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);

    //getBranchList();
  }

  console.log("PERMISSIONS SS S S S S SSSSS : ", menuSettings.permissions);
  console.log("CONDITION CHECKJJ : ", invList.length);
  console.log("PERMISSIONS SS S  S S S S: ", menuSettings.permissions.find(element => (element.code === 'listInvoices')).a_print_list === true);


  return (
    <Container>
      <SidebarMain sendBacktoParent={sendDataToParent} />
      {toRender ?
        <form>
          <Row>

            <Col xs={8} md={8} sm={8} lg={8}>
              <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem" }}>{menuSettings.permissions.find(element => (element.code === 'listInvoices' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'listInvoices')).page_title_list : "Invoices"}</span>
            </Col>

            <Col xs={4} md={4} sm={4} lg={4} className="d-flex justify-content-end float-end">
              <CsvGenerate dataPass={exportInvList} />
              &nbsp;&nbsp;
              <Button id="invoicePrint" name="invoicePrint" onClick={(e) => handleInvoicePrint(e)}
              disabled={(menuSettings.permissions.find(element => (element.code === 'listInvoices')).a_print_list === true && invList.length > 0) ? false : true}
              >
                <BsFillPrinterFill
                  size={18}
                  style={{ color: "white", cursor: "pointer" }}
                  name={`print-invoice-icon`}
                  id={`print-invoice-icon`}
                  //disabled={(menuSettings.permissions.find(element => (element.code === 'listInvoices')).a_print_list === true && invList.length > 0) ? false : true}
                  
                />
              </Button>
              &nbsp;&nbsp;
              {menuSettings.permissions.find(element => (element.code === 'listInvoices' && element.a_add === true)) &&
                <Button
                  variant="warning"
                  id="add-btn-id"
                  name="add-btn-name"
                  className=" float-end"
                  onClick={(e) => handleAdd(e)}
                >
                  New Invoice
                </Button>
              }
            </Col>

          </Row>
          <hr align="center" />
          <Row>
            <Col
              xs={3}
              md={3}
              sm={3}
              lg={3}>

              <Select
                inline
                id="customer"
                name="customer"
                value={{
                  value: customerIdSelect.value,
                  label: customerIdSelect.label,
                }}
                options={optCustomer}
                defaultValue={{
                  value: customerIdSelect.value,
                  label: customerIdSelect.label,
                }}
                onChange={handleCustomerSelect}
              //isDisabled={isReadOnly}
              />
            </Col>



            <Col xs={5} md={5} sm={5} lg={5}>
              <Row>
                <Col>
                  <Form.Control
                    type="date"
                    name="date_from"
                    placeholder="Start Date"
                    value={formValues.date_from}
                    onChange={onChangeStartDatePicker}
                  //readOnly={isReadOnly}

                  />
                  {/*<p className="error-msg">{formErrors.start_date_selection_error}</p>*/}
                </Col>
                <Col>
                  <Form.Label style={{ textAlign: "center", paddingTop: "5px" }}>To</Form.Label>
                </Col>

                <Col>

                  <Form.Control
                    type="date"
                    name="date_to"
                    placeholder="End Date"
                    value={formValues.date_to}
                    onChange={onChangeEndDatePicker}
                  />
                  {/*<p className="error-msg">{formErrors.end_date_selection_error}</p>*/}
                </Col>
                <Col>
                  <Button id="search-order-id"
                    size={"md"}
                    variant="success"
                    onClick={(e) => handleDateSearch(e)}>
                    Search
                  </Button>
                </Col>
              </Row>
            </Col>

            {/*
                  <Col xs={4} md={4} sm={4} lg={4} style={{paddingLeft: "70px"}}>
                    <Form.Label style={{fontWeight: 'bold'}}>
                        Total Invoices :
                      </Form.Label>
                        <Form.Label style={{fontWeight: 'bold', paddingTop: "8px"}}>
                          3
                      </Form.Label>
                       </Col>
                  */}


          </Row>

          <hr align="center" />
          {/* 
          <Row>

            <Col xs={12} md={12} sm={12} lg={12} style={{ textAlign: "right" }}>

              <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                Total Invoices :
              </Form.Label>
              <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
                {invSummary.inv_count}
              </Form.Label>

              <span style={{ color: '#C0C0C0' }}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>

              <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                Total Amount :
              </Form.Label>
              <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
                {invSummary.sub_total_amt > 0.01 ?
                  <>
                    {((new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invSummary.sub_total_amt).toFixed(2))))}
                  </>
                  : (0.00).toFixed(2)}
              </Form.Label>
              <span style={{ color: '#C0C0C0' }}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>

              <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                Total CGST :
              </Form.Label>
              <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
                {invSummary.cgst_amt > 0.01 ?
                  <>
                    {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invSummary.cgst_amt).toFixed(2)))}
                  </>
                  : (0.00).toFixed(2)}
              </Form.Label>

              <span style={{ color: '#C0C0C0' }}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
              <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                Total SGST :
              </Form.Label>
              <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
                {invSummary.sgst_amt > 0.01 ?
                  <>
                    {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invSummary.sgst_amt).toFixed(2)))}
                  </>
                  : (0.00).toFixed(2)}
              </Form.Label>
              <span style={{ color: '#C0C0C0' }}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>

              <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                Total IGST :
              </Form.Label>
              <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
                {invSummary.igst_amt > 0.01 ?
                  <>
                    {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invSummary.igst_amt).toFixed(2)))}
                  </>
                  : (0.00).toFixed(2)}
              </Form.Label>

              <span style={{ color: '#C0C0C0' }}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
              
              <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                Invoice Total Amount :
              </Form.Label>
              <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
                {invSummary.inv_total_amt > 0.01 ?
                  <>
                    {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invSummary.inv_total_amt).toFixed(2)))}
                  </>
                  : (0.00).toFixed(2)}
              </Form.Label>
            </Col>
          </Row>
          */}
          <tr className="table-primary">
                  <th style={{ textAlign: "left", width: "25%"}}>Total Invoices : {invSummary.inv_count}</th>
                  <th  style={{ textAlign: "center", width: "0%" }}></th>
                  <th  style={{ textAlign: "center", width: "0%" }}></th>
                  <th  style={{ textAlign: "center", width: "18%" }}></th>
                  <th  style={{ textAlign: "center", width: "10%" }}>Total:</th>
                  <th  style={{ textAlign: "center", width: "9%" }}>{invSummary.sub_total_amt > 0.01 ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invSummary.sub_total_amt).toFixed(2)): (0.00).toFixed(2)}</th>
                  <th  style={{ textAlign: "center", width: "9%" }}>{invSummary.cgst_amt > 0.01 ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invSummary.cgst_amt).toFixed(2)): (0.00).toFixed(2)}</th>
                  <th  style={{ textAlign: "center", width: "9%" }}>{invSummary.sgst_amt > 0.01 ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invSummary.sgst_amt).toFixed(2)): (0.00).toFixed(2)}</th>
                  <th  style={{ textAlign: "center", width: "9%" }}>{invSummary.igst_amt > 0.01 ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invSummary.igst_amt).toFixed(2)): (0.00).toFixed(2)}</th>
                  <th  style={{ textAlign: "center", width: "9%" }}>{invSummary.inv_total_amt > 0.01 ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invSummary.inv_total_amt).toFixed(2)) : (0.00).toFixed(2)}</th>
                  <th  style={{ textAlign: "center", width: "2%" }}></th>
                </tr>
          {toRender &&
            <Table striped bordered hover size="sm">
              <thead>
                <tr className="table-primary">
                  <th className="table-row-heading" style={{ textAlign: "center", width: "5%" }} key={"order-list-heading-key"}>S.No</th>
                  <th className="table-row-heading" style={{ textAlign: "center", width: "10%" }}>Inv.Date</th>
                  <th className="table-row-heading" style={{ textAlign: "center", width: "10%" }}>Inv No</th>
                  <th className="table-row-heading" style={{ textAlign: "center", width: "18%" }}>Customer</th>
                  <th className="table-row-heading" style={{ textAlign: "center", width: "10%" }}>GSTIN</th>
                  <th className="table-row-heading" style={{ textAlign: "center", width: "9%" }}>Sub Total</th>
                  <th className="table-row-heading" style={{ textAlign: "center", width: "9%" }}>CGST</th>
                  <th className="table-row-heading" style={{ textAlign: "center", width: "9%" }}>SGST</th>
                  <th className="table-row-heading" style={{ textAlign: "center", width: "9%" }}>IGST</th>
                  {/*<th className="table-row-heading"  style={{ textAlign: "center", width: "10%" }}>CESS</th>*/}
                  <th className="table-row-heading" style={{ textAlign: "center", width: "9%" }}>Total Amount</th>
                  <th className="table-row-heading" style={{ textAlign: "center", width: "2%" }}>Details</th>
                </tr>
              </thead>

              <tbody>
                {invList.map((invInd, idx) => (
                  <tr key={`inv-table-row-key-${idx}`}>
                    <td style={{ textAlign: "right", width: "5%" }}
                      key={`inv-table-row-key-sno-${idx}`}
                      id={`inv-table-row-id-sno-${idx}`}
                      name={`inv-table-row-name-sno-${idx}`}
                    >
                      {idx + 1}
                    </td>


                    <td style={{ textAlign: "left", width: "10%"}}
                      key={`inv-table-row-key-date-${idx}`}
                      id={`inv-table-row-id-date-${idx}`}
                      name={`inv-table-row-name-date-${idx}`}
                    >
                      {/*{formatDateIndian(new Date(invInd.inv_date))}*/}
                      {invInd.inv_date}
                    </td>

                    <td style={{ textAlign: "right", width: "10%" }}
                      key={`inv-table-row-key-invno-${idx}`}
                      id={`inv-table-row-id-invno-${idx}`}
                      name={`inv-table-row-name-invno-${idx}`}
                    >
                      {invInd.inv_no}
                    </td>

                    <td
                      style={{ width: "22%", width: "18%" }}
                      key={`inv-table-row-key-customer-${idx}`}
                      id={`inv-table-row-id-customer-${idx}`}
                      name={`inv-table-row-name-customer-${idx}`}
                    >
                      {invInd.customer_name}
                    </td>
                    <td
                      style={{ width: "16%", width: "10%" }}
                      key={`inv-table-row-key-gst-${idx}`}
                      id={`inv-table-row-id-gst-${idx}`}
                      name={`inv-table-row-name-gst-${idx}`}
                    >
                      {invInd.gstin}
                    </td>



                    <td
                      style={{ padding: "5px", textAlign: "right", width: "9%" }}
                      key={`inv-table-row-key-subtotal_amount-${idx}`}
                      id={`inv-table-row-id-subtotal_amount-${idx}`}
                      name={`inv-table-row-name-subtotal_amount-${idx}`}
                    >
                      {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invInd.sub_total_amt).toFixed(2)))}
                    </td>

                    <td
                      style={{ padding: "5px", textAlign: "right", width: "9%" }}
                      key={`inv-table-row-key-cgst_amount-${idx}`}
                      id={`inv-table-row-id-cgst_amount-${idx}`}
                      name={`inv-table-row-name-cgst_amount-${idx}`}
                    >
                      {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invInd.cgst_amt).toFixed(2)))}
                    </td>

                    <td
                      style={{ padding: "5px", textAlign: "right", width: "9%" }}
                      key={`inv-table-row-key-sgst_amount-${idx}`}
                      id={`inv-table-row-id-sgst_amount-${idx}`}
                      name={`inv-table-row-name-sgst_amount-${idx}`}
                    >
                      {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invInd.sgst_amt).toFixed(2)))}
                    </td>

                    <td
                      style={{ padding: "5px", textAlign: "right", width: "9%" }}
                      key={`inv-table-row-key-igst_amount-${idx}`}
                      id={`inv-table-row-id-igst_amount-${idx}`}
                      name={`inv-table-row-name-igst_amount-${idx}`}
                    >
                      {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invInd.igst_amt).toFixed(2)))}
                    </td>
                    {/*
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`inv-table-row-key-total_amount-${idx}`}
                  id={`inv-table-row-id-total_amount-${idx}`}
                  name={`inv-table-row-name-total_amount-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invInd.cess_amt).toFixed(2)))}
                </td>
               */}

                    <td
                      style={{ padding: "5px", textAlign: "right", width: "9%" }}
                      key={`inv-table-row-key-total_amount-${idx}`}
                      id={`inv-table-row-id-total_amount-${idx}`}
                      name={`inv-table-row-name-total_amount-${idx}`}
                    >
                      {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invInd.inv_total_amt).toFixed(2)))}
                    </td>

                    <td style={{ textAlign: "center", width: "2%" }}
                      key={`inv-table-row-key-actions-${idx}`}
                      id={`inv-table-row-id-actions-${idx}`}
                      name={`inv-table-row-name-actions-${idx}`}
                    >
                      <BsCardText
                        size={28}
                        style={{ color: "#0d6efd", cursor: "pointer" }}
                        onClick={(e) => handleView(e)}
                        name={`inv-view-icon-${idx}`}
                        id={`inv-view-icon-${idx}`}
                        disabled={menuSettings.permissions.find(element => (element.code === 'listInvoices' && element.a_detailview === true)) ? false : true}
                      />
                      &nbsp;&nbsp;
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          }
        </form>
        :
        <div className="container-spinner">
          <div className="vertical-center-spinner">
            <Image src={spinner_logo} roundedCircle style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
            <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning" style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      }


    </Container>
  );
};
//const Dashboard = withRouter(Dash);
export default ListInvoices;
