import React, {
    useState,
    useEffect,
    useRef,
    useReducer,
    Fragment,
  } from "react";
  import Table from "react-bootstrap/Table";
  import { fragment } from "react";
  
  import Select from "react-select";
  
  import {
    Form,
    Button,
    Row,
    Col,
    Alert,
    Modal,
    Container,
    Spinner,
    Image
  } from "react-bootstrap";
  //import Sidebar2 from "./Sidebar2";
  import SidebarMain from "./SidebarMain";
  
  import {
    BsFillTrashFill,
    BsPencilSquare,
    BsFileRuled,
    BsCardText,
    BsFillPrinterFill
  } from "react-icons/bs";
  
  import "../App.css";
  
  import { useNavigate } from "react-router-dom";
  import axios from "axios";
  import { client } from "../config/Config";
  import spinner_logo from '../assets/images/spinner_logo.png';
  import {formatDateIndian} from "./utils";
  
  
const ViewCustomerOS = () => {
    const initialized = useRef(false);
    var navigate = useNavigate();
    
    var menuSettingsStr = localStorage.getItem("_aP");
    var menuSettings = JSON.parse(menuSettingsStr);

    const currSelBusinessRef = useRef(0);
    const currBranchRef = useRef(0);

    const [checkedState, setCheckedState] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  
    function padTo2Digits(num) {
      //console.log("<<<<<<<<<<<<<< given number >>>>>>>>>>>>>>>> : ", num);
      var modNum = num.toString().padStart(2, "0");
      console.log("Modified  value : ", modNum);
      return modNum;
    }
  
    function formatDate(date) {
      return [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-");
    }

    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  //var lastDay = new Date(date.getFullYear(), date.getMonth() + 1,
      const intialValues = {
          //date_from: formatDate(new Date(firstDay)),
          //date_from: formatDate(new Date(new Date().setDate(new Date().getDate() - OS_INVOICE_FOR_NDAYS))),
          date_ason: formatDate(new Date()),
  }
  
    
    let customerArr = [
  
      { value: 1, label: "All Customers" },
      { value: 2, label: "Deppam Motors" },
      { value: 3, label: "ABC Pumps" },
      { value: 4, label: "ADP Compressors" },
    ];
  
    let customerReportArr = [
      {
        customer: "Deppam Motors",
        contact_person: "Karthi",
        contact_mobile: "8907654321", 
        balance_amount: "89076"
      },
      {
        customer: "ABC Pumps",
        contact_person: "Murali",
        contact_mobile: "9108723456", 
        balance_amount: "90876"
      },
      {
        customer: "ADP Compressors",
        contact_person: "Ram",
        contact_mobile: "8901234567", 
        balance_amount: "6578"
      },
      
    ];
  
   
    
    
  
    const [customerReportList, setCustomerReportList] = useState([]);
    const [customerSummary, setCustomerSummary] = useState([]);
    const [customerIdSelect, setCustomerIdSelect] = useState({});
    const [optCustomer, setOptCustomer] = useState([]);
    const [toRender, setToRender] = useState(false);
    const [show, setShow] = useState(false);
    const [formValues, setFormValues] = useState(intialValues);
    const [showMsg, setShowMsg] = useState("Please login again");
    const [showMsgTitle, setShowMsgTitle] = useState(
      "Invalid authentication Session!"
    );
   
  
   
    /*
    const getCustomerList = async () => {
      try {
        var aIVal = localStorage.getItem("_aI");
        var headers = { ..
          .client.headers,Authorization: `Bearer ${aIVal}`}
      
  
        var res = await axios.get(
          client.domain + "/crm/getAllCustomers",
          { headers },
          { withCredentials: false }
        );
        console.log("Response from server : ", res.data.detail);
  
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server : ", 0);
          setCustomerList(res.data.detail.data);
          setCustomerListFull(res.data.detail.data);
          setShowMsgTitle("Customer Info Added Successfully");
          setToRender(true);
          //navigate("/dashboard");
          //setDcReportData(res.data.detail.res_data);
          //setToRender(true);
        }
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log(
            "Not able to reach target server please try after sometime"
          );
          setShowMsg("Please try after sometime");
          setShowMsgTitle("Network Issue!");
          setShow(true);
        } else {
          console.log("ERROR occured : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log(
              "Request failed due to ",
              error.response.data.detail.res_data
            );
            setShowMsg("Please try again ...");
            setShowMsgTitle("Unable to process your request!");
            setShow(true);
            //navigate("/auth");
          } else {
            console.log("Non status ERROR code ...");
            setShowMsg(error.response.data.detail);
            setShowMsgTitle("Unable to process your request at this moment!");
            setShow(true);
          }
        }
      }
    };
    */

  
   
    const CallCustomerData = async () => {
      var retStateLoad = false;
      var customerRet = [];
  
      try {
        var aIVal = localStorage.getItem("_aT");
        var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
  
        var reqParams = {
          bid: currSelBusinessRef.current
        };
  
        var res = await axios.post(client.domain + "/bms/getCustInvSearchPreLoadData", reqParams, { headers }, { withCredentials: false });
        console.log("Response from server : ", res.data.detail);
  
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server : ", res.data.detail.data.customers);
          var customerList = [];
  
          //customerList.push({label: "All Customers", value: 0});
          res.data.detail.data.customers.map((customer) => {
            customerList.push(customer);
          })
          setOptCustomer(customerList);
          //setCustomerIdSelect(customerList[0]);
          //setCustomerIdSelect(res.data.detail.data.customers[0]);
         
          setToRender(true);
          customerRet = customerList;
          retStateLoad = true;
  
  
        }
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log("Not able to reach target server please try after sometime");
          setShowMsg("Please try after sometime");
          setShowMsgTitle("Network Issue!");
          setShow(true);
        } else {
          console.log("ERROR occured : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log("Request failed due to ", error.response.data.detail.res_data);
            setShowMsg("Please try again ...");
            setShowMsgTitle("Unable to process your request!");
            setShow(true);
            //navigate("/auth");
          } else {
            console.log("Non status ERROR code ...");
            setShowMsg(error.response.data.detail);
            setShowMsgTitle("Unable to process your request at this moment!");
            setShow(true);
          }
        }
      }
      return [retStateLoad, customerRet];
    };
    

    const getCustomerList = async (customer_id_pass) => {
      var retStateLoad = false;
      var customerReportRet = [];
      var summaryRet = [];
      try {
        var aIVal = localStorage.getItem("_aT");
        var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
        console.log("Selected Customer :", customerIdSelect);
        var reqParams = {
          bid: currSelBusinessRef.current,
          brid: currBranchRef.current || 0,
          customer_id: customer_id_pass,
          date_ason:formValues["date_ason"] 
  
        };
        console.log("REQUEST PARAMS: ", reqParams );
        var res = await axios.post(client.domain + "/bms/getCustomerOSList", reqParams, { headers }, { withCredentials: false });
        console.log("Response from server : ", res.data.detail);
  
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server Invoice : ",res.data.detail.data );
          setCustomerReportList(res.data.detail.data.customer_os);
          setCustomerSummary(res.data.detail.data.customer_os_summary[0]);
          //setCustomerListFull(res.data.detail.data);
          //setShowMsgTitle("Customer Info Added Successfully");
          setToRender(true);
          customerReportRet = res.data.detail.data.customer_os;
          summaryRet = res.data.detail.data.customer_os_summary;
          retStateLoad = true;
          
        }
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log( "Not able to reach target server please try after sometime");
          setShowMsg("Please try after sometime");
          setShowMsgTitle("Network Issue!");
          setShow(true);
        } else {
          console.log("ERROR occured : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log( "Request failed due to ",error.response.data.detail.res_data);
            setShowMsg("Please try again ...");
            setShowMsgTitle("Unable to process your request!");
            setShow(true);
            //navigate("/auth");
          } else {
            console.log("Non status ERROR code ...");
            setShowMsg(error.response.data.detail);
            setShowMsgTitle("Unable to process your request at this moment!");
            setShow(true);
          }
        }
      }
      return [retStateLoad, customerReportRet, summaryRet];
    };

    useEffect(() => {
      if (!initialized.current) {
        console.log("*************** useEffect::ListCustomers called ##########################");
        ( async() => {
        var [retStateLoad, customerRet] = await CallCustomerData();
        if (retStateLoad){
          var findDefault = customerRet.find(element => element.is_default === true);
                      if (findDefault !== undefined) {
                        setCustomerIdSelect(findDefault);  
                     
                    }
          console.log(" Customer :", customerRet);
         
          //setCustomerIdSelect(customerRet[0]);

          var [retStateLoad, customerReportRet, summaryRet] = await getCustomerList(findDefault.value); 
        }
      })();
        setToRender(true);
        //setCustomerIdSelect(optCustomer[0]);
        //getCustomerList();
        initialized.current = true;
       
      }
    }, []);
   /*
    const handleAdd = async (e) => {
      console.log("handle add customers called : ", e);
      navigate("/addUpdateStockIn", {
        state: { passed_element: [], action: "add" },
      });
    };
    */
  
    
    
   
    
    const handleCustomerPrint = async (e) => {
     //navigate("/printCustomer")
      
      var urlStr = window.location.href;
      var paramsTmp = urlStr.substring(
        urlStr.lastIndexOf(client.urlDelimiter) + 1
      );
      var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));
  
      console.log("CALLED URL : ", urlTmp);
      console.log("CALLED PAGE : ", paramsTmp);
  
      var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printCustomerOS?bid=${currSelBusinessRef.current}&brid=${currBranchRef.current}&customer_id=${customerIdSelect.value}&date_ason=${formValues["date_ason"]}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
      winPrint.moveTo(0,0);
      winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
      
      
    };
   
    /*
    const onChangeDatePicker = (e) => {
      console.log("Date onChange called ...", e);
      console.log("Date onChange DATE called ...", e.target.value);
      setFormValues({ ...formValues, [e.target.name]: e.target.value });
    };
  
    
    const onChangeStartDatePicker = (e) => {
      console.log("Date onChange called ...", e);
      console.log("Date onChange DATE called ...", e.target.value);
      setFormValues({ ...formValues, date_from: e.target.value });
    };
    */
  
    const onChangeAsOnDatePicker = (e) => {
      console.log("Date onChange called ...", e);
      console.log("Date onChange DATE called ...", e.target.value);
      setFormValues({ ...formValues, date_ason: e.target.value });
    };
  
  
    const handleCustomerSearch = async () => {
      console.log("handleDateSearch called ...");
      await getCustomerList(customerIdSelect.value);
  
    };
   const handleCustomerSelect = async (e) => {
    console.log("handleCustomerSelect called...");
    setCustomerIdSelect(e);
   };

   const sendDataToParent  = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if ( passedBackValue.hasOwnProperty("business_id") ) {
      currSelBusinessRef.current = passedBackValue["business_id"];
      //navigate(0);
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);
    
    //getBranchList();
  }
   
    return (
      <Container>
        <SidebarMain sendBacktoParent={sendDataToParent} />
        { toRender ?
        <form>
        <Row>
            <Col xs={9} md={9} sm={9} lg={9}>
              <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.25rem", verticalAlign: "bottom" }}>{menuSettings.permissions.find(element => (element.code === 'viewCustomerOS' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'viewCustomerOS')).page_title : "Customer Outstanding Statment"}</span>
            </Col>
            
            <Col
              xs={3}
              md={3}
              sm={3}
              lg={3}
              className="d-flex justify-content-end float-end">
              <Button id="customerPrint" name = "customerPrint"  onClick={(e)  => handleCustomerPrint(e)}
                  disabled={menuSettings.permissions.find(element => (element.code === 'viewCustomerOS' && element.a_print === true && customerReportList.length > 0)) ? false : true}>
                  <BsFillPrinterFill
                    size={18}
                    style={{ color: "white", cursor: "pointer" }}
                    name={`print-customer-icon`}
                    id={`print-customer-icon`}
                    
                  />
                </Button>    
            </Col>
            </Row>
            <hr align="center" />

            <Row>  
            <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Label>Customer</Form.Label>
              <Select
                inline
                id="customer"
                name="customer"
                value={{
                  value: customerIdSelect.value,
                  label: customerIdSelect.label,
                }}
                options={optCustomer}
                defaultValue={{
                  value: customerIdSelect.value,
                  label: customerIdSelect.label,
                }}
                onChange={handleCustomerSelect}
                //isDisabled={isReadOnly}
              />
              </Col>
             
              <Col xs={5} md={5} sm={5} lg={5}>
              <Row>
                {/*
                <Col>
                  <Form.Control
                    type="date"
                    name="date_from"
                    placeholder="Start Date"
                    value={formValues.date_from}
                    onChange={onChangeStartDatePicker}
                  //readOnly={isReadOnly}

                  />

                </Col>
                <Col>
                  <Form.Label style={{ textAlign: "center", paddingTop: "5px" }}>To</Form.Label>
                </Col>

                <Col>

                  <Form.Control
                    type="date"
                    name="date_to"
                    placeholder="End Date"
                    value={formValues.date_to}
                    onChange={onChangeEndDatePicker}
                  //readOnly={isReadOnly}

                  />
                </Col>
              */}
              <Col>
                  <Form.Label>Outstanding As On</Form.Label>
                  <Form.Control
                    type="date"
                    name="date_ason"
                    placeholder="Date"
                    value={formValues.date_ason}
                    onChange={onChangeAsOnDatePicker}
                  //readOnly={isReadOnly}

                  />
                </Col>
                <Col style={{paddingTop: "30px"}}>
                  <Button id="search-order-id"
                    size={"md"}
                    
                    variant="success"
                    onClick={(e) => handleCustomerSearch(e)}>
                    Search
                  </Button>
                </Col>
              </Row>
            </Col>
              
                </Row>
            <hr align="center" />
            {/*
            <Row>

            <Col xs={12} md={12} sm={12} lg={12} style={{ textAlign: "right" }}>

              <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                Customer :
              </Form.Label>
              <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
                {customerSummary.total_cust}
              </Form.Label>

              <span style={{ color: '#C0C0C0' }}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
              <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                Opening Balance :
              </Form.Label>
              <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
              {customerSummary.openbal > 0.01 ?
                  <>
                    {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customerSummary.openbal).toFixed(2)))}
                  </>
                  : (0.00).toFixed(2)}
               
              </Form.Label>

              <span style={{ color: '#C0C0C0' }}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>


              <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                Invoice OS :
              </Form.Label>
              <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
                {customerSummary.inv_os > 0.01 ?
                  <>
                    {((new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customerSummary.inv_os).toFixed(2))))}
                  </>
                  : (0.00).toFixed(2)}
              </Form.Label>
              <span style={{ color: '#C0C0C0' }}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>

              <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                Bills OS:
              </Form.Label>
              <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
                {customerSummary.bill_os > 0.01 ?
                  <>
                    {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customerSummary.bill_os).toFixed(2)))}
                  </>
                  : (0.00).toFixed(2)}
              </Form.Label>

              <span style={{ color: '#C0C0C0' }}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
              <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                OS :
              </Form.Label>
              <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
                {customerSummary.total_os > 0.01 ?
                  <>
                    {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customerSummary.total_os).toFixed(2)))}
                  </>
                  : (0.00).toFixed(2)}
              </Form.Label>
              <span style={{ color: '#C0C0C0' }}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>

              <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                Received Amount :
              </Form.Label>
              <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
                {customerSummary.received_amt > 0.01 ?
                  <>
                    {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customerSummary.received_amt).toFixed(2)))}
                  </>
                  : (0.00).toFixed(2)}
              </Form.Label>

              <span style={{ color: '#C0C0C0' }}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
              
              <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                Balance OS :
              </Form.Label>
              <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
                {customerSummary.balance_os > 0.01 ?
                  <>
                    {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customerSummary.balance_os).toFixed(2)))}
                  </>
                  : (0.00).toFixed(2)}
              </Form.Label>




            </Col>
          </Row>
          */}
           
            <tr className="table-primary">
                  <th className="table-row-heading" style={{textAlign: "left", width: "40%"}}>Total Customer : {customerSummary.total_cust}</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "0%"}}></th>
                  <th className="table-row-heading" style={{textAlign: "right", width: "10%"}}>{customerSummary.openbal > 0.01 ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customerSummary.openbal).toFixed(2)) : (0.00).toFixed(2)}</th>
                  <th className="table-row-heading" style={{textAlign: "right", width: "10%"}}>{customerSummary.inv_os > 0.01 ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customerSummary.inv_os).toFixed(2)) : (0.00).toFixed(2)}</th>
                  <th className="table-row-heading" style={{textAlign: "right", width: "10%"}}>{customerSummary.bill_os > 0.01 ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customerSummary.bill_os).toFixed(2)) : (0.00).toFixed(2)}</th>
                  <th className="table-row-heading" style={{textAlign: "right", width: "10%"}}>{customerSummary.total_os > 0.01 ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customerSummary.total_os).toFixed(2)) : (0.00).toFixed(2)}</th>
                  <th className="table-row-heading" style={{textAlign: "right", width: "10%"}}>{customerSummary.received_amt > 0.01 ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customerSummary.received_amt).toFixed(2)) : (0.00).toFixed(2)}</th>
                  <th className="table-row-heading" style={{textAlign: "right", width: "10%"}}>{customerSummary.balance_os > 0.01 ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customerSummary.balance_os).toFixed(2)) : (0.00).toFixed(2)}</th>
                  {/*<th className="table-row-heading" style={{textAlign: "center", width: "200px"}}>Details</th>*/}
                </tr>
  
            <Table striped bordered hover>
              <thead>
                <tr className="table-primary">
                  <th className="table-row-heading" style={{textAlign: "center", width: "5%"}}>S.No</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "35%"}}>Customer</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "10%"}}>Opening Balance</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "10%"}}>Invoice Outstanding</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "10%"}}>Bills Outstanding</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "10%"}}>Total Outstanding</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "10%"}}>Received Amount</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "10%"}}>Balanace Outstanding</th>
                  {/*<th className="table-row-heading" style={{textAlign: "center", width: "200px"}}>Details</th>*/}
                </tr>
              </thead>
              <tbody>
                {customerReportList.map((customer, idx) => (
                  <tr>
                    <td style={{textAlign: "right",width: "5%"}}>{idx + 1}</td>
                    <td style={{textAlign: "left", width: "35%"}}>{customer.customer_name}</td>
                    <td style={{textAlign: "right", width: "10%"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customer.openbal).toFixed(2)))}</td>
                    <td style={{textAlign: "right", width: "10%"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customer.inv_os).toFixed(2)))}</td>
                    <td style={{textAlign: "right", width: "10%"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customer.bill_os).toFixed(2)))}</td>
                    <td style={{textAlign: "right", width: "10%"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customer.total_os).toFixed(2)))}</td>
                    <td style={{textAlign: "right", width: "10%"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customer.received_amt).toFixed(2)))}</td>
                    <td style={{textAlign: "right", width: "10%"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customer.balance_os).toFixed(2)))}</td>
                    
                    {/*<td style={{textAlign: "center"}}>
                      <BsCardText
                        key={`edit-btn-vehicle-key-${idx}`}
                        size={28}
                        style={{ color: "#0D6EFD", cursor: "pointer" }}
                        onClick={(e) => handleView(e)}
                        name={`edit-icon-vehicle-name-${idx}`}
                        id={`edit-icon-vehicle-id-${idx}`}
                      />
                    </td>
                */}
                  </tr>
                ))}
              </tbody>
            </Table>
            </form>
       : 
       <div className="container-spinner">
      <div className="vertical-center-spinner">
       <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
 
       </div>
       </div>
      }      
       </Container>
     
    );
  }
  export default ViewCustomerOS;