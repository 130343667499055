import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  FormControl,
  Spinner,
  Modal,
  Image,
  FormGroup
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
//import Sidebar from "./Sidebar";
//import Sidebar2 from "./Sidebar2";
//import Topmenu from "./Topmenu";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";

import { useNavigate, useLocation } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client, ConfigMinValue, configMaxValue, appGlobal, MAX_QTY_LENGTH, MAX_AMOUNT_LENGTH} from "../config/Config";
import axios from "axios";

//import Footer from "./Footer";

import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "bootstrap/dist/css/bootstrap.min.css";
import spinner_logo from '../assets/images/spinner_logo.png';
import InputGroup from 'react-bootstrap/InputGroup';

import { convertNumberToWords } from "./utils";

import {
  BsCartX,
  BsArrowLeftCircle,
  BsFillPenFill,
  Bs3Circle,
  BsFillXCircleFill,
  BsPencilSquare,
  BsFillPrinterFill
} from "react-icons/bs";
import SidebarMain from "./SidebarMain";
//import { relativeTimeThreshold } from "moment/moment";
//import {getDistrictList, getStateList, getProductApiCall, getProductBuy, getProductsSellAll } from "./utils";

const AddUpdateSuppInvoiceMfgT1 = (props) => {
  const initialized = useRef(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);

  const [passedValue, setPassedValue] = useState(state.passed_element);
  const [submitAction, setSubmitAction] = useState(false);

  const [showAlert, setShowAlert] = useState();
  const [alertPurpose, setAlertPurpose] = useState('init');
  const [alertTitle, setAlertTitle] = useState('Success');
  const [alertBody, setAlertBody] = useState('Invoice Saved Successfully');
  const [dateIdSelect, setDateIdSelect] = useState({});

  var expected_inv_number_ref = useRef(0);  
  
  /*
  let initialItems = [
    {id:1, product: {value:3, label:"M15"}, price: "4900", qty: 5, "product_cost": "24500", "gst": "4410", "total_cost":"28910", gst:{cgst: 2205.00, sgst:2205.00, igst:0.00, totGst: 4410.00}}, 
    {id:1, product: {value:6, label:"M25"}, price: "4800", qty: 7, "product_cost": "215001", "gst": "38701", "total_cost":"253701", gst:{cgst: 2205.00, sgst:2205.00, igst:0.00, totGst: 4410}}
  ]
  */
  let initialItems = [];

  var sub_total = 0.00;

  const optionsDate = [
    { value: 1, label: "12-12-2023" },
    { value: 2, label: "01-04-2023" },
    { value: 3, label: "05-09-2023" },
    { value: 4, label: "07-08-2023" },
    { value: 5, label: "23-05-2023" },
  ];

  let stateArr = [
    { value: 1, label: "Andhra Pradesh" },
    { value: 2, label: "Assam" },
    { value: 3, label: "Tamil Nadu" },
    { value: 4, label: "Telangana" },
  ];

  let bankAccountArr = [
    { value: 1, label: "City Union Bank" },
    { value: 2, label: "Indian Bank" },
    { value: 3, label: "State Bank" },
    { value: 4, label: "Axis Bank" },
  ];
  const [optionsState, setOptionsState] = useState(stateArr);
  //const [bankAccountIdSelect, setBankAccountIdSelect] = useState({});
  //const [optBankAccount, setOptBankAccount] = useState(bankAccountArr);

  
  /*
  const handleBankAccountSelect = async (e) => {
    console.log("handleBankAccountSelect called...", e);
    setBankAccountIdSelect(e);
  }
  */

  const handleStateSelect = async (e) => {
    console.log("handleStateSelectB called ...", e);
    setStateIdSelect(e);

    // var stateIdInput = e.value;
    //var stateNameInput = e.label;
    //var [retStateDist, distArrRet] = await getDistrictList(e.value);
    {/*
    if (retStateDist) {
      setOptDist(distArrRet);
      setDistrictIdSelect(distArrRet[0]);
    }
  */}
  };

 




  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  //const intialValues = {company:"", address: "", city:"", zip:"", gst_number: "", phone: "", district: "", state: "", product:{value:"m15", label:"M15"}};
  const intialValues = {
    customer_name: "",
    customer_disp_name: "",
	  bill_date: formatDate(new Date()), 
    due_date: formatDate(new Date()), 
    inv_date: formatDate(new Date()), 
    bill_number: "",
    inv_number: "", 
   
   notes: "",
   tc_declaration: "",


    gst_number: "",
	  address1: "",
    address2: "",
    pincode: "", 
    state: 1,

   
	 
    
    
  };

  const initialValuesOrder = {
    expected_inv_number: 0,
    order_date: formatDate(new Date()),
  };

  
  let productArr = [
    { value: 1, label: "TMT Rods" },
    { value: 2, label: "Amman Steels" },
   
  ];


  const [formValuesOrder, setFormValuesOrder] = useState(initialValuesOrder);
   
  
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [stateIdSelect, setStateIdSelect] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [productMaster, setProductMaster] = useState(productArr);
  const [isLoading, setIsLoading] = useState(false);
  
  const [toRender, setToRender] = useState(false);

  const [districtIdSelect, setDistrictIdSelect] = useState({});

  const [billDistrictIdSelect, setBillDistrictIdSelect] = useState({});

  const [optDist, setOptDist] = useState([]);

  const [optionsSearch, setOptionsSearch] = useState([]);

  const [supplierSearchText, setSupplierSearchText] = useState("");

  const [searchedCustomer, setSearchedCustomer] = useState({});

  const [orderAddress, setOrderAddress] = useState({});
  const [orderInfoDet, setOrderInfoDet] = useState({});

  const [addressFetched, setAddressFetched] = useState(false);
  const [orderDetFetched, setOrderDetFetched] = useState(false);
  const [orderItemFetched, setOrderItemFetched] = useState(false);
  const [orderDcFetched, setOrderDcFetched] = useState(false);
  const [productList, setProductList] = useState();
  const [editAction, setEditAction] = useState(false);

  const [optDate, setOptDate] = useState(optionsDate);
  const [optionsProd, setOptionsProd] = useState([]);

  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("");
  const [msgType, setMsgType] = useState("success");
  const [submitPrintAction, setSubmitPrintAction] = useState(false);
  //const [disableState, setDisableState] = useState(true);
  const currSelBusinessRef = useRef(0);
  const currBranchRef = useRef(0);

  var validateErr = false;

  var prodListLoad = [
    //{prod_id: 1, product_name: "M-10", hsncode:"HSN778328", unit_price:4300.00, quantity:10, total_item_price: 43000.00, gst: 7740.00, total_price:50740.00},
    //{prod_id: 1, product_name: "M-20", hsncode:"HSN778329", unit_price:4700.00, quantity:10, total_item_price: 47000.00, gst: 8460.00, total_price:55460.00},
    //{prod_id: 1, product_name: "M-30", hsncode:"HSN778378", unit_price:4900.00, quantity:10, total_item_price: 49000.00, gst: 8820.00, total_price:57820.00}
  ];
  var dcLoad = [
    //{dc_id: 12, dc_number:"DC102091Z1", dc_date:"2023-08-01", grade_id:1, grade_name:"M-20", loading_time:"2023-08-01", vehicle_number:"TN 39 DX 9927", total_volume:"18 cum", volume: "10 cum", total_amount:4200, driver_name:"Azhagu Raja"},
    //{dc_id: 11, dc_number:"DC10209879", dc_date:"2023-07-31", grade_id:2, grade_name:"M-15", loading_time:"2023-07-31", vehicle_number:"TN 39 DY 1254", total_volume:"28 cum", volume: "18 cum", total_amount:42000, driver_name:"Mahesh C"}
  ];

  const [deliveryChellan, setDeliveryChellan] = useState(dcLoad);
  const [prodList, setProdList] = useState(prodListLoad);

  const [gstBillStatus, setGstBillStatus] = useState(true);

  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isEditable, setIsEditable] = useState(true);

  const [showConfigDialog, setShowConfigDialog] = useState(false);
  const [uomIdSelect, setUomIdSelect] = useState({});
  const [productCompositionList, setProductCompositionList] = useState([]);

  const [compositionUpdate, setCompositionUpdate] = useState(false);

  const [selectedCompositionId, setSelectedCompositionId] = useState(-1);

  const [selectedSupplierId, setSelectedSupplierId] = useState({});
  const [optSuppliers, setOptSuppliers] = useState([]);

  //const [searchText, setSearchText] = useState('');

  const handleValueChange = async (e, data) => {
    e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    //console.log("Event code : ", e.keyCode);

    if (
      name === "phone" ||
      name === "contact_number" ||
      name === "shipContactNumber" ||
      name === "billContactNumber" ||
      name === "pincode" ||
      name === "shipPincode" ||
      name === "billPincode" || 
      name === "expected_inv_number"
    ) {
      console.log("Inside check ******************");
      //const numericRegExp = /^(?:[1-9]\d*|\d)$/;
      if (e.target.value.match(appGlobal.wholeNumberRegExp)) {
        //var valueTmp = Math.round(e.target.value.replace(/\D/g, ""));
        if ( name === "expected_inv_number") {
          setFormValuesOrder({ ...formValuesOrder, [name]: e.target.value});
        }
        else {
          setFormValues({ ...formValues, [name]: e.target.value });
        }
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
      /*
      if ( name === 'contact_person' && addrCopyCheck) {
        setFormValues({ ...formValues, ["shipContactName"]: value });
      }
      */
    }
    var quantity_tmp = 0.00;
    var unit_price_tmp = 0.00;
    /*
    if ( !isNaN(parseFloat(formValues["quantity"])) ) {
      quantity_tmp = parseFloat(formValues["quantity"]);
    }
    if ( !isNaN(parseFloat(formValues["unit_price"])) ) {
      unit_price_tmp = parseFloat(formValues["unit_price"]);
    }
    */
    sub_total = quantity_tmp + unit_price_tmp;
    console.log("SUB TOTAL at handleValueChange : ", sub_total);
  };

  const handleSearch = (e, dat) => {
    console.log("Handled search method called ...", e);

   
   // getDistrictList(stateIdInput);
  };

  const handleDistrictSelect = (e, data) => {
    console.log("handleDistrictSelect called ...", e);

    setDistrictIdSelect(e);
  };

  const handleBillDistrictSelect = async (e) => {
    console.log("handle bill district : ", e);

    setBillDistrictIdSelect(e);
  };



  const handleChange = (e, data) => {
    //const min = 0;
    //const max = 10000;
    console.log("Handle Change clicked : ", e);
    console.log("Handle Change clicked target : ", e.target);
    //console.log("HTML tag : ", data);
    const { name, value } = e.target;
    console.log("Captured Quantity name : ", name);
    console.log("Captured Quantity value : ", value);
    //setFormValues({...formValues, [name]: value});
    console.log("FORM VALUES at handleChange func : ", formValues);

    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    var itemSelected = items[currentId];

    //console.log("Product: ", itemSelected.product);
    console.log("Product Name : ", itemSelected.product_name);
    console.log("Rate : ", itemSelected.rate);
    console.log("Unit price : ", itemSelected.unit_price);
    console.log("CGST : ", parseFloat(itemSelected.cgst));
    console.log("SGST : ", parseFloat(itemSelected.sgst));
    console.log("Total Amount : ", itemSelected.total_price);
    console.log("Area : ", itemSelected.area);

    console.log("Product Info : ", productMaster);
    const currentProductIndex = productMaster.findIndex(
      (productInd) => productInd.name === itemSelected.product_name);
    console.log("Currently selected row product id : ", currentProductIndex);
    /*
    if (currentProductIndex < 0) {
      itemSelected.error = "Please select product from list";

      const newitems = items.slice();
      newitems[currentId] = itemSelected;
      setItems(newitems);
      return 0;
    } else {
      itemSelected.error = "";
    }
    */

    //console.log("Currently selected row product value : ", productMaster[currentProductIndex]);

    //console.log("%%%%%%%%%%%%%% GST INFO ++++++++++++++ : ", productMaster[currentProductIndex]["gst"].cgst);

    /*
    itemSelected.product = {
      value: productMaster[currentProductIndex].product_id,
      label: productMaster[currentProductIndex].product_name,
      stock_id: productMaster[currentProductIndex].stock_id,
      cgst: productMaster[currentProductIndex]["gst"].cgst,
      sgst: productMaster[currentProductIndex]["gst"].sgst,
      igst: productMaster[currentProductIndex]["gst"].igst,
    };
    */

    //itemSelected.cgst_perc = productMaster[currentProductIndex].cgst;
    //itemSelected.sgst_perc = productMaster[currentProductIndex].sgst;
    //itemSelected.igst_perc = productMaster[currentProductIndex].igst;
    //itemSelected.addl_notes = productMaster[currentProductIndex].addl_notes;
    //itemSelected.unit_cost = productMaster[currentProductIndex].unit_cost;

    if (nameOfElement === "quantity" || nameOfElement === "unit_price" || nameOfElement === "total_item_price") {
      console.log("Product Quantity item edited ...");
      /*
      const valueTmp = Math.max(
        ConfigMinValue,
        Math.min(configMaxValue, Number(e.target.value))
      );
      */
      //var valueTmp = e.target.value.replace(/[^0-9.]/g, "");
      //var valueTmp = e.target.value.replace(/^-?\d+\.?\d*$/g, "");
      //var numericRegExp = new RegExp("^[0-9]+$");
      //const numericRegExp = /^[\+\-]?\d*\.?\d+(?:[Ee][\+\-]?\d+)?$/;
      //const numericRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      //const numericRegExp = new RegExp(/^-?\d*[\.]?\d+$/);

      // const numericRegExp = /^\d*\.?\d*$/;//original
      ////^([0-9]+-)*([0-9]+)$///.replace(/(\..*)\./g, "$1");
      //console.log("Matched value >>>>>>>>>>>>>>> : ", valueTmp);
      //if (numericRegExp.match(e.target.value)) {
      if (e.target.value.match(appGlobal.numericRegExp)) {
        console.log("PATTERN MATCHED ...: ", e.target.value);
        //if ( parseFloat(e.target.value.toString()) !== NaN ) {
          //console.log("INSIDE IF CONDITION ...");
          //itemSelected[nameOfElement] = parseFloat(e.target.value.toString());
          /*
          if ( e.target.value < 0.01 ) {
            console.log("INSIDE IF CONDITION ...");
            itemSelected[nameOfElement] = 0;
          }
          else {
            console.log("INSIDE ELSE CONDITION ...");
            itemSelected[nameOfElement] = e.target.value.toString();
          }
          */
        /*
        }
        else {
          console.log("INSIDE ELSE CONDITION ...");
          itemSelected[nameOfElement] = "";
        }
        */
        itemSelected[nameOfElement] = e.target.value;
      }
      else{
        console.log("PATTERN DOESN'T MATCH ...");
      }
    } else {
      console.log("Other than handled option clicked so skipping it ...");
    }
    //itemSelected.product_cost = productMaster[currentProductIndex].unit_cost * itemSelected.qty;
    //itemSelected.unit_cost = productMaster[currentProductIndex].unit_cost;
    /*
    if(itemSelected.product_type === 'PRODUCT') {
    if ( (itemSelected.quantity && itemSelected.unit_price) && parseFloat(itemSelected.quantity) !== isNaN && parseFloat(itemSelected.unit_price) !== isNaN) {
      itemSelected.total_item_price = itemSelected.unit_price * itemSelected.quantity;
    }
    else {
      itemSelected.total_item_price = 0.00;
    }
    }
    

    var cgstCalculate = 0.0;
    if (gstBillStatus) {
      cgstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].cgst) / 100;
    }
    itemSelected.cgst = cgstCalculate;

    var sgstCalculate = 0.0;

    if (gstBillStatus) {
      sgstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].sgst) / 100;
    }
    itemSelected.sgst = sgstCalculate;

    var igstCalculate = 0.0;
    if (gstBillStatus) {
      igstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].igst) / 100;
    }
    itemSelected.igst = igstCalculate;
    //let totalGst = cgstTmp + sgstTmp + igstTmp;

    //var gst = { cgst: cgstTmp, sgst: sgstTmp, igst: igstTmp, totGst: totalGst }
    //itemSelected.gst = gst;
    //itemSelected.product_cost = productMaster[currentProductIndex].unit_cost * itemSelected.qty;
    console.log("CGST STATE 501 : ", itemSelected.cgst);
    console.log("SGST STATE 501 : ", itemSelected.sgst);
    console.log("IGST STATE 501 : ", itemSelected.igst);

    itemSelected.total_price = parseFloat(itemSelected.total_item_price) + parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst);

    console.log("Modified unit cost : ", itemSelected.unit_price);
    console.log("Modified Product cost : ", itemSelected.total_item_price);
    console.log("Modified Total cost : ", itemSelected.total_price);
    console.log("Modified Additional Notes : ", itemSelected.addl_notes);
*/

    const newitems = items.slice();
    newitems[currentId] = itemSelected;
    setItems(newitems);
  };
 
 /*
  const getOrderAddress = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getOrderAddress?order_id=${passedValue.order_id}`, { headers },{ withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrderAddress : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getOrderAddress : ",res.data.detail.res_data);
        setOrderAddress(res.data.detail.res_data.order_address);

        formValues["shipContactName"] = res.data.detail.res_data.order_address.delivery_address.contact_name;
        formValues["shipContactNumber"] = res.data.detail.res_data.order_address.delivery_address.contact_number;
        formValues["shipAddress1"] = res.data.detail.res_data.order_address.delivery_address.address_line1;
        formValues["shipAddress2"] = res.data.detail.res_data.order_address.delivery_address.address_line2;
        formValues["shipPincode"] = res.data.detail.res_data.order_address.delivery_address.pincode;

        console.log("Delivery contact : ", formValues["shipContactName"]);

        formValues["billContactName"] = res.data.detail.res_data.order_address.billing_address.contact_name;
        formValues["billContactNumber"] = res.data.detail.res_data.order_address.billing_address.contact_number;
        formValues["billAddress1"] = res.data.detail.res_data.order_address.billing_address.address_line1;
        formValues["billAddress2"] = res.data.detail.res_data.order_address.billing_address.address_line2;
        formValues["billPincode"] = res.data.detail.res_data.order_address.billing_address.pincode;

        console.log("Billing : ", formValues["billContactName"]);

        setAddressFetched(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };
  */
  
  const SupplierInvoiceAddUpdateInitLoad = async () => {
    try {
      var responseRet = {};
      var retState = false;
      var selectedBidFromDb = localStorage.getItem('selected_business');
      var selectedBidObj = JSON.parse(selectedBidFromDb);
      var reqAddSuppBillInit = {
        bid: selectedBidObj.value
      };
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.post(client.domain + `/bms/getSuppInvAddUpdPreLoadData`, reqAddSuppBillInit, { headers }, { withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server SupplierInvoiceAddUpdateInitLoad : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server SupplierInvoiceAddUpdateInitLoad FULL : ", res.data.detail.data);
        console.log("Response message from server SupplierInvoiceAddUpdateInitLoad suppliers : ", res.data.detail.data.suppliers);
        console.log("Response message from server SupplierInvoiceAddUpdateInitLoad items : ", res.data.detail.data.items);
        console.log("Response messages from server SupplierInvoiceAddUpdateInitLoad countries : ", res.data.detail.data.countries);
        responseRet = res.data.detail.data;
        retState = true;
        //setOrderDetFetched(true);
        //optionsDistrict = res.data.detail.res_data.district_list

        //setOrderInfoDet(res.data.detail.res_data.order_info);
        //var dd = new Date(res.data.detail.res_data.order_info.order_date);
        //formValues["order_date"] = formatDate(dd);
        //console.log("Date of order_date : ", dd.getMonth());
        //setGstBillStatus(res.data.detail.res_data.order_info.bill_gst_include);
        //console.log("CUSTOMER ID : ", res.data.detail.res_data.order_info.customer_id);       
      }
     
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
    return [retState, responseRet];
  };
  
 /*
  const getOrderItemsByOrderId = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getOrderItemsByOrderId?order_id=${passedValue.order_id}`, { headers }, { withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrdersList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server OrderDetails : ", res.data.detail.res_data);
        console.log("Response message from server OrderDetails user_info : ", res.data.detail.res_data.user_info);
        console.log("Response message from server order items list : ", res.data.detail.res_data.order_item_list);
        console.log("Length of district array : ", res.data.detail.res_data.order_item_list.length);
        //optionsDistrict = res.data.detail.res_data.district_list

        //setOrderInfoDet(res.data.detail.res_data.order_info);

        if (res.data.detail.res_data.order_item_list.length > 0) {
          //setProdList(res.data.detail.res_data.order_item_list);
          setItems(res.data.detail.res_data.order_item_list);
          //setOptDist(res.data.detail.res_data.district_list)
          //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
        }

        setOrderItemFetched(true);
        //setDistrictIdSelect(res.data.detail.res_data.district_list);

        //localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        //localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
      }
     
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };
  */
 /*
  const getDCListByOrderId = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getDCListByOrderId?order_id=${passedValue.order_id}`, { headers }, { withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrdersList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server OrderDetails : ", res.data.detail.res_data);
        console.log("Response message from server OrderDetails user_info : ", res.data.detail.res_data.user_info);
        console.log("Response message from server order items list : ", res.data.detail.res_data.dc_list);
        console.log("Length of district array : ", res.data.detail.res_data.dc_list.length);
        //optionsDistrict = res.data.detail.res_data.district_list

        //setOrderInfoDet(res.data.detail.res_data.order_info);

        if (res.data.detail.res_data.dc_list.length > 0) {
          setDeliveryChellan(res.data.detail.res_data.dc_list);
          //setProdList(res.data.detail.res_data.dc_list);
          //setOptDist(res.data.detail.res_data.district_list)
          //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
        }

        //setDistrictIdSelect(res.data.detail.res_data.district_list);

        //localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        //localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
        setOrderDcFetched(true);
      }
     
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
    setOrderDcFetched(true);
  };
  */
  
  /*
  const getProductApiCall = async () => {
    console.log("getTradeType method called ...");
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {
        ...client.headers,
        Authorization: ` ${aIVal}`
      }
      var res = await axios.get(
        client.domain + "/ims/getProductsTTSell",
        { headers },
        { withCredentials: false }
      );

      console.log("Response from server getProducts : ", res.data.detail.data);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getProducts : ", res.data.detail.data);

        var newSellProdArr = [];
        res.data.detail.data.map((prodItem) => {
          var newSellProd = {
            "value": prodItem.id,
            "label": prodItem.name,
            "product_id": prodItem.id,
            "product_name": prodItem.name,
            "stock_id": 1,
            "uom": prodItem.uom_value,
            "unit_cost": prodItem.unit_price,
            "gst": { "cgst": prodItem["cgst"], "sgst": prodItem["sgst"], "igst": prodItem["igst"] }

          }
          newSellProdArr.push(newSellProd);
        })
        setProductMaster(newSellProdArr);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.data);

        }
      }
    }
  };
  */

  const getOrderAddress = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getOrderAddress?order_id=${passedValue.order_id}`, { headers },{ withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrderAddress : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getOrderAddress : ",res.data.detail.res_data);
        setOrderAddress(res.data.detail.res_data.order_address);

        formValues["shipContactName"] = res.data.detail.res_data.order_address.delivery_address.contact_name;
        formValues["shipContactNumber"] = res.data.detail.res_data.order_address.delivery_address.contact_number;
        formValues["shipAddress1"] = res.data.detail.res_data.order_address.delivery_address.address_line1;
        formValues["shipAddress2"] = res.data.detail.res_data.order_address.delivery_address.address_line2;
        formValues["shipPincode"] = res.data.detail.res_data.order_address.delivery_address.pincode;

        console.log("Delivery contact : ", formValues["shipContactName"]);

        formValues["billContactName"] = res.data.detail.res_data.order_address.billing_address.contact_name;
        formValues["billContactNumber"] = res.data.detail.res_data.order_address.billing_address.contact_number;
        formValues["billAddress1"] = res.data.detail.res_data.order_address.billing_address.address_line1;
        formValues["billAddress2"] = res.data.detail.res_data.order_address.billing_address.address_line2;
        formValues["billPincode"] = res.data.detail.res_data.order_address.billing_address.pincode;

        console.log("Billing : ", formValues["billContactName"]);

        setAddressFetched(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };

  /*
  const getOrderDetails = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getOrderById?order_id=${passedValue.order_id}`, { headers }, { withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrdersList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getOrderDetails : ", res.data.detail.res_data);
        console.log("Response message from server getOrderDetails user_info : ", res.data.detail.res_data.user_info);
        console.log("Response message from server getOrderDetails order info : ", res.data.detail.res_data.order_info);
        console.log("Length of orderInfo array : ", res.data.detail.res_data.order_info.length);
        setOrderDetFetched(true);
        //optionsDistrict = res.data.detail.res_data.district_list

        var dd = new Date(res.data.detail.res_data.order_info.order_date);
        setOrderInfoDet(res.data.detail.res_data.order_info);
        setFormValues({ ...formValues, notes: res.data.detail.res_data.order_info.notes});
        setFormValuesOrder({ ...formValuesOrder, expected_inv_number: parseInt(res.data.detail.res_data.order_info.order_no), order_date: formatDate(dd)});
        
        setGstBillStatus(res.data.detail.res_data.order_info.bill_gst_include);
        //console.log("CUSTOMER ID : ", res.data.detail.res_data.order_info.customer_id);

        / *
        if ( res.data.detail.res_data.order_list.length > 0 ) {
          setOrderList(res.data.detail.res_data.order_list);
          //setOptDist(res.data.detail.res_data.district_list)
          //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
        }
        * /
        //setDistrictIdSelect(res.data.detail.res_data.district_list);

        //localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        //localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
      }
      / *
      if (res.data.status === "SUCCESS" ) {
        this.setState ({
        tokenJwt: res.data.res_data.token,
        isLoaded: true,
        openOTP: true,
        })
          // this.props.history.push({pathname: client.taskViewPath, state:{pageList: this.state.page, orgName: this.state.orgName} });
      }
      else {
      }
    * /
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };
  */
/*
  const getOrderItemsByOrderId = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getOrderItemsByOrderId?order_id=${passedValue.order_id}`, { headers }, { withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrdersList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server OrderDetails : ", res.data.detail.res_data);
        console.log("Response message from server OrderDetails user_info : ", res.data.detail.res_data.user_info);
        console.log("Response message from server order items list : ", res.data.detail.res_data.order_item_list);
        console.log("Length of district array : ", res.data.detail.res_data.order_item_list.length);
        //optionsDistrict = res.data.detail.res_data.district_list

        //setOrderInfoDet(res.data.detail.res_data.order_info);

        if (res.data.detail.res_data.order_item_list.length > 0) {
          //setProdList(res.data.detail.res_data.order_item_list);
          setItems(res.data.detail.res_data.order_item_list);
          //setOptDist(res.data.detail.res_data.district_list)
          //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
        }

        setOrderItemFetched(true);
        //setDistrictIdSelect(res.data.detail.res_data.district_list);

        //localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        //localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
      }
      / *
      if (res.data.status === "SUCCESS" ) {
        this.setState ({
        tokenJwt: res.data.res_data.token,
        isLoaded: true,
        openOTP: true,
        })
          // this.props.history.push({pathname: client.taskViewPath, state:{pageList: this.state.page, orgName: this.state.orgName} });
      }
      else {
      }
    * /
      //setOrderItemFetched(true);
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };
  */
 /*
  const getDCListByOrderId = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getDCListByOrderId?order_id=${passedValue.order_id}`, { headers }, { withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrdersList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server OrderDetails : ", res.data.detail.res_data);
        console.log("Response message from server OrderDetails user_info : ", res.data.detail.res_data.user_info);
        console.log("Response message from server order items list : ", res.data.detail.res_data.dc_list);
        console.log("Length of district array : ", res.data.detail.res_data.dc_list.length);
        //optionsDistrict = res.data.detail.res_data.district_list

        //setOrderInfoDet(res.data.detail.res_data.order_info);

        if (res.data.detail.res_data.dc_list.length > 0) {
          setDeliveryChellan(res.data.detail.res_data.dc_list);
          //setProdList(res.data.detail.res_data.dc_list);
          //setOptDist(res.data.detail.res_data.district_list)
          //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
        }

        //setDistrictIdSelect(res.data.detail.res_data.district_list);

        //localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        //localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
        setOrderDcFetched(true);
      }
      / *
      if (res.data.status === "SUCCESS" ) {
        this.setState ({
        tokenJwt: res.data.res_data.token,
        isLoaded: true,
        openOTP: true,
        })
          // this.props.history.push({pathname: client.taskViewPath, state:{pageList: this.state.page, orgName: this.state.orgName} });
      }
      else {
      }
    * /
      //setOrderDcFetched(true);
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
    setOrderDcFetched(true);
  };
  */

  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect called ##########################");

      ( async () => {
        var [retState, responseRet] = await SupplierInvoiceAddUpdateInitLoad();
        if ( retState ) {
          console.log("RETUNED RESPONSE FOR SupplierInvoiceAddUpdateInitLoad at useEffect : ", responseRet);
        }
      })();

      setStateIdSelect(optionsState[0]);
     
      //setBankAccountIdSelect(optBankAccount[0]);
      console.log(formErrors);
      
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        console.log(formErrors);
      }
      
      if (state === null) {
        navigate("/listInvoicrMfgT1");
        
      } else {
        console.log("PASSED VALUE THROUGH STATE variable : ", state);
        
        
        
        //setProductMaster(product_list);
        //getProductApiCall();
        
        /*
        var stateIdInput = 1;
        var stateNameInput = "TAMIL NADU";
        const getDistrictListWrapper = async () => {
          await getDistrictList(stateIdInput, stateNameInput);
        }
        getDistrictListWrapper();
        
        var country_id = appGlobal.COUNTRY_ID;
        var retStateStList = false;
        var stateArrRet = [];
        const getStateListWrapper =  async() => {
          var [retStateStList, stateArrRet] = await getStateList(country_id);
          if ( retStateStList) {
            console.log("RETURNED STATE LIST : ", stateArrRet);
            setOptionsState(stateArrRet);
            if ( state.action === 'add') {
              setStateIdSelect(stateArrRet[0]);
            }
            else {
            //stateListRef.current =  stateArrRet;
            var findState = stateArrRet.find(element => element.value === state.passed_element.state_id);
            if (findState ) {
              setStateIdSelect({value: state.passed_element.state_id, label: findState.label});
            }
            }
          }
        }
        */

        //getStateListWrapper();
        /*
        (async () => {
          var stateIdInput = 1;
          var retStateCustomer = false;
          var customerInfo = {};
         var customerId = passedValue.customer_id;
          //var [retStateSellProd, newSellProdArr] = await getProductsSellAll();
          {/ *
          if ( retStateSellProd ) {
            console.log("RETURNED SELL PRODUCT LIST : ", newSellProdArr);
            setProductMaster(newSellProdArr);
          
          }
        * /}
          
          if (state.action === "edit" || state.action === "view") {
          if ( parseInt( customerId) > 0 ) {
            //[retStateCustomer, customerInfo] = await getCustomerDetails(customerId);

            //await getOrderAddress();
            //await getOrderDetails();
            //await getOrderItemsByOrderId();
            //await getDCListByOrderId();
          }
            //await getOrderAddress();
           //await getOrderDetails();
            ////await getOrderItemsByOrderId();
            //await getDCListByOrderId();
            setIsReadOnly(true);
            //setDisableState(true);
          }
          else {
            setIsReadOnly(false);
            //setDisableState(false);
          }
          
          if (state.action === 'view' || state.action === 'edit') {
            if ( parseInt( customerId) > 0 ) {  
              stateIdInput = customerInfo.state_id;
            }
            else {
              stateIdInput = 1
            }
          }
          else {
            stateIdInput = appGlobal.DEFAULT_STATE;
          }
         /*
          var retStateDist = false;
          var distArrRet = [];
          [retStateDist, distArrRet] = await getDistrictList(stateIdInput);

          if (retStateDist) {
            setOptDist(distArrRet);
            if (state.action === 'add') {
              //setDistrictIdSelect(distArrRet[0]);
              var findDist = distArrRet.find(element => element.label === appGlobal.DEFAULT_DISTRICT);
              if (findDist !== undefined) {
                console.log("++++++++++++++++++------------------- FOUND DISTRICT : ", findDist);
                setDistrictIdSelect(findDist);
              }
            }
            else {
              var findDist = distArrRet.find(element => element.value === customerInfo.district_id);
              if (findDist !== undefined) {
                console.log("++++++++++++++++++------------------- FOUND DISTRICT : ", findDist);
                //setDistrictIdSelect({ value: customerInfo.district_id, label: findDist.label });
                setDistrictIdSelect(findDist);
              }
            }
          }
          
         
          var country_id = appGlobal.COUNTRY_ID;
          var [retStateStList, stateArrRet] = await getStateList(country_id);
          if (retStateStList) {
            setOptionsState(stateArrRet);
            if (state.action === 'add') {
              setStateIdSelect(stateArrRet[0]);
            }
            else {
              var findState = stateArrRet.find(element => element.value === customerInfo.state_id);
              if (findState) {
                console.log("STATE FOUND OBJECT : ", findState);
                setStateIdSelect({ value: customerInfo.state_id, label: findState.label });
              }
            }
          }
          * /
          
          setToRender(true);
          /*
          if ( state.action === 'add' ) {
            var [retStateInvNumber, retExpectedInvNumber] = await getExpectedInvNumber();
            if ( retStateInvNumber ) {
              console.log("<<<<<<<<<<<<<<<<  EXPECTED INVOICE NUMBER : ", retExpectedInvNumber);
              expected_inv_number_ref.current = retExpectedInvNumber;
              setFormValuesOrder({ ...formValuesOrder, ["expected_inv_number"]: retExpectedInvNumber});
              //formValues["expected_inv_number"] = retExpectedInvNumber;
            }
          }
         * /
        })();
        */
         setToRender(true);
       
        setDateIdSelect(optionsDate[0]);
       
        initialized.current = true;
      }
    }
  }, []);

  const handleSubmit = async (event, actionVal) => {
    event.preventDefault();
    console.log("handleSubmit called ...");

    console.log("FORM VALUES at handleSubmit: ", formValues);
    console.log("LINE ITEMS : ", items);
    console.log("Form submit called ...");

    console.log("CUSTOMER : ", formValues["customer_name"]);

    console.log("GST Number : ", formValues["gst_number"]);
    console.log("Phone Number : ", formValues["phone"]);

    //console.log("Tot number of items : ", total_items);
    //console.log("Sum of Tot Product Cost : ", sum_product_cost);
    //console.log("Sum of Tot GST : ", sum_product_gst);
    //console.log("Sum of total cost : ", sum_product_total);

    //console.log("SEARCHED CUSTOMER : ", searchedCustomer);
   /*
    formErrors.alertMsg = "";
    var customer_name_tmp = "";
    var customer_id_tmp = 0;

    if (!searchedCustomer || Object.keys(searchedCustomer).length < 1) {
      customer_name_tmp = customerSearchText;
      customer_id_tmp = 0;
    } else {
      customer_name_tmp = searchedCustomer.label;
      customer_id_tmp = searchedCustomer.value;
    }

    console.log("Customer name to submit : ", customer_name_tmp);
    setFormValues({ ...formValues, ["customer_name"]: customer_name_tmp });
    
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log("After validateErrors and setsubmit call ...");

    if (validateErr) {
      console.log("Validate error occured ...");
      return 0;
    }
    

    var orderIdTmp = 0;
    var actionTmp = "add";
    var billingAddrIdTmp = 0;
    var shipAddrIdTmp = 0;
    var customerId = 0;
    var orderNoTmp = "";
    if (state.action === "view" || state.action === "edit") {
      orderIdTmp = orderInfoDet.order_id;
      //orderNoTmp = orderInfoDet.order_no;
      orderNoTmp = formValuesOrder["expected_inv_number"];
      billingAddrIdTmp = orderAddress.billing_address.billing_address_id;
      shipAddrIdTmp = orderAddress.delivery_address.delivery_address_id;

      customer_id_tmp = formValues["customer_id"];
      //formValuesOrder["order_date"] = orderInfoDet.order_date;
      actionTmp = "edit";
    }
    else {
      orderNoTmp = formValuesOrder["expected_inv_number"];
    }

    console.log("ORDER NUMBER >>>>>>>>>>>>>>>>>>>>> : ", orderNoTmp);

    if (items.length < 1) {
      //setShowMsg("Please add atleast one product in order");
      //setMsgType("warning");
      //setShow(true);
      console.log("Empty product list ...");
      setFormErrors({ ...formErrors, ["alertMsg"]: "Add atleast one item" });
      return 0;
    }

    

    var emptyItem = false;
    items.map((item) => {
      console.log("Item value : ", item);
      if (item.product_id < 1 || !item.product_name) {
        item.error = "Please select product from list";
        //setShowMsg("Please selecyt product from dropdown");
        //setMsgType("warning");
        //setShow(true);
        emptyItem = true;
        return 0;
      } else {
        item.error = "";
      }
    });

    if ( emptyItem === false ) {
    emptyItem = false;
    items.map((item) => {
      console.log("Item value : ", item);
      if ( item.product_type === "PRODUCT") {
      if ( !item.quantity || item.quantity < 0.01 ) {
        item.error_qty = "Please select valid quantity";
        //setShowMsg("Please selecyt product from dropdown");
        //setMsgType("warning");
        //setShow(true);
        emptyItem = true;
        return 0;
      } else {
        item.error_qty = "";
      }
    }

    });
  }

    console.log("<<<<<<<<<<<<<<<< API calling continue >>>>>>>>>>>>>>>>>>");
    if (emptyItem) {
      console.log("unselcted product item so exit from submission ...");
      return 0;
    }

    var dispNameTmp = "";
    var dispNameFromField = formValues["customer_disp_name"];
    
    
    if (dispNameFromField === "" || dispNameFromField === null || dispNameFromField === undefined) {
      dispNameTmp = customer_id_tmp;
      
    }
    else {
      dispNameTmp = dispNameFromField;
    }
    
    let reqValidateJwt = {
      / *
      company: formValues["company"],
      address: formValues["address"],
      city: formValues["city"],
      zip: formValues["zip"],

      phone: formValues["phone"],
      gst_number: formValues["gst_number"],

      district: formValues["district"],
      state: formValues["state"],
      * /

      customer_name: customer_name_tmp,
      customer_id: customer_id_tmp,
      customer_disp_name: dispNameTmp,
      gst_number: formValues["gst_number"] || "",
      phone: formValues["phone"] || "",
      primary_number: formValues["primary_number"] || "",
      secondary_number: formValues["secondary_number"] || "",
      contact_person: formValues["contact_person"] || "",
      contact_number: formValues["contact_number"] || "",
      email: formValues["email"] || "",
      address1: formValues["address1"] || "",
      address2: formValues["address2"] || "",
      distId: districtIdSelect.value,
      stateId: stateIdSelect.value,
      pincode: formValues["pincode"] || "",
      website: formValues["website"] || "",
      notes: formValues["notes"] || "",

      total_items: total_items,
      sum_product_cost: sum_product_cost,
      sum_product_gst: sum_product_gst,

      sum_product_cgst: sum_product_cgst,
      sum_product_sgst: sum_product_sgst,
      sum_product_igst: sum_product_igst,

      amount_discount: amount_discount,

      sum_product_total: sum_product_total,

      orderItems: JSON.stringify(items),

      order_id: orderIdTmp,
      order_no: orderNoTmp.toString(),

      ship_addr_id: shipAddrIdTmp,
      ship_contact_name: formValues["contact_person"] || "",
      ship_contact_number: formValues["contact_number"] || "",
      ship_address1: formValues["address1"],
      ship_address2: formValues["address2"],
      ship_pincode: formValues["pincode"] || "",

      bill_addr_id: billingAddrIdTmp,
      bill_contact_name: formValues["contact_person"] || "",
      bill_contact_number: formValues["contact_number"] || "",
      bill_address1: formValues["address1"],
      bill_address2: formValues["address2"],
      bill_pincode: formValues["pincode"] || "",
      
      bill_gst_include: gstBillStatus,
      order_date: formValuesOrder["order_date"]
    };

   
    console.log("payload:", reqValidateJwt);
   /*
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers ,Authorization: `Bearer ${aIVal}`}

      if ( actionVal === 'save_print') {
        setSubmitPrintAction(true);
      }
      else {
        setSubmitAction(true);
      }
      
      var res = await axios.post(client.domain + "/insertInvoice", reqValidateJwt, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.res_data);
        if ( actionVal === 'save_print') {
          setSubmitPrintAction(false);
        }
        else {
          setSubmitAction(false);
        }
        setAlertTitle("Success");
        setAlertPurpose('success');
        if(state.action === "add"){ 
          setAlertBody("Invoice Saved Successfully!");
        } 
       else {
        setAlertBody("Invoice Updated Successfully!");
        }
        setShowAlert(true);

        if ( actionVal === 'save-print' ) {
          navigate(-1);

          var urlStr = window.location.href;
          var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
          var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));
      
          console.log("CALLED URL : ", urlTmp);
          console.log("CALLED PAGE : ", paramsTmp);
      
          //window.open(`${urlTmp}${client.urlDelimiter}/printFullOrder?order_id=${passedValue.order_id}`);
          var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printFullOrder?order_id=${res.data.detail.res_data.order_id}`, "width=" + window.screen.width + ",height=" + window.screen.height);
          winPrint.moveTo(0,0);
          winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
        }
        else {
          setShowAlert(true);
        }  
      }
      else {
        setSubmitAction(false);
        setAlertPurpose('failed');  
        setAlertTitle("Failed");
        if(state.action === 'add'){    
          setAlertBody("Invoice Save Failed!");
        }
        else{
          setAlertBody("Invoice Update Failed!");
        }
       
        setShowAlert(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      setAlertPurpose("failure");
      setAlertTitle("Warning");
      setAlertBody("Unable to process your request at this moment.Please try after sometime!");
      setShowAlert(true);
      
     
      setSubmitAction(false);
    }
    */

    
    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    //window.open(`${urlTmp}${client.urlDelimiter}/printFullOrder?order_id=${passedValue.order_id}`);
    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printBillPayment?`, "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0,0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
  };
  const validate = (values) => {
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    //if (!customerSearchText && !searchedCustomer) {
    if (!values.customer_name){
      console.log("Customer name is missing ...", formValues["customer_name"]);
      //errors.customer_name = "Customer name is required!";
      //validateErr = true;
    }
   
    if (!values.customer_disp_name) {
      errors.customer_disp_name = "Customer name is required!";
      validateErr = true;
    }
    /*
    if (!values.addl_notes) {
      errors.addl_notes = "Customer name is required!";
      validateErr = true;
    }
    */
    
    
  
    /*
    if (!values.phone) {
      errors.phone = "Phone  is required!";
      validateErr = true;
    }
    */
    /*
    if (values.phone && values.phone.length != 10) {
      errors.phone = "Phone number should be 10 digits";
      validateErr = true;
    }
    */
      if ( gstBillStatus === true ){
      if (!values.gst_number || values.gst_number === '0' ) {
        errors.gst_number = "GST is required!";
        validateErr = true;
      }
    }
     
      /*
      if (!values.contact_number) {
        errors.contact_number = "Contact person number is required!";
        validateErr = true;
      }
      */
     /*  
    if (!values.address1) {
      errors.address1 = "Street / Location is required!";
      validateErr = true;
    }
    */
  
      /*
    if (!values.address2) {
      errors.address2 = "City or Town name is required!";
      validateErr = true;
    }
  */
    
    /*
    if (!values.pincode) {
      errors.pincode = "Pincode is required!";
      validateErr = true;
    }
    */
    /*
    if (values.pincode && values.pincode.length != 6) {
      errors.pincode = "Pincode should be 6 digits";
      validateErr = true;
    }
    */
    /*
    if (!values.primary_number) {
      errors.primary_number = "Mobile number is required";
      validateErr = true;
    }

    if (values.primary_number && values.primary_number.length != 10) {
      errors.primary_number = "Mobile number should be 10 digits";
      validateErr = true;
    }
    */
    // Delivery address validation
    /*
    if (!formValues.shipContactName) {
      errors.shipContactName = "Contact name is required!";
      validateErr = true;
    }

    if (!formValues.shipContactNumber) {
      errors.shipContactNumber = "Contact number is required!";
      validateErr = true;
    }
    */
    /*
    if (!formValues.shipPincode) {
      errors.shipPincode = " Pincode is required!";
      validateErr = true;
    }
    */

    /*
    if (!formValues.shipAddress2) {
      errors.shipAddress2 = "Town / City is required!";
      validateErr = true;
    }
    */

    // Billing address validation
    /*
    if (!formValues.billContactName) {
      errors.billContactName = "Contact name is required!";
      validateErr = true;
    }

    if (!formValues.billContactNumber) {
      errors.billContactNumber = "Contact number is required!";
      validateErr = true;
    }
    */

    /*
    if (!formValues.billAddress2) {
      errors.billAddress2 = "Town / City is required!";
      validateErr = true;
    }
    */
    /*
    if (!formValues.billPincode) {
      errors.billPincode = "Pincode is required!";
      validateErr = true;
    }
    */
    return errors;
  };

  /*
  const saveHandler = async (event) => {
    // Prevent page reload
    event.preventDefault();

    console.log("Form submit clicked ...");

    console.log("DATA : ", document.forms[0]);

    / *
    var { username, password } = document.forms[0];
    console.log("username : ", username.value);
    console.log("pasword : ", password.value);
    * /
  };
  */

  /*
  const backHandler = async (event) => {
    event.preventDefault();
    console.log("Back button clicked ...");
  };
  */

  const newItemHandle = async (event) => {
    event.preventDefault();
    console.log("New item addition button clicked ...", productMaster[0]);

    const newitems = items.slice();
    //const newitems = [];
    //let itemNew = { id: 1, product: { value: 0, label: "" }, unit_cost: "", qty: 1, "product_cost": "", "total_cost": "", gst: { cgst: 0.00, sgst: 0.00, igst: 0.00, totGst: 0.00 } };
    let itemNew = {
      //id: 0,
     // product_id: productMaster[0].id,
      //product_name: productMaster[0].name,
      //product_type:productMaster[0].product_type,
      rate: 0.00,
      quantity: "",
      unit_price: "",
      area: 1,
      //total_rate: 0.00,
      total_price: "",
      //cgst: 0.00,
      //sgst: 0.00,
      //igst: 0.00,
      //total_item_price: 0.00,
      addl_notes: "",
      
      editAction: false,
      enableState: false,
      composition: [],
      is_product_cc: false,
    };
    
    newitems.push(itemNew);
    setItems(newitems);
    
  };

  const handleProductSelect = (e, data) => {
    console.log(">>>>>>>>>>>>>>>>>>>>>>> HandleProductSelect called *******************");
    console.log("SELECTED Dropdown value : ", e);
    console.log("SELECTED element id : ", data);
    var currentId = data.name.substring(data.name.lastIndexOf("-") + 1);
    console.log("Current id : ", currentId);

    var itemSelected = items[currentId];
    //console.log("Product: ", itemSelected.product);
    console.log("********************** itemSelected: ", itemSelected);
    console.log("<<<<<<<<<<<<<<<<<<<<< ITEM ID >>>>>>>>>>>>>>>>>>: ", itemSelected.id);
    console.log("Price : ", itemSelected.unit_price);
    console.log("Product cost : ", itemSelected.total_item_price);
    console.log("GST : ", parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst));
    console.log("Total cost : ", itemSelected.total_price);
    console.log("Qty : ", itemSelected.quantity);

    console.log("Product Info : ", productMaster);
    const currentProductIndex = productMaster.findIndex((productInd) => productInd.name === e.label);
    console.log("Currently selected row product id : ", currentProductIndex);
    console.log("Currently selected row product value : ", productMaster[currentProductIndex]);

    itemSelected.product_id = e.value;
    itemSelected.product_name = e.label;
    itemSelected.enableState = true;
    //itemSelected.isEditable = true;
    itemSelected.editAction = false;
    //itemSelected.product.label = e.label;
    
    var productNew = {
      label: e.label,
      value: e.value,
      product_id: e.product_id,
      product_name: e.product_name,
      stock_id: e.stock_id,
      unit_cost: e.unit_cost,
      //cgst: e.cgst,
      //sgst: e.sgst,
      //igst: e.igst,
    };
    itemSelected.product = productNew;
    
    //itemSelected.price = productMaster[currentProductIndex].unit_cost;
    /*
    itemSelected.cgst_perc = productMaster[currentProductIndex].cgst;
    itemSelected.sgst_perc = productMaster[currentProductIndex].sgst;
    itemSelected.igst_perc = productMaster[currentProductIndex].igst;
    itemSelected.product_type = productMaster[currentProductIndex].product_type;
    itemSelected.unit_price = productMaster[currentProductIndex].unit_price;
    if ( (itemSelected.quantity && productMaster[currentProductIndex].unit_price) && parseFloat(productMaster[currentProductIndex].unit_price) !== isNaN && itemSelected.quantity) {
      itemSelected.total_item_price = productMaster[currentProductIndex].unit_price * itemSelected.quantity;
    }
    else {
      itemSelected.total_item_price = 0.00;
    }
    var cgstCalculate = 0.0;
    if (gstBillStatus) {
      cgstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].cgst) / 100;
    }
    itemSelected.cgst = cgstCalculate;

    var sgstCalculate = 0.0;
    if (gstBillStatus) {
      sgstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].sgst) / 100;
    }
    itemSelected.sgst = sgstCalculate;

    var igstCalculate = 0.0;
    if (gstBillStatus) {
      igstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].igst) / 100;
    }
    itemSelected.igst = igstCalculate;
    //let totalGst = cgstTmp + sgstTmp + igstTmp;

    //var gst = { cgst: cgstTmp, sgst: sgstTmp, igst: igstTmp, totGst: totalGst }
    //itemSelected.gst = gst
    //itemSelected.product_cost = productMaster[currentProductIndex].unit_cost * itemSelected.qty;

    itemSelected.total_price =
      parseFloat(itemSelected.total_item_price) +
      parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst);

    console.log("Modified unit cost : ", itemSelected.unit_price);
    console.log("Modified Product cost : ", itemSelected.total_item_price);
    console.log("Modified Total cost : ", itemSelected.total_price);
    console.log("Modified Additional Notes : ", itemSelected.addl_notes);
   */

    const newitems = items.slice();
    newitems[currentId] = itemSelected;
    setItems(newitems);
    //var fieldValue = "product";
    //setFormValues({ ...formValues, [fieldValue]: e })
    setIsEditable(false);
  };

  /*
  const searchActionFunc = (json) => {
    setOptionsSearch(json.detail.res_data.district_list);
    setIsLoading(false)
    console.log("SEARCHED VALUE inside searchActionFunc : ", json.detail.res_data.district_list);
  }
  */

  const supplierSearchSelectFunc = async (selected) => {
    console.log("**************** User selected value from search result +++++++++++++ : ", selected);
    if (selected.length > 0) {
      setSearchedCustomer(selected[0]);
      
      const response = await fetch(`${client.domain}/getCustomerInfo?customer_id=${selected[0].value}`);
      const supplier_info = await response.json();
      console.log("CUSTOMER INFO : ", supplier_info.detail.res_data.supplier_info);
      //setIsLoading(false);

      //setFormValues({...formValues, [name]: value});
      //let entries = Object.entries(company_info.detail.res_data.company_info)
      //entries.map( ([key, val] = entry) => {
      //entries.map( ([key, val] = entry) => {
      for (const [key, value] of Object.entries(
        supplier_info.detail.res_data.supplier_info
      )) {
        //console.log("KEY : ", key);
        //console.log("VAL : ", val);
        console.log("KEY : %s, VAL: %s", key, value);

        //setFormValues({...formValues, [key]: value});
      }

      setFormValues(supplier_info.detail.res_data.supplier_info);
      if ( gstBillStatus ) {
        if ( !supplier_info.detail.res_data.supplier_info.gst_number || supplier_info.detail.res_data.supplier_info.gst_number === '0') {
          setAlertPurpose("gst_validate");
          setAlertTitle("Warning");
          setAlertBody("Customer GST not available, needs to update customer detail. \nPress Ok to update GST.\nPress Cancel to continue invoice without GST");
          setShowAlert(true);
        }
    }
      //setIsLoading(true);

      console.log("STATE ID : ", supplier_info.detail.res_data.supplier_info["state_id"]);
      setStateIdSelect({
        value: supplier_info.detail.res_data.supplier_info["state_id"],
        label: supplier_info.detail.res_data.supplier_info["state_name"],
      });
      console.log("DISTRICT ID : ", supplier_info.detail.res_data.supplier_info["dist_id"]);
      setDistrictIdSelect({
        value: supplier_info.detail.res_data.supplier_info["dist_id"],
        label: supplier_info.detail.res_data.supplier_info["district_name"],
      });

      //setFormValues({...formValues, ["company_disp_name"]: "ABC"});
      //setFormValues({...formValues, ["pincode"]: "641654"});

      console.log("FORMVALUES 2 : ", formValues);
    }
  };
  
  const getCustomerDetails = async (customerId) => {
    var retState = false;
    //console.log("**************** User selected value from search result +++++++++++++ : ", selected);
    //if (selected.length > 0) {
    //setSearchedCompany(selected[0])
    //var customerId = passedValue.customer_id;

    const response = await fetch(`${client.domain}/getCustomerInfo?customer_id=${customerId}`);
    const supplier_info = await response.json();
    retState = true;
    console.log("CuSTOMER INFO : ", supplier_info.detail.res_data.supplier_info);

    //setFormValues({...formValues, [name]: value});
    //let entries = Object.entries(company_info.detail.res_data.company_info)
    //entries.map( ([key, val] = entry) => {
    //entries.map( ([key, val] = entry) => {
    for (const [key, value] of Object.entries(supplier_info.detail.res_data.supplier_info)) {
      //console.log("KEY : ", key);
      //console.log("VAL : ", val);
      console.log("KEY : %s, VAL: %s", key, value);

      //setFormValues({...formValues, [key]: value});
    }

    //setFormValues(customer_info.detail.res_data.customer_info);

    formValues["customer_id"] = supplier_info.detail.res_data.supplier_info.customer_id;
    formValues["customer_name"] = supplier_info.detail.res_data.supplier_info.customer_name;
    formValues["customer_disp_name"] = supplier_info.detail.res_data.supplier_info.customer_disp_name;
    formValues["contact_person"] = supplier_info.detail.res_data.supplier_info.contact_person;
    formValues["contact_number"] = supplier_info.detail.res_data.supplier_info.contact_number;
    formValues["gst_number"] = supplier_info.detail.res_data.supplier_info.gst_number;
    formValues["phone"] = supplier_info.detail.res_data.supplier_info.phone;
    formValues["email"] = supplier_info.detail.res_data.supplier_info.email;
    formValues["website"] = supplier_info.detail.res_data.supplier_info.website;
    formValues["address1"] = supplier_info.detail.res_data.supplier_info.address1;
    formValues["address2"] = supplier_info.detail.res_data.supplier_info.address2;
    
    formValues["pincode"] = supplier_info.detail.res_data.supplier_info.pincode;
    formValues["primary_number"] = supplier_info.detail.res_data.supplier_info.primary_number;
    formValues["secondary_number"] = supplier_info.detail.res_data.supplier_info.secondary_number;

    if (state.action === "edit" || state.action === "view") {
      console.log(
        "######################## Condition met for view or edit %%%%%%%%%%%%%%%%%%%%"
      );
      setSupplierSearchText(supplier_info.detail.res_data.supplier_info.customer_name);
      //setIsLoading(true);
    }

    /*
    if (!customer_info.detail.res_data.customer_info.gst_number && !gstBillStatus){
      setAlertPurpose('failed');
      setShowAlert(true);
      setAlertDialogBody("Please enter GST Number");
      setAlertDialogTitle("CONFIRMATION!");
    }
    */

    console.log("STATE ID : ", supplier_info.detail.res_data.supplier_info["state_id"]);
    setStateIdSelect({
      value: supplier_info.detail.res_data.supplier_info["state_id"],
      label: supplier_info.detail.res_data.supplier_info["state_name"],
    });
    console.log("DISTRICT ID : ", supplier_info.detail.res_data.supplier_info["dist_id"]);
    setDistrictIdSelect({
      value: supplier_info.detail.res_data.supplier_info["dist_id"],
      label: supplier_info.detail.res_data.supplier_info["district_name"],
    });

    //setFormValues({...formValues, ["company_disp_name"]: "ABC"});
    //setFormValues({...formValues, ["pincode"]: "641654"});

    console.log("FORMVALUES 2 : ", formValues);
    //}
    return [retState, supplier_info.detail.res_data.supplier_info];
  };

  const getExpectedInvNumber = async() => {
    var retState = false;
    var retExpectedInvNumber = 0;
    var retState = false;
    var prodRes = {};
    console.log("getExpectedInvNumber called ... ");

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`, SerialKey: 'HNGD8743JESWE'};
      var res = await axios.get(client.domain + "/getExpectedInvNumber", { headers }, { withCredentials: false });
      console.log("Response from server getExpectedInvNumber : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getExpectedInvNumber : ", res.data.detail.data);
        retState = true;
        retExpectedInvNumber = res.data.detail.data.expected_inv_number;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
        }
      }
    }
    return [retState, retExpectedInvNumber];
  } 

  /*
  const searchHandleFunc = async (query) => {
    setIsLoading(true);

    fetch(`${client.domain}/search/customers?q=${query}`)
      .then((resp) => resp.json())
      .then((json) => {
        console.log("RESPONSE FOR SUPPLIER SEARCH : ", );
        //searchActionFunc(json)
        setOptionsSearch(json.detail.res_data.supplier_list);
        setIsLoading(false);
        console.log("SEARCHED VALUE inside searchActionFunc : ", json.detail.res_data.supplier_list);
      });
    //formValues["customer_name"] = query;
    //setFormValues({ ...formValues, ["customer_disp_name"]: query });
    / *
    if ( addrCopyCheck && !formValues.contact_person) {
      setFormValues({ ...formValues, ["shipContactName"]: query });
    }
    * /
    //setFormValues({ ...formValues, ["customer_name"]: query });
    setSupplierSearchText(query);
  }
  */

  
  console.log("FORM VALUES Before rendering :", formValues);

  var total_items = 0;
  var sum_product_cost = 0.0;
  var sum_product_gst = 0.0;
  var sum_product_cgst = 0.0;
  var sum_product_sgst = 0.0;
  var sum_product_igst = 0.0;

  var sum_product_total = 0.0;
  var amount_discount = 0.0;
  var sum_rounded_off = 0.00;

  console.log("<<<<<<<<<<<<<<<<+++++++++++++++ Length of items : ", items.length);
  items.map((item) => {
    console.log("IND ITEM : ", item);
    console.log("Product Cost : ", item.total_item_price);
    var totGst = item.cgst + item.sgst + item.igst;
    console.log("GST  : ", totGst);

    //console.log("GST  : ", item.gst.cgst);
    //console.log("GST  : ", item.gst.sgst);
    //console.log("GST  : ", item.gst.igst);

    console.log("Total Price : ", item.total_price);
    total_items = total_items + 1;
    sum_product_cost = sum_product_cost + parseFloat(item.total_item_price);

    //sum_product_gst = sum_product_gst + item.gst.totGst;
    sum_product_cgst = sum_product_cgst + item.cgst;
    sum_product_sgst = sum_product_sgst + item.sgst;
    if ( parseFloat(item.igst) !== isNaN ) {
      sum_product_igst = sum_product_igst + item.igst;
    }
    else {
      sum_product_igst = sum_product_igst + 0.00;
    }

    //sum_product_total = sum_product_total + item.total_cost;
    //sum_product_total =  sum_product_total +
  });

  console.log("************** SUM PRODUCT COST : ", sum_product_cost);

  //sum_product_cgst = Math.round((sum_product_cgst * 100)/100);
  //sum_product_sgst = Math.round((sum_product_sgst * 100)/100);
  console.log("^^^^^^^^^^^^^^^^^^^^^ IGST BEFORE ROUNDED OFF:", sum_product_igst);
  if ( sum_product_igst !== 'NaN' || sum_product_igst !== NaN ) {
    //sum_product_igst = Math.round((sum_product_igst * 100)/100);
  }
  else {
    sum_product_igst = 0.00;
  }
  console.log("************** CGST : ", sum_product_cgst);
  console.log("************** SGST : ", sum_product_sgst);
  console.log("************** IGST : ", sum_product_igst);
  sum_product_gst = sum_product_cgst + sum_product_sgst + sum_product_igst;
  sum_product_total = sum_product_cost + sum_product_gst;
  console.log("************** SUM PRODUCT TOTAL 1 : ", sum_product_total);

  sum_product_total = sum_product_total - amount_discount;
  console.log("************** SUM PRODUCT TOTAL 2 : ", sum_product_total);
  //sum_product_total = Math.round((sum_product_total + Number.EPSILON)*100)/100;

  console.log("Tot number of items : ", total_items);
  console.log("Sum of Tot Product Cost : ", sum_product_cost);

  console.log("Sum of Tot CGST : ", sum_product_cgst);
  console.log("Sum of Tot SGST : ", sum_product_sgst);
  console.log("Sum of Tot iGST : ", sum_product_igst);

  console.log("Sum of Tot GST : ", sum_product_gst);

  console.log("Sum of total cost : ", sum_product_total);

  //var tmp_val2 = sum_product_total - Math.round(sum_product_total);
  var tmp_val2 = Math.round(sum_product_total) - sum_product_total;
  console.log("<<<<<<<<<<<<<< ROUNDED OFF 1 : ", tmp_val2);
  sum_rounded_off = Math.round((tmp_val2 + Number.EPSILON)*100)/100;
  console.log("<<<<<<<<<<<<<< ROUNDED OFF 2 : ", sum_rounded_off);

  

  console.log("Currently selected district : ", districtIdSelect);
  console.log("opt district : ", optDist);
  //console.log("*********** opt district value ############# : ", optDist.value);

  console.log("Currently selected state : ", stateIdSelect);
  console.log("+++++++++++++++ Customer search text ^^^^^^^^^^^^^^^^^^^^  :", supplierSearchText);
  console.log("Searched customer <<<<<<<<<<<<<<<<<<< : ", searchedCustomer);

  const [addrCopyCheck, setAddrCopyCheck] = useState(false);

  const [refresh, setRefresh] = useState(false);
  

  /*
  const handleCopyToShipping = async (e) => {
    console.log("handleCopyToShipping called : ", e);
    console.log("handleCopyToShipping called CHECKED : ", e.target.checked);
    
    //var contactPersontmp = formValues["contact_person"];
    //var contactPersonAssign = "";
    //var contactNumbertmp = formValues["contact_number"];
    //var contactNumberAssign = "";
    //setFormValues({...formValues, ["addrCopyCheck"]: e.currentTarget.checked});
      if (e.target.checked === true) {
        if ( !formValues.contact_person ) {
         formValues["shipContactName"] = formValues["customer_name"]; 
        }
        else {
          formValues["shipContactName"] = formValues["contact_person"];
        }

        if ( !formValues.contact_number ) {
          formValues["shipContactNumber"] = formValues["phone"];
        }
        else {
          formValues["shipContactNumber"] = formValues["contact_number"];
        }
        formValues["shipAddress1"] = formValues["address1"];
        formValues["shipAddress2"] = formValues["address2"];
        setFormValues({ ...formValues, ["shipPincode"]: formValues["pincode"] });
        //setFormValues(e.target.checked);
      }
      setAddrCopyCheck(e.target.checked);
      setRefresh(true);
    };
    */
      
      /*
      var contactPersontmp = formValues["contact_person"];
      var contactPersonAssign = "";

      console.log("Customer contact name before check : ", contactPersontmp);

      console.log("Customer contact name length before check : ", contactPersontmp.length);

      if (contactPersontmp.length > 0) {
        contactPersonAssign = contactPersontmp;
      } else {
        var customer_name_tmp = "";
        var customer_id_tmp = 0;

        console.log("searched customer array : ", searchedCustomer);
        console.log("searched customer length : ", searchedCustomer.length);
        console.log("searched customer length Object : ", Object.keys(searchedCustomer).length);
        //if (!searchedCustomer || searchedCustomer.length < 1) {
        if (Object.keys(searchedCustomer).length < 1) {
          console.log("Inside IF condition");
          customer_name_tmp = customerSearchText;
          customer_id_tmp = 0;
        } else {
          console.log("Inside ELSE conditiob");
          customer_name_tmp = searchedCustomer.label;
          customer_id_tmp = searchedCustomer.value;
        }

        console.log("Customer name to submit : ", customer_name_tmp);

        contactPersonAssign = customer_name_tmp;
      }

      var contactNumbertmp = formValues["contact_number"];
      var contactNumberAssign = "";

      console.log("Contact number : ", contactNumbertmp);
      if (contactNumbertmp) {
        contactNumberAssign = contactNumbertmp;
      } else {
        contactNumberAssign = formValues["phone"];
      }

      formValues["shipContactName"] = contactPersonAssign;
      formValues["shipContactNumber"] = contactNumberAssign;
      formValues["shipAddress1"] = formValues["address1"];
      formValues["shipAddress2"] = formValues["address2"];
      //formValues["billPincode"] =  formValues["pincode"];
      //setFormValues({...formValues, ["billContactName"]: formValues["contact_person"]});
      //setFormValues({...formValues, ["billContactNumber"]: formValues["contact_number"]});
      //setFormValues({...formValues, ["billAddress1"]: formValues["address1"]});
      //setFormValues({...formValues, ["billAddress2"]: formValues["Address2"]});
      setFormValues({ ...formValues, ["shipPincode"]: formValues["pincode"] });

      //setBillDistrictIdSelect(districtIdSelect);
    }
  };
   */
  const [addrCopyBillCheck, setAddrCopyBillCheck] = useState(false);

  /*
  const handleCopyToBilling = async (e) => {
    console.log("handle copy to billing called :", e);
    console.log("handle copy to billing called CHECKED :", e.target.checked);
    setAddrCopyBillCheck(e.target.checked);

    if (e.target.checked === true) {
      formValues["billContactName"] = formValues["shipContactName"];
      formValues["billContactNumber"] = formValues["shipContactNumber"];
      formValues["billAddress1"] = formValues["shipAddress1"];
      formValues["billAddress2"] = formValues["shipAddress2"];
      setFormValues({...formValues, ["billPincode"]: formValues["shipPincode"]});
    }
    setRefresh(true);
  };
  */

  const handleItemDelete = (e) => {
    console.log("handle item delete called ...", e);
    console.log("handle item delete e.currentTarget : ", e.currentTarget.id);
    console.log("handle item delete e.target : ", e.target.id);

    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected DELETE element name : ", nameOfElement);
      console.log("Current SELECTED DELETE id : ", curSelId);
      console.log("CUR SELECTED DELETE VALUE : ", items[curSelId]);

      let sliced = items.splice(curSelId, 1);
      console.log("Removed item from order items: ", sliced);
      var newItem = items.slice();
      setItems(newItem);

      //setRefresh(true);
      //console.log("Removed item from order items: ", sliced);
      //setRefresh(false);
    }
  };

  const handleBack = async (e) => {
    console.log("handle back called : ", e);
    navigate(-1);
  };
  /*

  const handleClose = async (e) => {
    setShow(false);
    //navigate(-1);
  };
  */

  const handleGSTBillStatus = async (e) => {
    console.log("HandleGSTBillStatus called : ", e);
    console.log("target checked : ", e.target.checked);

    var itemModNew = [];
    items.map((item) => {
      console.log("IND ITEM : ", item);

      var cgstCalculate = 0.0;
      var sgstCalculate = 0.0;
      var igstCalculate = 0.0;

      if (e.target.checked) {
        //if (item["product"] !== undefined) {
          console.log("CGST FROM EXISTING : ", item.cgst_perc);
          console.log("TOTAL ITEM PRICE FROM EXISTING : ", item.total_item_price);
          cgstCalculate = (item.total_item_price * parseFloat(item.cgst_perc)) / 100;
          sgstCalculate = (item.total_item_price * parseFloat(item.sgst_perc)) / 100;
          igstCalculate = (item.total_item_price * parseFloat(item.igst_perc)) / 100;
        //}
      }
      item.cgst = cgstCalculate;
      item.sgst = sgstCalculate;
      item.igst = igstCalculate;

      console.log("CALCULATED CGST : ", cgstCalculate);
      console.log("CALCULATED SGST : ", sgstCalculate);
      console.log("CALCULATED IGST : ", igstCalculate);
      item.total_price =
        item.total_item_price + cgstCalculate + sgstCalculate + igstCalculate;
      //item.total_price = Math.round(item.total_price+'e' + 2) + 'e-' + 2

      console.log("CALCULATED TOTAL : ", item.total_price);

      itemModNew.push(item);
    });

    setGstBillStatus(e.target.checked);
    setItems(itemModNew);
  };

  const handleEdit = async (e) => {
    console.log("Handle Edit clicked : ", e);
    console.log("Handle Edit clicked current target ID: ", e.currentTarget.id);

    setIsReadOnly(false);
    //setDisableState(false);
  };

  const getProductForLoad = async (productIdInput, prodIdIdx) => {
    var retState = false;
    var prodRes = {};
    console.log("get product for load called ... ");

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`, SerialKey: 'HNGD8743JESWE'};
      var res = await axios.get(client.domain + "/ims/getProduct?id=" + productIdInput, { headers }, { withCredentials: false });
      console.log("Response from server getProductForLoad : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getProductForLoad : ", res.data.detail.data);
        console.log("Response message from server getproductForLoad (Products) : ", res.data.detail.data);

        //setFormValues(res.data.detail.data);
        /*
        if ( res.data.detail.data.is_composite === true && res.data.detail.data.tt_key === 'SELL' && 'composite' in res.data.detail.data && res.data.detail.data.composite.length > 0 ) {
          setProductCompositionList(res.data.detail.data.composite);
        }
        */
        //setProductCompositionList(res.data.detail.data.composite);
        console.log("SELECTED PROD INDEX ID : ", prodIdIdx);
        console.log("SELECTED PROD DATA : ", items[prodIdIdx]);

        var selectedProdTmp2 = items[prodIdIdx];

        selectedProdTmp2.composition = res.data.detail.data.composite;

        var newItemsTmp2 = items.slice();
        newItemsTmp2[prodIdIdx] = selectedProdTmp2;
        setItems(newItemsTmp2);
        //setProductCompositionList(res.data.detail.data.composite);
        /*
        console.log("UOM array : ", uomOpt);
        var findUom = uomRef.current.find(element => element.value === res.data.detail.data.uom_key);
        console.log("**************** findUOM value ++++++++++++ : ", findUom);
        if (findUom !== undefined) {
          setUomIdSelect({ label: findUom.label, value: findUom.value })
        }
        */
        //setProdDetLoaded(true);
        //setToRender(true);
        //setEditAction(false);
        setShowConfigDialog(true);

        retState = true;
        prodRes = res.data.detail.data;
        console.log("PRODUCT LOAD STATUS SET TO TRUE")
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );

        }
      }
    }
    return [retState, prodRes];
  }

  const handleCompositeEdit = async (e, rowData) => {
    setEditAction(true);
    console.log("Handle Edit clicked FULL : ", e);
    console.log("Handle Edit clicked FULL currentTarget : ", e.currentTarget);
    console.log("Handle Edit clicked FULL target : ", e.target);
    var { id, name } = e.currentTarget;
    //var {id1, name1 } = e.currentTarget;
    console.log("id : ", id , ", name : " , name);
    //console.log("id : ", id, ", name : " , name);
    console.log("Handle Edit clicked FULL : ", rowData);
    
    //console.log("Handle Edit clicked currentTarget: ", rowData.currentTarget);
    //console.log("Handle Edit clicked target : ", rowData.target);
    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var productIdInput = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(0, clickedTagId.lastIndexOf("-"));
      console.log("current selected Edit element name : ", nameOfElement);
      console.log("Current SELECTED Edit id : ", productIdInput);
      console.log("CUR SELECTED Edit VALUE : ", items[productIdInput]);
      
      if ( items[productIdInput].addl_notes) {
        setFormValues({ ...formValues, addl_notes: items[productIdInput].addl_notes});
      }
      else {
        setFormValues({ ...formValues, addl_notes: ""});
      }
      

      setSelectedCompositionId(productIdInput);
      /*
      let sliced = items.splice(productIdInput, 1);
      console.log("Edit item from order items: ", sliced);
      var newItem = items.slice();
      setItems(newItem);
      */
    }
    

    // get composition from api call
    //https://api.erp.avinasoft.in/ims/getProduct?id=6

   
    var selectedItem = items[productIdInput];
     
    //var [retStateProdBuy, buyProdArr] =  await getProductBuy();
    //if ( retStateProdBuy ) {
      //setProductList(buyProdArr);
   // }
    var productIdPass = items[productIdInput].product_id;
    selectedItem.enableState = false;
    selectedItem.editAction = true;
    //if ( selectedItem.composition.length < 1 ) { 
      await getProductForLoad(productIdPass, productIdInput);
    //}
    //else {
    //  setShowConfigDialog(true);
    //}
     
    setEditAction(false);
    selectedItem.enableState = true;
    //selectedItem.isEditable=true;
    selectedItem.editAction = false;

    if (!selectedItem.hasOwnProperty("composition") ) {
      console.log("********************** Product composition doesn't exist +++++++++++++++++ ");
      //selectedItem.composition = [];
    }
    else {
      console.log("******************* Product composition already exist in items ++++++++++++++");
    }
   

    const newitems = items.slice();
    newitems[productIdInput] = selectedItem;
    setItems(newitems);
  };


  const handleClear = async (e) => {
    e.preventDefault();
    console.log("handle clear called : ", e);
    console.log("Handle clear clicked current target ID: ", e.currentTarget.id);
    navigate(0);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    console.log("handle save called : ", e);
    console.log("Handle save clicked current target ID: ", e.currentTarget.id);
  };

  const handleKeyDown = async (e) => {
    console.log("handle key down called : ", e);
    return e.keyCode !== 190;
  };

  const onChangeDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    setFormValuesOrder({ ...formValuesOrder, ["order_date"]: e.target.value });
  };

  const onChangeDeliveryDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    setFormValuesOrder({ ...formValuesOrder, ["delivery_date"]: e.target.value });
  };

  const onChangeRefDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    setFormValuesOrder({ ...formValuesOrder, ["ref_date"]: e.target.value });
  };

  const onChangeBuyerDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    setFormValuesOrder({ ...formValuesOrder, ["buyer_date"]: e.target.value });
  };

  const onChangeRRDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    setFormValuesOrder({ ...formValuesOrder, ["rr_date"]: e.target.value });
  };

  
  const onChangeDueDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    setFormValuesOrder({ ...formValuesOrder, ["due_date"]: e.target.value });
  };
/*
  const newItemHandleComposition = async (event) => {
    event.preventDefault();
    console.log("New item addition button clicked : ", event);
    console.log("New item addition button clicked target :", event.target);
    console.log("New item addition button clicked currentTarget : ", event.currentTarget);

    /*
    var { name, id } = event.currentTarget;
    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    var itemSelected = items[currentId];

    //console.log("Product: ", itemSelected.product);
    console.log("Product Name : ", itemSelected.name);
    console.log("Price : ", itemSelected.unit_price);
    console.log("Product cost : ", itemSelected.total_item_price);
    console.log("GST : ", parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst));
    console.log("Total cost : ", itemSelected.total_price);
    console.log("Qty : ", itemSelected.quantity);

    console.log("Product Info : ", productMaster);
    const currentProductIndex = productMaster.findIndex(
      (productInd) => productInd.name === itemSelected.product_name);
    console.log("Currently selected row product id : ", currentProductIndex);
    if (currentProductIndex < 0) {
      itemSelected.error = "Please select product from list";
    }
    * /
    //const newProductCompositionList = productCompositionList.slice();
    var selectedSellProdItem = items[selectedCompositionId];
    
    //let itemNew = { id: 1, product: { value: 0, label: "" }, unit_cost: "", qty: 1, "product_cost": "", "total_cost": "", gst: { cgst: 0.00, sgst: 0.00, igst: 0.00, totGst: 0.00 } };
    let itemNewComposite = {
      id: 0,
      product_id: 0,
      product_name: "",
      quantity: 0.00,
    };
    //selectedSellProdItem.composition = [];
    /*
    if ( selectedSellProdItem.composition.length < 1 ) {
      selectedSellProdItem.composition = [];
    }
    * /
    selectedSellProdItem.composition.push(itemNewComposite);
    //selectedSellProdItem.composition.push();
    //newProductCompositionList.push(itemNew);
    //setProductCompositionList(newProductCompositionList);
    //itemNew.composition.push();
    //itemsNew[selectedCompositionId].composition.push(itemNewComposite);
    const itemsNew = items.slice();
    itemsNew[selectedCompositionId] = selectedSellProdItem;
    console.log("modified with composition array : ", selectedSellProdItem);
    console.log("item array : ", itemsNew);
    setItems(itemsNew);
  };
  */
 
  const handleProductSelectComposition = (e, item, idx) => {
    console.log(">>>>>>>>>>>>>>>>>>>>>>> HandleProductSelect called *******************");
    console.log("SELECTED Dropdown value : ", e);
    console.log("SELECTED element full : ", item);
    console.log("SELECTED element idx : ", idx);
    //var currentId = data.name.substring(data.name.lastIndexOf("-") + 1);
    //console.log("Current id : ", currentId);
   // console.log("Product Info : ", productMaster);

    var itemSelected = items[idx];
    console.log("Passed element item : ", item);
    console.log("Item selected : ", itemSelected);


    items[selectedCompositionId].composition.push();
    /*
    if (itemSelected.product) {
      console.log("Product: ", itemSelected.product);
    }
    */
    //console.log("<<<<<<<<<<<<<<<<<<<<< ITEM ID >>>>>>>>>>>>>>>>>>: ", itemSelected.id);

    /*
    console.log("Product Info : ", productMaster);
    const currentProductIndex = productCompositionList.findIndex(
      (productInd) => productInd.name === e.label
    );
    */

    //console.log("Currently selected row product id : ", currentProductIndex);
    //console.log("Currently selected row product value : ", productCompositionList[currentProductIndex]);

    //itemSelected.product_id = e.id;
    //itemSelected.product_name = e.name;
    //itemSelected.uom = e.uom;
    //itemSelected.product.label = e.label;
    /*
    var productNew = {
      id: e.id,
      name: e.name,
    };
    console.log("Product Value : ", productNew);
    itemSelected.product = productNew;
    */

    /*
    const newProductCompositionList = productCompositionList.slice();
    newProductCompositionList[currentId] = itemSelected;
    setProductCompositionList(newProductCompositionList);
    */
    //setProductMaster(newSellProdArr);


  };
  const handleItemDeleteComposition = (e) => {
    console.log("handle item delete called ...", e);
    console.log("handle item delete e.currentTarget : ", e.currentTarget.id);
    console.log("handle item delete e.target : ", e.target.id);

    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected DELETE element name : ", nameOfElement);
      console.log("Current SELECTED DELETE id : ", curSelId);
      console.log("CUR SELECTED DELETE VALUE : ", productCompositionList[curSelId]);

      let sliced = productCompositionList.splice(curSelId, 1);
      console.log("Removed item from order items: ", sliced);
      var newProductCompositionList = productCompositionList.slice();
      setProductCompositionList(newProductCompositionList);

    }
  };

  const handleCloseComposition = async () => {
    console.log("handle close composition called ...");
    setShowConfigDialog(false);
  }

  const handleSaveComposition = async () => {
    console.log("Handle Save compostion called ...");
    setShowConfigDialog(false);
    setCompositionUpdate(true);

    var selectedItem = items[selectedCompositionId];
    //selectedItem.compositionUpdate = true;
    //selectedItem.is_product_cc = true;
    console.log("SELECTED ITEMS<<<<<<<<<<<<:", selectedItem);

    var newItemsTmp = items.slice();
    newItemsTmp[selectedCompositionId] = selectedItem;
    setItems(newItemsTmp);
  }
  const handleCloseCompositionProduct = async () => {
    console.log("handle close composition  product called ...");
    navigate(0);

  }

  const handleOnItemChangeComposition = (e) => {
    //e.preventDefault();
    console.log("handleOnItemChangeComposition called : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);


    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    var itemSelected = items[selectedCompositionId];
    itemSelected.addl_notes = value;
    setFormValues({ ...formValues, addl_notes: value});
    //console.log("selected composition for M grade products : ", itemSelected.composition);
    
    //console.log("SELECTED COMPOSITION COMPONENt element : ", itemSelected.composition[currentId]);
   
    //console.log("Product: ", itemSelected.product);
    console.log("Product Name : ", itemSelected.product_name);
    //setFormValues({ ...formValues, [name]: value });
    
    console.log("Product Info : ", productMaster);
    const currentProductIndex = productMaster.findIndex(
      (productInd) => productInd.name === itemSelected.product_name
    );
     
    console.log("Currently selected row product id : ", currentProductIndex);
    if (currentProductIndex < 0) {
      itemSelected.error = "Please select product from list";

      //const newProductCompositionList = productCompositionList.slice();
      //newProductCompositionList[currentId] = itemSelected;
      //setProductCompositionList(newProductCompositionList);
      return 0;
    } else {
      itemSelected.error = "";
    }

    console.log("Currently selected row product value : ", productMaster[currentProductIndex]);
    

    /*
    itemSelected.product = {
      id: productList[currentProductIndex].id,
      name: productList[currentProductIndex].name,
      
    };
    */
    //itemSelected.addl_notes = productMaster[currentProductIndex].addl_notes;
    /*
    if (nameOfElement === "quantity") {
      console.log("Product Quantity item edited ...");

      // const regex =  /^[0-9\b]+$/;
      /*
       const numericRegExp = /^\d*\.?\d*$/;
       //if (numericRegExp.match(e.target.value)) {
       if (e.currentTarget.value.match(numericRegExp)) {
         itemSelected[nameOfElement] = e.currentTarget.value;
       }
       * /
      //const numericRegExp = /^\d*\.?\d*$/;
      if (e.currentTarget.value.match(appGlobal.numericRegExp)) {
        //itemSelected[nameOfElement] = e.currentTarget.value.replace(/^0+/, '');
        itemSelected.composition[currentId].quantity = value;
      }
    } else {
      console.log("Other than handled option clicked so skipping it ...");
    }
    */

    //const newProductCompositionList = productCompositionList.slice();
    //newProductCompositionList[currentId] = itemSelected;
    //setProductCompositionList(newProductCompositionList);
    var newItemsTmp = items.slice();
    newItemsTmp[selectedCompositionId] = itemSelected;
    console.log("NEW ITEMS TMP<<<<<<<<<<<<< :", newItemsTmp);
    setItems(newItemsTmp);
  };

  const handleAlertOK = () => {
    console.log("Handle alert close called ...");
    setShowAlert(false);
    if ( alertPurpose === 'success') {
      navigate("/listCustInv");
      setAlertPurpose('init');
    }
    else if( alertPurpose === 'confirmation') {
      //navigate(0);
      formValues["customer_name"] = "";
      formValues["customer_disp_name"] = "";
      formValues["gst_number"] = "";
      formValues["phone"] = "";
      formValues["primary_number"] = "";
      formValues["secondary_number"] = "";
      formValues["contact_person"] = "";
      formValues["contact_number"] = "";
      formValues["email"] = "";
      formValues["address1"] = "";
      formValues["address2"] = "";
      //formValues["distId"] = "";
      //formValues["stateId"] = "";
     
      
      setFormValues({ ...formValues, ["pincode"]: ""});
    }
    else if ( alertPurpose === 'gst_validate' ) {
      navigate('/listCustomers');
    }
  }
  const handleCloseDialog = () => {
    console.log("handleCloseDialog called ....");
    setShowAlert(false);

    if ( alertPurpose === 'success') {
      navigate("/listOrders");
      setAlertPurpose('init');
    }
    else if ( alertPurpose === 'gst_validate' ) {
      console.log("Inside Cancel for gst validate");
      setGstBillStatus(false);
    }
  }
  const handleReset = async (e) => {
    e.preventDefault();
    console.log("handle clear called : ", e);
    console.log("Handle clear clicked current target ID: ", e.currentTarget.id);
    setDistrictIdSelect(optDist[0]);
    //setStateIdSelect(optionsState[0]);
    setAlertPurpose('confirmation');
    setAlertTitle("Confirmation");
    setAlertBody("Are you sure want to Reset  Customer Details and Address");
    setShowAlert(true);
      
    //navigate(0);
  };
 
  const handleDateSelect = (e, data) => {
    console.log("handleDateSelect called ...", e);

    setDateIdSelect(e);
  };
  const handlePrintFullOrder = async(e) => {
    console.log("Handle print order clicked : ", e);
    //passedValue.order_id

    e.preventDefault();
    console.log("Handle action print Clicked : ", e);

    console.log("CALLED CURRENT PAGE OrderDetails PRINT DOCUMENT : ", window.location.href);

    console.log("CALLED window locaion OrderDetails PRINT DOCUMENT : ", window.location);
    //console.log("ORDER ID : ", orderId);
    //console.log("DC ID : ", dcId);

    //const navigate = useNavigate();

    //navigate("/printDC");
    //navigate("/printDocument", {state:{orderId: orderId, dcId: dcId}}, { replace: true });

    //navigate("/renderInWindow", {state:{orderId: orderId, dcId: dcId}}, { replace: true });
    console.log("LOCATION PATH DIRECTORY PRINT DOCUMENT : ", __dirname);

    //navigate("/newWindow", {state:{orderId: orderId, dcId: dcId}}, { replace: true });

    //window.open(`http://localhost:3000/#/printDocument?order_id=${orderId}&dc_id=${dcId}`);
    //window.open(`http://${window.location.host}/#/printDocument?order_id=${orderId}&dc_id=${dcId}`);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    //window.open(`${urlTmp}${client.urlDelimiter}/printFullOrder?order_id=${passedValue.order_id}`);
    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printBillPayment`, "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0,0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
  }

  
  const handleAdd = (e) => {
    e.preventDefault();
    console.log("Add or Update Order button clicked ...", e);
  };

  const sendDataToParent  = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if ( passedBackValue.hasOwnProperty("business_id") ) {
      currSelBusinessRef.current = passedBackValue["business_id"];
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);
    
  }

 const handleSupplierSelect = async(e) => {
  console.log("handleSupplierSelect called : ", e);
  setSelectedSupplierId(e);
 } 

  
  return (
    <>
      
          <Container>
            <SidebarMain sendBacktoParent={sendDataToParent}/>
            { toRender ?
        (
            <Form>
              <Row>
                <Col xs={4} md={4} sm={4} lg={4}>
                  <span style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                  {menuSettings.permissions.find(element => (element.code === 'listSuppInvoices' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'listSuppInvoices')).page_title_addupd : "Supplier Invoice"}
                  </span>
                  {(state.action === "view" || state.action === "edit") && (
                    <>
                      &nbsp;&nbsp;
                      <Badge pill bg="success">
                        <h6>{orderInfoDet.order_no}</h6>
                      </Badge>
                    </>
                  )}
                </Col>
                <Col
                  xs={8}
                  md={8}
                  sm={8}
                  lg={8}
                  className="d-flex justify-content-end float-end"
                >
                  <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                    <BsArrowLeftCircle
                      key={`back-btn-order-key`}
                      size={24}
                      style={{ cursor: "pointer" }}

                      name={`back-icon-order-name`}
                      id={`edit-icon-order-id`}
                    />
                  </Button>
                  {(state.action === "edit" || state.action === "view") && (
                    <>
                     &nbsp;&nbsp;
                <Button id="fullOrderPrint" name = "fullorderPrint"  onClick={(e)  => handlePrintFullOrder(e)}
                disabled={menuSettings.permissions.find(element => (element.code === 'listSuppInvoices' && element.a_print === true)) ? false : true}
                >
                <BsFillPrinterFill
                  size={18}
                  style={{ color: "white", cursor: "pointer" }}
                  name={`print-order-dc-icon`}
                  id={`print-order-dc-icon`}
                />
                  </Button>
                  
                      &nbsp;&nbsp;
                      <Button
                        variant="primary"
                        className="btn btn-primary btn-md"
                        onClick={(e) => handleEdit(e)}
                        disabled={menuSettings.permissions.find(element => (element.code === 'listSuppInvoices' && element.a_edit === true)) ? false : true}
                      >
                        <BsPencilSquare
                          key={`edit-btn-order-key`}
                          size={24}
                          style={{ cursor: "pointer" }}

                          name={`edit-btn-order-name`}
                          id={`edit-btn-order-id`}
                        />
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
              <hr align="center" />
              <Row>

              <Col xs={2} md={2} sm={2} lg={2}>
                      <Form.Group className="mb-3">
                      <Form.Label className="field required">Bill Number</Form.Label>
                      
                      <Form.Control
                        type="text"
                        name="bill_number"
                        id="bill_number"
                        placeholder="Bill Number"
                        value={formValues.bill_number}
                        onChange={handleValueChange}
                        readOnly={isReadOnly}
                        style={{
                          border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                        }}
                      />
                    </Form.Group>
                    {/*<p className="error-msg">{formErrors.expected_inv_number}</p>*/}
                    </Col>

              <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label className="field">Bill Date</Form.Label>
                  
                  <Form.Control
                  type="date"
                  name="bill_date"
                  //disabled="true"
                  placeholder="Bill Date"
                  value={formValues.bill_date}
                  onChange={onChangeDatePicker}
                  //readOnly={isReadOnly}
                  //disabled={state.action !== 'add' || isLoading }
                  disabled={ isLoading }
                />
                </Form.Group>
                <p className="error-msg">{formErrors.bill_date}</p>
              </Col>
              <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label className="field">Due Date</Form.Label>
                  
                  <Form.Control
                  type="date"
                  name="due_date"
                  //disabled="true"
                  placeholder="Due Date"
                  value={formValues.due_date}
                  onChange={onChangeDueDatePicker}
                  //readOnly={isReadOnly}
                  //disabled={state.action !== 'add' || isLoading }
                  //disabled={ isLoading }
                />
                </Form.Group>
                <p className="error-msg">{formErrors.due_date}</p>
              </Col>


              <Col xs={5} md={5} sm={5} lg={5}>
                    <Form.Group
                      className="mb-3"
                      controlId="invform.customer_name"
                    >
                      <Form.Label className="field">Supplier Search</Form.Label>
                      {/*
                      <AsyncTypeahead
                        id="supplier-search-fd-id"
                        name="supplier_name"
                        isLoading={isLoading}
                        onSearch={(query) => {
                          searchHandleFunc(query);
                        }}
                        onChange={(selected) => {
                          supplierSearchSelectFunc(selected);
                        }}
                        options={optionsSearch}
                        placeholder="Search Supplier ..."
                        readOnly={state.action === 'add' ? isReadOnly : true}
                        style={{
                          borderColor: "gray"
                        }}
                        className="rounded-sm"
                      />
                      */}
                      <Select
                                    id={`supplier-inv-supplier-name`}
                                    name={`supplier-inv-supplier-name`}
                                    //value={options.value}
                                    value={{
                                      value: selectedSupplierId.value,
                                      label: selectedSupplierId.label,
                                    }}
                                    options={optSuppliers}
                                    defaultValue={{
                                      value: selectedSupplierId.value,
                                      label: selectedSupplierId.label,
                                    }}
                                    //isDisabled={isReadOnly}
                                    onChange={handleSupplierSelect}
                                  />
                    </Form.Group>

                    <p className="error-msg">{formErrors.supplier_name}</p>
                    </Col>

                    <Col
                     xs={1}
                     md={1}
                     sm={1}
                     lg={1}
                    style={{paddingTop: "30px"}}>  
          
                      <Button
                      variant="warning"
                      id="add-btn-id"
                     name="add-btn-name"
            
                    onClick={(e) => handleAdd(e)}
            >
              New 
            </Button> 
          </Col>
                </Row>
                <Row>
                <Col xs={3} md={3} sm={3} lg={3}>
                      <Form.Group className="mb-3">
                      <Form.Label className="field required">Supplier Inv Number</Form.Label>
                      
                      <Form.Control
                        type="text"
                        name="inv_number"
                        id="inv_number"
                        placeholder="Supplier Inv Number"
                        value={formValues.inv_number}
                        onChange={handleValueChange}
                        readOnly={isReadOnly}
                        style={{
                          border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                        }}
                      />
                    </Form.Group>
                    {/*<p className="error-msg">{formErrors.expected_inv_number}</p>*/}
                    </Col>

              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field">Supplier Inv Date</Form.Label>
                  
                  <Form.Control
                  type="date"
                  name="inv_date"
                  //disabled="true"
                  placeholder="Supplier Inv Date"
                  value={formValues.inv_date}
                  onChange={onChangeDatePicker}
                  //readOnly={isReadOnly}
                  //disabled={state.action !== 'add' || isLoading }
                  disabled={ isLoading }
                />
                </Form.Group>
                <p className="error-msg">{formErrors.inv_date}</p>
              </Col>

              <Col xs={3} md={3} sm={3} lg={3}>
                    <Form.Group className="mb-3" >
                      <Form.Label>GST IN</Form.Label>

                      <Form.Control
                        type="text"
                        name="gst_number"
                        id="gst_number"
                        placeholder="GST IN"
                        value={formValues.gst_number}
                        onChange={handleValueChange}
                        readOnly={state.action === 'add' ? isReadOnly : true}
                        //disabled="true"
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                    </Form.Group>

                    <p className="error-msg">{formErrors.gst_number}</p>
                  </Col>
                </Row>
                
                
            

              <div className="ui-form">
                
  
                <Row>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <div className="d-flex justify-content-start">
                      <h4>
                        <Badge pill bg="secondary">
                          Item Details
                        </Badge>
                      </h4>
                      <p
                        className="error-msg"
                        style={{
                          position: "relative",
                          //top: "5px",
                          fontWeight: "bold",
                        }}
                      >
                        {formErrors.alertMsg}
                      </p>
                    </div>
                  </Col>

                  <Col xs={6} md={6} sm={6} lg={6}>
                    <div className="d-flex justify-content-end">
                     
                      <Button
                        className="btn btn-warning"
                        style={{ alignItems: "self-end" }}
                        onClick={newItemHandle}
                        //disabled={isReadOnly ? true : false}
                      >
                        Add Item
                      </Button>
                    </div>
                  </Col>
                </Row>
                <hr align="center" />
                <p></p>

                {items.length > 0 &&
                  <>

            <Table striped bordered hover>
                      <thead>
                        <tr className="table-primary">
                          <th className="table-row-heading" style={{width : "20px"}}>S.No</th>
                          <th className="table-row-heading" style={{width : "100px"}}>Item</th>
                          
                          <th className="table-row-heading" style={{width : "200px"}}>Quantity</th>
                          <th className="table-row-heading" style={{width : "100px"}}>Unit</th>
                          <th className="table-row-heading" style={{width : "200px"}}>Rate per Unit</th>
                          <th className="table-row-heading" style={{width : "200px"}}>Amount</th>
                          <th className="table-row-heading" style={{width : "20px"}}>***</th>
                        </tr>
                      </thead>

                      <tbody>
                        {items.map((item, idx) => (
                          <tr>
                            <td>{idx + 1}</td>
                            <td style={{width: '25%'}}>
                              <Row>
                                <div style={{width: '75%'}}>
                                  <Select
                                    id={`product-item-${idx}`}
                                    name={`product-item-${idx}`}
                                    //value={options.value}
                                    value={{
                                      value: item.product_id,
                                      label: item.product_name,
                                    }}
                                    options={productMaster}
                                    defaultValue={{
                                      value: item.product_id,
                                      label: item.product_name,
                                    }}
                                    //isDisabled={isReadOnly}
                                    onChange={handleProductSelect}
                                  />
                              </div>
                                {/*<Col sm={2} md={2} xs={2} lg={2}>*/}
                                {/*{!item.editAction &&*/}
                                {/*
                                <div style={{width: '25%'}}>
                                  <Button
                                    variant="primary"
                                    className="btn btn-primary btn-sm"
                                    onClick={handleCompositeEdit}
                                    name={`edit-btn-order-name-${idx}`}
                                    id={`edit-btn-order-id-${idx}`}
                                    //disabled={(item.enableState || (!isReadOnly && (state.action === 'view' || state.action === 'edit'))) ? false : true}
                                  //disabled={submitAction ? true: false}
                                  disabled={isReadOnly ? true : false}

                                  >
                                    
                                    {item.editAction ?
                                      <>
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                        <span className="visually-hidden">Loading...</span>
                                      </> :
                                      ''}
                                    
                                    
                                      <BsPencilSquare
                                        key={`edit-btn-order-key`}
                                        size={12}
                                        style={{ cursor: "pointer" }}
                                      />
                                  </Button> 
                                  </div>
                                    */}
                                  {/*}*/}                             
                                
                              </Row>
                              
                            </td>

                            
                            
                            <td>
                            
                           
                              <Form.Group  as={Row} className="mb-3" controlId="m3">
                            <Col xs={12} sm={12} md={12} lg={12} style={{width : "200px"}}>
                              <Form.Control
                                type="text"
                                name={`quantity-${idx}`}
                                placeholder="Quantity"
                                value={item.quantity}
                                // max="100000"
                                onChange={handleChange}
                                //readOnly={isReadOnly}
                                maxLength={MAX_QTY_LENGTH}
                                style={{textAlign: "right",border: !isReadOnly && "1px solid gray",}}
                              />
                            </Col>
                            <p className="error-msg">{item.error_qty}</p>
                          </Form.Group>
                           
                            </td>
                            <td
                              style={{
                                width: "120px",
                                padding: "5px",
                                textAlign: "right",
                              }}
                            >
                              {item.unit}
                            </td>
                          
                            <td>
                            
                            <Form.Group  as={Row} className="mb-3" controlId="m3">
                            <Col xs={12} sm={12} md={12} lg={12} style={{width : "200px"}}>
                              <Form.Control
                                type="text"
                                name={`unit_price-${idx}`}
                                placeholder="Unit Price"
                                value={item.unit_price}
                                // max="100000"
                                onChange={handleChange}
                                //readOnly={isReadOnly}
                                maxLength={MAX_AMOUNT_LENGTH}
                                style={{textAlign: "right",border: !isReadOnly && "1px solid gray"}}
                              />
                            </Col>
                          </Form.Group>
                          <p className="error-msg">{item.unit_price_err}</p>
                          
                            </td>
                          
                            {/*  
                            <td
                              style={{
                                width: "120px",
                                padding: "5px",
                                textAlign: "right",
                              }}
                            >
                              { item.product_type === 'SERVICE' ?
                              <>
                              <Form.Group  as={Row} className="mb-3" controlId="m3">
                              <Col xs={12} sm={12} md={12} lg={12}>
                                <Form.Control
                                  type="text"
                                  name={`total_item_price-${idx}`}
                                  placeholder="Total Item Price"
                                  value={item.total_item_price}
                                  // max="100000"
                                  onChange={handleChange}
                                  readOnly={isReadOnly}
                                  maxLength={MAX_AMOUNT_LENGTH}
                                  style={{textAlign: "right",border: !isReadOnly && "1px solid gray"}}
                                />
                              </Col>
                            </Form.Group>
                            <p className="error-msg">{item.total_item_price_err}</p>
                            </>
                            :
                            <>
                              {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.total_item_price).toFixed(2)))}
                              </>
                              }
                            </td>
                            
                            <td
                              style={{
                                width: "120px",
                                padding: "5px",
                                textAlign: "right",
                              }}
                            >
                              
                              {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.cgst).toFixed(2)))}
                            </td>
                            <td
                              style={{
                                width: "100px",
                                padding: "5px",
                                textAlign: "right",
                                //overflow: "auto",
                                //display: "block",
                                //width: "auto !important",
                                //tableLayout: "auto !important",
                              }}
                            >
                            
                              {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.sgst).toFixed(2)))}
                            </td>
                            */}
                            <td
                              style={{
                                width: "120px",
                                padding: "5px",
                                textAlign: "right",
                              }}
                            >
                              {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.total_price).toFixed(2)))}
                            </td>
                            <td>
                              <BsFillXCircleFill
                                size={28}
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={(e) => handleItemDelete(e)}
                                name={`item-delete-icon-${idx}`}
                                id={`item-delete-icon-${idx}`}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    
                <Row className="show-grid">
                
                  <Col xs={6} sm={6} md={6} lg={6}>
                  
                    <Row>
                    <Col xs={12} md={12} sm={12}>
                        <div style={{paddingBottom: "40px"}}>
                          <span style={{ fontWeight: "bold" }}>
                            Amount Chargeable (In Words):
                          </span>
                          <span> {sum_product_total > 0.01 ? convertNumberToWords(Math.round(sum_product_total)) : ""}</span>
                        </div>
                      </Col>        
                    </Row>
                    <p></p>
                   
                  </Col>

                  

                  <Col col-12 col-sm-6 xs={6} sm={6} md={6} lg={6} style={{alignContent: 'float-end', align: 'right',paddingTop: "35px"}} className="order-last order-sm-last justify-content-end float-end">
                  <Row>  
                      <Col xs={"10"} sm={"10"} md={"10"} lg={"10"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                    <Form.Label style={{fontWeight: 'bold'}}>
                     Sub Total :
                      </Form.Label>
                      </Col>
                      <Col xs={"2"} sm={"2"} md={"2"} lg={"2"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                        
                        <Form.Label style={{fontWeight: 'bold'}}>
                        0.00
                        {/*{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(sum_product_cost).toFixed(2)))}*/}
                      </Form.Label>
                       </Col>
                    
                    </Row>
                  
                    <Row>  
                      <Col xs={"10"} sm={"10"} md={"10"} lg={"10"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                    <Form.Label style={{fontWeight: 'bold'}}>
                     Round Off :
                      </Form.Label>
                      </Col>
                      <Col xs={"2"} sm={"2"} md={"2"} lg={"2"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                        
                        <Form.Label style={{fontWeight: 'bold'}}>
                        
                        {/*{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(sum_rounded_off).toFixed(2)))}*/}
                        0.00
                      </Form.Label>
                       </Col>
                    
                    </Row>
                   
                   
     
                 </Col>
                </Row>

                <Row>
              

              <Col xs={6} md={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    as="textarea" rows={3}
                    name="notes"
                    id="notes"
                    placeholder="Notes"
                    value={formValues.notes}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
              </Col>

              
                </Row>
                    
                  </>
                }
                

                {!isReadOnly && (
                <Row>
                   <Col xs={12} md={12} sm={12}>
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn btn-secondary"
                    id="btn-clear-receipt-id"
                    name="btn-clear-receipt-name"
                    onClick={handleClear}
                  >
                    Clear
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    className="btn btn-primary"
                    id="save-print"
                    name="save-print"
                    onClick={(e) => handleSubmit(e, "save-print")}
                  >
                    {submitPrintAction &&
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    }
                    Save & Print</Button>
                  &nbsp;
                  &nbsp;&nbsp;
                  <button className="btn btn-primary" onClick={(e) => handleSubmit(e, 'save-only')}>
                  { submitAction &&
                    <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                  }
                    
                    Save</button>
                </div>
              </Col>        
            </Row>
                )}
              </div>
              </Form>
     )
        :
        <div className="container-spinner">
        <div className="vertical-center-spinner">
         <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>  
         
                <span className="visually-hidden">Loading...</span>
              </Spinner>
         </div>
         </div>
      }
           
          </Container>
        
      {/* 
      <Modal show={showConfigDialog}>
        <Modal.Header>
          <Modal.Title>Additional Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            
                
                <Col xs={12} md={12} sm={12} lg={12}>
                <Form.Group className="mb-3">
                  
                  <Form.Control
                    //type="text"
                    as="textarea" rows={3}
                    name="addl_notes"
                    id="addl_notes"
                    placeholder=" Notes"
                    value={formValues.addl_notes}
                    maxLength={75}
                    onChange={handleOnItemChangeComposition}
                    //readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
              </Col>
              
              
            {/*
            <Table
              striped bordered hover
              size="sm">
              <thead>
                <tr className="table-primary">
                  <th className="table-row-heading">S.No</th>
                  <th className="table-row-heading">Product</th>
                  <th className="table-row-heading">Qty</th>
                  <th className="table-row-heading">Uom</th>
                  {/ *<th className="table-row-heading">...</th>* /}
                </tr>
              </thead>

              <tbody>
                {/ *{productCompositionList.map((item, idx) => (* /}
                {(selectedCompositionId > -1 && 'composition' in items[selectedCompositionId]) &&
                items[selectedCompositionId].composition.map((item, idx) => (
                  <tr key={`prod-composition-table-${idx}`}>
                    <td className="w-25">{idx + 1}</td>
                    <td className="w-50">
                      <Select
                        id={`composite-product-item-${idx}`}
                        name={`composite-product-item-${idx}`}
                        //value={options.value}
                        value={{
                          value: item.product_id,
                          label: item.product_name,
                        }}
                        options={productList}
                        defaultValue={{
                          value: item.product_id,
                          label: item.product_name,
                        }}
                        //isDisabled={(disableComposite || isReadOnly)? true: false}
                        isDisabled={true}
                        onChange={(e, item) => handleProductSelectComposition(e, item, idx)}
                      />
                      <p className="error-msg">{item.error}</p>
                    </td>
                    <td className="w-25">
                      <Form.Group className="mb-3">
                        <Form.Control
                          //pattern="^[0-9]*\.[0-9]{2}$"
                          //step=".01"
                          type="text"
                          size="sm"
                          id={`quantity-${idx}`}
                          name={`quantity-${idx}`}
                          placeholder="Quantity"
                          onChange={(e) => handleOnItemChangeComposition(e)}
                          readOnly={isReadOnly}
                          value={item.quantity}
                          //disabled={(disableComposite || isReadOnly)? true: false}
                          //defaultValue={item.quantity}

                          style={{
                            border: !isReadOnly && "1px solid gray",
                          }}

                        />
                      </Form.Group>
                    </td>
                    <td>{item.uom}</td>
                    {/ *
                    <td>
                      <BsFillXCircleFill
                        size={28}
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={(e) => handleItemDeleteComposition(e)}
                        name={`item-delete-icon-${idx}`}
                        id={`item-delete-icon-${idx}`}
                      />
                    </td>
                    * /}
                  </tr>
                ))
                }
              </tbody>
            </Table>
              * /}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseComposition}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveComposition}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
              */}

      <Modal show={showAlert}>
        <Modal.Header>
          <Modal.Title>{alertTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{alertBody}</Modal.Body>
        <Modal.Footer>
        { alertPurpose !== 'success' &&
        <Button variant="secondary" onClick={handleCloseDialog}>Cancel</Button>
        }
          <Button variant="primary" onClick={handleAlertOK}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddUpdateSuppInvoiceMfgT1;
