import React, { useState, useEffect, useRef, Fragment } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Form,
    Button,
    Table,
    Spinner,
    Image
} from "react-bootstrap";
import { withRouter } from "react-router";
//import Sidebar from './Sidebar';
//import Sidebar2 from "./Sidebar2";
//import Footer from "./Footer";
//import Topmenu from "./Topmenu";
//import OrderDetails from "./OrderDetails";
import "../Dashboard.css";

//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";

import { useNavigate } from "react-router-dom";

//import * as Icon from "react-bootstrap-icons";
import axios from "axios";
import { client } from "../config/Config";
import Select from "react-select";
import spinner_logo from '../assets/images/spinner_logo.png';



import {
    BsFillTrashFill,
    BsPencilSquare,
    BsFileRuled,
    BsArrowLeftCircle,
    BsCardText,
    BsReceipt,
    BsFillPrinterFill,
} from "react-icons/bs";

import { AiOutlineOrderedList } from "react-icons/ai";

//import MenuOms from "./MenuOms";

import rmcBlueIcon from "../assets/images/ready_mix_blue.png";
import SidebarMain from "./SidebarMain";

import { getDistrictList, getStateList, getProductApiCall, getProductBuy, getProductsSell, formatDate, formatDateIndian } from "./utils";

import { OS_RECEIPT_FOR_NDAYS } from "../config/Config";

const ListSuppPayments = (props) => {

  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
//var lastDay = new Date(date.getFullYear(), date.getMonth() + 1,
  const intialValues = {
    date_from: formatDate(new Date(firstDay)),
    date_to: formatDate(new Date())
   };
  


    const initialized = useRef(false);
    const [toRender, setToRender] = useState(false);
   
    var menuSettingsStr = localStorage.getItem("_aP");
    var menuSettings = JSON.parse(menuSettingsStr);
   
    let paymentArr = [

       {
            sno: "1",
            payment_number: "PAYT004",
            payment_date: "07-08-2024",
			      payment_by_name:"DCR Readymix",
            amount: "8844",
            payment_mode: "Cash",
            ref_number: "1224",
            pay_received_by:"Ramesh",
            towards: "for readymix",
			      payment_type: "",
            payment_type: "Advance"
            },
     
          {
            sno: "2",
            payment_number: "PAYT005",
            payment_date: "08-08-2024",
            payment_by_name: "MMR Readymix",
            amount: "6754",
            payment_mode: "Cash",
            ref_number: "7765",
            pay_received_by:"Ram",
            towards: "for readymix",
            payment_type: "Outstanding",
            },
           
            {
              sno: "3",
              payment_number: "PAYT006",
              payment_date: "02-04-2024",
              payment_by_name: "KMC Readymix",
              amount: "6654",
              payment_mode: "Cash",
              ref_number: "1888",
              pay_received_by:"Raj",
              towards: "for kmc concrete",
              payment_type: "Advance",
              
              },
    ]

    
    let supplierArr = [
  
      { value: 1, label: "All Suppliers" },
      { value: 2, label: "Amman Steels" },
      { value: 3, label: "TATA Steels" },
      { value: 4, label: "TMT Rods" },
    ];
    

    const [optSupplier, setOptSupplier] = useState([]);
    const [supplierIdSelect, setSupplierIdSelect] = useState({});
    const [paymentList, setPaymentList] = useState([]);
    const [supplierSummary, setSupplierSummary] = useState([]);
    //const [receiptListFull, setReceiptListFull] = useState([]);
    const [formValues, setFormValues] = useState(intialValues);
    const [show, setShow] = useState(false);
    const [showMsg, setShowMsg] = useState("Please login again");
    const [showMsgTitle, setShowMsgTitle] = useState( "Invalid authentication Session!" );
    const currSelBusinessRef = useRef(0);
    const currBranchRef = useRef(0);
    const navigate = useNavigate();

    var total_payment_ref = useRef(0.00);
    var amount_ref = useRef(0.00);
  
   /*
    const getSupplierListDropdown = async () => {
        console.log("getSupplierListDropdown method called ...");
        var retState = false;
        
        try {
          var aIVal = localStorage.getItem("_aI");
          var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
          var res = await axios.get(
            client.domain + "/srm/getAllSuppliers",
            { headers },
            { withCredentials: false }
          );
          
          console.log(
            "Response from server getSupplierList : ",
            res.data.detail.data
          );
          if (res.data.detail.status === "SUCCESS") {
            console.log(
              "Response message from server getSupplierList : ",
              res.data.detail.data
              
            );
            
            var supplierArr = [];
            var suppAll = { value: 0, label: 'All Suppliers'};
            supplierArr.push(suppAll);
            res.data.detail.data.map((itemSupplier, idx) => {
              console.log("Ind val : ", itemSupplier);
              var supplierInd = {
                value: itemSupplier.id,
                label: itemSupplier.name,
              };
              console.log("In supplier modified : ", supplierInd);
              supplierArr.push(supplierInd);
            });
    
            console.log("Mod Full VALUE : ", supplierArr);
            retState = true;
          }
         
        } catch (error) {
          console.log("FULL ERROR : ", error);
          if (error.code === "ERR_NETWORK") {
            console.log(
              "Not able to reach target server please try after sometime"
            );
          } else {
            console.log("ERROR : ", error.response.data.detail);
            if (error.response.data.detail.status === "FAILED") {
              console.log(
                "Request failed due to ",
                error.response.data.detail.data
              );
             
            }
          }
        }
        return [retState, supplierArr];
      };
      */

      /*
      const getPaymentList = async(supplier_id, start_date, end_date) => {
        console.log("getSupplierList method called ...");
        console.log("start date   : ", start_date);
        console.log("end date     : ", end_date);
        console.log("suupplier id  : ", supplier_id);
        try {
          var aIVal = localStorage.getItem("_aI");
          console.log("Authorization token : ", aIVal);
    
          var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
    
          console.log("Headers : ", headers);
    
          
          var res = await axios.get(client.domain + `/getAllPaymentsByFilter?paid_to=${supplier_id}&start_date=${start_date}&end_date=${end_date}`, {headers}, {
            withCredentials: false,
          });
          //.then((res) => {
          //console.log("ADD New user res:", res);
          //localStorage.setItem('_token', res.data.res_data.token);
          console.log("Response from server getPaymentList : ", res.data.detail);
          if (res.data.detail.status === "SUCCESS") {
            console.log("Response message from server getPaymentList : ", res.data.detail.data);
           if (res.data.detail.data.length > 0) {
              setPaymentList(res.data.detail.data);
              //setReceiptListFull(res.data.detail.data);
            }
            setToRender(true);
          }
          
        } catch (error) {
          console.log("FULL ERROR : ", error);
          if (error.code === "ERR_NETWORK") {
            console.log("Not able to reach target server please try after sometime");
        setToRender(true);
          } else {
            console.log("ERROR : ", error.response.data.detail);
            if (error.response.data.detail.status === "FAILED") {
              console.log("Request failed due to ",error.response.data.detail.res_data);
            }
          }
        }
        setToRender(true);
      };
      */

      const getSupplierList = async (supplier_id_pass) => {
        var retStateLoad = false;
        var paymentRet = [];
        var summaryRet = [];
        try {
          var aIVal = localStorage.getItem("_aT");
          var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
          console.log("Selected Supplier :", supplierIdSelect);
          var reqParams = {
            bid: currSelBusinessRef.current,
            brid: currBranchRef.current || 0,
            supplier_id: supplier_id_pass,
            date_from: formValues["date_from"],
            date_to: formValues["date_to"]
    
          };
          console.log("REQUEST PARAMS: ", reqParams );
          var res = await axios.post(client.domain + "/bms/getSuppPymtList", reqParams, { headers }, { withCredentials: false });
          console.log("Response from server : ", res.data.detail);
    
          if (res.data.detail.status === "SUCCESS") {
            console.log("Response message from server Invoice : ",res.data.detail.data );
            setPaymentList(res.data.detail.data.payments);
            setSupplierSummary(res.data.detail.data.payment_summary[0]);
            //setCustomerListFull(res.data.detail.data);
            //setShowMsgTitle("Customer Info Added Successfully");
            setToRender(true);
            paymentRet = res.data.detail.data.payments;
            summaryRet = res.data.detail.data.payment_summary;
            retStateLoad = true;
            
          }
        } catch (error) {
          console.log("FULL ERROR : ", error);
          if (error.code === "ERR_NETWORK") {
            console.log( "Not able to reach target server please try after sometime");
            setShowMsg("Please try after sometime");
            setShowMsgTitle("Network Issue!");
            setShow(true);
          } else {
            console.log("ERROR occured : ", error.response.data.detail);
            if (error.response.data.detail.status === "FAILED") {
              console.log( "Request failed due to ",error.response.data.detail.res_data);
              setShowMsg("Please try again ...");
              setShowMsgTitle("Unable to process your request!");
              setShow(true);
              //navigate("/auth");
            } else {
              console.log("Non status ERROR code ...");
              setShowMsg(error.response.data.detail);
              setShowMsgTitle("Unable to process your request at this moment!");
              setShow(true);
            }
          }
        }
        return [retStateLoad, paymentRet, summaryRet];
      };
    
      const CallSupplierData = async () => {
        var retStateLoad = false;
        var supplierRet = [];
    
        try {
          var aIVal = localStorage.getItem("_aT");
          var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
    
          var reqParams = {
            bid: currSelBusinessRef.current
          };
    
          var res = await axios.post(client.domain + "/bms/getSuppPymtSearchPreLoadData", reqParams, { headers }, { withCredentials: false });
          console.log("Response from server : ", res.data.detail);
    
          if (res.data.detail.status === "SUCCESS") {
            console.log("Response message from server : ", res.data.detail.data.suppliers);
            var supplierList = [];
    
            //customerList.push({label: "All Customers", value: 0});
            res.data.detail.data.suppliers.map((supplier) => {
              supplierList.push(supplier);
            })
            setOptSupplier(supplierList);
            setToRender(true);
            supplierRet = supplierList;
            retStateLoad = true;
            
              
          }
        } catch (error) {
          console.log("FULL ERROR : ", error);
          if (error.code === "ERR_NETWORK") {
            console.log( "Not able to reach target server please try after sometime");
            setShowMsg("Please try after sometime");
            setShowMsgTitle("Network Issue!");
            setShow(true);
          } else {
            console.log("ERROR occured : ", error.response.data.detail);
            if (error.response.data.detail.status === "FAILED") {
              console.log( "Request failed due to ",error.response.data.detail.res_data);
              setShowMsg("Please try again ...");
              setShowMsgTitle("Unable to process your request!");
              setShow(true);
              //navigate("/auth");
            } else {
              console.log("Non status ERROR code ...");
              setShowMsg(error.response.data.detail);
              setShowMsgTitle("Unable to process your request at this moment!");
              setShow(true);
            }
          }
        }
        return [retStateLoad, supplierRet];
      };

      useEffect(() => {
        if (!initialized.current) {
            console.log("*************** useEffect called ##########################");
            //setCustomerIdSelect(optCustomer[0]);
            setToRender(true);
            ( async() => {
              var [retStateLoad, supplierRet] = await CallSupplierData();
              if (retStateLoad){
                  var findDefault = supplierRet.find(element => element.is_default === true);
                      if (findDefault !== undefined) {
                        setSupplierIdSelect(findDefault);
                        
                      var [retStateLoad, dcRet, summaryRet] = await getSupplierList(findDefault.value);
                     
                    }
                console.log(" Supplier :", supplierRet);
              
              }
             
            })();
           
            initialized.current = true;
        }
    }, []);
      
    
    const handlePaymentAdd = async (e) => {
        console.log("handleClickAddOrder called : ", e);

        navigate(`/addUpdateSuppPayment`, {
            state: { passed_element: [], action: "add" },
          });
    };
   
  const handleView = async (e) => {
    console.log("handle edit customers called : ", e);
    console.log("handle edit customers currentTarget called : ", e.currentTarget);
    console.log("handle edit customers target called : ", e.target);
    var selectedTagId = e.currentTarget.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    if (selectedTagId) {
      var currentId = selectedTagId.substring(selectedTagId.lastIndexOf("-") + 1);
      var nameOfElement = selectedTagId.substring(0, selectedTagId.lastIndexOf("-"));
      console.log("Name of element VIEW: ", nameOfElement);
      console.log("Current id VIEW : ", currentId);
      console.log("<<<<<<<<<<<<<<<<< ITEM SELECTED VIEW ********** ",  paymentList[currentId]);
      navigate("/addUpdateSuppPayment", {
        state: {
          passed_element: paymentList[currentId],
          action: "view",
        },
      });
    }
  };
  const handleSupplierSelect = async (e) => {
    console.log("Handle supplier select called : ", e);
    setSupplierIdSelect(e);
};
const onChangeStartDatePicker = (e) => {
  console.log("Date onChange called ...", e);
  console.log("Date onChange DATE called ...", e.target.value);
  setFormValues({ ...formValues, date_from: e.target.value });
};

const onChangeEndDatePicker = (e) => {
  console.log("Date onChange called ...", e);
  console.log("Date onChange DATE called ...", e.target.value);
  setFormValues({ ...formValues, date_to: e.target.value });
};

const handleDateSearch = async (e) => {
    console.log("handleDateSearch called : ", e);
    await getSupplierList(supplierIdSelect.value);
};


  const handlePaymentPrint = async (e) => {
    //setPrintAction(true);
    //setFormValues({ ...formValues, summaryPrint: "ONLY"});
    //setPrintDialog(true);
    //navigate("/printFullReceipt");
    //console.log("Handle Edit clicked FULL : ", e);
    //console.log("Handle Edit clicked FULL currentTarget : ", e.currentTarget);
    //console.log("Handle Edit clicked FULL target : ", e.target);

    //const { name, value } = e.currentTarget;
    //var currentId = name.substring(name.lastIndexOf("-") + 1);
    //console.log("SELECTED DC ROW ID FOR PRINT : ", currentId);
    //setSelectedDCRowForPrint(currentId);
    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printListSuppPayments?bid=${currSelBusinessRef.current}&brid=${currBranchRef.current}&supplier_id=${supplierIdSelect.value}&date_from=${formValues["date_from"]}&date_to=${formValues["date_to"]}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0,0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
  };

  const sendDataToParent  = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if ( passedBackValue.hasOwnProperty("business_id") ) {
      currSelBusinessRef.current = passedBackValue["business_id"];
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);
    
    //getBranchList();
  }

    return (
        <Container>
            <SidebarMain sendBacktoParent={sendDataToParent} />
             { toRender ?
             <form>
                    <Row>

                        <Col xs={9} md={9} sm={9} lg={9}>
                            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem" }}>{menuSettings.permissions.find(element => (element.code === 'listSuppPayments' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'listSuppPayments')).page_title_list : "Bill Payments"}</span>
                        </Col>
                        <Col xs={3}
                            md={3}
                            sm={3}
                            lg={3}
                            className="d-flex justify-content-end float-end">

                              <Button id="paymentPrint" name = "paymentPrint"  onClick={(e)  => handlePaymentPrint(e)}
                               disabled={menuSettings.permissions.find(element => (element.code === 'listSuppPayments' && element.a_print_list === true && paymentList.length > 0)) ? false : true}
                              >
                              <BsFillPrinterFill
                              size={18}
                              style={{ color: "white", cursor: "pointer" }}
                              name={`print-payment-icon`}
                              id={`print-payment-icon`}
                              
                              />
                            </Button>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              {menuSettings.permissions.find(element => (element.code === 'listSuppPayments' && element.a_add === true)) &&
                            <Button id="add-payment-id"
                                size={"sm"}
                                variant="warning"
                                //disabled={menuSettings.permissions.find(element => (element.code === 'listSuppPayments' && element.a_new === true)) ? false : true}
                                onClick={(e) => handlePaymentAdd(e)}>
                                New payment
                            </Button>
                             }
                        </Col>
                        </Row>
                        <hr align="center" />
                        <Row>
                        <Col xs={3} md={3} sm={3} lg={3}>
                            
                            <Select
                                inline
                                id="supplier"
                                name="supplier"
                                value={{
                                    value: supplierIdSelect.value,
                                    label: supplierIdSelect.label,
                                }}
                                options={optSupplier}
                                defaultValue={{
                                    value: supplierIdSelect.value,
                                    label: supplierIdSelect.label,
                                }}
                                onChange={handleSupplierSelect}
                            />
                          </Col>
        <Col xs={5} md={5} sm={5} lg={5}>
          <Row>       
              <Col>
                <Form.Control
                  type="date"
                  name="date_from"
                  placeholder="Start Date"
                  value={formValues.date_from}
                  onChange={onChangeStartDatePicker}
                  //readOnly={isReadOnly}
                  
                />
              
            </Col>
            <Col>
              <Form.Label style={{textAlign: "center", paddingTop: "5px"}}>To</Form.Label>
            </Col>
          
            <Col>
           
                <Form.Control
                  type="date"
                  name="date_to"
                  placeholder="End Date"
                  value={formValues.date_to}
                  onChange={onChangeEndDatePicker}
                  //readOnly={isReadOnly}
                  
                />
                 </Col>
                <Col>
                 <Button id="search-order-id"
                    size={"md"}
                    variant="success"
                    onClick={(e) => handleDateSearch(e)}>
                    Search
                    </Button>
                  </Col>
                 </Row>
                </Col>          
                  </Row>
                  
                  <hr align="center" />
                  {/*
                  <Row>
                    <Col xs={7} md={7} sm={7} lg={7}></Col>
                    <Col xs={5} md={5} sm={5} lg={5} style={{textAlign: "right"}}> 
           
                        <Form.Label style={{fontWeight: 'bold',color: "#43C70F"}}>
                           Total Payments :
                        </Form.Label>
                         <Form.Label style={{fontWeight: 'bold', paddingLeft: "2px",color: "#43C70F"}}>
                            {supplierSummary.pymt_count}
                          </Form.Label>
          
                                  <span style={{ color: '#C0C0C0'}}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
        
                         <Form.Label style={{fontWeight: 'bold',color: "#43C70F"}}>
                              Total Amount :
                          </Form.Label>
                          <Form.Label style={{fontWeight: 'bold', paddingLeft: "2px",color: "#43C70F"}}>
                          {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplierSummary.pymt_total_amt).toFixed(2)))}
                          </Form.Label>
         
                    </Col>  
                  </Row>  
                  */}

            <tr className="table-primary">
              <th className="table-row-heading" style={{textAlign : "left", width: "25%"}}>Total Payments : {supplierSummary.pymt_count}</th>
              <th className="table-row-heading" style={{textAlign : "center", width: "0%"}}></th>
              <th className="table-row-heading" style={{textAlign : "center", width:"0%"}}></th>
              <th className="table-row-heading" style={{textAlign : "right", width: "25%"}}>Total :</th>
              <th className="table-row-heading" style={{textAlign : "right", width: "15%"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplierSummary.pymt_total_amt).toFixed(2)))}</th>
              <th className="table-row-heading" style={{textAlign : "center", width: "15%"}}></th>
              <th className="table-row-heading" style={{textAlign : "center", width: "15%"}}></th>
              {/*<th className="table-row-heading" style={{textAlign : "center"}}>Paid By</th>
              <th className="table-row-heading" style={{textAlign : "center"}}>Paid For</th>
              <th className="table-row-heading" style={{textAlign : "center"}}>Paid As</th>*/}
              <th className="table-row-heading" style={{textAlign : "center", width: "5%"}}></th>
            </tr>

          <Table striped bordered hover size="sm">
          <thead>
            <tr className="table-primary">
              <th className="table-row-heading" key={"order-list-heading-key"} style={{width: "5%"}}>S.No</th>
              <th className="table-row-heading" style={{textAlign : "center", width: "10%"}}>Payment Date</th>
              <th className="table-row-heading" style={{textAlign : "center", width:"10%"}}>Payment No</th>
              <th className="table-row-heading" style={{textAlign : "center", width: "25%"}}>Supplier</th>
              <th className="table-row-heading" style={{textAlign : "center", width: "15%"}}>Amount</th>
              <th className="table-row-heading" style={{textAlign : "center", width: "15%"}}>Payment Mode</th>
              <th className="table-row-heading" style={{textAlign : "center", width: "15%"}}>Reference No</th>
              {/*<th className="table-row-heading" style={{textAlign : "center"}}>Paid By</th>
              <th className="table-row-heading" style={{textAlign : "center"}}>Paid For</th>
              <th className="table-row-heading" style={{textAlign : "center"}}>Paid As</th>*/}
              <th className="table-row-heading" style={{textAlign : "center", width: "5%"}}>Details</th>
            </tr>
          </thead>

          <tbody>
            {paymentList.map((paymentInd, idx) => (
              <tr key={`receipt-table-row-key-${idx}`}>
                
                <td style={{textAlign : "right", width: "5%"}}
                  key={`payment-table-row-key-sno-${idx}`}
                  id={`payment-table-row-id-sno-${idx}`}
                  name={`payment-table-row-name-sno-${idx}`}  
                >
                  {idx + 1}
                </td>

                

                <td
                  key={`payment-table-row-key-date-${idx}`}
                  id={`payment-table-row-id-date-${idx}`}
                  name={`payment-table-row-name-date-${idx}`}
                  style= {{width: "10%", textAlign: "left"}}
                >
                  {paymentInd.pymt_date}
                </td>
                <td
                  key={`payment-table-row-key-ono-${idx}`}
                  id={`payment-table-row-id-ono-${idx}`}
                  name={`payment-table-row-name-ono-${idx}`}
                  style= {{width: "10%", textAlign: "right"}}
                >
                  {paymentInd.pymt_no}
                </td>

                <td style={{textAlign: "left", width: "25%"}}
                  key={`payment-table-row-key-paid-to-${idx}`}
                  id={`payment-table-row-id-paid-to-${idx}`}
                  name={`payment-table-row-name-paid-to-${idx}`}
                >
                  {paymentInd.supplier_name}
                </td>

                <td
                  key={`payment-table-row-key-amount-${idx}`}
                  id={`payment-table-row-id-amount-${idx}`}
                  name={`payment-table-row-name-amount-${idx}`}
                  style={{textAlign: "right",width: "15%"}}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(paymentInd.pymt_amt).toFixed(2)))}
                </td>

                <td style={{textAlign: "left", width: "15%"}}
                  key={`payment-table-row-key-payment-mode-${idx}`}
                  id={`payment-table-row-id-payment-mode-${idx}`}
                  name={`payment-table-row-name-payment-mode-${idx}`}
                >
                  {paymentInd.pymt_mode}
                </td>

                <td style={{textAlign: "right", width: "15%"}}
                  key={`payment-table-row-key-ref-number-${idx}`}
                  id={`payment-table-row-id-ref-number-${idx}`}
                  name={`payment-table-row-name-ref-number-${idx}`}
                >
                  {paymentInd.ref_no}
                </td>
                 {/*
                <td style={{textAlign: "left"}}
                  key={`payment-table-row-key-payment-by-${idx}`}
                  id={`payment-table-row-id-payment-by-${idx}`}
                  name={`payment-table-row-name-payment-by-${idx}`}
                >
                  {paymentInd.pay_received_by}
                </td>

                <td style={{textAlign: "left"}}
                  key={`payment-table-row-key-towards-${idx}`}
                  id={`payment-table-row-id-towards-${idx}`}
                  name={`payment-table-row-name-towards-${idx}`}
                  //style= {{width: "120px"}}
                >
                  {paymentInd.towards}
                </td>

                <td style={{textAlign: "left"}}
                  key={`payment-table-row-key-payment-type-${idx}`}
                  id={`payment-table-row-id-payment-type-${idx}`}
                  name={`payment-table-row-name-payment-type-${idx}`}
                >
                  {paymentInd.payment_type}
                </td>
                */}

                <td style={{textAlign: "center", width: "5%"}}
                  key={`payment-table-row-key-actions-${idx}`}
                  id={`payment-table-row-id-actions-${idx}`}
                  name={`payment-table-row-name-actions-${idx}`}
                >
                  <Button
                    variant="light"
                    size="sm"
                    onClick={(e) => handleView(e)}
                    name={`payment-edit-icon-${idx}`}
                    id={`payment-edit-icon-${idx}`}
                  >
                    <BsCardText
                    size={28}
                    style={{ color: "#0d6efd", cursor: "pointer" }}
                    name={`payment-view-icon-${idx}`}
                    id={`payment-view-icon-${idx}`}
                    disabled={menuSettings.permissions.find(element => (element.code === 'listSuppPayments' && element.a_detailview === true)) ? false : true}
                    />
                  </Button>
                </td>
                </tr>
            ))}
            </tbody>
            </Table>
            </form>
       : 
       <div className="container-spinner">
      <div className="vertical-center-spinner">
       <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
       </div>
       </div>
      }  
           
                
        </Container>
    );

}

export default ListSuppPayments;