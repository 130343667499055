import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';

import { client } from '../config/Config';

//import StockDocument from "./StockDocument";
import {getCompanyInfo, formatDateIndian } from "./utils";

//import logo_left from '../logo_left.png';
//import logo_right from '../logo_right.png';
import business_logo from '../assets/images/business_logo.png';

//const borderColor = '#a7a5a5';
const MAX_CHAR_PER_ROW = 40;
const NTIMES_SIZE = 0.65;
const itemRowHeight = 20;
const MULTIPLY_PERC = 0.75;
const TEXT_ALIGN = 4;
const BORDER_COLOR = '#DCDCDC';
const BORDER_COLOR2 = "#C8C8C8";
const HEADING_COLOR = '#646363';
const BORDER_WIDTH = 0.5;
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 9,
    paddingTop: 30,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom:10,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    //width: '100%',
    //height: '33%',
    width: '100%',
    height: '100%',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logo_image2: {
    width: '15%',
    height: '98px',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  business_content: {
    width: '60%',
    height: '98px',
    //paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container2: {
    //paddingBottom:0,
    flexDirection: 'row',
    //borderBottomColor: '#bff0fd',
    //backgroundColor: '#bff0fd',
    //borderBottomWidth: 1,
    //alignItems: 'center',
    //height: 12,
    //textAlign: 'center',
    //fontStyle: 'bold',
    //flexGrow: 1,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 1,
    marginBottom: 10,
    //borderWidth: 1,
    //borderColor: '#a8a8a8',

  },
  container: {
    flexDirection: 'row',
    borderBottomColor: BORDER_COLOR,
    borderTopColor: BORDER_COLOR,
    //backgroundColor: '#a8a8a8',
    borderBottomWidth: BORDER_WIDTH,
    borderTopWidth: BORDER_WIDTH,
    alignItems: 'center',
    //height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    //flexGrow: 1,
    //flexWrap: 'wrap',
    //marginTop: 1,
    //marginBottom: 10,
    //borderWidth: 1,
    //borderWidth: 1,
    //marginBottom: 5,
  },
  row: {
    flexDirection: 'row',
    //borderBottomColor: '#a8a8a8',
    //borderBottmWidth: 1,
    alignItems: 'center',
    //height: 20,
    fontStyle: 'bold',
  },
  
  sno: {
    width: '5%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    borderLeftColor: BORDER_COLOR,
    borderLeftWidth: BORDER_WIDTH,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN 
  },
  supplier: {
    width: '35%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN  
  },

  openbal: {
    width: '15%',
    fontSize: "10px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN  
  },
  inv: {
    width: '15%',
    fontSize: "10px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN  
  },
  /*
  bill: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'center' 
  },
  
  total: {
    width: '12%',
    fontSize: "10px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'center' 
  },
  */
  paid_amount: {
    width: '15%',
    fontSize: "10px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN   
  },
  net: {
    width: '15%',
    fontSize: "10px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN   
  },
  /*
  received_byReceipt: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'center'  
  },
  towardsReceipt: {
    width: '15%',
    fontSize: "10px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'center',
    //paddingLeft: ""  
  },
  addl_notesReceipt: {
    width: '15%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'center', 
    //paddingLeft: "30px"  
  },
  */
  snoData: {
    width: '5%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    borderLeftWidth: BORDER_WIDTH,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    paddingRight:3,
    textAlign: 'right',
    paddingRight: 4,
    borderLeftColor: BORDER_COLOR,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
  },
  supplierData: {
    width: '35%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    textAlign: 'left',
    paddingLeft: 4,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
  },
  openbalData: {
    width: '15%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    textAlign: 'right', 
    paddingRight: 4,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
  },
  invData: {
    width: '15%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    textAlign: 'right', 
    paddingRight: 4,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
  },
  /*
  billData: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: '#a7a5a5',
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderBottomColor: '#a7a5a5',
    textAlign: 'right',
    paddingRight: 2,
    height: itemRowHeight
  },
  
  totalData: {
    width: '12%',
    fontSize: "10px",
    borderRightColor: '#a7a5a5',
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderBottomColor: '#a7a5a5',
    textAlign: 'right', 
    paddingRight: 2,
    height: itemRowHeight
  },
  */
  paid_amountData: {
    width: '15%',
    fontSize: "9px",
    borderRightColor:BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    textAlign: 'right',
    paddingRight: 4,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
  },
  netData: {
    width: '15%',
    fontSize: "9px",
    borderRightColor: BORDER_COLOR,
    borderRightWidth: BORDER_WIDTH,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    textAlign: 'right',
    paddingRight: 4,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
  },
  /*
  received_byReceiptData: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: '#a7a5a5',
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderBottomColor: '#a7a5a5',
    textAlign: 'left',
    paddingLeft: 2,
    height: itemRowHeight
  },
  towardsReceiptData: {
    width: '15%',
    fontSize: "10px",
    borderRightColor: '#a7a5a5',
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderBottomColor: '#a7a5a5',
    textAlign: 'left',
    paddingLeft: 2,
    height: itemRowHeight
  },
  addl_notesReceiptData: {
    width: '15%',
    fontSize: "10px",
    borderBottomWidth: 1,
    borderBottomColor: '#a7a5a5',
    textAlign: 'left',
    paddingLeft: 2,
    borderRightWidth:1,  
    borderRightColor: '#a7a5a5',
    height: itemRowHeight
  },
  */

  sno1Data: {
    width: '5%',
    fontSize: "10px",
    paddingRight:4,
    textAlign: 'right',
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
    //borderLeftWidth: 1, 
    //borderLeftColor: BORDER_COLOR,
  
  },
  supplier1Data: {
    width: '35%',
    fontSize: "10px",
    textAlign: 'right',
    paddingRight: 4,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN  
  },
  openbal1Data: {
    width: '15%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4 ,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN   
  },
  inv1Data: {
    width: '15%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN 
  },
  /*
  bill1Data: {
    width: '10%',
    fontSize: "10px",
    textAlign: 'right',
    paddingLeft: 2
  
  },
  
  total1Data: {
    width: '12%',
    fontSize: "10px",
    textAlign: 'right', 
  },
  */
  paid_amount1Data: {
    width: '15%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN    
  },
  net1Data: {
    width: '15%',
    fontSize: "8px",
    textAlign: 'right',
    //borderRightWidth: BORDER_WIDTH,
    paddingRight: 4,
    height: itemRowHeight,
    paddingTop: TEXT_ALIGN
    //borderRightColor: BORDER_COLOR,
    
  },

  sno2Data: {
    width: '20%',
    fontSize: "9px",
    paddingLeft:4,
    textAlign: 'left',
    //borderLeftWidth: 1, 
    //borderLeftColor: BORDER_COLOR,
  
  },
  supplier2Data: {
    width: '20%',
    fontSize: "9px",
    textAlign: 'right',
    paddingRight: 4  
  },
  openbal2Data: {
    width: '15%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4    
  },
  inv2Data: {
    width: '15%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4 
  },
  /*
  bill1Data: {
    width: '10%',
    fontSize: "10px",
    textAlign: 'right',
    paddingLeft: 2
  
  },
  
  total1Data: {
    width: '12%',
    fontSize: "10px",
    textAlign: 'right', 
  },
  */
  paid_amount2Data: {
    width: '15%',
    fontSize: "8px",
    textAlign: 'right',
    paddingRight: 4    
  },
  net2Data: {
    width: '15%',
    fontSize: "8px",
    textAlign: 'right',
    //borderRightWidth: 1,
    paddingRight: 4,
    //borderRightColor: BORDER_COLOR,
    
  },
  /*
  received_by1ReceiptData: {
    width: '10%',
    fontSize: "10px",
    textAlign: 'left',
    paddingLeft: 2  
   
  },
  towards1ReceiptData: {
    width: '15%',
    fontSize: "10px",
    paddingLeft: 2  
  },
  addl_notes1ReceiptData: {
    width: '15%',
    fontSize: "10px",
    textAlign: 'left',
    paddingLeft: 2  
  },
  */


});

const PrintSupplierOS = (props) => {
  let receiptArr = [
 
      {
      sno: "1",
      receipt_number: "RCPT001",
      receipt_date: "01-01-2024",
      customer_name: "RRR Constructions",
      amount: "5000",
      received_mode: "Cash",
      ref_number: "123",
      received_by_name: "Joel",
      towards: "testing",
	    addl_notes: "Ui ",
      },
                             
  ]


 
 

  const [reportData, setReportData] = useState({});
  const [toRender, setToRender] = useState(false);
  const [supplierReportList, setSupplierReportList] = useState([]);
  const [companyInfo, setCompanyInfo] = useState({});
  const [reportHeader, setReportHeader] = useState({});
  const [reportTitle, setReportTitle] = useState({});
  const [supplierSummary, setSupplierSummary] = useState([]);
  const [setting, setSetting] = useState({});
  const [printParams, setPrintParams] = useState([]);

  /*
  var sum_amount = useRef(0.00);

  var total_receipt_ref = useRef(0.00);
  var amount_ref = useRef(0.00);

  
  total_receipt_ref.current = receiptList.length;
  amount_ref.current = 0.00;
  receiptList.map((receipt) => {
    console.log("Total Amount Ref :", amount_ref.current);
    amount_ref.current = parseFloat(amount_ref.current) + parseFloat(receipt.amount);
    })
  console.log("Total amount:", amount_ref.current);


  var customer_id_ref = useRef(0);
  var start_date_ref = useRef("");
  var end_date_ref = useRef("");
 */
  
  /*
  console.log("CALLED CURRENT PAGE : ", window.location.href);

    console.log("CALLED window locaion : ", window.location);
    let params = (new URL(window.location)).searchParams;
    console.log("COMPLETE PARAMS : ", params);
    let orderIdParam = params.get("order_id");
    console.log("ORDER ID FROM PARAM : ", orderIdParam);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.indexOf('?') + 1);
    var urlTmp = urlStr.substring(0, urlStr.indexOf("?"));

    console.log("URL : ", urlTmp);
    console.log("URL : ", paramsTmp);

    let searchParams = new URLSearchParams(paramsTmp);
    var customerIdTemp2 = searchParams.get("customer_id");
    console.log("CUSTOMED ID : ", customerIdTemp2);
    var productIdTemp2 = searchParams.get("product_id");
    console.log("PRODUCT ID : ", productIdTemp2);
    var startDateStr = searchParams.get("start_date");
    console.log("START DATE : ", startDateStr);
    var endDateStr = searchParams.get("end_date");
    console.log("END DATE : ", endDateStr);

    console.log("Extracted CUSTOMER ID  : ", customerIdTemp2);
    console.log("Extracted PRODUCT ID : ", productIdTemp2);
   */

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  const formateTime = (currTime) => {
    var h = (currTime.getHours() < 10 ? "0" : "") + currTime.getHours();
    var m = (currTime.getMinutes() < 10 ? "0" : "") + currTime.getMinutes();
    var hrMin = h + ":" + m;
    return hrMin;
  };

  const formatPrintDate = (currTime) => {
    var dateComponent = [
      padTo2Digits(currTime.getDate()),
      padTo2Digits(currTime.getMonth() + 1),
      currTime.getFullYear(),
    ].join("-");

    var timeComponent = [
      padTo2Digits(currTime.getHours() % 12 || 12 ),
      padTo2Digits(currTime.getMinutes())
    ].join(":");

    const ampm = currTime.getHours() >= 12 ? 'PM' : 'AM';

    return dateComponent + " " + timeComponent + " " + ampm;
  }
  /*
  const getAllSummaryList = async () => {
    var retState = false;
    var deliverySummaryList = [];
    try {
      
      var res = await axios.get(client.domain + `/getDeliverySummaryList?customer_id=${customerIdTemp2}&start_date=${startDateStr}&end_date=${endDateStr}&product_id=${productIdTemp2}`, { withCredentials: false });
      console.log("Response from server getAllSummaryList : ", res.data.detail);
      if (res.data.detail.status === 'SUCCESS') {
        console.log("Response message from server SummaryList : ", res.data.detail.data);
        retState = true;
        if (res.data.detail.data.length > 0) {
          deliverySummaryList = res.data.detail.data; 
        }
        
      }
    }
    catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === 'ERR_NETWORK') {
        console.log("Not able to reach target server please try after sometime");
      }
      else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === 'FAILED') {
          console.log('Request failed due to ', error.response.data.detail.res_data);
         
        }
      }
    }
    return [retState, deliverySummaryList];
  }
  
    */

  const getSupplierOSPrint = async (bid_pass, brid_pass, supplier_id_pass, date_ason_pass) => {
    var retStateLoad = false;
    var headerRet = [];
    var titleRet = [];
    var supplierReportRet = [];
    var summaryRet = [];
    var settingRet = [];
    var  paramsRet = [];
   
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      //console.log("Selected Customer :", customerIdSelect);
      var reqParams = {
        bid: bid_pass,
        brid: brid_pass,
        supplier_id: supplier_id_pass,
        date_ason: date_ason_pass,
        //date_to: date_to_pass

      };
      console.log("REQUEST PARAMS: ", reqParams );
      var res = await axios.post(client.domain + "/bms/getSupplierOSListPrint", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server Receipt : ",res.data.detail.data );
        setReportHeader(res.data.detail.data.report_header);
        setReportTitle(res.data.detail.data.report_title);
        setSupplierReportList(res.data.detail.data.supplier_os);
        setSupplierSummary(res.data.detail.data.supplier_os_Summary[0]);
        setSetting(res.data.detail.data.report_print_setting);
        setPrintParams(res.data.detail.data.report_print_params);
        //setCustomerListFull(res.data.detail.data);
        //setShowMsgTitle("Customer Info Added Successfully");
        //setToRender(true);
        headerRet = res.data.detail.data.report_header;
        titleRet = res.data.detail.data.report_tile;
        supplierReportRet = res.data.detail.data.supplier_os;
        summaryRet = res.data.detail.data.supplier_os_Summary;
        settingRet = res.data.detail.data.report_print_setting;
        paramsRet = res.data.detail.data.report_print_params;
        retStateLoad = true;
        //navigate("/dashboard");
        //setDcReportData(res.data.detail.res_data);
        //setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log( "Not able to reach target server please try after sometime");
        //setShowMsg("Please try after sometime");
        //setShowMsgTitle("Network Issue!");
        //setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log( "Request failed due to ",error.response.data.detail.res_data);
          //setShowMsg("Please try again ...");
          //setShowMsgTitle("Unable to process your request!");
          //setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          //setShowMsg(error.response.data.detail);
          //setShowMsgTitle("Unable to process your request at this moment!");
          //setShow(true);
        }
      }
    }
    return [retStateLoad, supplierReportRet, summaryRet, headerRet, titleRet, settingRet, paramsRet];
  };
  
  useEffect(() => {
    console.log("useEffect method called ...");
    //setToRender(true); 
    //getCustomerList();
    console.log("CALLED CURRENT PAGE : ", window.location.href);

    console.log("CALLED window locaion : ", window.location);
    let params = (new URL(window.location)).searchParams;
    console.log("COMPLETE PARAMS : ", params);
    let customerIdParam = params.get("customer_id");
    console.log("CUSTOMER ID FROM PARAM : ", customerIdParam);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.indexOf('?') + 1);
    var urlTmp = urlStr.substring(0, urlStr.indexOf("?"));

    console.log("URL : ", urlTmp);
    console.log("URL : ", paramsTmp);

    let searchParams = new URLSearchParams(paramsTmp);
    console.log("SEARCH PARAMS :", searchParams );
    var bid = searchParams.get("bid");
    console.log("^^^^^^^^^^^^^^^^^^^ BUSINESS ID : ", bid);
    
    var brid = searchParams.get("brid");
    console.log("^^^^^^^^^^^^^^^^^^^ BUSINESS BRANCH0 ID : ", brid);

    var supplierId = searchParams.get("supplier_id");
    console.log("^^^^^^^^^^^^^^^^^^^ Supplier ID : ", supplierId);
    
    var date_ason = searchParams.get("date_ason");
    console.log("^^^^^^^^^^^^^^^^^^^ Date As On : ", date_ason);
    /*
    var date_to = searchParams.get("date_to");
    console.log("^^^^^^^^^^^^^^^^^^^ Date To : ", date_to);
    */
    (async () => {
      var [retStateLoad, customerReportRet, summaryRet, headerRet, titleRet, settingRet, paramsRet] = await getSupplierOSPrint(bid, brid, supplierId, date_ason);
      setToRender(true);
    })();
  
    
  }, [])
  

  return (
    <PDFViewer style={styles.viewer}>
      {toRender === true &&
        <Document>
          <Page size="A4" orientation="landscape" style={styles.page} wrap={true}>
          
              <View style={{ flexDirection: 'row', borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR }}>
              {setting.is_print_logo === true &&
                <View style={{ textAlign: 'right', width: '15%' }}>
                  <Image style={{ marginHorizontal: '10%', width: 'auto', height: 'auto', height: "64px", width: "64px" }} src={business_logo} />
                </View>
              }
                <View style={{ width: "85%", alignItems: "center", /*paddingRight: "30px",*/ /*borderBottomWidth: 1,*/ borderBottomColor: BORDER_COLOR/*,marginTop: 1, marginBottom: 10, borderWidth: 1*/ }} key="address-key">
                  <Text style={{ fontSize: "20px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>{reportHeader.business_name}</Text>
                  {/*<Text>{reportHeader.business_taxdetails}</Text>*/}
                  <Text>{reportHeader.ho_address}</Text>
                  {setting.is_print_branch_address === true &&
                    <Text>{reportHeader.bo_address}</Text>
                  }
                  <hr style={{paddingBottom: "5px"}}/>
                </View>  
              </View>
            
           
            
            <View style={{ width: "100%", alignItems: "center",/* paddingLeft: "20px",*/ textAlign: 'center', borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR, paddingTop: "5px" }}>
              <Text style={{ fontSize: "10px", textAlign: 'center', alignContent: 'center', fontFamily: 'Helvetica-Bold', color: HEADING_COLOR }}>{reportTitle.rpt_title}</Text>
            </View>

            <View style={{...styles.row,paddingTop:TEXT_ALIGN}} key="items-${idx}">
                    <Text style={{...styles.sno2Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Total supplier :{supplierSummary.total_supp}</Text>
                    <Text style={{...styles.supplier2Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Total:</Text>
                    <Text style={{...styles.inv2Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplierSummary.openbal).toFixed(2)))}</Text>
                    <Text style={{...styles.openbal2Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplierSummary.inv_os).toFixed(2)))}</Text>
                    {/*<Text style={{...styles.bill1Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customerSummary.bill_os).toFixed(2)))}</Text>
                    <Text style={{...styles.total1Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customerSummary.total_os).toFixed(2)))}</Text>*/}
                    <Text style={{...styles.paid_amount2Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplierSummary.paid_amt).toFixed(2)))}</Text>
                    <Text style={{...styles.net2Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplierSummary.balance_os).toFixed(2)))}</Text>
              </View>
            {/*
            <hr style={{paddingBottom: "5px"}}/>
            <View style={{flexDirection: "row",borderBottomWidth: 1, borderBottomColor: '#a7a5a5', paddingBottom: "5px"}}>
            <View style={{ width: "7%"}}>
                <Text style={{fontFamily: 'Helvetica-Bold'}}>Summary :-  </Text>
                </View>

                <View style={{flexDirection: "row", width: "13%", textAlign: "right"}}>
                <Text style={{fontFamily: 'Helvetica-Bold'}}>Total supplier : </Text>
                <Text> {supplierSummary.total_supp}</Text>
                </View>

                <View style={{flexDirection: "row",  width: "20%", textAlign: "right"}}>
                <Text style={{fontFamily: 'Helvetica-Bold'}}>Total Opening Balance : </Text>
                <Text> {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplierSummary.openbal).toFixed(2)))}</Text>
                </View>

                <View style={{flexDirection: "row",  width: "20%", textAlign: "right"}}>
                <Text style={{fontFamily: 'Helvetica-Bold'}}>Total Invoice OS : </Text>
                <Text> {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplierSummary.inv_os).toFixed(2)))}</Text>
                </View>
                
                <View style={{flexDirection: "row",  width: "20%", textAlign: "right"}}>
                <Text style={{fontFamily: 'Helvetica-Bold'}}>Total  Paid Amount : </Text>
                <Text> {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplierSummary.paid_amt).toFixed(2)))}</Text>
                </View>

                <View style={{flexDirection: "row",  width: "20%", textAlign: "right"}}>
                <Text style={{fontFamily: 'Helvetica-Bold'}}>Total Balace OS : </Text>
                <Text> {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplierSummary.balance_os).toFixed(2)))}</Text>
                </View>
            </View>
                */}
           
            <hr style={{paddingBottom: "2px"}}/>

            <View style={styles.tableContainer}>
            
            <View fixed style={styles.container}>
                <Text style={styles.sno}>S.No</Text>
                <Text style={styles.supplier}>Supplier</Text>
                <Text style={styles.openbal}>Opening Balance</Text>
                <Text style={styles.inv}>Invoice Outstanding</Text>
                {/*
                <Text style={styles.bill}>Bills Outstanding</Text>
                
                <Text style={styles.total}>Total Outstanding</Text>
               */}
                <Text style={styles.paid_amount}>Paid Amount</Text>
                <Text style={styles.net}>Balance Outstanding</Text>
                
              </View>

              <View style={{flexGrow: 1}}>
              {supplierReportList.map((supplier, idx) => (
              <View style={styles.row} key="items-${idx}">
                    <Text break style={{...styles.snoData, height: supplier.supplier_name.length > MAX_CHAR_PER_ROW ? ((supplier.supplier_name.length / MAX_CHAR_PER_ROW) * itemRowHeight) : styles.snoData.height}}>{idx + 1}</Text>
                    <Text style={{...styles.supplierData, height: supplier.supplier_name.length > MAX_CHAR_PER_ROW ? ((supplier.supplier_name.length / MAX_CHAR_PER_ROW) * itemRowHeight) : styles.supplierData.height, paddingRight: '2px'}}>{supplier.supplier_name}</Text>
                    <Text style={{...styles.openbalData, height: supplier.supplier_name.length > MAX_CHAR_PER_ROW ? ((supplier.supplier_name.length / MAX_CHAR_PER_ROW) * itemRowHeight) : styles.openbalData.height}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplier.openbal).toFixed(2)))}</Text>
                    <Text style={{...styles.invData, height: supplier.supplier_name.length > MAX_CHAR_PER_ROW ? ((supplier.supplier_name.length / MAX_CHAR_PER_ROW) * itemRowHeight) : styles.invData.height}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplier.inv_os).toFixed(2)))}</Text>
                    {/*<Text style={{...styles.billData, height: customer.customer_name.length > MAX_CHAR_PER_ROW ? ((customer.customer_name.length / MAX_CHAR_PER_ROW) * itemRowHeight) : styles.billData.height}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customer.bill_os).toFixed(2)))}</Text>
                    <Text style={{...styles.totalData, height: customer.customer_name.length > MAX_CHAR_PER_ROW ? ((customer.customer_name.length / MAX_CHAR_PER_ROW) * itemRowHeight) : styles.totalData.height}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customer.total_os).toFixed(2)))}</Text>*/}
                    <Text style={{...styles.paid_amountData, height: supplier.supplier_name.length > MAX_CHAR_PER_ROW ? ((supplier.supplier_name.length / MAX_CHAR_PER_ROW) * itemRowHeight) : styles.paid_amountData.height}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplier.paid_amt).toFixed(2)))}</Text>
                    <Text style={{...styles.netData, height: supplier.supplier_name.length > MAX_CHAR_PER_ROW ? ((supplier.supplier_name.length / MAX_CHAR_PER_ROW) * itemRowHeight) : styles.netData.height}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplier.balance_os).toFixed(2)))}</Text>
                    
              </View>
               ))}
              </View>

              
              </View>
              <View style={{...styles.row,borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR}} key="items-${idx}">
                    <Text style={styles.sno1Data}>  </Text>
                    <Text style={{...styles.supplier1Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Total:</Text>
                    <Text style={{...styles.inv1Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplierSummary.openbal).toFixed(2)))}</Text>
                    <Text style={{...styles.openbal1Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplierSummary.inv_os).toFixed(2)))}</Text>
                    {/*<Text style={{...styles.bill1Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customerSummary.bill_os).toFixed(2)))}</Text>
                    <Text style={{...styles.total1Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customerSummary.total_os).toFixed(2)))}</Text>*/}
                    <Text style={{...styles.paid_amount1Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplierSummary.paid_amt).toFixed(2)))}</Text>
                    <Text style={{...styles.net1Data,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplierSummary.balance_os).toFixed(2)))}</Text>
              </View>
              
            <hr style={{paddingBottom: "10px"}}/>
            
            <View fixed style={{flexDirection: 'row'}}>
        
            {setting.is_print_datetime === true &&
              <Text style={{textAlign: 'left', flex: 1, paddingTop: '15px'}} render={({ pageNumber, totalPages }) => (`Printed On: ${formatPrintDate(new Date())}`)} fixed />
            }

            {setting.is_print_endofrpttext === true &&
              <Text style={{ textAlign: 'center', paddingTop: '15px', flex: 1}} render={({ pageNumber, totalPages }) => (
                <View> {pageNumber === totalPages &&
                  <Text>.... End of the document ....</Text>
                  }
                </View>
              )}  />
            }

              {setting.is_print_pageno === true &&
                <Text style={{flex: 1, textAlign: 'right', paddingTop: '15px'}} render={({ pageNumber, totalPages }) => (
                `Page No : ${pageNumber} / ${totalPages}`
                )} fixed />
              }
          
          </View>
            
          </Page>
        </Document>
      }
    </PDFViewer>
  );
}

export default PrintSupplierOS;