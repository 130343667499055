import React, { useState, useEffect, useRef, useReducer } from "react";
import Table from "react-bootstrap/Table";
import { fragment } from "react";
import select from "react-select";
import Form from "react-bootstrap/Form";
//import Sidebar2 from "./Sidebar2";
import Button from "react-bootstrap/Button";
import axios from "axios";
//import { CUSTOMER_TABLE, client, DB_CUSTOM_VERSION, COMPANY_TABLE, DATABASE_NAME } from "../config/Config";
import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsCardText,
} from "react-icons/bs";

import { useNavigate } from "react-router-dom";
import { Row, Col, Alert, Modal, Container, Spinner,Image } from "react-bootstrap";

//import MenuIms from "./MenuIms";
import SidebarMain from "./SidebarMain";
import spinner_logo from '../assets/images/spinner_logo.png';

//import { DATABASE_NAME, COMPANY_TABLE_TABLE } from "../config/Config";
//import { createStoreCompany } from "./utils";

function ListRcs() {
  const initialized = useRef(false);
  const navigate = useNavigate();
  const [checkedState, setCheckedState] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  
 // const [productList, setProductList] = useState([]);

  

  const [checked, setChecked] = useState(false);
  

  const [companyList, setCompanyList] = useState([]);
  const [toRender, setToRender] = useState(false);
  const [show, setShow] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(-1);
  const [showMsg, setShowMsg] = useState("Please login again");
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );

  const [dialogTitleMsg, setDialogTitleMsg] = useState("FAILURE");
  const [dialogBodyMsg, setDialogBodyMsg] = useState("Unable to save unit of measurement changes please try after sometime");
  const [dialogAction, setDialogAction] = useState('success');
  const [submitAction, setSubmitAction] = useState(false);

  /*
  const handleCloseDialog = () => {
    setShowDialog(false);
  };
  const handleShowDialog = () => {
    setShowDialog(true);
  };
  */
/*
  const handleUpdate = async(uomData) => {
    console.log("handleSave called ...");

    / *
    var uomData = {
      name: formValues["name"],
      description: formValues["description"],
      is_active: true
    };
    * /
    const request = indexedDB.open(DATABASE_NAME, DB_CUSTOM_VERSION);
    request.onerror = (eventError) => {
      console.error("Why didn't you allow my web app to use IndexedDB?!");
    };
  
    request.onsuccess = (eventSuccess) => {
      console.log("DB CONNECTION success ...");

      var db = eventSuccess.target.result;

      const txn = db.transaction(COMPANY_TABLE, 'readwrite');
            
      // get the Contacts object store
      const store = txn.objectStore(COMPANY_TABLE);
      console.log(store.autoIncrement);
                
      let query = store.put(uomData);
            
      // handle success case
      query.onsuccess = function (eventOnSuccess) {
          console.log("INSERT SUCCESS : ", eventOnSuccess);
          setDialogTitleMsg("Success");
          setDialogBodyMsg("Company Updated Successfully!");
          setSubmitAction(false);
          setDialogAction('success');
          setShowDialog(true);
      };
            
      // handle the error case
      query.onerror = function (eventOnError) {
        console.log("INSERT ERROR : ", eventOnError.target.errorCode);
        setDialogTitleMsg("Failed");
        setDialogBodyMsg("Unable to Update Company. Please try after sometime");
        setDialogAction('failure');
        setSubmitAction(false);
        setShowDialog(true);
      }
            
      // close the database once the 
      // transaction completes
      txn.oncomplete = function () {
        db.close();
      };
    }

  }
  */
  
  const updateProductStatus = async (selectedId) => {
    var selectedObj = companyList[selectedId];
    console.log("SELECTED OBJECT : ", selectedObj);

    selectedObj.is_active = !selectedObj.is_active;
    //handleUpdate(selectedObj);

    /*
    let reqStatusUpdatePayload = {
      id: selectedObj.id,
      is_active: !selectedObj.is_active,
    };

    var headers = client.headers;
    console.log("payload:", reqStatusUpdatePayload);

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}


      var res = await axios.post(
        client.domain + "/ims/activateProduct",
        reqStatusUpdatePayload,
        { headers },
        { withCredentials: false }
      );
      
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        ;

        var itemSelectedUpdate = productList[selectedId];
        itemSelectedUpdate.is_active = !itemSelectedUpdate.is_active;
        console.log(
          "<<<<<<<<<<<<<<<<< ITEM SELECTED MODIFIED ********** ",
          itemSelectedUpdate
        );

        const newitems = productList.slice();
        newitems[selectedId] = itemSelectedUpdate;
        setProductList(newitems);

        setShowDialog(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
        }
      }
    }
    */
    setShowDialog(false);
  };
  /*
  const handleSwitchAction = () => {
    console.log("handleSwitchAction calledss ...");
    console.log("user selected id for action is : ", selectedId);

    var selectedObj = customerList[selectedId];
    console.log("SELECTED OBJECT : ", selectedObj);

    selectedObj.is_active = !selectedObj.is_active;
    handleUpdate(selectedObj);
  };

  const actionEditHandle = (e, data) => {
    console.log("Handle action edit Clicked : ", e);
    //console.log("Handle Clicked edit DATA : ", data);
    console.log("Handle action edit Clicked target : ", e.target);
    console.log(
      "Handle action edit Clicked target Current : ",
      e.currentTarget
    );
    console.log(
      "Handle action edit Clicked target Current id : ",
      e.currentTarget.id
    );
    console.log("Handle action edit Clicked target ID : ", e.target.id);
    console.log("Handle action edit Clicked target NAME : ", e.target.name);
  };
  */

  /*
  const getProductList = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
     

      var res = await axios.get(
        client.domain + "/ims/getAllProducts",
        { headers },
        { withCredentials: false }
      );
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", 0);
        setCustomerList(res.data.detail.data);
        setShowMsgTitle("Product Info Added Successfully");
        setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
  };
  */
  const handleStateChange = (e) => {
    console.log("clicked Value :", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);

    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);
  };
  /*
  const SwitchComponent = () => {};

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };
  */

  /*
  const getUomList = async() => {
    console.log("getUomList called ...");

    const requestList = indexedDB.open(DATABASE_NAME, 1);
    requestList.onerror = (eventError) => {
      console.error("Why didn't you allow my web app to use IndexedDB?!");
    };
  
    requestList.onsuccess = (eventSuccess) => {
      console.log("DB CONNECTION success ...");

      var db = eventSuccess.target.result;

    const request = db.transaction(UOM_TABLE).objectStore(UOM_TABLE).openCursor();

    request.onsuccess = ()=> {
        const cursor = request.result;

        if(cursor){
          console.log("EXTRACTED VALUE : ", cursor.value);
            cursor.continue();
        }else{
            console.log('No more entries')
        }
    }

    request.onerror = (eventErrRead) => {
      console.log("READ ERROR ", eventErrRead);
    }
  }
}
*/


/*
const getCompanyList = async() => {
  console.log("getCompanyList called ...");

    const requestList = indexedDB.open(DATABASE_NAME, DB_CUSTOM_VERSION);
    requestList.onerror = (eventError) => {
      console.error("Why didn't you allow my web app to use IndexedDB?!");
    };
  
    requestList.onsuccess = (eventSuccess) => {
      console.log("DB CONNECTION success ...");

      var db = eventSuccess.target.result;

      if ( !db.objectStoreNames.contains(COMPANY_TABLE) ) {
        console.log("TABLE DOESN'T EXIST");
      }
      else {
    const request = db.transaction(COMPANY_TABLE)
                   .objectStore(COMPANY_TABLE)
                   .getAll();

    request.onsuccess = ()=> {
        const companies = request.result;

        console.log('Got all the Companies');
        console.log("EXTRACTED DATA v2 : ", companies);
        setCompanyList(companies);
        //return uoms;
    }

    request.onerror = (err)=> {
        console.error(`Error to get all uoms: ${err}`)
    }
}
    }

requestList.onupgradeneeded = (eventUpgrade) => {
      console.log("onupgradeneeded called for CUSTOMER ...", eventUpgrade);
      var db = eventUpgrade.target.result;

      //createStoreCompany(db);
    }
}
*/


  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect::ListRCs called ##########################");
      setToRender(true);
      (async () => {
        //await getUomList();
        //await getCompanyList();
        setToRender(true);
      })();
      initialized.current = true;
    }
  }, []);

  const handleAdd = async (e) => {
    console.log("handle add customer called : ", e);
    navigate("/addUpdateRc", {
      state: { passed_element: [], action: "add" },
    });
  };

  const handleView = async (e) => {
    console.log("handle edit uom called : ", e);
    console.log("handle edit uom currentTarget called : ", e.currentTarget);
    console.log("handle edit uoms target called : ", e.target);
    var selectedTagId = e.currentTarget.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    if (selectedTagId) {
      var currentId = selectedTagId.substring(
        selectedTagId.lastIndexOf("-") + 1
      );
      var nameOfElement = selectedTagId.substring(
        0,
        selectedTagId.lastIndexOf("-")
      );
      console.log("Name of element VIEW: ", nameOfElement);
      console.log("Current id VIEW : ", currentId);

      console.log(
        "<<<<<<<<<<<<<<<<< ITEM SELECTED VIEW ********** ",
        companyList[currentId]
      );
      navigate("/addUpdateRc", {
        state: {
          passed_element: companyList[currentId],
          action: "view",
        },
      });
    }
  };
/*
  const handleOnChange = async (e) => {
    console.log("handle on change called : ", e);

    var selectedTagId = e.target.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    var currentId = selectedTagId.substring(selectedTagId.lastIndexOf("-") + 1);
    var nameOfElement = selectedTagId.substring(0, selectedTagId.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    console.log("<<<<<<<<<<<<<<<<< ITEM SELECTED ********** ", customerList[currentId]);

    setSelectedId(currentId);

    setDialogTitleMsg("Warning");
    setDialogBodyMsg("Are you sure want to change state");
    setDialogAction('warning');
    setSubmitAction(false);
    setShowDialog(true);
  };
  */

  /*
  const handleAlertClose = async (e) => {
    setShow(false);
    navigate(-1);
  };
  */

  const handleCloseDialog = async(e) => {
    console.log("handleCloseDialog called ...", e);
    setShowDialog(false);
  }

  const handleOkDialog = async(e) => {
    console.log("handleCloseDialog called ...", e);
    setShowDialog(false);
    if (dialogAction === 'success' ) {
      //navigate('/listUoms');
      navigate(0);
    }
    else if ( dialogAction === 'failure' ) {
      setShowDialog(false);
    }
    else if ( dialogAction === 'warning') {
      setShowDialog(false);
      //handleSwitchAction(selectedId);
      //updateProductStatus(selectedId);
    }
  }

  return (
    <Container>
      <SidebarMain />
      { toRender ?
      <form>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6}>
            <h4>RCs</h4>
          </Col>
          <Col xs={6} md={6} sm={6} lg={6}>
          <Button
                variant="warning"
                id="add-btn-id"
                name="add-btn-name"
                className=" float-end"
                onClick={(e) => handleAdd(e)}
              >
                New Rc
              </Button>
          </Col>
        </Row>
          <hr align="center" />
          <div className="ui-divider"></div>
        <Table striped bordered hover>
          <thead>
            <tr className="table-primary">
              <th className="table-row-heading" style={{textAlign: "center"}}>S.No</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Legal Name</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Trade Name</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Registration Number</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Constitution Of Business</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Address</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Details</th>
            </tr>
          </thead>
          <tbody>
            {companyList.map((company, idx) => (
              <tr key={`company-list-row-key-${idx}`}>
                <td style={{textAlign: "right"}}>{idx + 1}</td>
                <td style={{textAlign: "left"}}>{company.legal_name}</td>
                <td style={{textAlign: "left"}}>{company.trade_name}</td>
                <td style={{textAlign: "left"}}>{company.reg_number}</td>
                <td style={{textAlign: "left"}}>{company.const_business}</td>
                <td style={{textAlign: "left"}}>{company.address1}</td>
                <td style={{textAlign: "center"}}>
                <BsCardText
                      key={`edit-btn-product-key-${idx}`}
                      size={28}
                      style={{ color: "#0D6EFD", cursor: "pointer" }}
                      onClick={(e) => handleView(e)}
                      name={`edit-icon-rc-name-${idx}`}
                      id={`edit-icon-rc-id-${idx}`}
                    />
                  </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Modal
              show={showDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{dialogTitleMsg}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{dialogBodyMsg}</Modal.Body>
              <Modal.Footer>
                { (dialogAction === 'warning') &&
                  <Button variant="secondary" onClick={handleCloseDialog}>Cancel</Button>
                }
                <Button variant="primary" onClick={handleOkDialog}>Ok</Button>
              </Modal.Footer>
            </Modal>
          	</form>
       : 
       <div className="container-spinner">
      <div className="vertical-center-spinner">
       <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
       </div>
       </div>
      }   
    </Container>
  );
}
export default ListRcs;
