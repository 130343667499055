import React, {
    useState,
    useEffect,
    useRef,
    useReducer,
    Fragment,
  } from "react";
  import Table from "react-bootstrap/Table";
  import { fragment } from "react";
  
  import Select from "react-select";
  
  import {
    Form,
    Button,
    Row,
    Col,
    Alert,
    Modal,
    Container,
    Spinner,
    Image
  } from "react-bootstrap";
  //import Sidebar2 from "./Sidebar2";
  import SidebarMain from "./SidebarMain";
  
  import {
    BsFillTrashFill,
    BsPencilSquare,
    BsFileRuled,
    BsCardText,
    BsFillPrinterFill
  } from "react-icons/bs";
  
  import "../App.css";
  
  import { useNavigate } from "react-router-dom";
  import axios from "axios";
  import { client } from "../config/Config";
  import spinner_logo from '../assets/images/spinner_logo.png';
  import {formatDateIndian} from "./utils";
  
  
  const ViewSupplierOS = () => {
    const initialized = useRef(false);
    var navigate = useNavigate();
    
    var menuSettingsStr = localStorage.getItem("_aP");
    var menuSettings = JSON.parse(menuSettingsStr);

    const currSelBusinessRef = useRef(0);
    const currBranchRef = useRef(0);

    const [checkedState, setCheckedState] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  
    function padTo2Digits(num) {
      //console.log("<<<<<<<<<<<<<< given number >>>>>>>>>>>>>>>> : ", num);
      var modNum = num.toString().padStart(2, "0");
      console.log("Modified  value : ", modNum);
      return modNum;
    }
  
    function formatDate(date) {
      return [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-");
    }
  
    const intialValues = {
      date_ason: formatDate(new Date()),
      //end_date: formatDate(new Date()),
      
    }
  
   
    const [supplierReportList, setSupplierReportList] = useState([]);
    const [supplierSummary, setSupplierSummary] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [supplierIdSelect, setSupplierIdSelect] = useState({});
    const [optSupplier, setOptSupplier] = useState([]);
    const [toRender, setToRender] = useState(false);
    const [show, setShow] = useState(false);
    const [formValues, setFormValues] = useState(intialValues);
    const [showMsg, setShowMsg] = useState("Please login again");
    const [showMsgTitle, setShowMsgTitle] = useState("Invalid authentication Session!");

    const CallSupplierData = async () => {
      var retStateLoad = false;
      var supplierRet = [];
  
      try {
        var aIVal = localStorage.getItem("_aT");
        var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
  
        var reqParams = {
          bid: currSelBusinessRef.current
        };
  
        var res = await axios.post(client.domain + "/bms/getSuppInvSearchPreLoadData", reqParams, { headers }, { withCredentials: false });
        console.log("Response from server : ", res.data.detail);
  
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server : ", res.data.detail.data.suppliers);
          var supplierList = [];
  
          //customerList.push({label: "All Customers", value: 0});
          res.data.detail.data.suppliers.map((supplier) => {
            supplierList.push(supplier);
          })
          setOptSupplier(supplierList);
          //setCustomerIdSelect(customerList[0]);
          //setCustomerIdSelect(res.data.detail.data.customers[0]);
         
          setToRender(true);
          supplierRet = supplierList;
          retStateLoad = true;
  
  
        }
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log("Not able to reach target server please try after sometime");
          setShowMsg("Please try after sometime");
          setShowMsgTitle("Network Issue!");
          setShow(true);
        } else {
          console.log("ERROR occured : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log("Request failed due to ", error.response.data.detail.res_data);
            setShowMsg("Please try again ...");
            setShowMsgTitle("Unable to process your request!");
            setShow(true);
            //navigate("/auth");
          } else {
            console.log("Non status ERROR code ...");
            setShowMsg(error.response.data.detail);
            setShowMsgTitle("Unable to process your request at this moment!");
            setShow(true);
          }
        }
      }
      return [retStateLoad, supplierRet];
    };
    

    const getSupplierList = async (supplier_id_pass) => {
      var retStateLoad = false;
      var supplierReportRet = [];
      var summaryRet = [];
      try {
        var aIVal = localStorage.getItem("_aT");
        var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
        console.log("Selected Supplier :", supplierIdSelect);
        var reqParams = {
          bid: currSelBusinessRef.current,
          brid: currBranchRef.current || 0,
          supplier_id: supplier_id_pass,
          date_ason:formValues["date_ason"] 
  
        };
        console.log("REQUEST PARAMS: ", reqParams );
        var res = await axios.post(client.domain + "/bms/getSupplierOSList", reqParams, { headers }, { withCredentials: false });
        console.log("Response from server : ", res.data.detail);
  
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server Invoice : ",res.data.detail.data );
          setSupplierReportList(res.data.detail.data.supplier_os);
          setSupplierSummary(res.data.detail.data.supplier_os_summary[0]);
          //setCustomerListFull(res.data.detail.data);
          //setShowMsgTitle("Customer Info Added Successfully");
          setToRender(true);
          supplierReportRet = res.data.detail.data.supplier_os;
          summaryRet = res.data.detail.data.supplier_os_summary;
          retStateLoad = true;
          
        }
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log( "Not able to reach target server please try after sometime");
          setShowMsg("Please try after sometime");
          setShowMsgTitle("Network Issue!");
          setShow(true);
        } else {
          console.log("ERROR occured : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log( "Request failed due to ",error.response.data.detail.res_data);
            setShowMsg("Please try again ...");
            setShowMsgTitle("Unable to process your request!");
            setShow(true);
            //navigate("/auth");
          } else {
            console.log("Non status ERROR code ...");
            setShowMsg(error.response.data.detail);
            setShowMsgTitle("Unable to process your request at this moment!");
            setShow(true);
          }
        }
      }
      return [retStateLoad, supplierReportRet, summaryRet];
    };
   
    useEffect(() => {
      if (!initialized.current) {
        console.log("*************** useEffect::ListSuppliers called ##########################");
        ( async() => {
        var [retStateLoad, supplierRet] = await CallSupplierData();
        if (retStateLoad){
          var findDefault = supplierRet.find(element => element.is_default === true);
                      if (findDefault !== undefined) {
                        setSupplierIdSelect(findDefault);  
                     
                    }
          console.log(" Supplier :", supplierRet);
          var [retStateLoad, supplierReportRet, summaryRet] = await getSupplierList(findDefault.value); 
        }
      })();
        setToRender(true);
        
        initialized.current = true;
       
      }
    }, []);
  
   
    useEffect(() => {
      if (!initialized.current) {
        console.log(
          "*************** useEffect::ViewSuppliers called ##########################"
        );
        setToRender(true)
        setSupplierIdSelect(optSupplier[0]);
        //getCustomerList();
        initialized.current = true;
       
      }
    }, []);
   /*
    const handleAdd = async (e) => {
      console.log("handle add customers called : ", e);
      navigate("/addUpdateStockIn", {
        state: { passed_element: [], action: "add" },
      });
    };
    */
  
    
    
   
    
    const handleSupplierPrint = async (e) => {
     //navigate("/printCustomer")
      
      var urlStr = window.location.href;
      var paramsTmp = urlStr.substring(
        urlStr.lastIndexOf(client.urlDelimiter) + 1
      );
      var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));
  
      console.log("CALLED URL : ", urlTmp);
      console.log("CALLED PAGE : ", paramsTmp);
  
      var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printSupplierOS?bid=${currSelBusinessRef.current}&brid=${currBranchRef.current}&supplier_id=${supplierIdSelect.value}&date_ason=${formValues["date_ason"]}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
      winPrint.moveTo(0,0);
      winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
      
      
    };
   
  
    const onChangeAsOnDatePicker = (e) => {
      console.log("Date onChange called ...", e);
      console.log("Date onChange DATE called ...", e.target.value);
      setFormValues({ ...formValues, date_ason: e.target.value });
    };
  
  
    const handleSupplierSearch = async () => {
      console.log("handleSupplierSearch called ...");
      await getSupplierList(supplierIdSelect.value);
      //console.log("++++++++++++ START DATE : %s, END DATE : %s >>>>>>>>>>>>>>>>", formValues["start_date"], formValues["end_date"]);
    };
   const handleSupplierSelect = async (e) => {
    console.log("handleSupplierSelect called...");
    setSupplierIdSelect(e);
   };

   const sendDataToParent  = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if ( passedBackValue.hasOwnProperty("business_id") ) {
      currSelBusinessRef.current = passedBackValue["business_id"];
      //navigate(0);
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);
    
    //getBranchList();
  };
   
    return (
      <Container>
        <SidebarMain sendBacktoParent={sendDataToParent} />
        { toRender ?
        <form>
        <Row>
            <Col xs={9} md={9} sm={9} lg={9}>
              <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.25rem", verticalAlign: "bottom" }}>{menuSettings.permissions.find(element => (element.code === 'viewSupplierOS' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'viewSupplierOS')).page_title : "Supplier Outstanding Statment"}</span>
            </Col>
            <Col xs={3} md={3} sm={3} lg={3} className="d-flex justify-content-end float-end">
              <Button id="supplierPrint" name = "supplierPrint"  onClick={(e)  => handleSupplierPrint(e)}
                disabled={menuSettings.permissions.find(element => (element.code === 'viewSupplierOS' && element.a_print === true && supplierReportList.length > 0)) ? false : true}
              >
                  <BsFillPrinterFill
                    size={18}
                    style={{ color: "white", cursor: "pointer" }}
                    name={`print-supplier-icon`}
                    id={`print-supplier-icon`}
                    
                  />
              </Button>
            </Col>
            </Row>
            <hr align="center" />
                
             <Row>
                <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Label>Supplier</Form.Label>
                    <Select
                      inline
                      id="supplier"
                      name="supplier"
                      value={{
                        value: supplierIdSelect.value,
                        label: supplierIdSelect.label,
                      }}
                      options={optSupplier}
                      defaultValue={{
                        value: supplierIdSelect.value,
                        label: supplierIdSelect.label,
                      }}
                      onChange={handleSupplierSelect}
                      //isDisabled={isReadOnly}
                    />
                   
                    </Col>
                <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Label>Outstanding As On</Form.Label>
                  <Form.Control
                    type="date"
                    name="date_ason"
                    placeholder="Date"
                    value={formValues.date_ason}
                    onChange={onChangeAsOnDatePicker}
                  //readOnly={isReadOnly}

                  />
                </Col>
                <Col xs={1} md={1} sm={1} lg={1} style={{paddingTop: "30px"}}>
                    <Button id="search-id"
                      variant="success"
                      onClick={(e) => handleSupplierSearch(e)}>
                      Search
                    </Button>
                </Col>
              </Row>
            <hr align="center" />
            {/*
            <Row>

            <Col xs={12} md={12} sm={12} lg={12} style={{ textAlign: "right" }}>

              <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                Total Supplier :
              </Form.Label>
              <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
                {supplierSummary.total_supp}
              </Form.Label>

              <span style={{ color: '#C0C0C0' }}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
              
              <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
               Total  Opening Balance :
              </Form.Label>
              <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
              {supplierSummary.openbal > 0.01 ?
                  <>
                    {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplierSummary.openbal).toFixed(2)))}
                  </>
                  : (0.00).toFixed(2)}
               
              </Form.Label>
              <span style={{ color: '#C0C0C0' }}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
              <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                Total Invoice OS :
              </Form.Label>
              <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
                {supplierSummary.inv_os > 0.01 ?
                  <>
                    {((new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplierSummary.inv_os).toFixed(2))))}
                  </>
                  : (0.00).toFixed(2)}
              </Form.Label>
              <span style={{ color: '#C0C0C0' }}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
              <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                Total Paid Amount :
              </Form.Label>
              <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
                {supplierSummary.paid_amt > 0.01 ?
                  <>
                    {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplierSummary.paid_amt).toFixed(2)))}
                  </>
                  : (0.00).toFixed(2)}
              </Form.Label>

              <span style={{ color: '#C0C0C0' }}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
              
              <Form.Label style={{ fontWeight: 'bold', color: "#43C70F" }}>
                Total Balance OS :
              </Form.Label>
              <Form.Label style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#43C70F" }}>
                
                  
                    {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplierSummary.balance_os).toFixed(2)))}
                 
              </Form.Label>

            </Col>
          </Row>
          */}

          <tr className="table-primary">
                  <th className="table-row-heading" style={{textAlign: "left", width: "52%"}}>Total Supplier : {supplierSummary.total_supp}</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "0%"}}></th>
                  <th className="table-row-heading" style={{textAlign: "right", width: "12%"}}>{supplierSummary.openbal > 0.01 ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplierSummary.openbal).toFixed(2)): (0.00).toFixed(2)}</th>
                  <th className="table-row-heading" style={{textAlign: "right", width: "12%"}}>{supplierSummary.inv_os > 0.01 ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplierSummary.inv_os).toFixed(2)) : (0.00).toFixed(2)}</th>
                  <th className="table-row-heading" style={{textAlign: "right", width: "12%"}}>{supplierSummary.paid_amt > 0.01 ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplierSummary.paid_amt).toFixed(2)) : (0.00).toFixed(2)}</th>
                  <th className="table-row-heading" style={{textAlign: "right", width: "12%"}}>{supplierSummary.balance_os > 0.01 ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplierSummary.balance_os).toFixed(2)) : (0.00).toFixed(2)}</th>
                  {/*<th className="table-row-heading" style={{textAlign: "center", width: "200px"}}>Details</th>*/}
                </tr>
  
            <Table striped bordered hover>
            <thead>
                <tr className="table-primary">
                  <th className="table-row-heading" style={{textAlign: "center", width: "5%"}}>S.No</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "47%"}}>Supplier</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "12%"}}>Opening Balance</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "12%"}}>Invoice Outstanding</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "12%"}}>Paid Amount</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "12%"}}>Balanace Outstanding</th>
                  {/*<th className="table-row-heading" style={{textAlign: "center", width: "200px"}}>Details</th>*/}
                </tr>
              </thead>
              <tbody>
                {supplierReportList.map((supplier, idx) => (
                  <tr>
                  <td style={{textAlign: "right", width: "5%"}}>{idx + 1}</td>
                  <td style={{textAlign: "left", width: "47%"}}>{supplier.supplier_name}</td>
                  <td style={{textAlign: "right", width: "12%"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplier.openbal).toFixed(2)))}</td>
                  <td style={{textAlign: "right", width: "12%"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplier.inv_os).toFixed(2)))}</td>
                  <td style={{textAlign: "right", width: "12%"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplier.paid_amt).toFixed(2)))}</td>
                  <td style={{textAlign: "right", width: "12%"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(supplier.balance_os).toFixed(2)))}</td>
                  
                  {/*<td style={{textAlign: "center"}}>
                    <BsCardText
                      key={`edit-btn-vehicle-key-${idx}`}
                      size={28}
                      style={{ color: "#0D6EFD", cursor: "pointer" }}
                      onClick={(e) => handleView(e)}
                      name={`edit-icon-vehicle-name-${idx}`}
                      id={`edit-icon-vehicle-id-${idx}`}
                    />
                  </td>
              */}
                </tr>
                ))}
              </tbody>
            </Table>
            </form>
       : 
       <div className="container-spinner">
      <div className="vertical-center-spinner">
       <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
 
       </div>
       </div>
      }      
       </Container>
     
    );
  }
  export default ViewSupplierOS;