
import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  Label,
  Badge,
  Table,
  Spinner,
  Image,
  Modal
} from "react-bootstrap";
import "../Dashboard.css";
import "../App.css";
import { useNavigate } from "react-router-dom";

import Select from "react-select";

import { client, ConfigMinValue, configMaxValue, appGlobal, LS_MSG_TAG } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

//import "react-datetime-picker/dist/DateTimePicker.css";
//import "react-calendar/dist/Calendar.css";
//import "react-clock/dist/Clock.css";

import { BsCartX, BsArrowLeftCircle, BsFillXCircleFill, BsFillPrinterFill, BsPencilSquare } from "react-icons/bs";
import SidebarMain from "./SidebarMain";

import { formatDate, formatTime, getCustomerMessages  } from "./utils";
import spinner_logo from '../assets/images/spinner_logo.png';
import { AsyncTypeahead } from "react-bootstrap-typeahead";
//import moment from "moment/moment";

const AddUpdateSuppPayment = (async) => {

  let initialItems = [];

  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);

  const intialValues = {
    id: 0,
    pymt_amt: 0.00,
    pymt_no: "",
    received_from: "",
    //amount: 0.00,
    ref_no: "",
    towards: "",
    payment_against: "",
    pay_received_by: "",
    pymt_date: formatDate(new Date()),
    ref_date: formatDate(new Date()),
    //received_mode: "CASH",
    supplier_id: 0,
    payment_by_name: "",
    received_type: "",
    notes: "",
    paid_for: "",
    paid_to_name: "",
  };

  /*
  let invArr = [
    { value: 657, label: " INV001 - 12-12-2023 - 2000" },
    { value: 876, label: " INV002 - 10-1-2023 - 3000" },
    { value: 978, label: " INV003 - 05-2-2023  - 5000" },
  ]
  */
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  //const [receivedSaved, setReceivedSaved] = useState(false);
  const [toRender, setToRender] = useState(false);
  const [pendingOrderList, setPendingOrderList] = useState([]);
  const [orderPendingLoaded, setOrderPendingLoaded] = useState(false);
  const [isLoadingSupp, setIsLoadingSupp] = useState(false);
  const [isLoadingInv, setIsLoadingInv] = useState(false);
  const [optionsSearch, setOptionsSearch] = useState([]);
  const [optionsSearchInv, setOptionsSearchInv] = useState([]);
  const [customerSearchText, setCustomerSearchText] = useState("");
  const [invoiceSearchText, setInvoiceSearchText] = useState("");
  const [searchedCustomer, setSearchedCustomer] = useState({});
  const [items, setItems] = useState(initialItems);
  const [selectedDCItemRow, setSelectedDCItemRow] = useState(0);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [submitPrintAction, setSubmitPrintAction] = useState(false);
  const [submitAction, setSubmitAction] = useState(false);

  const [orderSelectedIndex, setOrderSelectedIndex] = useState([])
  const initialized = useRef(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  

  const [optReferenceInvoice, setOptReferenceInvoice] = useState([]);
  const [referenceInvoiceIdSelect, setReferenceInvoiceIdSelect] = useState({ value: 0, label: "Please select" });
  const [receiptInfoDet, setReceiptInfoDet] = useState({});
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState();
  const [alertPurpose, setAlertPurpose] = useState('init');
  const [alertTitle, setAlertTitle] = useState('Success');
  const [alertBody, setAlertBody] = useState('Receipt Saved Successfully');
 
  const initPaymentType = [{value: "Outstanding", label: "Outstanding"}, {value: "Advance", label: "Advance"}]
  const [paymentType, setPaymentType] = useState([]);
  const [selectedPaymentType, setSelectedPaymentType] = useState({});
  const [optSupplier, setOptSupplier] = useState([]);
  const [submitAddAction, setSubmitAddAction] = useState(false);
  const [supplierIdSelect, setSupplierIdSelect] = useState({});
  const currSelBusinessRef = useRef(0);
  const currBranchRef = useRef(0);
  const submitActionRef = useRef("save-only");
  const editLoadRef = useRef(true);

  var validateErr = false;

  /*
  const receivedModeList = [
    { value: "Cheque", label: "CHEQUE" },
    { value: "Cash", label: "CASH" },
    { value: "DD", label: "Demand Draft" },
    { value: "NetBanking", label: "Net Banking" }
  ]
  */
 

  const [optPaymentMode, setOptPaymentMode] = useState([]);
  const [paymentModeIdSelect, setPaymentModeIdSelect] = useState({});
  

  const [optPaid, setOptPaid] = useState([]);
  const [paidIdSelect, setPaidIdSelect] = useState({});
  const [number, setNumber] = useState({});

  /*
  const receivedFromList = [
    { value: "techm", label: "TECH MAHINDRA" },
    { value: "comviva", label: "COMVIVA" },
    { value: "rockbreeze", label: "ROCKBREEZE" },
    { value: "mthsense", label: "Mth Sense" }
  ];
  */

  /*
  const receivedFromList = [
    { value: "1", label: "Raja" },
    { value: "2", label: "Jegan" },
    { value: "3", label: "Arul" },
    { value: "4", label: "Ravi" }
  ];
  */
  const [optPaidBy, setOptPaidBy] = useState([]);
  const [paidByIdSelect, setPaidByIdSelect] = useState({ value: 0, label: "Please select" });

  const getEmployeeList = async () => {
    var retState = false;
    var paidByArr = [];
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
      var res = await axios.get(client.domain + "/paymentReceiveEmployeeList", { headers }, { withCredentials: false });
      console.log("Response from server getEmployeeList : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getEmployeeList : ", res.data.detail.res_data);

        /*
        var custArr = [];
        res.data.detail.res_data.getEmployeeList.map((cust) => {
          var custInd = { value: cust.id, label: cust.name, id: cust.id, name: cust.name};
          custArr.push(custInd);
        })
        */
        paidByArr = res.data.detail.res_data.employee_list;
        /*
        setOptReceivedBy(res.data.detail.res_data.employee_list);
        if (res.data.detail.res_data.employee_list.length > 0) {
          setReceivedByIdSelect(res.data.detail.res_data.employee_list[0]);
        }
        */
        setToRender(true);
        retState = true;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);

        }
      }
    }
    return [retState, paidByArr]
  }

  const getPaymentType = async () => {
    var retState = false;
    var retPaymentType = [];

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
      var res = await axios.get(client.domain + "/getPaymentType", { headers }, { withCredentials: false });
      console.log("Response from server getPaymentType : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getPaymentMode : ", res.data.detail.data);
        //setPaymentType(res.data.detail.data);
        retPaymentType = res.data.detail.data;
      //setPendingOrderList(res.data.detail.data);
        /*
        res.data.detail.data.map((cust) => {
          var paymentInd = { value: cust, label: cust };
          retPaymentType.push(paymentInd);
        })
        */
      
       //paymentModeArr = res.data.detail.data;

        //setOptReceivedFrom(custArr);
        //setReceivedFromIdSelect(custArr[0]);
        //setOptReceivedMode(receivedModeArr);
        //setReceivedModeIdSelect(receivedModeArr[0]);
        setToRender(true);

        retState = true;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);

        }
      }
    }

    return [retState, retPaymentType];

  }

  const getPaymentMode = async () => {
    var paymentModeArr = [];
    var retState = false;
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
      var res = await axios.get(client.domain + "/getPaymentMode", { headers }, { withCredentials: false });
      console.log("Response from server getPaymentMode : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getPaymentMode : ", res.data.detail.data);

        /*
        res.data.detail.data.map((cust) => {
          var modeInd = { value: cust, label: cust };
          paymentModeArr.push(modeInd);
        })
        */
       paymentModeArr = res.data.detail.data;

        //setOptReceivedFrom(custArr);
        //setReceivedFromIdSelect(custArr[0]);
        //setOptReceivedMode(receivedModeArr);
        //setReceivedModeIdSelect(receivedModeArr[0]);
        setToRender(true);

        retState = true;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);

        }
      }
    }
    return [retState, paymentModeArr]
  }

  
  const CallPaymentData = async () => {
    var retStateLoad = false;
    var supplierRet = [];
    var paymentModeRet = [];
    var numberRet = [];
    var paidRet = [];

    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }

      var reqParams = {
        bid: currSelBusinessRef.current
      };

      var res = await axios.post(client.domain + "/bms/getSuppPymtAddUpdPreLoadData", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.status);
        setOptSupplier(res.data.detail.data.Suppliers);
        //setItemTypeIdSelect(res.data.detail.data.item_types[0]);
        setOptPaymentMode(res.data.detail.data.payment_modes);
        console.log("Response message from server TRADE TYPE : ", res.data.detail.data.receipt_modes);
        //setTradeTypeIdSelect(res.data.detail.data.trade_types[0]);
        setNumber(res.data.detail.data.new_rcpt_no);
        setOptPaid(res.data.detail.data.payment_against)
        //setOptUnit(res.data.detail.data.units);
        //setUnitIdSelect(res.data.detail.data.units[0]);

        setToRender(true);

        supplierRet = res.data.detail.data.Suppliers;
        paymentModeRet = res.data.detail.data.payment_modes;
        numberRet = res.data.detail.data.new_rcpt_no;
        paidRet = res.data.detail.data.payment_against;
        retStateLoad = true;

      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
        setShow("Please try after sometime");
        setShow("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          setShow("Please try again ...");
          setShow("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShow(error.response.data.detail);
          setShow("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
    return [retStateLoad, supplierRet, paymentModeRet, numberRet, paidRet];
  };

  const callPaymentDetById = async (paymentIdPassed) => {
    var retState = false;
    var paymentInfoRet = {};
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
      //var apiToCall = "";

      var reqParams = {
        id: paymentIdPassed
      };

      var res = await axios.post(client.domain + "/bms/getSuppPymtDetails", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      console.log("Response from server INSIDE ARRAY : ", res.data.detail.data[0]);
      console.log("+++++++++++++++++++ API RESPONSE STATUS **************************** : ", res.data.detail.status);
      if (res.data.detail.status === "SUCCESS") {
        //setShowMsg(res.data.detail.data.message);
        //setShowMsgTitle("Success");
        //setShowDialog(true);

        //setFormValues(res.data.detail.data[0]);
        ///var stdDate = moment(res.data.detail.data[0].pymt_date, "dd-mm-yyyy");
        console.log("STD DATE FROM APIs : ", res.data.detail.data[0].pymt_date);
        setFormValues({ ...formValues,
          bid: res.data.detail.data[0].bid,
          brid: res.data.detail.data[0].brid,
          supplier_id: res.data.detail.data[0].supplier_id, 
          supplier_name: res.data.detail.data[0].supplier_name,
          notes: res.data.detail.data[0].notes,
          pymt_against_id: res.data.detail.data[0].pymt_against_id,
          pymt_amt: res.data.detail.data[0].pymt_amt,
          pymt_date: formatDate(new Date(res.data.detail.data[0].pymt_date)),
          pymt_mode_id: res.data.detail.data[0].pymt_mode_id,
          pymt_no: res.data.detail.data[0].pymt_no,
          paid_for: res.data.detail.data[0].paid_for,
          ref_date: formatDate(new Date(res.data.detail.data[0].ref_date)), 
          ref_no: res.data.detail.data[0].ref_no
        });
        paymentInfoRet = res.data.detail.data[0];
       
        setSubmitAction(false);
        retState = true;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);

        }
      }
      setSubmitAction(false);
    }
    return [retState, paymentInfoRet];
  };

  
  useEffect(() => {
    if (!initialized.current) {
      if (state !== null) {
        console.log("PASSED VALUE : ", state);
        console.log("FULL WINDOW :", window);
        console.log("FULL WINDOW LOCATION : ", window.location);
        console.log("Referred URL :", window.frames.top.document.referrer);
        //setReceivedModeIdSelect(optReceivedMode[0]);
        //setReceivedByIdSelect(optReceivedBy[0]);
        //setReferenceInvoiceIdSelect(optReferenceInvoice[0]);


        (async () => {
          var [retStateLoad, supplierRet, paymentModeRet, numberRet, paidRet] = await CallPaymentData();
          if ( retStateLoad ) {
          if ((state.action === 'edit' || state.action === 'view') && editLoadRef.current) {
            setIsReadOnly(true);
            setFormValues({ ...formValues, pymt_no: state.passed_element.pymt_no });
            var [retState, paymentInfoRet] = await callPaymentDetById(state.passed_element.id);
            if (retState) {
              console.log("RETURNED PAYMENT INFO : ", paymentInfoRet);              
                //console.log("RETURNED TRADE TYPE : ", tradeTypeRet);
                var findPaymentEdit = supplierRet.find(element => element.value === paymentInfoRet.supplier_id);
                if (findPaymentEdit !== undefined) {
                  setSupplierIdSelect(findPaymentEdit);
                }

                var findModeEdit = paymentModeRet.find(element => element.value === paymentInfoRet.pymt_mode_id);
                if (findModeEdit !== undefined) {
                  setPaymentModeIdSelect(findModeEdit);
                }

                var findPaidEdit = paidRet.find(element => element.value === paymentInfoRet.pymt_against_id);
                if (findPaidEdit !== undefined) {
                  setPaidIdSelect(findPaidEdit);
                }
              }
            }
            else {
              setFormValues({ ...formValues, pymt_no: numberRet.new_rcpt_no });
            setSupplierIdSelect(supplierRet[0]);
            setNumber(numberRet[0]);
           
            var findPaymentMode = paymentModeRet.find(element => element.is_default === true);
            if (findPaymentMode !== undefined) {
              setPaymentModeIdSelect(findPaymentMode);  
            }

            var findPaid = paidRet.find(element => element.is_default === true);
            if (findPaid !== undefined) {
              setPaidIdSelect(findPaid);  
            }
            }
        }
            
        })();

      }
      setToRender(true)
      initialized.current = true;
    }
  }, []);


  

  const handlePaymentDateChange = async (e) => {
    console.log("handleReceiptDateChange called ...", e);
    console.log("handleReceiptDateChange DATE called ...", e.target.value);

    setFormValues({ ...formValues, payment_date: e.target.value });
  };

  const handleChequeDateChange = async (e) => {
    console.log("handleChequeDateChange called ...", e);
    console.log("handleChequeDateChange DATE called ...", e.target.value);
    setFormValues({ ...formValues, ref_date: e.target.value });
  };

  /*
  const onChangeReceivedTime = (e) => {
    console.log("Date onChange Loading time called ...", e);
    console.log("Date onChange Loading time TIME called ...", e.target.value);
    
    setFormValues({ ...formValues, ["receipt_time"]: e.target.value });
  };
  */

  const handleBack = async (e) => {
    console.log("handle back called : ", e);
    navigate(-1);
  };

  const handlePaymentMode = (e) => {
    console.log("Handle Payment Mode Select Called : ", e);
    setPaymentModeIdSelect({ value: e.value, label: e.label });
  }

  const handleReferenceInvoice = (e) => {
    console.log("Handle Reference Invoice Select Called : ", e);
    setReferenceInvoiceIdSelect({ value: e.value, label: e.label });
  }

  

  const handleChange = (e, data) => {
    console.log("Handle Change clicked : ", e);
    console.log("Handle Change clicked target : ", e.currentTarget);
    //console.log("HTML tag : ", data);
    const { name, value } = e.currentTarget;
    console.log("Captured Quantity name : ", name);
    console.log("Captured Quantity value : ", value);

    if (name === "pymt_amt") {
      console.log("amount item edited ...");
      if (e.currentTarget.value.match(appGlobal.numericRegExp)) {
        //setFormValues({ ...formValues, [name]: e.currentTarget.value.replace(/^0+/, '') });
        setFormValues({ ...formValues, [name]: value });
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  }

  const handleClear = async (e) => {
    e.preventDefault();
    console.log("handle clear called : ", e);
    console.log("Handle clear clicked current target ID: ", e.currentTarget.id);
    navigate(0);
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);

    /*
    if (!values.paid_to_name) {
      errors.paid_to_name = "Supplier Name is required!";
      validateErr = true;

    }
    */
  
    if (paymentModeIdSelect.label=== 'Cheque') {
      if (!values.ref_no) {
        errors.ref_no = "Reference Number is required!";
        validateErr = true;
      }
    }
    
    if (!values.pymt_amt) {
      errors.pymt_amt = " Amount is required!";
      validateErr = true;

    }
  /*
   if (!values.pay_received_by) {
    errors.pay_received_by = "Name Of Receiving Person required!"
    validateErr = true;
   }
   */

    console.log("BEFORE RETURN validate errors ...", validateErr);
    return errors;
  };
  /*
  const callApiToSubmitPayment = async (settledOrderIds, actionVal) => {
    console.log("ACTON VALUE : ", actionVal);
    console.log("PAYMENT MODE SELECT : ", paymentModeIdSelect.value);
    console.log("RECEIVED MODE SELECT : ", paidByIdSelect.value);
    var payment_id_tmp = 0;
    
    if ( state.action === 'view' || state.action === 'edit' ) {
      payment_id_tmp = formValues["id"];
    }
    
    var reqPayment = {
      "id": payment_id_tmp,
      "payment_number": formValues.payment_number || "",
      "payment_date": formValues.payment_date,
      "payment_mode": paymentModeIdSelect.value,
      "paid_to": formValues.paid_to,      
      "pay_received_by": formValues.pay_received_by,
      "payment_by": paidByIdSelect.value,
      "received_from": formValues.customer_id,
      "amount": formValues.amount,
      "towards": formValues.towards,
      "addl_notes": formValues.addl_notes,
      "ref_number": formValues.ref_number,
      "ref_date": formValues.ref_date,
      //"payment_by": formValues.payment_by || "",
      "payment_number": formValues.payment_number,
      "payment_type": selectedPaymentType.value
    };

    console.log("Receipt settle Request payload : ", reqPayment);

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
      
      if ( actionVal === 'save-print') {
        setSubmitPrintAction(true);
      }
      else {
        setSubmitAction(true);
      }

      var res = await axios.post(client.domain + "/insertPaymentWOAdjust", reqPayment, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.data);

        if ( actionVal === 'save-print') {
          setSubmitPrintAction(false);
        }
        else {
          setSubmitAction(false);
        }
        setAlertPurpose("success");
        setAlertTitle("Success");
        
        if(state.action === "add"){
          setAlertBody("Supplier Payment Saved Successfully!");
        }
        else{
          setAlertBody("Supplier Payment Updated Successfully!");
        }
       
          if ( actionVal === 'save-print' ) {
            navigate(-1);
            //var receipt_id_tmp = res.data.detail.receipt_id;
            //console.log("RETURNED RECEIPT ID AFTER SUBMIT : ", receipt_id_tmp);

            var urlStr = window.location.href;
            var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
            var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

            console.log("CALLED URL : ", urlTmp);
            console.log("CALLED PAGE : ", paramsTmp);

            var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printSuppPayment?payment_id=${formValues["id"]}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
            winPrint.moveTo(0, 0);
            winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
          }
          else {
            setShowAlert(true);
          }  
      }
      else {
        console.log("FAILED PAYMENT INSERTION ...");
        setAlertPurpose("failure");
        setSubmitAction(false);
        setAlertTitle("Failure");
        if ( state.action === 'add') {
          setAlertBody("Supplier Payment Save Failed!");
        }
        else {
          setAlertBody("Supplier Payment Update Failed!");
        }
        
        setShowAlert(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);

      if (error.code === 'ERR_NETWORK') {
        console.log("Not able to reach target server please try after sometime");
        //setShowMsg('Please try after sometime');
        //setShowMsgTitle('Network Issue!');
        //setShow(true);
      }
      else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === 'FAILED') {
          console.log('Request failed due to ', error.response.data.detail.res_data);
          //setShowMsg('Please Re-Login');
          //setShowMsgTitle('Invalid Session!');
          //setShow(true);
          //navigate("/auth");
        }
      }
      setAlertPurpose("failure");
      setSubmitAction(false);
      setAlertTitle("Warning");
      setAlertBody("Unable to process your request at this moment.Please try after sometime!");
      setShowAlert(true);
    }
  };
  */

  const handleSubmit = async (event, actionVal) => {
    event.preventDefault();
    console.log("<<<<<<<<<<<<<< handleSubmit called ...", actionVal);
    validateErr = false;
    setFormErrors(validate(formValues));
    console.log("FORM VALUES at handleSubmit: ", formValues);
      validateErr = false;
      setFormErrors(validate(formValues));
      console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ", validateErr);
      if (validateErr) {
        return -1;
      }

    var reqParam = {
      bid: currSelBusinessRef.current,
      brid: currBranchRef.current,
      pymt_date: formValues["pymt_date"] || formatDate(new Date()),
      supplier_id: supplierIdSelect.value,
      pymt_amt: formValues["pymt_amt"],
      pymt_mode_id: paymentModeIdSelect.value,
      ref_no: formValues["ref_no"] || "",
      ref_date: formValues["ref_date"] || formatDate(new Date()),
      pymt_against_id: paidIdSelect.value,
      paid_for: formValues["paid_for"] || "",
      notes: formValues["notes"] || "",
    };

    if (state.action === "edit" || state.action === "view") {
      reqParam.id = state.passed_element.id;
      //reqParam.is_active = checkState;
    }
     try {
       var aIVal = localStorage.getItem("_aT");
       var headers = {...client.headers ,Authorization: `Bearer ${aIVal}`}
 
       if ( actionVal === 'save-print') {
         setSubmitPrintAction(true);
       }
       else if ( actionVal === 'save-add') {
        setSubmitAddAction(true);
      }
       else {
         setSubmitAction(true);
       }
       var apiToCall = "/bms/addSupplierPayment";
      if (state.action === "view" || state.action === 'edit') {
        apiToCall = "/bms/updateSupplierPayment";
      }
      var res = await axios.post(client.domain + apiToCall, reqParam, { headers }, { withCredentials: false });
       console.log("Response from server : ", res.data.detail);
       if (res.data.detail.status === "SUCCESS") {
         console.log("Response message from server : ", res.data.detail.res_data);
         if ( actionVal === 'save-print' ) {
           setSubmitPrintAction(false);
           submitActionRef.current = "save-print";
         }
         else if ( actionVal === 'save-add') {
          setSubmitAddAction(false);
          submitActionRef.current = "save-add";
        }
         else {
           setSubmitAction(false);
           submitActionRef.current = "save-only";
         }
         setAlertTitle("Success");
         setAlertPurpose('success');
         if(state.action === "add"){ 
           setAlertBody(getCustomerMessages(LS_MSG_TAG, "MSG_SUPPLIER_PAYMENT_SAVED"));
         } 
        else {
         setAlertBody(getCustomerMessages(LS_MSG_TAG, "MSG_SUPPLIER_PAYMENT_UPDATED"));
         }
         //setShowAlert(true);
 
         if ( actionVal === 'save-print' ) {
           //navigate('/listSuppPayments');
 
           var urlStr = window.location.href;
           var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
           var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));
       
           console.log("CALLED URL : ", urlTmp);
           console.log("CALLED PAGE : ", paramsTmp);
           //var idPass = state.passed_element.id;
           //window.open(`${urlTmp}${client.urlDelimiter}/printFullOrder?order_id=${passedValue.order_id}`);
           var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printSuppPayment?id=${res.data.detail.data.id}`, "width=" + window.screen.width + ",height=" + window.screen.height);
           winPrint.moveTo(0,0);
           winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
         }
         else if ( actionVal === 'save-add') {
          setShowAlert(true);
          //navigate(0);
        }
         else {
           setShowAlert(true);
         }  
       }
       else {
         setSubmitAction(false);
         setAlertPurpose('failed');  
         setAlertTitle("Failed");
         if(state.action === 'add'){    
           setAlertBody("Supplier Payment Save Failed!");
         }
         else{
           setAlertBody("Supplier Payment Update Failed!");
         }
        
         setShowAlert(true);
       }
     } catch (error) {
       console.log("FULL ERROR : ", error);
       setAlertPurpose("failure");
       setAlertTitle("Warning");
       setAlertBody("Unable to process your request at this moment.Please try after sometime!");
       setShowAlert(true);
       
      
       setSubmitAction(false);
     }

  };
  
/*
  const handleSubmit = async (event, actionVal) => {
    event.preventDefault();
    console.log("<<<<<<<<<<<<<< handleSubmit called ...", actionVal);
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ", validateErr);

    var settledOrderIds = [];
    
    if ( !validateErr ) {
      await callApiToSubmitPayment(settledOrderIds, actionVal);
    }
    //setReceivedSaved(true);
  };*/

  const handlePrintPayment = async () => {
    console.log("Handle print receipt called ...");
    //navigate("/printCustReceipt")

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    
    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printSuppPayment?id=${state.passed_element.id}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0, 0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
  }

  const handleCheckBoxOnChange = async (e, data) => {
    console.log("handleCheckBoxOnChange called event : ", e);
    console.log("handleCheckBoxOnChange called currentTarget : ", e.currentTarget);
    console.log("handleCheckBoxOnChange called currentTarget CHECKED STATE : ", e.currentTarget.checked);

    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelRowId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(0, clickedTagId.lastIndexOf("-"));

      console.log("current selected element name view : ", nameOfElement);
      console.log("Current SELECTED row id : ", curSelRowId);

      var selectedPendingOrder = pendingOrderList[curSelRowId];
      console.log("SELECTED PENDING ORDER LIST : ", selectedPendingOrder);

      if (e.currentTarget.checked === true) {
        selectedPendingOrder.selected = true;
      }
      else {
        selectedPendingOrder.selected = false;
      }

      var pendingOrderListNew = pendingOrderList.slice();
      pendingOrderListNew[curSelRowId] = selectedPendingOrder;
      setPendingOrderList(pendingOrderListNew);
    }
  };
  /*

  const getTotVolPerCustPerProd = async (customerId, productId, locationName, dc_date) => {
    console.log("SELECTED CUSTOMER ID : ", customerId);
    console.log("SELECTED PRODUCT ID : ", productId);

    var retState = false;
    var prodRes = {};
    var total_quantity_ret = 0.00;
    console.log("getTotVolPerCustPerProd called ... ");

    var userReq = {
      params: {
        customerId: customerId,
        productId: productId,
        location: locationName,
        dc_date: dc_date
      }
    };

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`, SerialKey: 'HNGD8743JESWE' };
      var res = await axios.get(client.domain + "/getTotVolPerCustPerProd", userReq, { headers }, { withCredentials: false });
      console.log("Response from server getProductForLoad : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getTotVolPerCustPerProd : ", res.data.detail.res_data);
        //setShowConfigDialog(true);

        retState = true;
        total_quantity_ret = res.data.detail.res_data.total_quantity;

        var itemSelected = items[selectedDCItemRow];
        //itemSelected.total_quantity = total_quantity_ret + itemSelected.quantity;
        itemSelected.existing_quantity = parseFloat(total_quantity_ret);
        itemSelected.total_quantity = itemSelected.existing_quantity + parseFloat(itemSelected.quantity);
        var itemsNew = items.slice();
        itemsNew[selectedDCItemRow] = itemSelected;
        setItems(itemsNew);


        //console.log("PRODUCT LOAD STATUS SET TO TRUE")
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );

        }
      }
    }
    return [retState, total_quantity_ret];
  }
  */


  const supplierSearchSelectFunc = async (selected) => {
    console.log("**************** User selected value from search result +++++++++++++ : ", selected);
    formValues["paid_to"] = selected[0].name;
    if (selected.length > 0) {
      setSearchedCustomer(selected[0]);

      const response = await fetch(`${client.domain}/srm/getSupplier?id=${selected[0].value}`);
      const supplier_info = await response.json();
      console.log("SUPPLIER INFO : ", supplier_info.detail.data);
      //setIsLoading(false);

      //setFormValues({...formValues, [name]: value});
      //let entries = Object.entries(company_info.detail.res_data.company_info)
      //entries.map( ([key, val] = entry) => {
      //entries.map( ([key, val] = entry) => {
      for (const [key, value] of Object.entries(supplier_info.detail.data)) {
        //console.log("KEY : ", key);
        //console.log("VAL : ", val);
        console.log("KEY : %s, VAL: %s", key, value);

        //setFormValues({...formValues, [key]: value});
      }
      //setFormValues({ ...formValues, id: supplier_info.detail.data._info.customer_id, customer_name: customer_info.detail.res_data.customer_info.customer_name });
      setFormValues({ ...formValues, paid_to: supplier_info.detail.data.id})
      //var [retState, invoice_list] = await searchHandleInvoiceFunc(customer_info.detail.res_data.customer_info.customer_id);
      //console.log("++++++++++++++++++++++++++Before assign^^^^^^^^^^^^^^^^^^^^^^^^^^^")
      //setOptReferenceInvoice(invoice_list);
      //console.log("++++++++++++++++after+++++++++++++++")
      /*
      //setFormValues(customer_info.detail.res_data.customer_info);
      formValues["customer_id"] = customer_info.detail.res_data.customer_info.customer_id;
      formValues["customer_name"] = customer_info.detail.res_data.customer_info.customer_name;
      formValues["customer_disp_name"] = customer_info.detail.res_data.customer_info.customer_disp_name;
      formValues["contact_number"] = customer_info.detail.res_data.customer_info.contact_number;
      formValues["contact_person"] = customer_info.detail.res_data.customer_info.contact_person;
      formValues["address1"] = customer_info.detail.res_data.customer_info.address1;
      formValues["address2"] = customer_info.detail.res_data.customer_info.address2;
      formValues["dist_id"] = customer_info.detail.res_data.customer_info.district_id;
      formValues["district_name"] = customer_info.detail.res_data.customer_info.district_name
      formValues["state_id"] = customer_info.detail.res_data.customer_info.state_id;
      formValues["state_name"] = customer_info.detail.res_data.customer_info.state_name;
      formValues["pincode"] = customer_info.detail.res_data.customer_info.pincode;
      
      if ( customer_info.detail.res_data.customer_info.address1 ) {
        var dc_date_time = formValues["dc_date"] + " " + formValues["loading_start_time"];
        getTotVolPerCustPerProd(formValues["customer_id"], customer_info.detail.res_data.customer_info.address1, dc_date_time);
      }
      console.log("FORMVALUES 2 : ", formValues);
      */
    }
  };

  const invoiceSearchSelectFunc = async (selected) => {
    console.log("**************** User selected value from search result +++++++++++++ : ", selected);
    if (selected.length > 0) {
      setSearchedCustomer(selected[0]);

      const response = await fetch(`${client.domain}/getCustomerInfo?customer_id=${selected[0].value}`);
      const customer_info = await response.json();
      console.log("CUSTOMER INFO : ", customer_info.detail.res_data.customer_info);
      //setIsLoading(false);

      //setFormValues({...formValues, [name]: value});
      //let entries = Object.entries(company_info.detail.res_data.company_info)
      //entries.map( ([key, val] = entry) => {
      //entries.map( ([key, val] = entry) => {
      for (const [key, value] of Object.entries(customer_info.detail.res_data.customer_info)) {
        //console.log("KEY : ", key);
        //console.log("VAL : ", val);
        console.log("KEY : %s, VAL: %s", key, value);

        //setFormValues({...formValues, [key]: value});
      }

      setFormValues({ ...formValues, ref_invoice_number: customer_info.detail.res_data.customer_info.customer_id, ref_invoice_value: customer_info.detail.res_data.customer_info.customer_name });
      /*
      //setFormValues(customer_info.detail.res_data.customer_info);
      formValues["customer_id"] = customer_info.detail.res_data.customer_info.customer_id;
      formValues["customer_name"] = customer_info.detail.res_data.customer_info.customer_name;
      formValues["customer_disp_name"] = customer_info.detail.res_data.customer_info.customer_disp_name;
      formValues["contact_number"] = customer_info.detail.res_data.customer_info.contact_number;
      formValues["contact_person"] = customer_info.detail.res_data.customer_info.contact_person;
      formValues["address1"] = customer_info.detail.res_data.customer_info.address1;
      formValues["address2"] = customer_info.detail.res_data.customer_info.address2;
      formValues["dist_id"] = customer_info.detail.res_data.customer_info.district_id;
      formValues["district_name"] = customer_info.detail.res_data.customer_info.district_name
      formValues["state_id"] = customer_info.detail.res_data.customer_info.state_id;
      formValues["state_name"] = customer_info.detail.res_data.customer_info.state_name;
      formValues["pincode"] = customer_info.detail.res_data.customer_info.pincode;
      
      if ( customer_info.detail.res_data.customer_info.address1 ) {
        var dc_date_time = formValues["dc_date"] + " " + formValues["loading_start_time"];
        getTotVolPerCustPerProd(formValues["customer_id"], customer_info.detail.res_data.customer_info.address1, dc_date_time);
      }
      console.log("FORMVALUES 2 : ", formValues);
      */
    }
  };

  const handlePostSaveOk = async (e) => {
    setShowAlert(false);
    console.log("+++++++++++++++++++++++++ handlePostSaveOk clicked************************** ", submitActionRef.current);
    if(submitActionRef.current === 'save-add'){
      console.log("SaveOk action called for dialog confirm **************************");
      navigate(0);
      //navigate("/listSuppPayments");
      //navigate(`/addUpdateSuppPayment`, {state: { passed_element: [], action: "add" }});
      editLoadRef.current = false;
    }
   else if( submitActionRef.current === 'save-print'){
      console.log("SavePrint action called for dialog confirm **************************");
      navigate('/printSuppPayment');
    }
    else{
    console.log("Action called for dialog confirm **************************");
    navigate("/listSuppPayments");
    }
  };

  const handleCloseDialog = async () => {
    setShowAlert(false);
    console.log("handleCloseDialog clicked ...");

    if ( alertPurpose === 'success') {
      navigate(-1);
      setAlertPurpose('init');
    }
    else  {
      setAlertPurpose('init');
    }
    /*
    if (dialogResStatus === 'success') {
      navigate(-1);
    }
    */
  };
  const searchHandleFunc = async (query) => {
    setIsLoadingSupp(true);

    fetch(`${client.domain}/search/suppliers?q=${query}`)
      .then((resp) => resp.json())
      .then((json) => {
        //searchActionFunc(json)
        setOptionsSearch(json.detail.res_data.supplier_list);
        setIsLoadingSupp(false);
        console.log("SEARCHED VALUE inside searchHandleFunc : ", json.detail.res_data.supplier_list);
      });
    //formValues["customer_name"] = query;
    //setFormValues({ ...formValues, ["customer_disp_name"]: query });
    /*
    if ( addrCopyCheck && !formValues.contact_person) {
      setFormValues({ ...formValues, ["shipContactName"]: query });
    }
    */
    //setFormValues({ ...formValues, ["customer_name"]: query });
    setCustomerSearchText(query);
  };

  /*
  const searchHandleInvoiceFunc = async (customer_id_pass) => {
    var retState = false;
    var invoice_list_ret = [];
    console.log("searchHandleInvoiceFunc called ...");
    setIsLoadingInv(true);
    fetch(`${client.domain}/search/invoice?customer_id=${customer_id_pass}`)
      .then((resp) => resp.json())
      .then((json) => {
        //searchActionFunc(json)
        if (json.detail.res_data.hasOwnProperty("invoice_list")) {
          console.log("Invoice List exist ...");
          console.log("SEARCHED VALUE inside searchHandleInvoiceFunc : ", json.detail.res_data.invoice_list);
          invoice_list_ret = json.detail.res_data.invoice_list;
          setOptReferenceInvoice(invoice_list_ret);
          var findInvoice = json.detail.res_data.invoice_list.find(element => element.value === state.passed_element.invoice_id);
            if (findInvoice !== undefined) {
              console.log("++++++++++++++++++------------------- FOUND INVOICE INFO ++++++++++++ : ", findInvoice);
              setReferenceInvoiceIdSelect({ value: state.passed_element.invoice_id, label: findInvoice.label });
            }
          //setOptionsSearchInv(json.detail.res_data.invoice_list);
          retState = true;
        }
        else {
          //setOptionsSearchInv([]);
        }
        //let testArr = [{ value:657, label: " INV001 - 12-12-2023 - 2000" }, { value:876, label: " INV002 - 10-1-2023 - 3000" },{ value:978, label: " INV003 - 05-2-2023  - 5000" }]
        //setOptionsSearchInv(testArr);
        setIsLoadingInv(false);

      });
    //formValues["customer_name"] = query;
    //setFormValues({ ...formValues, ["adderss1"]: query });SEARCHED VALUE inside searchHandleInvoiceFunc
    / *
    if ( addrCopyCheck && !formValues.contact_person) {
      setFormValues({ ...formValues, ["shipContactName"]: query });
    }
    * /
    //setFormValues({ ...formValues, ["customer_name"]: query });
    setInvoiceSearchText(customerSearchText);

    return [retState, invoice_list_ret];
  };
  */

  const handleEdit = async (e) => {
    console.log("handle edit  clicked : ", e);
    setIsReadOnly(false);
  };

  console.log("FORMVALUES FULL : ", formValues);
  console.log("INVOICE OPTIONS : ", optionsSearchInv);

  const handleRadioChange = async (e) => {
    console.log("Value button clicked : ", e);

    const { name, id } = e.currentTarget;
    console.log("Captured value name : ", name);
    console.log("Captured value id : ", id);

    var currentRowName = id.substring(id.lastIndexOf("-") + 1);
    var nameOfElement = id.substring(0, id.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current row id : ", currentRowName);
    setFormValues({ ...formValues, ["received_type"]: currentRowName.toUpperCase() });



  };

  const handlePaidRadioChange = async (e) => {
    console.log("Value button clicked : ", e);

    const { name, id } = e.currentTarget;
    console.log("Captured value name at handleReceivedRadioChange : ", name);
    console.log("Captured value id at handleReceivedRadioChange : ", id);

    var currentRowName = id.substring(id.lastIndexOf("-") + 1);
    var nameOfElement = id.substring(0, id.lastIndexOf("-"));
    console.log("Name of element at handlePaidRadioChange : ", nameOfElement);
    console.log("Current row id at handlePaidRadioChange : ", currentRowName);
    //setFormValues({ ...formValues, ["received_type"]: currentRowName.toUpperCase() });
    setPaidIdSelect({ value: parseInt(currentRowName), label: e.currentTarget.label })


  };

  const handleSupplierSelect = async (e) => {
    console.log("Handle supplier select called : ", e);
    setSupplierIdSelect(e);
};


  console.log("************** RECEIVED AMOUNT &&&&&&&&&&&&&&&&& : ", formValues.amount);

  const sendDataToParent  = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if ( passedBackValue.hasOwnProperty("business_id") ) {
      currSelBusinessRef.current = passedBackValue["business_id"];
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);
    
  }

  return (
    <Container>
      <SidebarMain sendBacktoParent={sendDataToParent}/>
      {toRender ?
        (
          <Form>
            <Row>
              <Col xs={4} md={4} sm={4} lg={4}>
              
              <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>{menuSettings.permissions.find(element => (element.code === 'listSuppPayments' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'listSuppPayments')).page_title_addupd : "Bill Payment"}</span> 
                {/* 
                {(state.action === "view" || state.action === "edit") && (
                    <>
                      &nbsp;&nbsp;
                      <Badge pill bg="success">
                        <h6>{state.passed_element.payment_number}</h6>
                      </Badge>
                    </>
                  )}
                */}
              </Col>
              <Col
                xs={8}
                md={8}
                sm={8}
                lg={8}
                className="d-flex justify-content-end float-end"
              >
                <Button variant="warning" className="btn btn-warning btn-md">
                  <BsArrowLeftCircle
                    key={`back-btn-payment-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleBack(e)}
                    name={`back-icon-payment-name`}
                    id={`back-icon-payment-id`}
                  />
                </Button>
                &nbsp;&nbsp;
                {(state.action === "edit" || state.action === "view") &&
                  <Button id="paymentPrint" name="paymentPrint" onClick={(e) => handlePrintPayment(e)}
                  disabled={menuSettings.permissions.find(element => (element.code === 'listSuppPayments' && element.a_print === true)) ? false : true}
                  >
                    <BsFillPrinterFill
                      size={18}
                      style={{ color: "white", cursor: "pointer" }}
                      name={`print-payment-icon`}
                      id={`print-payment-icon`}
                    />
                  </Button>
                }
                &nbsp;

                {(state.action === "edit" || state.action === "view") && (
                  <>
                    &nbsp;&nbsp;
                    <Button variant="primary" className="btn btn-primary btn-md" onClick={(e) => handleEdit(e)}
                    disabled={menuSettings.permissions.find(element => (element.code === 'listSuppPayments' && element.a_edit === true)) ? false : true}
                    >
                      <BsPencilSquare
                        key={`edit-btn-payment-key`}
                        size={24}
                        style={{ cursor: "pointer" }}

                        name={`edit-icon-paBiSolidEditAlttient-name`}
                        id={`edit-icon-payment-id`}
                      />
                    </Button>
                  </>
                )}
              </Col>
            </Row>

            <p></p>
            <hr align="center" />

            <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Payment No</Form.Label>
                  <Form.Control
                    type="text"
                    name="pymt_no"
                    id="pymt_no"
                    placeholder="Payment No"
                    value={formValues.pymt_no}
                    onChange={handleChange}
                    //disabled="true"
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                    //maxLength={12}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.pymt_no}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Payment Date</Form.Label>
                  <Form.Control
                    type="date"
                    id="pymt_date"
                    name="pymt_date"
                    placeholder="Payment Date"
                    value={formValues.pymt_date}
                    onChange={handlePaymentDateChange}
                    readOnly={isReadOnly}
                  />
                </Form.Group>
              </Col>
              {/*
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group
                  className="mb-3"

                >
                  <Form.Label className="field">Supplier</Form.Label>
                  <AsyncTypeahead
                    id="supplier-search-fd-id"
                    name="supplier_name_search"
                    isLoading={isLoadingSupp}
                    onSearch={(query) => {
                      searchHandleFunc(query);
                    }}
                    onChange={(selected) => {
                      //supplierSearchSelectFunc(selected);
                      console.log("<<<<<<<<<<<<<<< SELECTED SUPPLIER : ", selected);
                      setFormValues({ ...formValues, paid_to: selected[0].value, paid_to_name: selected[0].label});
                    }}
                    options={optionsSearch}
                    placeholder="Search Supplier ..."
                    readOnly={(state === null || state.action === 'add') ? isReadOnly : true}
                    style={{
                      borderColor: "gray"
                    }}
                    className="rounded-sm" />
                </Form.Group>
              </Col>
                  */}
                       <Col xs={3} md={3} sm={3} lg={3}>
                          <Form.Label>Supplier</Form.Label>  
                            <Select
                                inline
                                id="supplier"
                                name="supplier"
                                value={{
                                    value: supplierIdSelect.value,
                                    label: supplierIdSelect.label,
                                }}
                                options={optSupplier}
                                defaultValue={{
                                    value: supplierIdSelect.value,
                                    label: supplierIdSelect.label,
                                }}
                                onChange={handleSupplierSelect}
                            />
                          </Col>
                          {/*
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Supplier Name </Form.Label>
                  <Form.Control
                    type="text"
                    name="supplier_name"
                    id="supplier_name"
                    placeholder="Supplier Name"
                    value={formValues.supplier_name}
                    onChange={handleChange}
                    disabled="true"
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                    //maxLength={12}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.supplier_name}</p>
              </Col>
                  */}

              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Amount</Form.Label>
                  <Form.Control
                    type="text"
                    name="pymt_amt"
                    id="pymt_amt"
                    placeholder="Amount"
                    value={formValues.pymt_amt}
                    //maxLength={10}
                    onChange={handleChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",textAlign: "right"
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.pymt_amt}</p>
              </Col>
              {/*
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Name of Receiving Person </Form.Label>
                  <Form.Control
                    type="text"
                    name="pay_received_by"
                    id="pay_received_by"
                    placeholder="Payment Receiving Person"
                    value={formValues.pay_received_by}
                    onChange={handleChange}
                    //disabled="true"
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                    //maxLength={12}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.pay_received_by}</p>
              </Col>
              */} 
            </Row>
            <Row>
              <Col xs={3} sm={3} md={3} lg={3}>

                <Form.Group className="mb-3">
                  <Form.Label >Pay Mode</Form.Label>
                  <Select

                    id="payment_mode"
                    name="payment_mode"
                    value={{
                      value: paymentModeIdSelect.value,
                      label: paymentModeIdSelect.label,
                    }}
                    options={optPaymentMode}
                    defaultValue={{
                      value: paymentModeIdSelect.value,
                      label: paymentModeIdSelect.label,
                    }}
                    onChange={handlePaymentMode}
                    isDisabled={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.payment_mode}</p>

              </Col>

              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className={paymentModeIdSelect.label === 'Cheque' ? 'field required' : 'field mb-3'}>Cheque / Reference No </Form.Label>
                  <Form.Control
                    type="text"
                    name="ref_no"
                    id="ref_no"
                    placeholder="Cheque / Reference No"
                    value={formValues.ref_no}
                    onChange={handleChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.ref_no}</p>
              </Col>


              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Cheque / Reference Date</Form.Label>
                  <Form.Control
                    type="date"
                    id="ref_date"
                    name="ref_date"
                    placeholder="Cheque / Reference Date"
                    value={formValues.ref_date}
                    onChange={handleChequeDateChange}
                    readOnly={isReadOnly}
                  />
                </Form.Group>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                {/*<div key={`inline-radio`} className="mb-3" style={{ paddingTop: '35px' }}>*/}
                <Form.Label>Paid As</Form.Label>
                <Form.Group>

                  {optPaid.map((paidInd, idx) => (
                    <>
                      <Form.Check
                        disabled={isReadOnly}
                        inline
                        label={paidInd.label}
                        name="payment_against"
                        type={"radio"}
                        id={`inline-radio-${paidInd.value}`}
                        onChange={(e) => handlePaidRadioChange(e)}
                        //checked={formValues["payment_type"] === paymentInd.value ? true : false}
                        checked={paidIdSelect.value === paidInd.value ? true : false}
                      />
                    </>
                  ))}
                </Form.Group>
                {/*</div>*/}
              </Col>
              {/*
              <Col xs={3} md={3} sm={3} lg={3}>
                <div key={`inline-radio`} className="mb-3" style={{ paddingTop: '35px' }}>
                  <Form.Group>
                    <Form.Label>Paid As &nbsp;&nbsp;</Form.Label>
                    <Form.Check
                      disabled={isReadOnly}
                      inline
                      label="Outstanding"
                      name="received_type"
                      type={"radio"}
                      id={`inline-radio-outstanding`}
                      onChange={(e) => handleRadioChange(e)}
                      checked={formValues["received_type"] === 'OUTSTANDING' ? true : false}
                    />
                    <Form.Check
                      disabled={isReadOnly}
                      inline
                      label="Advance"
                      name="received_type"
                      type={"radio"}
                      id={`inline-radio-advance`}
                      onChange={(e) => handleRadioChange(e)}
                      checked={formValues["received_type"] === 'ADVANCE' ? true : false}
                    />
                  </Form.Group>
                </div>
                </Col>*/}

            </Row>
            {/*
            <Row>

            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Paid By</Form.Label>
                  <Select

                    id="pay_received_by"
                    name="pay_received_by"
                    value={{
                      value: paidByIdSelect.value,
                      label: paidByIdSelect.label,
                    }}
                    options={optPaidBy}
                    defaultValue={{
                      value: paidByIdSelect.value,
                      label: paidByIdSelect.label,
                    }}
                    onChange={handlePaidBy}
                    isDisabled={isReadOnly}
                  />
                </Form.Group>
              </Col>
              
              
              <Col xs={3} md={3} sm={3} lg={3}>
                <div key={`inline-radio`} className="mb-3" style={{ paddingTop: '35px' }}>
                  <Form.Group>
                  <Form.Label>Paid As &nbsp;&nbsp;</Form.Label>
                  {paymentType.map((paymentInd, idx) => (
                    <>                  
                    <Form.Check
                      disabled={isReadOnly}
                      inline
                      label={paymentInd.label}
                      name="payment_type"
                      type={"radio"}
                      id={`inline-radio-${paymentInd.value}`}
                      onChange={(e) => handleRadioChange(e)}
                      //checked={formValues["payment_type"] === paymentInd.value ? true : false}
                      checked={selectedPaymentType.value === paymentInd.value ? true : false}
                    />
                    </>
                    ))}
                  </Form.Group>
                </div>
              </Col>
                  
            </Row>
             */}

            <Row>
              <Col xs={6} md={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Paid For</Form.Label>
                  <Form.Control
                    as="textarea" rows={3}
                    name="paid_for"
                    id="paid_for"
                    placeholder="Purpose of Payment"
                    value={formValues.paid_for}
                    onChange={handleChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
              </Col>

              <Col xs={6} md={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    as="textarea" rows={3}
                    name="notes"
                    id="notes"
                    placeholder="Notes"
                    value={formValues.notes}
                    onChange={handleChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
              </Col>

            </Row>
            {!isReadOnly && (
            <Row>
              <Col xs={12} md={12} sm={12}>
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn btn-secondary"
                    id="btn-clear-receipt-id"
                    name="btn-clear-receipt-name"
                    onClick={handleClear}
                  >
                    Clear
                  </Button>
                  &nbsp;&nbsp;
                  {state.action === "add" &&
                  <Button
                    className="btn btn-primary"
                    id="save-add"
                    name="save-add"
                    onClick={(e) => handleSubmit(e, "save-add")}
                  >
                    {submitAddAction &&
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    }
                    Save & Add</Button>
                  }
                  &nbsp;&nbsp;
                  <Button
                    className="btn btn-primary"
                    id="save-print"
                    name="save-print"
                    onClick={(e) => handleSubmit(e, "save-print")}
                  >
                    {submitPrintAction &&
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    }
                    Save & Print</Button>
                  &nbsp;&nbsp;
                  <button className="btn btn-primary" onClick={(e) => handleSubmit(e, 'save-only')}>
                  { submitAction &&
                    <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                  }
                    Save</button>
                </div>
              </Col>
            </Row>
            )}
            <Modal
              show={showAlert}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{alertTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{alertBody}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            </Form>
     )
        :
        <div className="container-spinner">
        <div className="vertical-center-spinner">
         <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>  
         
                <span className="visually-hidden">Loading...</span>
              </Spinner>
         </div>
         </div>
      }
          
    </Container>
  );

}

export default AddUpdateSuppPayment;