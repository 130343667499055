import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Button, View,Spinner,Image } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
//import Sidebar2 from "./Sidebar2";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
//import ListGroup from "react-bootstrap/ListGroup";
//import Badge from "react-bootstrap/Badge";
//import DateTimeField from "react-bootstrap-datetimepicker"
//import DateTimePicker from "react-datetime-picker";

import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

//import "react-datetime-picker/dist/DateTimePicker.css";
//import "react-calendar/dist/Calendar.css";
//import "react-clock/dist/Clock.css";

import { BsFillPenFill, BsFillTrashFill,BsCardText } from "react-icons/bs";

//import DateTimePicker from "react-datetime-picker";

//import MenuSettings from "./MenuSettings";
import SidebarMain from "./SidebarMain";
import spinner_logo from '../assets/images/spinner_logo.png';


const ListDocSettings = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();

  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);
  
  //console.log("PASSED STATE VALUE IN Patient Add/Update page : ", state);
  
  var docNumberArr = [
    {
      doc_type_name: "order",
      doc_no_prefix: "ORD",
      doc_no_start: "1000",
      doc_no_generated:"1001",
    },
    {
      doc_type_name: "purchase order",
      doc_no_prefix: "PO",
      doc_no_start: "2000",
      doc_no_generated:"2001",
    },
    {
      doc_type_name: "delivery charge",
      doc_no_prefix: "DC",
      doc_no_start: "3000",
      doc_no_generated:"3001",
    },
  ];

  const [docnumberList, setDocNumberList] = useState(docNumberArr);
  const [toRender, setToRender] = useState(false);
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("Please login again");
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );
  //const [docNumber, setDocNumber] = useState(docNumberArr);

  let initialItems = [];
  var intialValues = {
    startNumber: 1001,
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [validateError, setValidateError] = useState(false);

  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const currSelBusinessRef = useRef(0);
  const currBranchRef = useRef(0);

  var validateErr = false;
  /*
  const getDocNumberList = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
      var headers = client.headers;

      var res = await axios.get(
        client.domain + "/core/getDocNumbering",
        { headers },
        { withCredentials: false }
      );
      console.log("Response from server at getDocNumberList : ", res.data.detail.data);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.data);
        setDocNumberList(res.data.detail.data);
        setShowMsgTitle("Document Numbering Info Added Successfully");
        setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
  };
  */
  const getDocNumberList = async () => {
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}

      var reqParams = {
        bid: currSelBusinessRef.current
      };

      var res = await axios.post(client.domain + "/bms/getDocNumberList", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", 0);
        setDocNumberList(res.data.detail.data);
        //setCustomerListFull(res.data.detail.data);
        //setShowMsgTitle("Customer Info Added Successfully");
        setToRender(true);
        //navigate("/dashboard");
        //setDcReportData(res.data.detail.res_data);
        //setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log( "Not able to reach target server please try after sometime");
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log( "Request failed due to ",error.response.data.detail.res_data);
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
  };

  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect::ListDoctNumber called ##########################");
      setToRender(true);
      getDocNumberList();
     // console.log("PASSED STATE VALUE IN Document Add/Update page : ", state);
      initialized.current = true;
    }
  }, []);

  const handleView = async (e) => {
    console.log("handle edit docnumber called : ", e);
    console.log("handle edit docnumber currentTarget called : ", e.currentTarget);
    console.log("handle edit docnumber target called : ", e.target);
    var selectedTagId = e.currentTarget.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    if (selectedTagId) {
      var currentId = selectedTagId.substring(
        selectedTagId.lastIndexOf("-") + 1
      );
      var nameOfElement = selectedTagId.substring(
        0,
        selectedTagId.lastIndexOf("-")
      );
      console.log("Name of element VIEW: ", nameOfElement);
      console.log("Current id VIEW : ", currentId);

      console.log("<<<<<<<<<<<<<<<<< ITEM SELECTED VIEW ********** ", docnumberList[currentId]);
      navigate("/addUpdateDocSetting", {
        state: {
          passed_element: docnumberList[currentId],
          action: "view",
        },
      });
    }
  };

  const sendDataToParent  = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if ( passedBackValue.hasOwnProperty("business_id") ) {
      currSelBusinessRef.current = passedBackValue["business_id"];
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);
    
    //getBranchList();
  }

  return (
      <Container>
        <SidebarMain sendBacktoParent={sendDataToParent} />
        { toRender ?  
        <form>
          <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
              <h4>Document Numbering</h4>
            </Col>
          </Row>
          <hr align="center" />
          <div className="ui-divider"></div>
          <Table striped bordered hover>
            <thead>
              <tr className="table-primary">
                <th className="table-row-heading" style={{textAlign: "center"}}>S.No</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Document Type Name</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Prefix</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Start Number</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Suffix</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Last Generated Number</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Allow Manual</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Details</th>
              </tr>
            </thead>

            <tbody>
              {docnumberList.map((docNumber, idx) => (
                <tr key={`doc-num-row-key-${idx}`}>
                  <td style={{textAlign: "right"}}>{idx + 1}</td>
                  <td style={{textAlign: "left"}}>{docNumber.name}</td>
                  <td style={{textAlign: "left"}}>{docNumber.prefix}</td>
                  <td style={{textAlign: "right"}}>{docNumber.sno_start}</td>
                  <td style={{textAlign: "right"}}>{docNumber.sno_suffix}</td>
                  <td style={{textAlign: "right"}}>{docNumber.sno_lastgen}</td>
                  <td style={{textAlign: "right"}}>{docNumber.allow_manual}</td>
                  <td style={{textAlign: "center"}}>
                    <BsCardText
                      key={`edit-btn-docnumber-key-${idx}`}
                      size={28}
                      style={{ color: "#0D6EFD", cursor: "pointer" }}
                      onClick={(e) => handleView(e)}
                      name={`edit-icon-docnumber-name-${idx}`}
                      id={`edit-icon-docnumber-id-${idx}`}
                      disabled={menuSettings.permissions.find(element => (element.code === 'listDocSettings' && element.a_detailview === true)) ? false : true}
                    />
                  </td>
                 
                </tr>
              ))}
            </tbody>
          </Table>
          </form>
      : 
      <div className="container-spinner">
     <div className="vertical-center-spinner">
      <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}> 
            </Spinner>
      </div>
      </div>
}
    </Container>
         
  );
};

export default ListDocSettings;
