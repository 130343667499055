import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  Fragment,
} from "react";
import Table from "react-bootstrap/Table";
import { fragment } from "react";

import Select from "react-select";

import {
  Form,
  Button,
  Row,
  Col,
  Alert,
  Modal,
  Container,
  Spinner,
  Image
} from "react-bootstrap";
//import Sidebar2 from "./Sidebar2";
import SidebarMain from "./SidebarMain";

import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsCardText,
  BsFillPrinterFill
} from "react-icons/bs";

import "../App.css";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { client } from "../config/Config";
import spinner_logo from '../assets/images/spinner_logo.png';


function ListItems() {
  const initialized = useRef(false);
  var navigate = useNavigate();
  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);

  const [checkedState, setCheckedState] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  
  /*
  let itemArr = [
    {
      name: "Steel Sheet",
      unit: "Tonne",
      hsn: "67553",
      cgst:"9.88",
      sgst:"7.88",
      igst: "0.9"
    },
    {
      name: "Steel Rod",
      unit: "Tonne",
      hsn: "72392",
      cgst:"3.77",
      sgst:"1.33",
      igst: "0.8"
    },
    {
      name: "Steel",
      unit: "KG",
      hsn: "4934",
      cgst:"7.44",
      sgst:"3.3",
      igst: "0.3"
    },
  ];
  */
 

  const [checked, setChecked] = useState(false);

  const [itemList, setItemList] = useState([]);
  const [customerListFull, setCustomerListFull] = useState([]);
  const [toRender, setToRender] = useState(false);
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("Please login again");
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );

  const [selectedId, setSelectedId] = useState(-1);

  const [showDialog, setShowDialog] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [customerIdSelect, setCustomerIdSelect] = useState({});
  const [optCustomer, setOptCustomer] = useState([]);
  const [placeIdSelect, setPlaceIdSelect] = useState({});
  const [optPlace, setOptPlace] = useState([]);
  const currSelBusinessRef = useRef(0);
  const currBranchRef = useRef(0);

 

  const getItemList = async () => {
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}

      var reqParams = {
        bid: currSelBusinessRef.current
      };

      var res = await axios.post(client.domain + "/bms/getItemList", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", 0);
        setItemList(res.data.detail.data);
        //setCustomerListFull(res.data.detail.data);
        //setShowMsgTitle("Customer Info Added Successfully");
        setToRender(true);
        //navigate("/dashboard");
        //setDcReportData(res.data.detail.res_data);
        //setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log( "Not able to reach target server please try after sometime");
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log( "Request failed due to ",error.response.data.detail.res_data);
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
  };
 

  
  
  /*
  const handleCustomerSelect = (e, data) => {
    console.log("handleCustomerSelect called ...", e);

    var customerListFilter = []

    if ( e.value === 0 ) {
      setCustomerList(customerListFull);
    }
    else {
      customerListFull.map((cust) => {
        if ( cust.id === e.value ) {
          customerListFilter.push(cust);
        }
      })
      setCustomerList(customerListFilter);
    }
    setCustomerIdSelect(e);
  };
  const handlePlaceSelect = (e, data) => {
    console.log("hadlePlaceSelect called ...", e);
    var customerListFilter = []
    if ( e.value === 0 ) {
      setCustomerList(customerListFull);
    }
    else {
      customerListFull.map((cust) => {
        if ( cust.address_line2 === e.label ) {
          customerListFilter.push(cust);
        }
      })
      console.log("customerListFilter :", customerListFilter);
      setCustomerList(customerListFilter);
    }
    //setOptPlace(placeArr);    
    setPlaceIdSelect(e);
    
  }
  */


  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect::ListItems called ##########################");
      setToRender(true);
      getItemList();
      /*
      
      initialized.current = true;
      ( async() => {
        var [retState, customerArr, placeListArrRet] = await getCustomerListDropdown();
        if ( retState ) {
          setOptCustomer(customerArr);
          setCustomerIdSelect(customerArr[0]);

          setOptPlace(placeListArrRet);
          setPlaceIdSelect(placeListArrRet[0]);
        }
      })();
      */
    }
  }, []);

  const handleAdd = async (e) => {
    console.log("handle add Item called : ", e);
    navigate("/addUpdateItem", {
      state: { passed_element: [], action: "add" },
    });
  };

  const handleView = async (e) => {
    console.log("handle edit Items called : ", e);
    console.log(
      "handle edit customers currentTarget called : ",
      e.currentTarget
    );
    console.log("handle edit customers target called : ", e.target);
    var selectedTagId = e.currentTarget.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    if (selectedTagId) {
      var currentId = selectedTagId.substring(
        selectedTagId.lastIndexOf("-") + 1
      );
      var nameOfElement = selectedTagId.substring(
        0,
        selectedTagId.lastIndexOf("-")
      );
      console.log("Name of element VIEW: ", nameOfElement);
      console.log("Current id VIEW : ", currentId);

      console.log(
        "<<<<<<<<<<<<<<<<< ITEM SELECTED VIEW ********** ",
      itemList[currentId]
      );
      navigate("/addUpdateItem", {
        state: {
          passed_element: itemList[currentId],
          action: "view",
        },
      });
    }
  };

  
/*
  const getCustomerListDropdown = async () => {
    console.log("getCustomerListDropdown method called ...");
    var retState = false;
    
  
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(
        client.domain + "/crm/getAllCustomers",
        { headers },
        { withCredentials: false }
      );
      
      console.log(
        "Response from server getCustomerList : ",
        res.data.detail.data
      );
      if (res.data.detail.status === "SUCCESS") {
        console.log(
          "Response message from server getCustomerList : ",
          res.data.detail.data
        );
        
  
        var customerArr = [];
        var custAll = { value: 0, label: 'All Customers'};
        customerArr.push(custAll);
        res.data.detail.data.map((itemCustomer, idx) => {
          console.log("Ind val : ", itemCustomer);
          var customerInd = {
            value: itemCustomer.id,
            label: itemCustomer.name,
          };
          console.log("In customer modified : ", customerInd);
          customerArr.push(customerInd);
        });


        var keySearch = "address_line2";
        //let a = [{"place": "avn"}, {"place": "Coimbatore"}, {"place": "Tiruppur"}, {"place": "avn"}, {"place": "Coimbatore"}, {"place": "Sevur"}];
        const uniquePlaceList = [...new Map(res.data.detail.data.map(item => [item[keySearch], item])).values()];
        console.log("UNQUE PLACE LIST : ", uniquePlaceList);

        var placeListArrRet = [];
        var placeAll = {value: 0, label: "All Sites"};
        placeListArrRet.push(placeAll);
        uniquePlaceList.map((place, idx) => {
          var placeObj = { value: idx + 1, label: place.address_line2};
          placeListArrRet.push(placeObj);
        })
        console.log("FULL PLACE LIST :", placeListArrRet);

  
        console.log("Mod Full VALUE : ", customerArr);
        retState = true;
      }
     
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.data
          );
         
        }
      }
    }
    return [retState, customerArr, placeListArrRet];
  };
  */
  

  const handleCustomerPrint = async (e) => {
   //navigate("/printCustomer")
    
    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(
      urlStr.lastIndexOf(client.urlDelimiter) + 1
    );
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printCustomer?`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0,0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
    
    
  };

  const sendDataToParent  = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if ( passedBackValue.hasOwnProperty("business_id") ) {
      currSelBusinessRef.current = passedBackValue["business_id"];
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);
    
    //getBranchList();
  }

  //var filename_gen = "customer";
 
  return (
    <Container>
      <SidebarMain sendBacktoParent={sendDataToParent} />
        { toRender ?
        <form>
          <Row>
              <Col xs={9} md={9} sm={9} lg={9}> 
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>{menuSettings.permissions.find(element => (element.code === 'listItems' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'listItems')).page_title_list : "Items"}</span>
           
             </Col>
             {/*
             <Col xs={1} md={1} sm={1} lg={1} style={{paddingTop: '8px'}} > 
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1rem",verticalAlign:"bottom" }}>Customer:</span>
           
             </Col>
             <Col xs={2} md={2} sm={2} lg={2}>
              <Select
                    inline
                    id="customer"
                    name="customer"
                    value={{
                      value: customerIdSelect.value,
                      label: customerIdSelect.label,
                    }}
                    options={optCustomer}
                    defaultValue={{
                      value: customerIdSelect.value,
                      label: customerIdSelect.label,
                    }}
                    onChange={handleCustomerSelect}
                    //isDisabled={isReadOnly}
                  />
                  
            </Col> 
            <Col xs={1} md={1} sm={1} lg={1} style={{paddingTop: '8px'}} > 
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1rem",verticalAlign:"bottom" }}>Site:</span>
           
             </Col>
             <Col xs={2} md={2} sm={2} lg={2}>
              
              <Select
                    inline
                    id="place"
                    name="place"
                    value={{
                      value: placeIdSelect.value,
                      label: placeIdSelect.label,
                    }}
                    options={optPlace}
                    defaultValue={{
                      value: placeIdSelect.value,
                      label: placeIdSelect.label,
                    }}
                    onChange={handlePlaceSelect}
                    //isDisabled={isReadOnly}
                  />
                  </Col>
                 <Col xs={1} md={1} sm={1} lg={1}> 
                   
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Reset
                    </Button>  
            </Col>           
            <Col xs={3} md={3} sm={3} lg={3} className="d-flex justify-content-end float-end">
            <Button id="customerPrint" name = "customerPrint"  onClick={(e)  => handleCustomerPrint(e)}
            //filename={filename_gen}
            >
                <BsFillPrinterFill
                 size={18}
                 style={{ color: "white", cursor: "pointer" }}
                 name={`print-customer-icon`}
                 id={`print-customer-icon`}
                 />
                 </Button>
                 &nbsp;&nbsp;&nbsp;&nbsp;
                  */}
            <Col xs={3} md={3} sm={3} lg={3} className="d-flex justify-content-end float-end">
              {menuSettings.permissions.find(element => (element.code === 'listItems' && element.a_add === true)) &&
              <Button
                variant="warning"
                id="add-btn-id"
                name="add-btn-name"
                className="justify-content-end float-end"
                onClick={(e) => handleAdd(e)}
              >
                New Items
              </Button>
              }
            </Col>
          </Row>
          <hr align="center" />
          <div className="ui-divider"></div>

          <Table striped bordered hover>
            <thead>
              <tr className="table-primary">
                <th className="table-row-heading" style={{textAlign: "center"}}>S.No</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Type</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Trade Type</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Name</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Unit</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>HSN</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Unit Price</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>CGST(%)</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>SGST(%)</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>IGST(%)</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>CESS(%)</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Status</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Details</th>
              </tr>
            </thead>
            <tbody>
              {itemList.map((item, idx) => (
                <tr>
                  <td style={{textAlign: "right"}}>{idx + 1}</td>
                  <td style={{textAlign: "left"}}>{item.type}</td>
                  <td style={{textAlign: "left"}}>{item.tt}</td>
                  <td style={{textAlign: "left"}}>{item.name}</td>
                  <td style={{textAlign: "left"}}>{item.unit}</td>
                  <td style={{textAlign: "left"}}>{item.hsn_code}</td>
                  <td style={{textAlign: "left"}}>{item.unit_price}</td>
                  <td style={{textAlign: "right"}}>{item.cgst_per}</td>
                  <td style={{textAlign: "right"}}>{item.sgst_per}</td>
                  <td style={{textAlign: "right"}}>{item.igst_per}</td>
                  <td style={{textAlign: "right"}}>{item.cess_per}</td>
                  <td style={{textAlign: "left"}}>{item.status}</td>

                  <td style={{textAlign: "center"}}>
                    <BsCardText
                      key={`view-btn-item-key-${idx}`}
                      size={28}
                      style={{ color: "#0D6EFD", cursor: "pointer" }}
                      onClick={(e) => handleView(e)}
                      name={`view-icon-item-name-${idx}`}
                      id={`view-icon-item-id-${idx}`}
                      disabled={menuSettings.permissions.find(element => (element.code === 'listItems' && element.a_detailview === true)) ? false : true}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        	</form>
       : 
       <div className="container-spinner">
      <div className="vertical-center-spinner">
       <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
       </div>
       </div>
      }  
      
     </Container>
   
  );
}
export default ListItems;
