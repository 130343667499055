import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Table,
  Spinner,
  Image
} from "react-bootstrap";
import { withRouter } from "react-router";
//import Sidebar from './Sidebar';
//import Sidebar2 from "./Sidebar2";
//import Footer from "./Footer";
//import Topmenu from "./Topmenu";
//import OrderDetails from "./OrderDetails";
import "../Dashboard.css";

//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";

import { useNavigate } from "react-router-dom";

//import * as Icon from "react-bootstrap-icons";
import axios from "axios";
import { client, OS_INVOICE_FOR_NDAYS } from "../config/Config";
import Select from "react-select";
//import logo_avinaerp from '../assets/images/logo_avinaerp.png';
import spinner_logo from '../assets/images/spinner_logo.png';
import CsvGenerate from "./CsvGenerate";



import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsArrowLeftCircle,
  BsCardText,
  BsFillPrinterFill
} from "react-icons/bs";

import { AiOutlineOrderedList } from "react-icons/ai";

//import MenuOms from "./MenuOms";

import rmcBlueIcon from "../assets/images/ready_mix_blue.png";
import SidebarMain from "./SidebarMain";
import { CSVLink, CSVDownload } from "react-csv";

import {getDistrictList, getStateList, getProductApiCall, getProductBuy, getProductsSell, searchAllUniqLocations, formatDateIndian } from "./utils";

const ListInwardDC = () => {
  /*
  csvData={customers}
            fileName={"cust-Inv_2024_01_17_11_34_55_88899.csv"}
            wscols={wscols}
  */          
  const initialized = useRef(false);
  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);
 /*
  let deliveryArr = [
    
    {
      
      delivery_date: "02-03-2024",
      delivery_number: "DN001",
      customer_name: "Ram",
      total_amount: "7896",
     
    },
    {
      
      delivery_date: "07-08-2024",
      delivery_number: "DN002",
      customer_name: "Ajay",
      total_amount: "6764",
     
    },
    {
      
      delivery_date: "05-05-2024",
      delivery_number: "DN003",
      customer_name: "Vishal",
      total_amount: "78654",
     
    },
  ];
  */

  
  

  //const [deliveryList, setDeliveryList] = useState([]);
  const [dcListFull, setDcListFull] = useState([]);
  const [invoiceExcelList, setInvoiceExcelList] = useState([]);
  //const [orderList, setOrderList] = useState(orderListLoad);
  //const [orderListFull, setOrderListFull] = useState([]);
  //const [orderListFull, setOrderListFull] = useState([]);
  const [orderDcFetched, setOrderDcFetched] = useState(false);

  var total_dn_ref = useRef(0.00);
  var total_amount_ref = useRef(0.00);
  /*
  total_dn_ref.current = deliveryList.length;
  total_amount_ref.current = 0.00;
  deliveryList.map((deliveryInd) => {
    total_amount_ref.current = parseFloat(total_amount_ref.current) + parseFloat(deliveryInd.total_amount);
    })
    */
  
  
  function padTo2Digits(num) {
    //console.log("<<<<<<<<<<<<<< given number >>>>>>>>>>>>>>>> : ", num);
    var modNum = num.toString().padStart(2, "0");
    console.log("Modified  value : ", modNum);
    return modNum;
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
//var lastDay = new Date(date.getFullYear(), date.getMonth() + 1,
  const intialValues = {
    date_from: formatDate(new Date(firstDay)),
    date_to: formatDate(new Date()),
    //date_to: formatDate(new Date(new Date().setDate(new Date().getDate() + OS_INVOICE_FOR_NDAYS))),
    gst_number: "",

  }
 /*
  let customerArr = [
  
    { value: 1, label: "All Customers" },
    { value: 2, label: "Deppam Motors" },
    { value: 3, label: "ABC Pumps" },
    { value: 4, label: "ADP Compressors" },
  ];
  */

  

  
  const [toRender, setToRender] = useState(false);
  const [customerList, setCustomerList] = useState([]);
 
  const [customerIdSelect, setCustomerIdSelect] = useState({});
  const [optCustomer, setOptCustomer] = useState([]);
 ;
  const [formValues, setFormValues] = useState(intialValues);
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("Please login again");
  const [showMsgTitle, setShowMsgTitle] = useState( "Invalid authentication Session!" );

  const [dcList, setDcList] = useState([]);
  const[dcSummary, setDcSummary] = useState([])
  const currSelBusinessRef = useRef(0);
  const currBranchRef = useRef(0);
  
  const navigate = useNavigate();

  const handleAdd = (e) => {
    e.preventDefault();
    console.log("Add or Update Order button clicked ...", e);
    navigate(`/addUpdateInwardDCMfgT2`, {
      state: { passed_element: [], action: "add" },
    });
  };
  

  

  const handleView = (e, data) => {
    console.log("Handle action edit Clicked : ", e);
    //console.log("Handle Clicked edit DATA : ", data);
    console.log("Handle action edit Clicked target : ", e.target);
    console.log("Handle action edit Clicked target Current : ", e.currentTarget);
    console.log("Handle action edit Clicked target Current id : ", e.currentTarget.id);
    console.log("Handle action edit Clicked target ID : ", e.target.id);
    //console.log("Handle action edit Clicked target NAME : ", e.target.name);
    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(0, clickedTagId.lastIndexOf("-"));
      console.log("current selected element name : ", nameOfElement);
      console.log("Current SELECTED id : ", curSelId);
      console.log("CUR SELECTED VALUE : ", dcList[curSelId]);

      //navigate(`/${client.urlDelimiter}orderDetails`, {state : orderList[curSelId]});
      navigate(`/addUpdateInwardDCMfgT2`, {
        state: { passed_element: dcList[curSelId], action: "edit" },
      });
    }
  };

 
  const getDCList = async (customer_id_pass) => {
    var retStateLoad = false;
    var dcRet = [];
    var summaryRet = [];
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      console.log("Selected Customer :", customerIdSelect);
      var reqParams = {
        bid: currSelBusinessRef.current,
        brid: currBranchRef.current || 0,
        customer_id: customer_id_pass,
        date_from: formValues["date_from"],
        date_to: formValues["date_to"]

      };
      console.log("REQUEST PARAMS: ", reqParams );
      var res = await axios.post(client.domain + "/bms/getCustINDCList", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server Invoice : ",res.data.detail.data );
        setDcList(res.data.detail.data.customer_dc);
        setDcSummary(res.data.detail.data.customer_dc_summary[0]);
        //setCustomerListFull(res.data.detail.data);
        //setShowMsgTitle("Customer Info Added Successfully");
        setToRender(true);
        dcRet = res.data.detail.data.customer_dc;
        summaryRet = res.data.detail.data.customer_dc_summary;
        retStateLoad = true;
        
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log( "Not able to reach target server please try after sometime");
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log( "Request failed due to ",error.response.data.detail.res_data);
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
    return [retStateLoad, dcRet, summaryRet];
  };

  const CallCustomerData = async () => {
    var retStateLoad = false;
    var customerRet = [];

    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}

      var reqParams = {
        bid: currSelBusinessRef.current
      };

      var res = await axios.post(client.domain + "/bms/getCustINDCSearchPreLoadData", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.data.customers);
        var customerList = [];

        //customerList.push({label: "All Customers", value: 0});
        res.data.detail.data.customers.map((customer) => {
          customerList.push(customer);
        })
        setOptCustomer(customerList);
        //setCustomerIdSelect(customerList[0]);
        //setCustomerIdSelect(res.data.detail.data.customers[0]);
        /*
          var findDefault = res.data.detail.data.customers.find(element => element.is_default === true);
          if (findDefault !== undefined) {
            setCustomerIdSelect(findDefault);  
          };
          */
        
        setToRender(true);
        customerRet = customerList;
        retStateLoad = true;
        
          
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log( "Not able to reach target server please try after sometime");
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log( "Request failed due to ",error.response.data.detail.res_data);
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
    return [retStateLoad, customerRet];
  };
  
 
  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect called ##########################");
      //setCustomerIdSelect(customerArr[0]);
      //getInvoiceList();
      //CallCustomerData();

      setToRender(true);
      ( async() => {
        var [retStateLoad, customerRet] = await CallCustomerData();
        if (retStateLoad){
          var findDefault = customerRet.find(element => element.is_default === true);
          console.log("FIND DEFAULT :", findDefault);
          if (findDefault !== undefined) {
            setCustomerIdSelect(findDefault);  

            var [retStateLoad, dcRet, summaryRet] = await getDCList(findDefault.value);
          }
          console.log(" Customer :", customerRet);
         
          //setCustomerIdSelect(customerRet[0]);

           
        }
       
      })();
      initialized.current = true;
    }
  }, []);
  
  const handleBack = async (e) => {
    console.log("handle back called : ", e);
    navigate(-1);
  };
  
  const handleDateSearch = async() => {
    console.log("handleDateSearch called ...");
    await getDCList(customerIdSelect.value); 
    
  };
  const handleCustomerSelect = (e, data) => {
    console.log("handleCustomerSelect called ...", e);
    setCustomerIdSelect(e);
  };

  const onChangeStartDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);
    setFormValues({ ...formValues, date_from: e.target.value });
  };
 
  const onChangeEndDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);
    setFormValues({ ...formValues, date_to: e.target.value });
  };
 
  const handleDeliveryPrint = async (e) => {
    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(
      urlStr.lastIndexOf(client.urlDelimiter) + 1
    );
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));
    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);
    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printListInwardDC?bid=${currSelBusinessRef.current}&brid=${currBranchRef.current}&customer_id=${customerIdSelect.value}&date_from=${formValues["date_from"]}&date_to=${formValues["date_to"]}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0,0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
  };

  const sendDataToParent  = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if ( passedBackValue.hasOwnProperty("business_id") ) {
      currSelBusinessRef.current = passedBackValue["business_id"];
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);
    
    //getBranchList();
  }

 

 
  return (
    <Container>
     <SidebarMain sendBacktoParent={sendDataToParent} />
     { toRender ?  
      <form>
        <Row>
        
        <Col xs={9} md={9} sm={9} lg={9}> 
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem" }}>{menuSettings.permissions.find(element => (element.code === 'listInwardDC' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'listInwardDC')).page_title_list : "Inward DC"}</span>
        </Col>
       
           

                
          <Col
            xs={3}
            md={3}
            sm={3}
            lg={3}
            className="d-flex justify-content-end float-end"
          >  
                <Button id="deliveryPrint" name = "deliveryPrint"  onClick={(e)  => handleDeliveryPrint(e)}
                  //disabled={menuSettings.permissions.find(element => (element.code === 'listInwardDC' && element.a_print_list === true && dcList.length > 0)) ? false : true}
                >
                  <BsFillPrinterFill
                  size={18}
                  style={{ color: "white", cursor: "pointer" }}
                  name={`print-delivery-icon`}
                  id={`print-delivery-icon`}
                  
                  />
                </Button>
                &nbsp;&nbsp;
                {/*{menuSettings.permissions.find(element => (element.code === 'listInwardDC' && element.a_add === true)) &&*/}
                <Button
                  variant="warning"
                  id="add-btn-id"
                  name="add-btn-name"
                  className=" float-end"
                  onClick={(e) => handleAdd(e)}
                >
                    New Inward DC
                </Button> 
               
          </Col>
         
        </Row>
        <hr align="center" />
      
        <Row>
        <Col
            xs={3}
            md={3}
            sm={3}
            lg={3}>
             
                <Select
                    inline
                    id="customer"
                    name="customer"
                    value={{
                      value: customerIdSelect.value,
                      label: customerIdSelect.label,
                    }}
                    options={optCustomer}
                    defaultValue={{
                      value: customerIdSelect.value,
                      label: customerIdSelect.label,
                    }}
                    onChange={handleCustomerSelect}
                    //isDisabled={isReadOnly}
                  />
                  </Col>

                 
             
            <Col xs={5} md={5} sm={5} lg={5}>
              <Row>
              <Col>
                <Form.Control
                  type="date"
                  name="date_from"
                  placeholder="Start Date"
                  value={formValues.date_from}
                  onChange={onChangeStartDatePicker}
                  //readOnly={isReadOnly}
                  
                />
              
            </Col>
            <Col>
              <Form.Label style={{textAlign: "center", paddingTop: "5px"}}>To</Form.Label>
            </Col>
          
            <Col>
           
                <Form.Control
                  type="date"
                  name="date_to"
                  placeholder="End Date"
                  value={formValues.date_to}
                  onChange={onChangeEndDatePicker}
                  //readOnly={isReadOnly}
                  
                />
                 </Col>
                <Col>
                 <Button id="search-order-id"
                    size={"md"}
                    variant="success"
                    onClick={(e) => handleDateSearch(e)}>
                    Search
                    </Button>
                </Col>
            </Row>
           </Col>          
                  </Row>
                 
                  <hr align="center" />
                  {/*
                  <Row>
                       
                      <Col xs={12} md={12} sm={12} lg={12} style={{textAlign: "right"}}> 
                       
                        <Form.Label style={{fontWeight: 'bold',color: "#43C70F"}}>
                        Total Delivery Notes :
                      </Form.Label>
                        <Form.Label style={{fontWeight: 'bold', paddingLeft: "2px",color: "#43C70F"}}>
                          {dcSummary.dc_count}
                      </Form.Label>
                      
                    <span style={{ color: '#C0C0C0'}}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
                      {/ *
                        <Form.Label style={{fontWeight: 'bold',color: "#43C70F"}}>
                         Total Amount :
                      </Form.Label>
                        <Form.Label style={{fontWeight: 'bold', paddingLeft: "2px",color: "#43C70F"}}>
                        { dcSummary.sub_total_amt > 0.01 ?
                          <>
                        {((new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(dcSummary.sub_total_amt).toFixed(2))))}
                        </>
                         : (0.00).toFixed(2) }
                      </Form.Label>
                      <span style={{ color: '#C0C0C0'}}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>

                      <Form.Label style={{fontWeight: 'bold',color: "#43C70F"}}>
                      Total CGST :
                      </Form.Label>
                        <Form.Label style={{fontWeight: 'bold', paddingLeft: "2px",color: "#43C70F"}}>
                          { dcSummary.cgst_amt > 0.01 ?
                          <>
                        {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(dcSummary.cgst_amt).toFixed(2)))}
                         </>
                         : (0.00).toFixed(2) }
                      </Form.Label>
                      
                    <span style={{ color: '#C0C0C0'}}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
                    <Form.Label style={{fontWeight: 'bold',color: "#43C70F"}}>
                      Total SGST :
                      </Form.Label>
                        <Form.Label style={{fontWeight: 'bold', paddingLeft: "2px",color: "#43C70F"}}>
                        { dcSummary.sgst_amt > 0.01 ?
                        <>
                        {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(dcSummary.sgst_amt).toFixed(2))) }
                        </>
                        : (0.00).toFixed(2)} 
                      </Form.Label>
                      <span style={{ color: '#C0C0C0'}}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>

                    <Form.Label style={{fontWeight: 'bold',color: "#43C70F"}}>
                      Total IGST :
                      </Form.Label>
                        <Form.Label style={{fontWeight: 'bold', paddingLeft: "2px",color: "#43C70F"}}>
                        { dcSummary.igst_amt > 0.01 ?
                        <>
                        {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(dcSummary.igst_amt).toFixed(2)))}
                      </>
                        : (0.00).toFixed(2)  }
                      </Form.Label>

                      <span style={{ color: '#C0C0C0'}}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
                    <Form.Label style={{fontWeight: 'bold',color: "#43C70F"}}>
                      Total CESS :
                      </Form.Label>
                      
                        <Form.Label style={{fontWeight: 'bold', paddingLeft: "2px",color: "#43C70F"}}>
                        { dcSummary.cess_amt > 0.01 ?
                        <>
                        {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(dcSummary.cess_amt).toFixed(2)))}
                        </>
                        : (0.00).toFixed(2)}
                      </Form.Label>

                      <span style={{ color: '#C0C0C0'}}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
                    * /}
                    <Form.Label style={{fontWeight: 'bold',color: "#43C70F"}}>
                       Total Amount :
                      </Form.Label>
                        <Form.Label style={{fontWeight: 'bold', paddingLeft: "2px",color: "#43C70F"}}>
                        { dcSummary.dc_total_amt > 0.01 ?
                        <>
                        {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(dcSummary.dc_total_amt).toFixed(2)))}
                        </>
                        : (0.00).toFixed(2)}
                      </Form.Label>
                       </Col>  
                     </Row>
                     */}

              <tr className="table-primary">
              <th className="table-row-heading" key={"order-list-heading-key"} style={{ textAlign: "left", width: "25%"}}>Total Delivery Notes : {dcSummary.dc_count}</th>
              <th className="table-row-heading"  style={{ textAlign: "center", width: "0%" }}></th>
              <th className="table-row-heading"  style={{ textAlign: "center", width: "0%" }}></th>
              <th className="table-row-heading"  style={{ textAlign: "right", width: "50%" }}>Total :</th>
              {/*
              <th className="table-row-heading"  style={{ textAlign: "center", width: "" }}>Sub Total</th>
              <th className="table-row-heading"  style={{ textAlign: "center", width: "" }}>CGST</th>
              <th className="table-row-heading"  style={{ textAlign: "center", width: "" }}>SGST</th>
              <th className="table-row-heading"  style={{ textAlign: "center", width: "" }}>IGST</th>
              <th className="table-row-heading"  style={{ textAlign: "center", width: "" }}>CESS</th>
             */}
              <th className="table-row-heading"  style={{ textAlign: "right", width: "15%" }}>{ dcSummary.dc_total_amt > 0.01 ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(dcSummary.dc_total_amt).toFixed(2)) : (0.00).toFixed(2)}</th>
              <th className="table-row-heading" style={{ textAlign: "center", width: "10%" }}></th>
            </tr>
        
        { toRender &&
       
        <Table striped bordered hover size="sm">
          <thead>
            <tr className="table-primary">
              <th className="table-row-heading" key={"order-list-heading-key"} style={{ textAlign: "center", width: "5%"}}>S.No</th>
              <th className="table-row-heading"  style={{ textAlign: "center", width: "10%" }}>DN Date</th>
              <th className="table-row-heading"  style={{ textAlign: "center", width: "10%" }}>DN No</th>
              <th className="table-row-heading"  style={{ textAlign: "center", width: "50%" }}>Customer</th>
              {/*
              <th className="table-row-heading"  style={{ textAlign: "center", width: "" }}>Sub Total</th>
              <th className="table-row-heading"  style={{ textAlign: "center", width: "" }}>CGST</th>
              <th className="table-row-heading"  style={{ textAlign: "center", width: "" }}>SGST</th>
              <th className="table-row-heading"  style={{ textAlign: "center", width: "" }}>IGST</th>
              <th className="table-row-heading"  style={{ textAlign: "center", width: "" }}>CESS</th>
             */}
              <th className="table-row-heading"  style={{ textAlign: "center", width: "15%" }}>Total Amount</th>
              <th className="table-row-heading" style={{ textAlign: "center", width: "10%" }}>Details</th>
            </tr>
          </thead>

          <tbody>
            {dcList.map((deliveryInd, idx) => (
              <tr key={`delivery-table-row-key-${idx}`}>
                <td style={{ textAlign: "right", width: "5%"  }}
                  key={`delivery-table-row-key-sno-${idx}`}
                  id={`delivery-table-row-id-sno-${idx}`}
                  name={`delivery-table-row-name-sno-${idx}`}
                >
                  {idx + 1}
                </td>
                 
                <td style={{ textAlign: "left", width: "10%" }}
                  key={`delivery-table-row-key-date-${idx}`}
                  id={`delivery-table-row-id-date-${idx}`}
                  name={`delivery-table-row-name-date-${idx}`}
                >
                  {deliveryInd.dc_date}
                  {/*{formatDateIndian(new Date(deliveryInd.dc_date))}*/}
                </td>

                <td style={{ textAlign: "right", width: "10%" }}
                  key={`delivery-table-row-key-billno-${idx}`}
                  id={`delivery-table-row-id-billno-${idx}`}
                  name={`delivery-table-row-name-billno-${idx}`}
                >
                  {deliveryInd.dc_no}
                </td>
                <td
                style={{width:"50%"}}
                  key={`delivery-table-row-key-customer-${idx}`}
                  id={`delivery-table-row-id-customer-${idx}`}
                  name={`delivery-table-row-name-customer-${idx}`}
                >
                  {deliveryInd.customer_name}
                </td>
                {/*
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`delivery-table-row-key-total_amount-${idx}`}
                  id={`delivery-table-row-id-total_amount-${idx}`}
                  name={`delivery-table-row-name-total_amount-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(deliveryInd.sub_total_amt).toFixed(2)))}
                </td>
                
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`delivery-table-row-key-total_amount-${idx}`}
                  id={`delivery-table-row-id-total_amount-${idx}`}
                  name={`delivery-table-row-name-total_amount-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(deliveryInd.cgst_amt).toFixed(2)))}
                </td>
                
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`delivery-table-row-key-total_amount-${idx}`}
                  id={`delivery-table-row-id-total_amount-${idx}`}
                  name={`delivery-table-row-name-total_amount-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(deliveryInd.sgst_amt).toFixed(2)))}
                </td>
                
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`delivery-table-row-key-total_amount-${idx}`}
                  id={`delivery-table-row-id-total_amount-${idx}`}
                  name={`delivery-table-row-name-total_amount-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(deliveryInd.igst_amt).toFixed(2)))}
                </td>
                
                
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`delivery-table-row-key-total_amount-${idx}`}
                  id={`delivery-table-row-id-total_amount-${idx}`}
                  name={`delivery-table-row-name-total_amount-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(deliveryInd.cess_amt).toFixed(2)))}
                </td>
                */}
                
             
               
                <td
                  style={{ padding: "5px", textAlign: "right", width: "15%" }}
                  key={`delivery-table-row-key-total_amount-${idx}`}
                  id={`delivery-table-row-id-total_amount-${idx}`}
                  name={`delivery-table-row-name-total_amount-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(deliveryInd.dc_total_amt).toFixed(2)))}
                </td>
                
                <td style={{ textAlign: "center", width: "10%" }}
                  key={`delivery-table-row-key-actions-${idx}`}
                  id={`delivery-table-row-id-actions-${idx}`}
                  name={`delivery-table-row-name-actions-${idx}`}
                >
                  <BsCardText
                    size={28}
                    style={{ color: "#0d6efd", cursor: "pointer" }}
                    onClick={(e) => handleView(e)}
                    name={`delivery-view-icon-${idx}`}
                    id={`delivery-view-icon-${idx}`}
                    disabled={menuSettings.permissions.find(element => (element.code === 'listInwardDC' && element.a_detailview === true)) ? false : true}
                  />
                  &nbsp;&nbsp; 
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        }  
        </form>
       : 
       <div className="container-spinner">
      <div className="vertical-center-spinner">
       {/*<Button variant="primary" disabled>*/}
       <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
 
       {/*</Button>*/}
       </div>
       </div>
      }      
       
      
    </Container>
  );
};
//const Dashboard = withRouter(Dash);
export default ListInwardDC;
