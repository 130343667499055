import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { Page, Text, View, Document, StyleSheet, PDFViewer,Image } from '@react-pdf/renderer';

import { COMPANY_NAME, client } from '../config/Config';
//import logo_left from '../logo_left.png';
//import logo_right from '../logo_right.png';
import { convertNumberToWords } from "./utils";
import business_logo from '../assets/images/business_logo.png';

//import StockDocument from "./StockDocument";

//const borderColor = '#a8a8a8';
const BORDER_COLOR = '#DCDCDC';
const BORDER_COLOR2 = "#C8C8C8";
const HEADING_COLOR = '#646363';
const BORDER_WIDTH = 0.5
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 9,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    //width: '100%',
    //height: '33%',
    width: '100%',
    height: '100%',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logo_image2: {
    width: '15%',
    height: '98px',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  business_content: {
    width: '60%',
    height: '98px',
    //paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container2: {
    //paddingBottom:0,
    flexDirection: 'row',
    //borderBottomColor: '#bff0fd',
    //backgroundColor: '#bff0fd',
    //borderBottomWidth: 1,
    //alignItems: 'center',
    //height: 12,
    //textAlign: 'center',
    //fontStyle: 'bold',
    //flexGrow: 1,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: BORDER_WIDTH,
    marginBottom: 5,
    borderWidth: 1,
    borderColor: BORDER_COLOR,
  },
  container: {
    flexDirection: 'row',
    borderBottomColor: BORDER_COLOR,
    backgroundColor: BORDER_COLOR,
    //borderBottomWidth: 1,
    alignItems: 'center',
    //height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
  },
  
  row: {
    flexDirection: 'row',
    //borderBottomColor: '#a8a8a8',
    //borderBottmWidth: 1,
    alignItems: 'center',
    //height: 24,
    fontStyle: 'bold',
  },
 
  
});

const PrintCustReceipt = (props) => {
  const [reportData, setReportData] = useState({});
  const [toRender, setToRender] = useState(false);
  //const [reportHeader, setReportHeader] = useState({});
  const [reportTitle, setReportTitle] = useState({});
  const [branchAddress, setBranchAddress] = useState({}); 
  const [businessAddress, setBusinessAddress] = useState({});
  const [receipt, setReceipt] = useState({});
  const [printParams, setPrintParams] = useState([]);
  const [setting, setSetting] = useState({});
  const initialized = useRef(false);

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
 
  
  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  const formateTime = (currTime) => {
    var h = (currTime.getHours() < 10 ? "0" : "") + currTime.getHours();
    var m = (currTime.getMinutes() < 10 ? "0" : "") + currTime.getMinutes();
    var hrMin = h + ":" + m;
    return hrMin;
  };

  const formatPrintDate = (currTime) => {
    var dateComponent = [
      padTo2Digits(currTime.getDate()),
      padTo2Digits(currTime.getMonth() + 1),
      currTime.getFullYear(),
    ].join("-");

    var timeComponent = [
      padTo2Digits(currTime.getHours() % 12 || 12 ),
      padTo2Digits(currTime.getMinutes())
    ].join(":");

    const ampm = currTime.getHours() >= 12 ? 'PM' : 'AM';

    return dateComponent + " " + timeComponent + " " + ampm;
  }
  /*
  const getReportList = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
     

      var res = await axios.get(
        client.domain + "/ims/getReportCurrentStock",
        { headers },
        { withCredentials: false }
      );

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getReportList : ", res.data.detail.data);
        setReportData(res.data.detail.data);

        //setShowMsgTitle("Report Info Added Successfully");
        setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        //setShowMsg("Please try after sometime");
        //setShowMsgTitle("Network Issue!");
        //setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg("Please try again ...");
          //setShowMsgTitle("Unable to process your request!");
          //setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          //setShowMsg(error.response.data.detail);
          //setShowMsgTitle("Unable to process your request at this moment!");
          //setShow(true);
        }
      }
    }
  };
  */

  /*
  const getReportList = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
     

      var res = await axios.get(
        client.domain + "/ims/getReportCurrentStock",
        { headers },
        { withCredentials: false }
      );

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getReportList : ", res.data.detail.data);
        setReportData(res.data.detail.data);

        //setShowMsgTitle("Report Info Added Successfully");
        setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        //setShowMsg("Please try after sometime");
        //setShowMsgTitle("Network Issue!");
        //setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg("Please try again ...");
          //setShowMsgTitle("Unable to process your request!");
          //setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          //setShowMsg(error.response.data.detail);
          //setShowMsgTitle("Unable to process your request at this moment!");
          //setShow(true);
        }
      }
    }
  };
*/
  /*
  const getEmployeePaymentById = async(payment_id) => {
    var retState = false;
      var paymentDetails = {};
      console.log("getExpensePaymentById method called ...: ", payment_id);
  
      try {
        var aIVal = localStorage.getItem("_aI");
        var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`}
        var res = await axios.get(client.domain + `/getEmployeePaymentById?payment_id=${payment_id}`, { headers }, { withCredentials: false });
        
        console.log("Response from server getEmployeePaymentById : ", res.data.detail.data);
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server getEmployeePaymentById : ", res.data.detail.data);        
          paymentDetails = res.data.detail.data;
          retState = true;
        }
       
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log(
            "Not able to reach target server please try after sometime"
          );
        } else {
          console.log("ERROR : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log(
              "Request failed due to ",
              error.response.data.detail.res_data
            );
          }
        }
      }
      return [retState, paymentDetails];
  }
  */
  const getCustReceipt = async (id_pass) => {
    var retStateLoad = false;
    var headerRet = [];
    var titleRet = [];
    var branchAddressRet = [];
    var businessAddressRet = [];
    var receiptRet = [];
    var paramsRet = [];
    var settingRet = [];
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      //console.log("Selected Customer :", customerIdSelect);
      var reqParams = {
        id: id_pass,

        
      };
      console.log("REQUEST PARAMS: ", reqParams );
      var res = await axios.post(client.domain + "/bms/getCustRcptPrint", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server Invoice : ",res.data.detail.data );
        //setReportHeader(res.data.detail.data.ReportHeader);
        setReportTitle(res.data.detail.data.report_title);
        setBusinessAddress(res.data.detail.data.business_address);
        setBranchAddress(res.data.detail.data.branch_address);
        setReceipt(res.data.detail.data.receipt);
        setPrintParams(res.data.detail.data.report_print_params);
        setSetting(res.data.detail.data.report_print_setting);
        //setToRender(true);
        //headerRet = res.data.detail.data.ReportHeader;
        titleRet = res.data.detail.data.report_title;
        branchAddressRet = res.data.detail.data.branch_address;
        businessAddressRet = res.data.detail.data.business_address;
        receiptRet = res.data.detail.data.receipt;
        paramsRet = res.data.detail.data.report_print_params;
        settingRet = res.data.detail.data.report_print_setting;
        
       
        retStateLoad = true;
        //navigate("/dashboard");
        //setDcReportData(res.data.detail.res_data);
        //setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log( "Not able to reach target server please try after sometime");
        //setShowMsg("Please try after sometime");
        //setShowMsgTitle("Network Issue!");
        //setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log( "Request failed due to ",error.response.data.detail.res_data);
          //setShowMsg("Please try again ...");
          //setShowMsgTitle("Unable to process your request!");
          //setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          //setShowMsg(error.response.data.detail);
          //setShowMsgTitle("Unable to process your request at this moment!");
          //setShow(true);
        }
      }
    }
    return [retStateLoad, receiptRet, headerRet, titleRet, paramsRet, settingRet];
  };
  

  useEffect(() => {
    console.log("useEffect method called ...");
    
    if (!initialized.current) {
    console.log("CALLED CURRENT PAGE : ", window.location.href);

    console.log("CALLED window locaion : ", window.location);
    let params = (new URL(window.location)).searchParams;
    console.log("COMPLETE PARAMS : ", params);
    
    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.indexOf('?') + 1);
    var urlTmp = urlStr.substring(0, urlStr.indexOf("?"));

    console.log("URL : ", urlTmp);
    console.log("URL : ", paramsTmp);

    let searchParams = new URLSearchParams(paramsTmp);
    console.log("SEARCH PARAMS :", searchParams );
    var id = searchParams.get("id");
    console.log("^^^^^^^^^^^^^^^^^^^  ID : ", id);
  

    (async () => {
      initialized.current = true;
      var [retStateLoad, receiptRet, paramsRet,titleRet, branchAddressRet, businessAddressRet, settingRet] = await getCustReceipt(id);
      setToRender(true); 
    })();
  }
    
  }, [])

  return (
    <PDFViewer style={styles.viewer}>
      {toRender === true &&
        <Document>
          <Page size="A4" orientation="portrait" style={styles.page}>

          <View style={{ flexDirection: 'row', borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR }}>
              {setting.is_print_logo === true &&
                <View style={{ textAlign: 'right', width: '15%' }}>
                  <Image style={{ marginHorizontal: '10%', width: 'auto', height: 'auto', height: "64px", width: "64px" }} src={business_logo} />
                </View>
              }
                <View style={{ width: "85%", alignItems: "center", /*paddingRight: "30px",*/ /*borderBottomWidth: 1,*/ borderBottomColor: BORDER_COLOR/*,marginTop: 1, marginBottom: 10, borderWidth: 1*/ }} key="address-key">
                  <Text style={{ fontSize: "20px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>{businessAddress.business_name}</Text>
                  {/*<Text>{reportHeader.business_taxdetails}</Text>*/}
                  <Text>{businessAddress.address_line1}, {businessAddress.address_line2},{businessAddress.district}, {businessAddress.state}, {businessAddress.country}, {businessAddress.pincode}</Text>
                  {/*<Text>{businessAddress.district}, {businessAddress.state}, {businessAddress.country}, {businessAddress.pincode}</Text>*/}
                  <Text><Text style={{fontFamily: 'Helvetica-Bold'}}>Mobile :</Text>{businessAddress.mobile}, <Text style={{fontFamily: 'Helvetica-Bold'}}>Phone :</Text>{businessAddress.phone}</Text>
                  <Text><Text style={{fontFamily: 'Helvetica-Bold'}}>GSTIN :</Text>{businessAddress.gstin} {/*<Text style={{fontFamily: 'Helvetica-Bold'}}>PAN :</Text>{businessAddress.pan}*/}</Text>
                  <hr style={{paddingBottom: "5px"}}/>
                </View>  
              </View>
            
           
            
            <View style={{ width: "100%", alignItems: "center",/* paddingLeft: "20px",*/ textAlign: 'center', borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR, paddingTop: "5px" }}>
              <Text style={{ fontSize: "10px", textAlign: 'center', alignContent: 'center', fontFamily: 'Helvetica-Bold', color: HEADING_COLOR }}>{reportTitle.rpt_title}</Text>
            </View>
            <hr style={{paddingBottom: "5px"}}/>
          

           
            <View>
            <View style={{flexDirection: 'row',textAlign: 'left',paddingBottom: "20px"}}>
                <Text style={{fontFamily: 'Helvetica',  alignItems: "left",textAlign: 'left'}}>Receipt Date </Text> 
                <Text style={{  alignItems: "left",textAlign: 'left',paddingLeft:"41px"}}> : {receipt.rcpt_date}</Text>     
                </View> 
                
                <View style={{flexDirection: 'row',textAlign: 'left',paddingBottom: "20px"}}>
                  <Text style={{fontFamily: 'Helvetica', alignItems: "left",textAlign: 'left'}}>Receipt No  </Text>
                 
                  <Text style={{  alignItems: "left",textAlign: 'left',paddingLeft:"45px"}}>: {receipt.rcpt_no}</Text>
                  </View> 
                  </View>
                  
            {/*
            <View style={{ width: "100%", alignItems: "left", textAlign: 'left' , borderBottomWidth: 1, paddingTop: "10px", borderBottomColor: '#646363'}}>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica', borderBottomWidth: 1, paddingBottom: "2px", borderBottomColor: '#646363'}}>Paid To M/S. <Text style={{fontFamily: 'Helvetica-Bold', fontSize: "10px"}}>{paymentDetails.paid_to_name}</Text></Text> 
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica', borderBottomWidth: 1, paddingTop: "10px", borderBottomColor: '#646363' }}>a sum of Rupees <Text style={{fontFamily: 'Helvetica-Bold', fontSize: "10px"}}>{convertNumberToWords(paymentDetails.amount)}</Text></Text>
              <View style={{ flexDirection: 'row', borderBottomWidth: 1, paddingTop: "10px", borderBottomColor: '#646363'}}>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica', width: '50%'  }}>by <Text style={{fontFamily: 'Helvetica-Bold', fontSize: "10px"}}>{paymentDetails.payment_mode}</Text> </Text>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica', width: '15%'  }}>dated on </Text>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica', width: '20%'  }}><Text style={{fontFamily: 'Helvetica-Bold', fontSize: "10px"}}>{formatDateIndian(new Date(paymentDetails.ref_date))}</Text></Text>
              </View>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica', paddingTop: "10px",}}>For <Text style={{fontFamily: 'Helvetica-Bold', fontSize: "10px"}}>{paymentDetails.towards}</Text></Text>
            </View>
            */}

            <View style={{flexDirection: 'row', }}>
               <Text style={{width: '20%'}}>Received From</Text>
               <Text style={{width: '80%', borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR, borderBottomStyle: "dashed"}}>: {receipt.customer_name}</Text>
            </View>
            <hr style={{paddingBottom: "20px"}}/>

            <View style={{flexDirection: 'row', }}>
               <Text style={{width: '20%'}}>Received Amount</Text>
               <Text style={{width: '80%', borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR, borderBottomStyle: "dashed"}}>: {receipt.rcpt_amt}</Text>
            </View>
            <hr style={{paddingBottom: "20px"}}/>

            <View style={{flexDirection: 'row'}}>
               <Text style={{width: '20%'}}>Amount in words </Text>
               {receipt.rcpt_amt > 0.00 ?
               <>
               <Text style={{width: '80%', borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR,  borderBottomStyle: "dashed"}}>: {convertNumberToWords(receipt.rcpt_amt)}</Text>
               </>
               :
               <Text style={{width: '80%', borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR,  borderBottomStyle: "dashed"}}>: {}</Text>
               
               }
               </View>
            <hr style={{paddingBottom: "20px"}}/>

            <View style={{flexDirection: 'row'}}>
               <Text style={{width: '20%'}}>Received Mode </Text>
               <Text style={{width: '80%', borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR, borderBottomStyle: "dashed"}}>: {receipt.rcpt_mode_text}</Text>
            </View>
            <hr style={{paddingBottom: "20px"}}/>

            <View style={{flexDirection: 'row'}}>
               <Text style={{width: '20%'}}>Reference No </Text>
               <Text style={{width: '80%', borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR, borderBottomStyle: "dashed"}}>: {receipt.ref_no}</Text>
            </View>
            <hr style={{paddingBottom: "20px"}}/>

            <View style={{flexDirection: 'row'}}>
               <Text style={{width: '20%'}}>Reference Date </Text>
               <Text style={{width: '80%', borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR, borderBottomStyle: "dashed"}}>: {receipt.ref_date}</Text>
            </View>
            <hr style={{paddingBottom: "20px"}}/>

            <View style={{flexDirection: 'row'}}>
               <Text style={{width: '20%'}}>Received For </Text>
               <Text style={{width: '80%', borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR, borderBottomStyle: "dashed"}}>: {receipt.received_for}</Text>
            </View>
            <hr style={{paddingBottom: "20px"}}/>

            
           

            
            <View>
            { printParams.find(element => (element.code === 'ForBusinessName')) &&
              
              <Text style={{  paddingLeft: "360px",paddingBottom: "30px",}} >For {JSON.parse(localStorage.getItem('selected_business')).label}</Text>
              
              }
            </View>

            <View>
              { printParams.find(element => (element.code === 'AuthorisedSignatory')) &&
              <Text style={{paddingTop: "2px", paddingLeft: "367px"}} >{(printParams.find(element => (element.code === 'AuthorisedSignatory'))).text}</Text>
              }              
            </View>

           
            <hr style={{paddingBottom: "20px"}}/>

            <View fixed style={{flexDirection: 'row'}}>
        
        {setting.is_print_datetime === true &&
          <Text style={{textAlign: 'left', flex: 1, paddingTop: '15px'}} render={({ pageNumber, totalPages }) => (`Printed On: ${formatPrintDate(new Date())}`)} fixed />
        }

          
        {setting.is_print_endofrpttext === true &&
          <Text style={{ textAlign: 'center', paddingTop: '15px', flex: 1}} render={({ pageNumber, totalPages }) => (
            <View> {pageNumber === totalPages &&
            <Text>.... End of the document ....</Text>
             }
            </View>
            )}  />
          }

          
          {setting.is_print_pageno === true &&
          <Text style={{flex: 1, textAlign: 'right', paddingTop: '15px'}} render={({ pageNumber, totalPages }) => (
            `Page No : ${pageNumber} / ${totalPages}`
          )} fixed />
           }
          
          </View>

          

            
           
            {/*
              <View style={{flexDirection: 'row'}}>
            
            <Text style={{textAlign: 'left', flex: 1}} render={({ pageNumber, totalPages }) => (
                `Printed On: ${formatPrintDate(new Date())}`
            )} fixed />
             
             
              </View> 
          */}
           
            

           
           
              

          </Page>
        </Document>
      }
    </PDFViewer>
  );
}

export default PrintCustReceipt;