import { React, Fragment, useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  Badge,
  Modal,
  Spinner,
  Image
} from "react-bootstrap";

//import Sidebar2 from "./Sidebar2";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import "../Dashboard.css";
import "../App.css";
import { client,appGlobal } from "../config/Config";
import axios from "axios";


import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsTrashFill,
  BsArrowLeftCircle,
  BsFillPenFill,
} from "react-icons/bs";
import SidebarMain from "./SidebarMain";
import {getDistrictList, getStateList } from "./utils";
import spinner_logo from '../assets/images/spinner_logo.png';



//function AddUpdateCustomer () {
const AddUpdateUnit = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  let initialItems = [];
  const intialValues = {
    unit: "",
    description: "",
  };

 
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});

  const [validateError, setValidateError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);

  const [isReadOnly, setIsReadOnly] = useState(true);
  const [toRender, setToRender] = useState(false);

  const [show, setShow] = useState(false);
 // const [showMsg, setShowMsg] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  
  //const [selectedId, setSelectedId] = useState(-1);
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );
  const [submitAction, setSubmitAction] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [checkState, setCheckState] =  useState(true);
  const [refresh, setRefresh] =  useState(false);
  

  var validateErr = false;

  

  const handleOnChange = (e) => {
    //e.preventDefault();
    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);

    if (name === "phone" || name === "contact_person_number" || name === "pincode")  {
      console.log("Inside check ******************");
      //const numericRegExp = /^(?:[1-9]\d*|\d)$/;
      if (e.target.value.match(appGlobal.wholeNumberRegExp)) {
        //var valueTmp = Math.round(e.target.value.replace(/\D/g, ""));
        setFormValues({ ...formValues, [name]: e.target.value });
      }
    } 
    else if (name === "opening_balance") {
     if ( e.target.value.match(appGlobal.numericRegExp) ) {
      setFormValues({ ...formValues, [name]: value });
     }
    }
    else {
      setFormValues({ ...formValues, [name]: value });
    }
    /*
    {
      console.log("Inside check ******************");
     
      var valueTmp = e.currentTarget.value.replace(/[^\d]/g, '');
    
      setFormValues({ ...formValues, [name]: valueTmp });
      //}
    } else {
      setFormValues({ ...formValues, [name]: value });

    }

    */
    
  };
  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    if ( values.phone ) {
    console.log("phone number length : ", values["phone"].length);
    }
    if ( values.pincode ) {
      console.log("pincode number length : ", values["pincode"].length);
    }

    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.name) {
      errors.name = "Name is required!";
      validateErr = true;
      //setValidateError(true);
    }
    /*
    if (!values.contact_person) {
      errors.contact_person = "Contact person is required!";
      validateErr = true;
      //setValidateError(true);
    }
    */

    if (!values.address_line1) {
      errors.address_line1 = "Street Or Location is required!";
      validateErr = true;
      //setValidateError(true);
    }
    /*
    if (!values.pincode) {
      errors.pincode = "Pincode is required!";
      validateErr = true;
      setValidateError(true);
    }
    */
    if (values.pincode && values.pincode.length != 6) {
      errors.pincode = "Pincode should be 6 digits!";
      validateErr = true;
      setValidateError(true);
    }
    /*
    if (!values.phone) {
      errors.phone = "Phone number is required!";
      validateErr = true;
      //setValidateError(true);
    }
    if (!values.contact_person_number) {
      errors.contact_person_number = "Contact Number is required!";
      validateErr = true;
      setValidateError(true);
    }
    */

    if (values.phone && values.phone.length != 10) {
      errors.phone = "Phone number should be 10 digits!";
      validateErr = true;
      //setValidateError(true);
    }
    

    

    if (values.contact_person_number && values.contact_person_number.length != 10) {
      errors.contact_person_number = "Contact Number should be 10 digits!";
      validateErr = true;
      setValidateError(true);
    }
  
 
    //console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ",
      validateErr
    );
    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
      //console.log("LINE ITEMS : ", items);
      //callUpdateApi();
     
    }
  };
    

  
  
  
 
  

  const handleEdit = async (e) => {
    console.log("handle edit  clicked : ", e);
    setIsReadOnly(false);
  };
  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
    navigate(-1);
  };

  const handleReset = async (e) => {
    console.log("handle reset clicked : ", e);
    navigate(0);
  };
  
  const handleCloseDialog = () => {
    setShowDialog(false);
    navigate("/ListUnits");
  };
  
 


  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::addUpdateUnit called ##########################"
      );
      
      if (state === null) {
        navigate("/listUnits");
      } else {
        console.log("PASSED STATE VALUE IN Employee Add/Update page : ", state);
         console.log("Inside else for view or edit ...");
           
        if (state.action === "add") {
          
        }
        else {
          setFormValues(state.passed_element);
        }
        
         setToRender(true);
       
        console.log("newObj :", formValues);
        if (state.action === "add") {
          setIsReadOnly(false);
        }
      }
      
      initialized.current = true;
    }
  }, []);

 /*
  const callUpdateApi = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var apiToCall = "";
      if (state.action === "add") {
        apiToCall = "/crm/addCustomer";
      } else if (state.action === "view") {
        apiToCall = "/crm/updateCustomer";
      } else {
        apiToCall = "/crm/addCustomer";
      }

      var reqParam = {
        id: formValues["id"],
        name: formValues["name"] || "",
        display_name: formValues["name"] || "",
        contact_person: formValues["contact_person"],
        contact_person_number: formValues["contact_person_number"],
        gst_no: formValues["gst_no"] || "",
        phone: formValues["phone"] || "",
        contact_number_primary: formValues["contact_number_primary"] || "",
        Contact_number_secondary: formValues["contact_number_secondary"] || "",
        email: formValues["email"] || "",
        website: formValues["website"] || "",
        address_line1: formValues["address_line1"] || "",
        address_line2: formValues["address_line2"] || "",
        district_id: districtIdSelect.value,
        pincode: formValues["pincode"] || "",
        opening_balance: formValues["opening_balance"] || 0.00
      };
      var res = await axios.post(client.domain + apiToCall, reqParam, { headers }, { withCredentials: false });
     
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        setShowMsg(res.data.detail.data.message);
        setShowMsgTitle("Success");
        setShowDialog(true);
        setSubmitAction(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          
        }
      }
      setSubmitAction(false);
    }
  };
  */
  const handlePostSaveOk = () => {
    console.log("user confirmed to customer added ...");
    setShowDialog(false);
    navigate("/listCustomers");
  };
  const handleSave = async (e) => {
    e.preventDefault();
    console.log("Handle Click save called : ", e);
    console.log("Formvalues : ", formValues);

    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (validateErr === false) {
      setSubmitAction(true);
     // callUpdateApi();
     
    }
  };

  const handleCheckBox = async (e) => {
    console.log("handle copy to billing called :", e);
    console.log("handle copy to billing called CHECKED :", e.target.checked); 
    setCheckState(e.target.checked);
    if (state.action === "add"){
      setCheckState(true);
    }
    else{
      setCheckState(e.target.checked);
    }
    setRefresh(true);
  };

 
  console.log("formvalues FULL : ", formValues);

  
 

  return (
    <>
      <Container>
        <SidebarMain />
        { toRender ?
        (
        <Form>
          <Row>
            <Col xs={4} md={4} sm={4} lg={4}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>Unit</span>
            { (state.action === 'view' || state.action === 'edit') &&
              <>
              &nbsp;&nbsp;
             
            <Button variant={state.passed_element.is_active ? "outline-success" : "outline-danger"} size="sm"><span style={{cursor: "default", fontWeight: "500"}}>{state.passed_element.status}</span></Button>
            </>
            }
            
            </Col>
            
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
              className="float-end justify-content-end"
            >
              <div className="d-flex justify-content-end">
                <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                  <BsArrowLeftCircle
                    key={`back-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                   
                    name={`back-icon-patient-name`}
                    id={`edit-icon-patient-id`}
                  />
                </Button>
                {(state.action === "edit" || state.action === "view") && (
                  <>
                &nbsp;&nbsp;
                <Button variant="primary" className="btn btn-primary btn-md"  onClick={(e) => handleEdit(e)}>
                  <BsPencilSquare
                    key={`edit-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                  
                    name={`edit-icon-paBiSolidEditAlttient-name`}
                    id={`edit-icon-doctor-id`}
                  />
                </Button>
                </>
                )}
              </div>
            </Col>
          </Row>

          <div className="ui-divider"></div>
          <div className="ui-form">
            <hr align="center" />
            

            <Row>
            <Col xs={5} md={5} sm={5} lg={5}>
                <Form.Group className="mb-3">
                  <Form.Label>Unit</Form.Label>
                  <Form.Control
                    type="text"
                    id="unit"
                    name="unit"
                    //className="mobNum"
                    placeholder="Unit"
                    onChange={handleOnChange}
                    readOnly={isReadOnly}
                   // maxLength={10}
                    value={formValues["unit"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.unit}</p>
              </Col>
             
              <Col xs={5} md={5} sm={5} lg={5}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Description
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="description"
                    name="description"
                    placeholder="Description"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["description"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.description}</p>
              </Col>
              </Row>
              
            
            {!isReadOnly && (
              <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">

                  <Form.Check
                            style={{paddingTop: "5px"}}
                            label="Active"
                            name="checkbox"
                            id="id-addr-copy-bill"
                            type="checkbox"
                            className="checkCopy"
                            //defaultChecked=
                            checked={checkState}
                            onChange={handleCheckBox}
                          ></Form.Check>
                          &nbsp;&nbsp;
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
              
            
                    <Button
                      type="submit"
                      id="save"
                      onClick={(e) => handleSave(e)}
                      disabled={submitAction ? true: false}
                    >
                     {submitAction && 
                      <>
                      <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                     <span className="visually-hidden">Loading...</span>
                     </>
                    }
                      
                     Save
                    </Button>
                  </div>
                </Col>
                
              </Row>
            )}
            
           
            <Modal
              show={showDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{showMsgTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{showMsg}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          </Form>
     )
        :
        <div className="container-spinner">
        <div className="vertical-center-spinner">
         <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>  
         
                <span className="visually-hidden">Loading...</span>
              </Spinner>
         </div>
         </div>
      }
       
      </Container>
      </>
  );
};

export default AddUpdateUnit;
