import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { client } from '../config/Config';

//import StockDocument from "./StockDocument";
import {formatDateIndian, convertNumberToWords } from "./utils";

import _ from "lodash";

//import avinaoffice_logo from '../avinaoffice_logo.png';
import business_logo from '../assets/images/business_logo.png';


const borderColor = '';
//const rowItemHeight = 18;
const MAX_CHAR_PER_ROW = 40;
const MAX_CHAR_PER_ROW_DATA = 30;
const NTIMES_SIZE = 0.65;
const itemRowHeight = 18;
const MULTIPLY_PERC = 0.75;
const ADDR1_ITEM_ROW_HEIGHT = 50;
const ADDR_ITEM_ROW_HEIGHT = 45;
const ADDR3_ITEM_ROW_HEIGHT = 30;
const BORDER_COLOR = '#DCDCDC';
const BORDER_COLOR2 = "#C8C8C8";
const CONTAINER_BG_COLOR = "#F0F0F0";
const BORDER_WIDTH = 0.5;
const SIZE_OF_NEWLINE = 5;
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 9,
    paddingTop: 10,
    paddingLeft: 25,
    paddingRight: 25,
    paddingBottom: 10,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    //width: '100%',
    //height: '33%',
    width: '100%',
    height: '100%',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logo_image2: {
    width: '15%',
    height: '98px',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  business_content: {
    width: '60%',
    height: '98px',
    //paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container2: {
    //paddingBottom:0,
    flexDirection: 'row',
    //borderBottomColor: '#bff0fd',
    //backgroundColor: '#bff0fd',
    //borderBottomWidth: 1,
    //alignItems: 'center',
    //height: 12,
    //textAlign: 'center',
    //fontStyle: 'bold',
    //flexGrow: 1,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  

  tableContainer2: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    //marginTop: BORDER_WIDTH,
    //marginBottom: 10,
    borderWidth: BORDER_WIDTH,
    borderColor: BORDER_COLOR
   // borderColor: '#a8a8a8',

  },
  container: {
    flexDirection: 'row',
    //borderBottomColor: '#a8a8a8',
    //backgroundColor: '#dcdcdc',
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    alignItems: 'center',
    height: 48,
    textAlign: 'center',
    fontStyle: 'bold',
    //flexGrow: 1,
    //flexWrap: 'wrap',
    //marginTop: 1,
    //marginBottom: 10,
    //borderWidth: 1,
    //borderWidth: 1,
    //marginBottom: 5,
  },

  container1: {
    flexDirection: 'row',
    //borderBottomColor: '#a8a8a8',
    //backgroundColor: CONTAINER_BG_COLOR,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    //borderTopWidth: 1,
    alignItems: 'center',
    //height: 20,
    textAlign: 'center',
    //fontStyle: 'bold',
    //flexGrow: 1,
    //flexWrap: 'wrap',
    //marginTop: 1,
    //marginBottom: 10,
    //borderWidth: 1,
    //borderWidth: 1,
    //marginBottom: 5,
  },

  container2: {
    flexDirection: 'row',
    //borderBottomColor: '#a8a8a8',
    //backgroundColor: CONTAINER_BG_COLOR,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: BORDER_COLOR,
    //borderTopWidth: 1,
    alignItems: 'center',
    height: 20,
    textAlign: 'center',
    //fontStyle: 'bold',
    //flexGrow: 1,
    //flexWrap: 'wrap',
    //marginTop: 1,
    //marginBottom: 10,
    //borderWidth: 1,
    //borderWidth: 1,
    //marginBottom: 5,
  },
  row: {
    flexDirection: 'row',
    //borderBottomColor: '#a8a8a8',
    //borderBottmWidth: 1,
    alignItems: 'center',
    //height: 24,
    fontStyle: 'bold',
  },
  

  
 

tableContainer1: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderWidth: BORDER_WIDTH,
    borderColor: BORDER_COLOR
    

  },

  sno: {
    width: '5%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop:"2px"
   // paddingLeft:10
   
  },
  item: {
    width: '25%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop:"2px"
    //paddingRight:90
    //paddingLeft:10
  },
  
  unit: {
    width: '15%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop:"2px"
   // paddingLeft:10
   
  },
  total_qty: {
    width: '15%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop:"2px"
    //paddingRight:90
    //paddingLeft:10
  },
  balance_qty: {
    width: '15%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop:"2px"
   // paddingLeft:10
   
  },
  used_qty: {
    width: '15%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop:"2px"
    //paddingRight:90
    //paddingLeft:10
  },

  yield_qty: {
    width: '10%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop:"2px"
    //paddingRight:90
    //paddingLeft:10
  },
  snoData: {
    width: '5%',
    fontSize: "9px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'right',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingRight:"2px"
    //paddingTop:"5px"
   // paddingLeft:10
   
  },
  itemData: {
    width: '25%',
    fontSize: "9px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'left',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingLeft:"2px",
    paddingTop:"2px",
    //paddingRight:90
    //paddingLeft:10
  },
  
  unitData: {
    width: '15%',
    fontSize: "9px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'left',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingLeft:"2px",
    paddingTop:"2px",
   // paddingLeft:10
   
  },
  total_qtyData: {
    width: '15%',
    fontSize: "8px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'right',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingRight:"2px",
    paddingTop:"2px",
    //paddingRight:90
    //paddingLeft:10
  },
  used_qtyData: {
    width: '15%',
    fontSize: "8px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'right',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingRight:"2px",
    paddingTop:"2px"
   // paddingLeft:10
   
  },
  balance_qtyData: {
    width: '15%',
    fontSize: "8px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'right',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingRight:"2px",
    paddingTop:"2px"
    //paddingRight:90
    //paddingLeft:10
  },

  yield_qtyData: {
    width: '10%',
    fontSize: "8px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'right',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingRight:"2px",
    paddingTop:"2px"
    //paddingRight:90
    //paddingLeft:10
  },


  sno2: {
    width: '5%',
    fontSize: "9px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop:"2px"
   // paddingLeft:10
   
  },
  item2: {
    width: '35%',
    fontSize: "9px",
    //borderRigColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop:"2px"
    //paddingRight:90
    //paddingLeft:10
  },
  
  date: {
    width: '10%',
    fontSize: "9px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop:"2px"
   // paddingLeft:10
   
  },
  no: {
    width: '20%',
    fontSize: "9px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop:"2px"
    //paddingRight:90
    //paddingLeft:10
  },
  unit2: {
    width: '15%',
    fontSize: "9px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop:"2px"
   // paddingLeft:10
   
  },
  /*
  qty2: {
    width: '15%',
    fontSize: "9px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop:"2px"
    //paddingRight:90
    //paddingLeft:10
  },
  */

  qty2_yield: {
    width: '15%',
    fontSize: "9px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'center',
    height: itemRowHeight,
    paddingTop:"2px"
    //paddingRight:90
    //paddingLeft:10
  },
  sno2Data: {
    width: '5%',
    fontSize: "9px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'right',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingRight:"2px"
    //paddingTop:"5px"
   // paddingLeft:10
   
  },
  item2Data: {
    width: '35%',
    fontSize: "9px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'left',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingLeft:"2px",
    paddingTop:"2px",
    //paddingRight:90
    //paddingLeft:10
  },
  
  noData: {
    width: '20%',
    fontSize: "9px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'left',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingLeft:"2px",
    paddingTop:"2px",
   // paddingLeft:10
   
  },
  dateData: {
    width: '10%',
    fontSize: "9px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'left',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingLeft:"2px",
    paddingTop:"2px",
    //paddingRight:90
    //paddingLeft:10
  },
  unit2Data: {
    width: '15%',
    fontSize: "9px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'left',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingLeft:"2px",
    paddingTop:"2px"
   // paddingLeft:10
   
  },
  /*
  qty2Data: {
    width: '15%',
    fontSize: "8px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'right',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingRight:"2px",
    paddingTop:"2px"
    //paddingRight:90
    //paddingLeft:10
  },
  */

  qty2_yieldData: {
    width: '15%',
    fontSize: "8px",
    //borderRightColor: borderColor,
    borderRightWidth: BORDER_WIDTH,
    borderRightColor: BORDER_COLOR,
    textAlign: 'right',
    //borderBottomWidth:1,
    //borderBottomColor: '#ffffff',
    height: itemRowHeight,
    paddingRight:"2px",
    paddingTop:"2px"
    //paddingRight:90
    //paddingLeft:10
  },
  
});

const PrintCustomerDCRecon = (props) => {
    /*
    let invArr = [
        {
       description: "M5",
       hsn:"566125",
       quantity:"5",
       rate:"4500",
       per: "2",
       amount:"9000"
        },
        {
            description: "M8",
            hsn:"1234456",
            quantity:"2",
            rate:"6700",
            per: "6",
            amount:"4563"
             },
             {
                description: "lsgvncxmk nxcjfsdkj tyure yueie",
                hsn:"6775443",
                quantity:"8",
                rate:"7865",
                per: "3",
                amount:"7865"
                 },
                 {
                  description: "M5",
                  hsn:"57546",
                  quantity:"9",
                  rate:"3452",
                  per: "1",
                  amount:"3000"
                   },
                   {
                    description: "M7",
                    hsn:"89765",
                    quantity:"5",
                    rate:"2007",
                    per: "8",
                    amount:"5674"
                     }
       
      ];
      */
      
      const default_setings = {
        "is_print_logo": true,
        "is_print_tag_text": true,
        "is_print_datetime": false,
        "is_print_endofrpttext": false,
        "is_print_pageno": false,
        "print_copies": 3
    };
 
  const [reportData, setReportData] = useState({});
  const [toRender, setToRender] = useState(false);
  //const [invoiceList, setInvoiceList] = useState(invArr);
  const [companyInfo, setCompanyInfo] = useState({});
  const [invList, setInvList] = useState([]);
  const [reportHeader, setReportHeader] = useState({});
  const [reportTitle, setReportTitle] = useState({});
  const [dC, setDC] = useState({});
  const [printParams, setPrintParams] = useState([]);
  const [setting, setSetting] = useState({});
  const [OUTDCItems, setOUTDCItems] = useState([]);
  const [INDCItems, setINDCItems] = useState([]);
  const initialized = useRef(false);

  
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  const formateTime = (currTime) => {
    var h = (currTime.getHours() < 10 ? "0" : "") + currTime.getHours();
    var m = (currTime.getMinutes() < 10 ? "0" : "") + currTime.getMinutes();
    var hrMin = h + ":" + m;
    return hrMin;
  };

  const formatPrintDate = (currTime) => {
    var dateComponent = [
      padTo2Digits(currTime.getDate()),
      padTo2Digits(currTime.getMonth() + 1),
      currTime.getFullYear(),
    ].join("-");

    var timeComponent = [
      padTo2Digits(currTime.getHours() % 12 || 12 ),
      padTo2Digits(currTime.getMinutes())
    ].join(":");

    const ampm = currTime.getHours() >= 12 ? 'PM' : 'AM';

    return dateComponent + " " + timeComponent + " " + ampm;
  }
 /*
  const getTaskList = async() => {
    //console.log("getPaymentList method called ...");
    //console.log("start date   : ", start_date);
   // console.log("end date     : ", end_date);
    //console.log("paid to  : ", paid_to);
    //console.log("expense category : ", expense_category_id);
    var retState = false;
    var retTaskList = [];
    try {
      var aIVal = localStorage.getItem("_aI");
      console.log("Authorization token : ", aIVal);

      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}

      console.log("Headers : ", headers);

      
      var res = await axios.get(client.domain + `/pmo/getAllTasks?`, {headers}, {
        withCredentials: false,
      });
     
      console.log("Response from server getTaskList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getTaskList : ", res.data.detail.data);
       if (res.data.detail.data.length > 0) {
          setTaskList(res.data.detail.data);
          //setReceiptListFull(res.data.detail.data);
          retTaskList = res.data.detail.data;
        }
        retState = true;
        setToRender(true);
      }
      
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
    setToRender(true);
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ",error.response.data.detail.res_data);
        }
      }
    }
    setToRender(true);

    return [retState, retTaskList];
  };
  */



  const getDCPrint = async (bid_pass, brid_pass, indc_id_pass) => {
    var retStateLoad = false;
    var paramsRet = [];
    var titleRet = [];
    var settingRet = [];
    var headerRet = [];
    var dcRet = []; 
    var indcRet = [];
    var outdcRet = [];

    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      //console.log("Selected Customer :", customerIdSelect);
      var reqParams = {
        //id: id_pass,
        bid: bid_pass,
        brid: brid_pass,
        indc_id: indc_id_pass


      };
      console.log("REQUEST PARAMS: ", reqParams );
      var res = await axios.post(client.domain + "/bms/getCustomerDCReconPrint", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server Invoice : ", res.data.detail.data );
        setReportHeader(res.data.detail.data.report_header);
        setPrintParams(res.data.detail.data.report_print_params);
        setDC(res.data.detail.data.customer_indc);
        setReportTitle(res.data.detail.data.report_title);
        setINDCItems(res.data.detail.data.customer_indc_items);
        setOUTDCItems(res.data.detail.data.customer_outdc_items);
        setSetting(res.data.detail.data.report_print_setting);
        //setCustomerListFull(res.data.detail.data);
        //setShowMsgTitle("Customer Info Added Successfully");
        //setToRender(true);
        paramsRet = res.data.detail.data.report_print_params;
        titleRet = res.data.detail.data.report_title;
        dcRet = res.data.detail.data.customer_indc;
        headerRet = res.data.detail.data.report_header;
        indcRet = res.data.detail.data.customer_indc_items;
        outdcRet = res.data.detail.data.customer_outdc_items;
        settingRet = res.data.detail.data.report_print_setting;
        retStateLoad = true;
        //navigate("/dashboard");
        //setDcReportData(res.data.detail.res_data);
        //setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log( "Not able to reach target server please try after sometime");
        //setShowMsg("Please try after sometime");
        //setShowMsgTitle("Network Issue!");
        //setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log( "Request failed due to ",error.response.data.detail.res_data);
          //setShowMsg("Please try again ...");
          //setShowMsgTitle("Unable to process your request!");
          //setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          //setShowMsg(error.response.data.detail);
          //setShowMsgTitle("Unable to process your request at this moment!");
          //setShow(true);
        }
      }
    }
    
    return [retStateLoad, paramsRet,titleRet, settingRet, headerRet, dcRet, indcRet, outdcRet];
  };
  
  
  useEffect(() => {
    console.log("useEffect method called ...");
    if (!initialized.current) {
    console.log("CALLED CURRENT PAGE : ", window.location.href);

    console.log("CALLED window locaion : ", window.location);
    let params = (new URL(window.location)).searchParams;
    console.log("COMPLETE PARAMS : ", params);
    
    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.indexOf('?') + 1);
    var urlTmp = urlStr.substring(0, urlStr.indexOf("?"));

    console.log("URL : ", urlTmp);
    console.log("URL : ", paramsTmp);

    let searchParams = new URLSearchParams(paramsTmp);
    console.log("SEARCH PARAMS :", searchParams );
    var bid = searchParams.get("bid");
    console.log("^^^^^^^^^^^^^^^^^^^ BUSINESS ID : ", bid);
    
    var brid = searchParams.get("brid");
    console.log("^^^^^^^^^^^^^^^^^^^ BUSINESS BRANCH0 ID : ", brid);

    var indc_id = searchParams.get("indc_id");
    console.log("^^^^^^^^^^^^^^^^^^^ INDC ID : ", indc_id);

    (async () => {
      initialized.current = true;
      var [retStateLoad, paramsRet, titleRet, settingRet, headerRet, dcRet, indcRet, outdcRet] = await getDCPrint(bid, brid, indc_id);
      setToRender(true);
    })();
    }
    
  }, [])

  const calAddDetailsLength = () => {
    /*
    var column1 = "Dispatch Doc No" + addlDetails.dispatch_doc_no + "Dispatch through"  + addlDetails.dispatch_through + "Bill of Lading/Lr.RR No." + addlDetails.bol_no + "Dated :" + addlDetails.bol_date;

    var column2 = "Delivery Note Date :" + addlDetails.delivery_note_date + "Destination" + addlDetails.destination + "Motor Vehicle No." + addlDetails.vehicle_no;
    
    if ( column1.length + (4 * 22) > column2.length + (3 * 22) ) {
      return column1.length + (4 *  22);
    }
    else {
      return column2.length + (3 * 22);
    }
    */
  }
  
  var addLenRet = calAddDetailsLength();
   console.log("<<<<<<<<<<< LENGTH >>>>>>>>>>>:", addLenRet);

   addLenRet = 180;
  var neededHeight = (addLenRet / MAX_CHAR_PER_ROW ) * ADDR3_ITEM_ROW_HEIGHT;
  console.log("+++++++++++ NEEDED HEIGHT------------- : ", neededHeight);

  return (
    <PDFViewer style={styles.viewer}>
      {toRender === true &&
        <Document>
          {_.times(setting.print_copies, (idx) => (
          <Page size="A4" orientation="portrait" style={styles.page}>
            <View style={{ flexDirection: 'row', borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR }}>
              {setting.is_print_logo === true &&
                <View style={{ textAlign: 'right', width: '15%' }}>
                  <Image style={{ marginHorizontal: '10%', width: 'auto', height: 'auto', height: "64px", width: "64px" }} src={business_logo} />
                </View>
              }
                
                <View style={{ width: "85%", alignItems: "center", /*paddingRight: "30px",*/ /*borderBottomWidth: 1,*/ /*,marginTop: 1, marginBottom: 10, borderWidth: 1*/ }} key="address-key">
                  <Text style={{ fontSize: "20px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>{reportHeader.business_name}</Text>
                  <Text>{reportHeader.ho_address}</Text>
                  {setting.is_print_branch_address === true &&
                    <Text>{reportHeader.bo_address}</Text>
                  }
                  <hr style={{paddingBottom: "5px"}}/>
                  
                </View>
              </View>
          <View style={{ flexDirection: 'row'}}>

             {setting.is_print_tag_text === true &&
                <View style={{ textAlign: 'right', width: '100%', alignItems: 'right'}}>
                { idx === 0 && printParams.find(element => (element.code === 'Print_TagText_1stcopy')) &&
                  <Text style={{paddingLeft: "240px"}}>{(printParams.find(element => (element.code === 'Print_TagText_1stcopy'))).text}</Text>
                   }

                 { idx === 1 && printParams.find(element => (element.code === 'Print_TagText_2ndcopy')) &&
                  <Text style={{paddingLeft: "240px"}}>{(printParams.find(element => (element.code === 'Print_TagText_2ndcopy'))).text}</Text>
                   }

                 { idx === 2 && printParams.find(element => (element.code === 'Print_TagText_3rdcopy')) &&
                  <Text style={{paddingLeft: "240px"}}>{(printParams.find(element => (element.code === 'Print_TagText_3rdcopy'))).text}</Text>
                   }
                </View>
                 }
              </View>
                 
            
           
              
            <View style={{ width: "100%", alignItems: "center", textAlign: 'center', borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR, paddingTop: "2px" }}>
              <Text style={{ fontSize: "12px", textAlign: 'center', alignContent: 'center', fontFamily: 'Helvetica-Bold'}}>{reportTitle.rpt_title}</Text>
            </View>
            <hr style={{paddingBottom: "1px"}}/>

            <View style={styles.tableContainer}>

            <View style={{ width: "100%", alignItems: "left", textAlign: 'left', borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR, paddingTop: "2px" }}>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica-Bold'}}>Inward DC</Text>
            </View>
            <hr style={{paddingBottom: "1px"}}/>

           <View style={{flexDirection: "row", borderBottomWidth: BORDER_WIDTH, borderBottomColor: BORDER_COLOR, borderLeftWidth: BORDER_WIDTH, borderLeftColor: BORDER_COLOR}}>
          <View style={{width: "35%", height: '30px', flexDirection: "row",borderRightWidth: BORDER_WIDTH, borderRightColor: BORDER_COLOR}}>  
           <Text style={{textAlign: 'left', width: "40%", borderRightWidth: BORDER_WIDTH, borderRightColor: BORDER_COLOR, paddingLeft: 4}}>Inward DC No.{"\n"}{dC.dc_no}</Text>
           <Text style={{textAlign: 'left', width: "60%", paddingLeft: 4}}>Inward DC Date{"\n"}{dC.dc_date}</Text>
           </View>
           <View style={{width: "30%", height: '30px',borderRightWidth: BORDER_WIDTH, borderRightColor: BORDER_COLOR}}>
           <Text style={{textAlign: 'left', width: "60%", paddingLeft: 4}}>Customer{"\n"}{dC.customer_name}</Text>
           </View>
           <View style={{width: "35%", height: '30px', flexDirection: "row",borderRightWidth: BORDER_WIDTH, borderRightColor: BORDER_COLOR}}>
           <Text style={{textAlign: 'left', width: "50%", borderRightWidth: BORDER_WIDTH, borderRightColor: BORDER_COLOR, paddingLeft: 4}}>Customer DC Ref No.{"\n"}{dC.cust_indc_no}</Text>
           <Text style={{textAlign: 'left', width: "50%", paddingLeft: 4}}>Customer DC Date{"\n"}{dC.cust_indc_date}</Text>
           </View>
           </View>

           <View style={{ width: "100%", alignItems: "left", textAlign: 'left', paddingTop: "10px" }}>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica-Bold'}}>Inward DC Items</Text>
            </View>
            <hr style={{paddingBottom: "1px"}}/>
          <View style={styles.tableContainer1}>
            <View style={styles.container1}>
                 <Text style={styles.sno} className="align-items-center">S.No</Text>
                 <Text style={styles.item}>Item</Text>
                 <Text style={styles.unit}>Unit</Text>
                 <Text style={styles.total_qty}>Total Qty</Text>
                 <Text style={styles.used_qty}>Used Qty</Text>
                 <Text style={styles.yield_qty}>DC Qty</Text>
                 <Text style={styles.balance_qty}>Balance Qty</Text>
                 </View>
                
                 <View style={{flexGrow: 1}}>
              {INDCItems.map((item, idx) => (
              <View style={styles.row} key={`key-${idx}`}>
                    <Text style={{...styles.snoData, height: (item.item_name.length + SIZE_OF_NEWLINE) > MAX_CHAR_PER_ROW_DATA ? (((item.item_name.length + SIZE_OF_NEWLINE) / MAX_CHAR_PER_ROW_DATA) * itemRowHeight) : styles.snoData.height}}> {idx + 1}</Text>
                    <Text style={{...styles.itemData, height: (item.item_name.length + SIZE_OF_NEWLINE) > MAX_CHAR_PER_ROW_DATA ? (((item.item_name.length  + SIZE_OF_NEWLINE) / MAX_CHAR_PER_ROW_DATA) * itemRowHeight) : styles.itemData.height}}>{item.item_name}</Text>
                    <Text style={{...styles.unitData, height: (item.item_name.length + SIZE_OF_NEWLINE) > MAX_CHAR_PER_ROW_DATA ? (((item.item_name.length  + SIZE_OF_NEWLINE) / MAX_CHAR_PER_ROW_DATA) * itemRowHeight) : styles.unitData.height}}>{item.unit}</Text>
                    <Text style={{...styles.total_qtyData, height: (item.item_name.length  + SIZE_OF_NEWLINE) > MAX_CHAR_PER_ROW_DATA ? (((item.item_name.length  + SIZE_OF_NEWLINE) / MAX_CHAR_PER_ROW_DATA) * itemRowHeight) : styles.total_qtyData.height}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.qty_total).toFixed(2)))}</Text>
                    <Text style={{...styles.used_qtyData, height: (item.item_name.length  + SIZE_OF_NEWLINE) > MAX_CHAR_PER_ROW_DATA ? (((item.item_name.length  + SIZE_OF_NEWLINE) / MAX_CHAR_PER_ROW_DATA) * itemRowHeight) : styles.used_qtyData.height}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.qty_used).toFixed(2)))}</Text>
                    <Text style={{...styles.yield_qtyData, height: (item.item_name.length + SIZE_OF_NEWLINE) > MAX_CHAR_PER_ROW_DATA ? (((item.item_name.length  + SIZE_OF_NEWLINE) / MAX_CHAR_PER_ROW_DATA) * itemRowHeight) : styles.yield_qtyData.height}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.qty_yield).toFixed(2)))}</Text>
                    <Text style={{...styles.balance_qtyData, height: (item.item_name.length + SIZE_OF_NEWLINE) > MAX_CHAR_PER_ROW_DATA ? (((item.item_name.length  + SIZE_OF_NEWLINE) / MAX_CHAR_PER_ROW_DATA) * itemRowHeight) : styles.balance_qtyData.height}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.qty_balance).toFixed(2)))}</Text>
              </View>
               ))}    
              
              </View>
              </View>

              <View style={{ width: "100%", alignItems: "left", textAlign: 'left', paddingTop: "10px" }}>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica-Bold'}}>Outward DC & Items</Text>
            </View>
            <hr style={{paddingBottom: "1px"}}/>
            <View style={styles.tableContainer2}>
            <View style={styles.container2}>
                 <Text style={styles.sno2} className="align-items-center">S.No</Text>
                 <Text style={styles.no}>DC No.</Text>
                 <Text style={styles.date}>DC Date</Text>
                 <Text style={styles.item2}>Item</Text>
                 <Text style={styles.unit2}>Unit</Text>
                 {/*<Text style={styles.qty2}>Used Qty</Text>*/}
                 <Text style={styles.qty2_yield}>DC Qty</Text>
                 </View>
                
                 <View style={{flexGrow: 1}}>
              {OUTDCItems.map((item, idx) => (
              <View style={styles.row} key={`key-${idx}`}>
                    <Text style={{...styles.sno2Data, height: (item.item_name.length + SIZE_OF_NEWLINE) > MAX_CHAR_PER_ROW_DATA ? (((item.item_name.length + SIZE_OF_NEWLINE) / MAX_CHAR_PER_ROW_DATA) * itemRowHeight) : styles.snoData.height}}> {idx + 1}</Text>
                    <Text style={{...styles.noData, height: (item.item_name.length + SIZE_OF_NEWLINE) > MAX_CHAR_PER_ROW_DATA ? (((item.item_name.length  + SIZE_OF_NEWLINE) / MAX_CHAR_PER_ROW_DATA) * itemRowHeight) : styles.noData.height}}>{item.dc_no}</Text>
                    <Text style={{...styles.dateData, height: (item.item_name.length  + SIZE_OF_NEWLINE) > MAX_CHAR_PER_ROW_DATA ? (((item.item_name.length  + SIZE_OF_NEWLINE) / MAX_CHAR_PER_ROW_DATA) * itemRowHeight) : styles.dateData.height}}>{item.dc_date}</Text>
                    <Text style={{...styles.item2Data, height: (item.item_name.length + SIZE_OF_NEWLINE) > MAX_CHAR_PER_ROW_DATA ? (((item.item_name.length  + SIZE_OF_NEWLINE) / MAX_CHAR_PER_ROW_DATA) * itemRowHeight) : styles.item2Data.height}}>{item.item_name}</Text>
                    <Text style={{...styles.unit2Data, height: (item.item_name.length  + SIZE_OF_NEWLINE) > MAX_CHAR_PER_ROW_DATA ? (((item.item_name.length  + SIZE_OF_NEWLINE) / MAX_CHAR_PER_ROW_DATA) * itemRowHeight) : styles.unit2Data.height}}>{item.unit}</Text>
                    {/*<Text style={{...styles.qty2Data, height: (item.item_name.length + SIZE_OF_NEWLINE) > MAX_CHAR_PER_ROW_DATA ? (((item.item_name.length  + SIZE_OF_NEWLINE) / MAX_CHAR_PER_ROW_DATA) * itemRowHeight) : styles.qty2Data.height}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.qty_used).toFixed(2)))}</Text>*/}
                    <Text style={{...styles.qty2_yieldData, height: (item.item_name.length + SIZE_OF_NEWLINE) > MAX_CHAR_PER_ROW_DATA ? (((item.item_name.length  + SIZE_OF_NEWLINE) / MAX_CHAR_PER_ROW_DATA) * itemRowHeight) : styles.qty2_yieldData.height}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.jw_yield_qty).toFixed(2)))}</Text>
              </View>
               ))}    
              
              </View>
              </View>
                 
              {/*
              <View style={{flexDirection: "row"}}>
            <View>
            { printParams.find(element => (element.code === 'ForBusinessName')) &&
              
              <Text style={{paddingLeft: "420px", paddingTop: "2px"}} >For {JSON.parse(localStorage.getItem('selected_business')).label}</Text>
              
              }
            </View>

            </View>

            <View style={{flexDirection: "row"}}>
            <View> 
              { printParams.find(element => (element.code === 'CustomerSealSignature')) &&
              <Text style={{paddingTop: "20px", paddingBottom: "-30px", paddingRight: "0px"}} >{(printParams.find(element => (element.code === 'CustomerSealSignature'))).text}</Text>
              }
              </View>
              
              <View>
              { printParams.find(element => (element.code === 'AuthorisedSignatory')) &&
              <Text style={{paddingTop: "20px", paddingLeft: "320px"}} >{(printParams.find(element => (element.code === 'AuthorisedSignatory'))).text}</Text>
              }              
            </View>
            
            </View>
            */}
              
             
              </View>
              
              
            <hr style={{paddingBottom: "10px"}}/>
             
            {/*
            <View style={{flexDirection: 'row'}}>
            {setting.is_print_datetime === true &&
            <Text style={{textAlign: 'left', flex: 1}} render={({ pageNumber, totalPages }) => (
                `Printed On: ${formatPrintDate(new Date())}`
            )} fixed />
            }

            {printParams.find(element => element.code === "Note_ComputerGenInvoice") !== undefined &&
             <Text style={{ textAlign: 'center', paddingTop: '0px', flex: 1}} >{printParams.find(element => element.code === "Note_ComputerGenInvoice").text}</Text>
             }
             
             {setting.is_print_pageno === true &&
              <Text style={{flex: 1, textAlign: 'right', justifyContent: 'flex-end', alignSelf: 'flex-end'}} render={({ pageNumber, totalPages }) => (
                `Page No : ${pageNumber} / ${totalPages}`
              )} fixed />
               }

              </View>

              {setting.is_print_endofrpttext === true &&
              <View>
              <Text style={{ textAlign: 'center', paddingTop: '36px', flex: 1}} render={({ pageNumber, totalPages }) => (
                <View> {pageNumber === totalPages &&
                <Text>.... End of the document ....</Text>
                 }
                </View> 
                 )}  />
              </View>
              }
            */}

          </Page>
          ))}
        </Document>
      }
    </PDFViewer>
  );
}

export default PrintCustomerDCRecon;