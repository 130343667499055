import React, { Fragment, useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
//import Nav from 'react-bootstrap/Nav';
import {Nav, NavItem, Navbar, NavDropdown, Dropdown, Badge, DropdownButton, MenuItem, Col, Row, Modal} from 'react-bootstrap';
//import { Glyphicon } from '../GlyphiconToFa';
//import { RBGlyphicon } from 'react-bootstrap';
//import { ArrowRight } from 'react-bootstrap-icons';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Select from "react-select";
import styles from '../sideBarMenu.css';

//import { withRouter } from "react-router";
import '../Dashboard.css'
import '../App.css';

import logo_avs from '../assets/images/logo_avs.png';
import avina_shop from '../assets/images/avina_shop.png';
import business_logo from '../assets/images/business_logo.png';
import app_logo from '../assets/images/app_logo.png';

import { useNavigate } from "react-router-dom";

import { client, buildType, COMPANY_NAME } from '../config/Config';
import axios from 'axios';

//import {handleLogoutClick} from './utils';

import {BsFillPersonVcardFill, BsPower} from 'react-icons/bs';
import { IoSettings } from "react-icons/io5";

const SidebarMain = props => {

  console.log("PASSED PROPS <<<<<<<<<<<< : ", props);
  const [show, setShow] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  
  const [showMsg, setShowMsg] = useState('Unable to login');
  const [showMsgTitle, setShowMsgTitle] = useState('Login FAILED!');

  const projectArr = [
    {value: "GKB Forgings", label: "GKB Forgings"},
    {value: "Kurinji Forging", label: "Kurinji Forging"},
    {value: "Avinasoft Technologies", label: "Avinasoft Technologies"}
  ];
  const [companyIdSelect, setCompanyIdSelect] = useState(projectArr[0]);
  const [optCompany, setOptCompany] = useState(projectArr);

  const rcArray = [
    "Rockbreeze Technologies Private Limited",
    "TrackMQ technologies",
    "AntonRock Investments"
  ];

  const branchArray = [
    "Avinashi",
    "Tiruppur",
    "Coimbatore"
  ];
  const [businessList, setBusinessList] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState({});
  //const [selectedRc, setSelectedRc] = useState({});
  const [branchList,setBranchList] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    console.log("UseEffect called : ", props.passedFrom);
    
    var businessData = localStorage.getItem('business');
    //console.log("BUSINESS OBJECT IN STRING : ", businessData);
    var businessObj = JSON.parse(businessData);
    console.log("BUSINESS DATA IN OBJECT : ", businessObj);
    setBusinessList(businessObj);
    
    var selectedBusinessIdFromLs = 0;
    if ( localStorage.getItem("selected_business") === null || localStorage.getItem("selected_business") === undefined) {
      setSelectedBusiness(businessObj[0]);
      localStorage.setItem("selected_business", JSON.stringify(businessObj[0]));
      props.sendBacktoParent({business_id: businessObj[0].value, addl: businessObj[0].gstin});
      selectedBusinessIdFromLs = businessObj[0].value;
    }
    else {
      setSelectedBusiness(JSON.parse(localStorage.getItem("selected_business")));      
      console.log("<<<<<<<<<<<<< SELECTED AND STORED BUSINESS VALUE : ", JSON.parse(localStorage.getItem("selected_business")).value);
      props.sendBacktoParent({business_id: JSON.parse(localStorage.getItem("selected_business")).value, addl: JSON.parse(localStorage.getItem("selected_business")).gstin});
      selectedBusinessIdFromLs = parseInt(JSON.parse(localStorage.getItem("selected_business")).value);
    }

    var branchesData = localStorage.getItem('branches');
    //console.log("BRANCH OBJECT IN STRING : ", branchesData);
    var branchesObj = JSON.parse(branchesData);
    
    setBranchList(branchesObj);

    var branchData = localStorage.getItem('branches');
    var branchObj = JSON.parse(branchData);
    console.log("BRANCH ARRAY OBJECT : ", branchObj);
    var newBranchArr = [];
    branchObj.forEach(function(branchInfo){
      if ( branchInfo.bid === selectedBusinessIdFromLs ) {
        console.log("FILTERED BRANCH : ", branchInfo);
        newBranchArr.push(branchInfo);
      }
    })
    console.log("<<<<<<<<<<<<<+++++++++++++= FILETERED BRACHLIST *********** : ", newBranchArr);
    setBranchList(newBranchArr);

    if ( localStorage.getItem("selected_branch") === null ) {
      console.log("((((((((((((( BRANCH NOT SET BY DEFAULT )))))))))))))");
      if ( newBranchArr.length > 0 ) {
          setSelectedBranch(newBranchArr[0]);
          localStorage.setItem("selected_branch", JSON.stringify(newBranchArr[0]));
          props.sendBacktoParent({branch_id: newBranchArr[0].value});
        }
    }
    else {
      console.log("^^^^^^^^^^^^^^ SELECTED BRANCH *******************: ", localStorage.getItem("selected_branch"));
      setSelectedBranch(JSON.parse(localStorage.getItem("selected_branch")));
      props.sendBacktoParent({branch_id: (JSON.parse(localStorage.getItem("selected_branch"))).value});
    }
  }, []);
  
  const handleLogoutClick = async() => {
    console.log("Logout button clicked ...");
    setShowDialog(true);
  
    //callLogout();
  };

  const handlePostSaveOk = () => {
    console.log("user confirmed to customer added ...");
    callLogout();
    setShowDialog(false);
    //navigate("/listSuppliers");
  };

  const handleCancel = () => {
    console.log("user confirmed to customer added ...");
    setShowDialog(false);
    navigate(0);
  };
    
  const callLogout = async () => {
    var aIFromLocalStorage = localStorage.getItem('_aI');
    var usernameFromLocalStorage = localStorage.getItem('username');
    console.log("username : ", usernameFromLocalStorage);
    console.log("aI : ", aIFromLocalStorage);

    console.log("username : ", usernameFromLocalStorage);
    console.log("jwt token  : ", aIFromLocalStorage);
    
    let reqLogoutPayload = {
      //username : usernameFromLocalStorage, 
      //aI : aIFromLocalStorage
    };
  
      //var bearerToken = `Bearer ${aIFromLocalStorage}`;
      var headers = { ...client.headers, "Authorization": `Bearer ${aIFromLocalStorage}`};
      console.log("payload:", reqLogoutPayload);

      try {
      var res = await axios.post(client.domain + '/core/logout', reqLogoutPayload, { headers }, { withCredentials: false });
      //.then((res) => {
          //console.log("ADD New user res:", res);
          //localStorage.setItem('_token', res.data.res_data.token);
          console.log("Response from server : ", res.data.detail);
          if ( res.data.detail.status === 'SUCCESS') {
            console.log("Response message from server : ", res.data.detail.res_data);
            console.log("Response message from server userInfo : ", res.data.detail.res_data.user_info);
            //console.log("Response message from server aI : ", res.data.detail.res_data.aI);

            // Remove login session from localstorage
            localStorage.removeItem("username");
            localStorage.removeItem("_aI");
            localStorage.removeItem("_aP");

            navigate("/auth");
          }
         
        }
        catch(error) {
          console.log("FULL ERROR : ", error);
          if ( error.code === 'ERR_NETWORK' ) {
            console.log("Not able to reach target server please try after sometime");
          }
          else{
            console.log("ERROR : ", error.response.data.detail);
            if (error.response.data.detail.status === 'FAILED') {
              console.log('Request failed due to ', error.response.data.detail.res_data);
	      setShowMsg(error.response.data.detail.res_data);
              setShowMsgTitle('LOGIN FAILED!');
	      setShow(true);
            }
            navigate("/auth");
          }
        }
  };

  const handleProfile = async(e) => {
    console.log("handle profile clicked : ", e);
  }

  const handleCompanySelect = async(e) => {
    console.log("handleCompanySelect called ...", e);

    setCompanyIdSelect(e);
  }
  
   
    //const handleSelect = (eventKey) => {
    //    alert(`selected ${eventKey}`);
    //}
    
  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);

  /*
  menuSettings.map((settiing) =>{

  })
  */
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        console.log("Event :", e);
        console.log("Children :", children );
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </a>
  ));
  
  /*
  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');
  
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </ul>
        </div>
      );
    },
  );
  */

  const handleSelect = async(evt) => {
    // what am I suppose to write in there to get the value?
    console.log("handleSelect called : ", evt);
    console.log("handleSelect called : ", evt.currentTarget.value);
}

const handleClick = async(evt) => {
  console.log("handleClick called : ", evt);
  console.log("handleClick called EVENT TARGET : ", evt.target);
  console.log("handleSelect called at  handleClick : ", evt.target.innerText);
  console.log("handleSelect called at  handleClick a tag id : ", evt.target.name);

  console.log("handleBranchSelect called at  handleClick currentTarget : ", evt.target);
  console.log("handleBranchSelect called at  handleClick currentTarget ID: ", evt.target.id);

  var tagId = evt.target.id;
  var currentRowName = tagId.substring(tagId.lastIndexOf("-") + 1);
  var nameOfElement = tagId.substring(0, tagId.lastIndexOf("-"));
  console.log("Name of element : ", nameOfElement);
  console.log("Current row id : ", currentRowName);

  
  if ( nameOfElement === 'business-dn-item') {
    var tagAddl = evt.target.getAttribute("addl");
    console.log("<<<<<<<<<<<<<<<< ADDITIONAL PARAM IN HTML TAG : ", tagAddl);
    var currentRowNameAddl = tagAddl.substring(tagAddl.lastIndexOf("-") + 1);
    var nameOfElementAddl = tagAddl.substring(0, tagAddl.lastIndexOf("-"));
    console.log("Name of element Addl : ", nameOfElementAddl);
    console.log("Current row id Addl : ", currentRowNameAddl);

    setSelectedBusiness({value: currentRowName, label : evt.target.innerText});
    var businessList = localStorage.getItem("business");
    var businessObj = JSON.parse(businessList);
    var findBusiness = businessObj.find(element => element.value === parseInt(currentRowName));
    console.log("**************** findBUSINESS value at handleClick at SidebarMenu ++++++++++++ : ", findBusiness);
    if (findBusiness !== undefined) {
      console.log("SEACHED BUSINESS WHILE SELECTING BUSINESS : ", findBusiness);
    }
    navigate(0);

    //localStorage.setItem("selected_business", JSON.stringify({value: currentRowName, label : evt.target.innerText}));
    localStorage.setItem("selected_business", JSON.stringify(findBusiness));
    props.sendBacktoParent({business_id: currentRowName, addl: currentRowNameAddl});

    var branchData = localStorage.getItem('branches');
    var branchObj = JSON.parse(branchData);
    console.log("BRANCH ARRAY OBJECT : ", branchObj);
    var newBranchArr = [];
    branchObj.forEach(function(branchInfo){
      if ( branchInfo.bid === parseInt(currentRowName)) {
        console.log("FILTERED BRANCH : ", branchInfo);
        newBranchArr.push(branchInfo);
      }
    })
    console.log("<<<<<<<<<<<<<+++++++++++++= FILETERED BRACHLIST *********** : ", newBranchArr);
    setBranchList(newBranchArr);

    if ( newBranchArr.length > 0 ) {
      setSelectedBranch(newBranchArr[0]);
      localStorage.setItem("selected_branch", JSON.stringify(newBranchArr[0]));
      props.sendBacktoParent({branch_id: newBranchArr[0].value});
    }
    else {
      setSelectedBranch({});
      localStorage.setItem("selected_branch", JSON.stringify({}));
    }
    
  }
  //navigate(0);
}


const handleBranchClick = async(evt) => {
  console.log("handleBranchClick called : ", evt);
  console.log("handleClick called EVENT TARGET : ", evt.target);
  console.log("handleBranchSelect called at  handleClick : ", evt.target.innerText);
  console.log("handleBranch Selected called at  handleClick a tag id : ", evt.target.name);
  console.log("handleBranchSelect called at  handleClick currentTarget : ", evt.currentTarget);
  console.log("handleBranchSelect called at  handleClick currentTarget ID: ", evt.currentTarget.id);

  var tagId = evt.target.id;
  var currentRowName = tagId.substring(tagId.lastIndexOf("-") + 1);
  var nameOfElement = tagId.substring(0, tagId.lastIndexOf("-"));
  console.log("Name of element : ", nameOfElement);
  console.log("Current row id : ", currentRowName);

  props.sendBacktoParent({branch_id: currentRowName});
  setSelectedBranch({value: currentRowName, label : evt.target.innerText});
  localStorage.setItem("selected_branch", JSON.stringify({value: currentRowName, label : evt.target.innerText}));
}
    return (      
      <>        
        <Navbar inverse fluid key="sm" expand="sm" bg="secondary" className="bg-body-tertiary mb-3"  style={{borderBottom: '1px solid gray'}}>
        
          <Container fluid>
         
            <Navbar.Brand href={client.urlDelimiter}>
            <img src={business_logo} height="64" width="64" alt="Avinasoft"/>
            </Navbar.Brand>
             {/*<Navbar.Brand href={client.urlDelimiter}>{companyIdSelect.value}
            </Navbar.Brand>
            */}

           {/*
            <div style={{width: '250px'}}>
              
                <Select className="dropdown-button"
                      inline
                      id="company"
                      name="company"
                      value={{
                        value: companyIdSelect.value,
                        label: companyIdSelect.label,
                      }}
                      options={optCompany}
                      defaultValue={{
                        value: companyIdSelect.value,
                        label: companyIdSelect.label,
                      }}
                      onChange={handleCompanySelect}
                      //size="sm"
                      //style={{customStyles}}
                      //isDisabled={isReadOnly}
                    />
                    </div>
            */}

              {/*
              <Nav className="flex-column">  
                  
                 <NavDropdown
                    title={selectedRc}
                    id={`rc-dropdown-expand-sm`}
                    onClick={handleClick}
                  > 
                    <NavDropdown.Item >Rockbreeze Technologies Private Limited</NavDropdown.Item>
                    <NavDropdown.Item >TrackMQ Technologies</NavDropdown.Item>
                    <NavDropdown.Item >AntonRock Investments</NavDropdown.Item>
                    
                    </NavDropdown>
                        
                <NavDropdown
                    title={branch}
                    id={`branch-dropdown-expand-sm`}
                    onClick={handleBranchClick}
                    //className="justify-content-start flex-grow-1"
                    //style={{textAlign: "left", alignItems: "left", disply:'flex', justifyContent:'left'}}
                  > 
                    <NavDropdown.Item >Avinashi</NavDropdown.Item>
                    <NavDropdown.Item >Coimbatore</NavDropdown.Item>
                    <NavDropdown.Item >Tiruppur</NavDropdown.Item>
                    
                    </NavDropdown>
                
                </Nav>
          */}
          <Navbar.Offcanvas
              id={`offcanvas-rc-expand-sm`}
              aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
              
              placement="start"
            >
              <Offcanvas.Header closeButton>
                {/*<Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>*/}
                
                    {/*
                  {COMPANY_NAME}
                  <span style={{alignItems: 'right', textAlign: 'right', fontSize: '8px !important' }}>Logged In: {localStorage.getItem("dis_name")}</span>
                    */}
                  {/*</Offcanvas.Title>*/}

              </Offcanvas.Header>
              <Offcanvas.Body  key={'canvas-1'}>
              <Nav key={"logged-disp-name"} className="justify-content-start flex-grow-1" >
              {/*<Button size="sm" variant="outline-info" id="profile-name" style={{fontSize: '12px', verticalAlign: '-2px', marginTop: '8px'}}>Welcome to {localStorage.getItem("dis_name")}</Button>*/}
              <NavDropdown
                    title={selectedBusiness.label}
                    id={`business-dn`}
                    onClick={handleClick}
                  > 
                  {businessList.map((business, idx) => (
                    <NavDropdown.Item id={`business-dn-item-${business.value}`} name={`business-dn-item-${business.value}`} addl={`business-dn-item-${business.gstin}`}>{business.label}</NavDropdown.Item>
                  ))
                  }
                    {/*<NavDropdown.Item >Rockbreeze Technologies Private Limited</NavDropdown.Item>
                    <NavDropdown.Item >TrackMQ Technologies</NavDropdown.Item>
                    <NavDropdown.Item >AntonRock Investments</NavDropdown.Item>*/}
                    
                    </NavDropdown>
              </Nav>
              </Offcanvas.Body>

              <Offcanvas.Body  key={'canvas-2'}>
              <Nav key={"logged-disp-name"} className="justify-content-start" >
              { branchList.length > 0 &&
              <NavDropdown
                    title={selectedBranch.label}
                    id={`branch-dn-item`}
                    onClick={handleBranchClick}
                    //className="justify-content-start flex-grow-1"
                    //style={{textAlign: "left", alignItems: "left", disply:'flex', justifyContent:'left'}}
                  > 
                  {branchList.map((branch, idx) => (
                    <NavDropdown.Item id={`branch-dn-item-${branch.value}`} name={`branch-dn-item-${branch.value}`}>{branch.label}</NavDropdown.Item>
                    ))
                  }   
                  </NavDropdown>
                  }
              </Nav>
              </Offcanvas.Body>

              </Navbar.Offcanvas>
            {/*<Navbar.Brand href={client.urlDelimiter}>{COMPANY_NAME}
            </Navbar.Brand>
            */}
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-sm`}
              aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
              
              placement="end"
            >
              <Offcanvas.Header closeButton>
                {/*<Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>*/}
                
                    {/*
                  {COMPANY_NAME}
                  <span style={{alignItems: 'right', textAlign: 'right', fontSize: '8px !important' }}>Logged In: {localStorage.getItem("dis_name")}</span>
                    */}
                  {/*</Offcanvas.Title>*/}

              </Offcanvas.Header>
              <Offcanvas.Body  key={'canvas-1'}>
         
              <Nav key={"logged-disp-name"} className="justify-content-end flex-grow-1" style={{height: "36px"}} >
              {/*<Button size="sm" variant="outline-info" id="profile-name" style={{fontSize: '12px', verticalAlign: '-2px', marginTop: '8px'}}>Welcome to {localStorage.getItem("dis_name")}</Button>*/}
              <Form.Label style={{ color:"gray", paddingTop: "9px", fontWeight: "500"}}>Logged In:  {localStorage.getItem("dis_name")}</Form.Label>
              <div style={{ color: '#C0C0C0',paddingTop: "6px"}}>&nbsp;|&nbsp;</div>
              
              {/*<Form.Label style={{ color:"gray", paddingTop: "3px"}}> Help </Form.Label>*/}
              <div style={{paddingTop: "0px"}}>
              <NavDropdown 
              
                        title = "Help" 
                        style={{cursor: 'pointer',color:"gray", fontWeight: "500"}}
                        id="navdropdown-help"
                        className="dropdown1-toggle">
                    <NavDropdown.Item >Version</NavDropdown.Item> 
                    <NavDropdown.Item href={`${client.urlDelimiter}privacyPolicy`}>Privacy Policy</NavDropdown.Item> 
                </NavDropdown>
                </div>
              {/*<div style={{ color: '#C0C0C0',paddingTop: "6px"}}>&nbsp;|&nbsp;</div>*/}
              {/*<Nav.Link onClick={()=> handleLogoutClick()}><BsPower size={20} style={{cursor: 'pointer', color: 'red', borderLeftWidth: 1}}/></Nav.Link>*/}
              {/*<img src={app_logo} height="32" width="32" alt="Avinasoft" style={{paddingTop: "5px"}}/>*/}
              </Nav>
             
              </Offcanvas.Body>
              
             
              <Offcanvas.Body key={'canvas-2'}>
                <Nav key={`nav-for-checkin-button-${Math.random()}`}>
                  {/*
                <Button
                  key={`checkin-button-${Math.random()}`}
                  variant="success"
                  size="sm"
                  id="add-btn-id"
                  name="add-btn-name"
                  className="justify-content-start"
                  //onClick={(e) => handleAdd(e)}
                >
                  Check In
                </Button>
               */}
               
                </Nav>
                
               
                
              <Nav key={"usermenu"} className="justify-content-end flex-grow-1">
                  {/*<Button size="sm" variant="outline-info" id="profile-name" style={{fontSize: '12px', verticalAlign: '-2px', marginTop: '8px'}}>Check In</Button>*/}
               
                  
               
                &nbsp;&nbsp;
                <NavDropdown
                    title="Manage"
                    id={`offcanvasNavbarDropdown-expand-sm`}
                  > 
                    {/*<NavDropdown.Item href={`${client.urlDelimiter}listUnits`}>Units</NavDropdown.Item>*/}
                    {menuSettings.permissions.find(element => (element.code === 'listItems' && element.access === true)) && 
                      <NavDropdown.Item href={`${client.urlDelimiter}listItems`}>{menuSettings.permissions.find(element => (element.code === 'listItems' && element.access === true)).menu_text}</NavDropdown.Item>
                    }
                    {menuSettings.permissions.find(element => (element.code === 'listCustomers' && element.access === true)) && 
                      <NavDropdown.Item href={`${client.urlDelimiter}listCustomers`}>{menuSettings.permissions.find(element => (element.code === 'listCustomers' && element.access === true)).menu_text}</NavDropdown.Item>
                    }
                    {menuSettings.permissions.find(element => (element.code === 'listSuppliers' && element.access === true)) && 
                      <NavDropdown.Item href={`${client.urlDelimiter}listSuppliers`}>{menuSettings.permissions.find(element => (element.code === 'listSuppliers' && element.access === true)).menu_text}</NavDropdown.Item>
                    }
                    {menuSettings.permissions.find(element => (element.code === 'listBankAccounts' && element.access === true)) &&  
                      <NavDropdown.Item href={`${client.urlDelimiter}listBankAccounts`}>{menuSettings.permissions.find(element => (element.code === 'listBankAccounts' && element.access === true)).menu_text}</NavDropdown.Item> 
                    }
                    </NavDropdown>

                    <NavDropdown
                    title="Sales"
                    id={`offcanvasNavbarDropdown-expand-sm`}
                  > 
                    {menuSettings.permissions.find(element => (element.code === 'listInvoices' && element.access === true)) &&
                      <NavDropdown.Item href={`${client.urlDelimiter}listInvoices`}>{menuSettings.permissions.find(element => (element.code === 'listInvoices' && element.access === true)).menu_text}</NavDropdown.Item>
                    }

                    {menuSettings.permissions.find(element => (element.code === 'listDeliveryNotes' && element.access === true)) &&
                      <NavDropdown.Item href={`${client.urlDelimiter}listDeliveryNotes`}>{menuSettings.permissions.find(element => (element.code === 'listDeliveryNotes' && element.access === true)).menu_text}</NavDropdown.Item>
                    } 

                    {menuSettings.permissions.find(element => (element.code === 'listCustomerBills' && element.access === true)) &&
                      <NavDropdown.Item href={`${client.urlDelimiter}listCustomerBills`}>{menuSettings.permissions.find(element => (element.code === 'listCustomerBills' && element.access === true)).menu_text}</NavDropdown.Item>
                    }

                    {menuSettings.permissions.find(element => (element.code === 'listCustReceipts' && element.access === true)) &&
                      <NavDropdown.Item href={`${client.urlDelimiter}listCustReceipts`}>{menuSettings.permissions.find(element => (element.code === 'listCustReceipts' && element.access === true)).menu_text}</NavDropdown.Item>
                    } 
                    </NavDropdown>


                    <NavDropdown
                    title="Purchase"
                    id={`offcanvasNavbarDropdown-expand-sm`}
                  > 
                    {/*<NavDropdown.Item disabled={menuSettings.permissions.find(element => (element.code === 'listSupplierBills' && element.access === true)) ? false : true} href={`${client.urlDelimiter}listSupplierBills`}>Supplier Bills</NavDropdown.Item>*/}
                    {menuSettings.permissions.find(element => (element.code === 'listSuppInvoices' && element.access === true)) &&
                      <NavDropdown.Item href={`${client.urlDelimiter}listSuppInvoices`}>{menuSettings.permissions.find(element => (element.code === 'listSuppInvoices' && element.access === true)).menu_text}</NavDropdown.Item>
                    }
                    {menuSettings.permissions.find(element => (element.code === 'listSuppPayments' && element.access === true)) &&
                      <NavDropdown.Item href={`${client.urlDelimiter}listSuppPayments`}>{menuSettings.permissions.find(element => (element.code === 'listSuppPayments' && element.access === true)).menu_text}</NavDropdown.Item>
                    }
                    <NavDropdown.Item href={`${client.urlDelimiter}listInwardDC`}>Inward DC</NavDropdown.Item>
                    </NavDropdown>
               
                    <NavDropdown
                    title="Reports"
                    id={`offcanvasNavbarDropdown-expand-sm`}
                    
                  > 
                    {menuSettings.permissions.find(element => (element.code === 'viewCustomerOS' && element.access === true)) && 
                      <NavDropdown.Item href={`${client.urlDelimiter}viewCustomerOS`}>{menuSettings.permissions.find(element => (element.code === 'viewCustomerOS' && element.access === true)).menu_text}</NavDropdown.Item>
                    }
                    {menuSettings.permissions.find(element => (element.code === 'viewSupplierOS' && element.access === true)) && 
                      <NavDropdown.Item href={`${client.urlDelimiter}viewSupplierOS`}>{menuSettings.permissions.find(element => (element.code === 'viewSupplierOS' && element.access === true)).menu_text}</NavDropdown.Item>
                    }
                    {/*{menuSettings.permissions.find(element => (element.code === 'viewTaxSummary' && element.access === true)) && 
                      <NavDropdown.Item href={`${client.urlDelimiter}viewTaxSummary`}>{menuSettings.permissions.find(element => (element.code === 'viewTaxSummary' && element.access === true)).menu_text}</NavDropdown.Item>
                    }*/}
                    {menuSettings.permissions.find(element => (element.code === 'viewCustomerLedger' && element.access === true)) && 
                      <NavDropdown.Item href={`${client.urlDelimiter}viewCustomerLedger`}>{menuSettings.permissions.find(element => (element.code === 'viewCustomerLedger' && element.access === true)).menu_text}</NavDropdown.Item>
                    }
                    {menuSettings.permissions.find(element => (element.code === 'viewSupplierLedger' && element.access === true)) && 
                      <NavDropdown.Item href={`${client.urlDelimiter}viewSupplierLedger`}>{menuSettings.permissions.find(element => (element.code === 'viewSupplierLedger' && element.access === true)).menu_text}</NavDropdown.Item>
                    }
                    {menuSettings.permissions.find(element => (element.code === 'viewCustomerINOUTDC' && element.access === true)) && 
                      <NavDropdown.Item href={`${client.urlDelimiter}viewCustomerINOUTDC`}>{menuSettings.permissions.find(element => (element.code === 'viewCustomerINOUTDC' && element.access === true)).menu_text}</NavDropdown.Item>
                    }
                    </NavDropdown>
                    
                <NavDropdown 
                        title = {<span><IoSettings size={20} 
                        style={{cursor: 'pointer', color: '#A0A0A0'}}/></span>}
                        id="navdropdown-profile"
                        className="dropdown1-toggle">
                    {menuSettings.permissions.find(element => (element.code === 'listBusiness' && element.access === true)) && 
                      <NavDropdown.Item href={`${client.urlDelimiter}listBusiness`}>{menuSettings.permissions.find(element => (element.code === 'listBusiness' && element.access === true)).menu_text}</NavDropdown.Item>
                    }
                    {menuSettings.permissions.find(element => (element.code === 'listBranches' && element.access === true)) &&  
                      <NavDropdown.Item href={`${client.urlDelimiter}listBranches`}>{menuSettings.permissions.find(element => (element.code === 'listBranches' && element.access === true)).menu_text}</NavDropdown.Item>
                    }
                    {menuSettings.permissions.find(element => (element.code === 'myProfile' && element.access === true)) && 
                      <NavDropdown.Item href={`${client.urlDelimiter}myProfile`}>{menuSettings.permissions.find(element => (element.code === 'myProfile' && element.access === true)).menu_text}</NavDropdown.Item>
                    }
                  {menuSettings.permissions.find(element => (element.code === 'listDocSettings' && element.access === true)) &&
                    <NavDropdown.Item href={`${client.urlDelimiter}listDocSettings`}>{menuSettings.permissions.find(element => (element.code === 'listDocSettings' && element.access === true)).menu_text}</NavDropdown.Item>
                  }
                </NavDropdown>


            
            {/*<Nav.Link href={`${client.urlDelimiter}/addUpdateProfile`} onClick={(e)=> handleProfile(e)}><BsFillPersonVcardFill size={28}  style={{cursor: 'pointer', color: 'blue'}} /></Nav.Link>*/}
            <Nav.Link onClick={()=> handleLogoutClick()}><BsPower size={20} style={{cursor: 'pointer', color: 'red'}}/></Nav.Link>
                </Nav>
                
              
              </Offcanvas.Body>
            </Navbar.Offcanvas>
            <img src={app_logo} height="64" width="64" alt="Avinasoft" style={{paddingTop: "5px"}}/>
            
          
            <Modal
              show={showDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>Confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>Are you sure want to logout?</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
                
                <Button variant="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
                
              </Modal.Footer>
            </Modal>
                
           
          </Container>
         
         
        </Navbar>
       
    </>
        );
  };
  //const Sidebar = withRouter(Side);
  export default SidebarMain